import React, { PureComponent } from 'react';
import { string, func, shape } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'ramda';
import moment from 'moment';
import {
  showNotification as showNotificationAction,
} from 'admin-on-rest';

import HistoryListComponent from '../../../../../components/HistoryListComponent';
import historyDataMapper from './historyDataMapper';
import EmptyState from '../../../../../components/EmptyState';
import { getApplicationHistory } from '../../../../../api/history';
import sorter from '../../../../../utils/sorter';

class HistoryList extends PureComponent {
  static propTypes = {
    showNotification: func.isRequired,
    match: shape({
      params: shape({
        id: string,
      })
    }).isRequired,
  };

  state = { historyData: [] };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { match, showNotification } = this.props;

    try {
      const { id } = match.params;

      const { data } = await getApplicationHistory(id);

      if (!data) {
        return;
      }

      const sortedData = sorter(data, {direction: 'desc', field: 'updatedAt'}, moment);
      const historyData = (
        sortedData
          .map(item => historyDataMapper(item))
          .filter(item => item)
      );

      this.setState({ historyData });
    } catch (error) {
      showNotification('Something went wrong', 'warning');
    }
  };

  renderHistoryList = (historyData) => (
    <HistoryListComponent
      historyData={historyData}
    />
  );

  renderEmptyState = () => (
    <EmptyState
      title="No history for current application"
    />
  );

  render() {
    const { historyData } = this.state;

    if (!historyData.length) {
      return <EmptyState
        title="No history for current application"
      />;
    }

    const content = historyData.length
      ? this.renderHistoryList(historyData)
      : this.renderEmptyState();

    return content;
  }
}

const mapDispatchToProps = {
  showNotification: showNotificationAction,
};

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(HistoryList);
