const DATE_FORMAT_1 = 'M/D/YYYY, h:mm:ss A';
const DATE_FORMAT_2 = 'MMMM D, YYYY h:mm a';
const DATE_FORMAT_3 = '[at] hh:mm a [on] MM/DD/YYYY';
const DATE_FORMAT_4 = 'MMDDYYYY';
const DATE_FORMAT_5 = 'h:mm a';
const DATE_FORMAT_6 = 'MM/DD/YYYY';
const DATE_FORMAT_7 = 'YYYY-MM-DD';
const DATE_FORMAT_8 = 'MMM D';
const DATE_FORMAT_9 = 'h:mm A';
const DATE_FORMAT_10 = 'MMM D, Y';

export {
  DATE_FORMAT_1,
  DATE_FORMAT_2,
  DATE_FORMAT_3,
  DATE_FORMAT_4,
  DATE_FORMAT_5,
  DATE_FORMAT_6,
  DATE_FORMAT_7,
  DATE_FORMAT_8,
  DATE_FORMAT_9,
  DATE_FORMAT_10,
}
