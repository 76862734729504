import React, { PureComponent } from 'react';
import { string, shape, func, bool } from 'prop-types';
import Clipboard from 'react-clipboard.js';
import { ActionDone } from 'material-ui/svg-icons';

import Button from './Button';

export default class ClipboardButton extends PureComponent {
  static propTypes = {
    component: string,
    copyData: string,
    source: string,
    text: string,
    activeText: string,
    buttonStyle: shape(),
    setSelectedRow: func,
    active: bool,
  };

  static defaultProps = {
    component: 'span',
    copyData: '',
    source: '',
    text: 'Copy',
    activeText: 'Copied',
    buttonStyle: { borderRadius: 20, minWidth: 114, minHeight: 40 },
    active: false,
    setSelectedRow: () => {},
  };

  onSuccess = () => {
    const { setSelectedRow, source } = this.props;

    setSelectedRow(source);
  };

  render() {
    const {
      component,
      copyData,
      text,
      activeText,
      active,
      buttonStyle,
      source,
      ...props
    } = this.props;

    const buttonText = active ? activeText : text;
    const buttonBackground = active ? '#00b291' : '#2755c4';
    const icon = active ? <ActionDone /> : null;

    return (
      <Clipboard
        component={component}
        data-clipboard-text={copyData}
        onSuccess={this.onSuccess}
        {...props}
      >
        <Button
          primary
          icon={icon}
          label={buttonText}
          style={buttonStyle}
          buttonStyle={{ ...buttonStyle, backgroundColor: buttonBackground}}
          rippleStyle={buttonStyle}
          overlayStyle={buttonStyle}
        />
      </Clipboard>
    );
  }
}
