import React from 'react';
import { string } from 'prop-types';
import { isNil, isEmpty } from 'ramda';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import Dialog from 'material-ui/Dialog';
import colors from '../../constants/colors';

const customStyle = {
  textDecoration: 'underline',
  color: colors.cobaltBlue
};

const titleStyle = {
  fontFamily: 'GTWalsheim',
  textAlign: 'center',
  fontSize: '30px',
  fontWeight: 500,
  background: colors.navyBlue,
  color: colors.white
};

const bodyStyle = {
  padding: '25px 30px',
  fontSize: '16px',
  lineHeight: '24px',
  color: colors.navyBlue
};

const actionsContainerStyle = {
  textAlign: 'center'
};

const actionButtonStyle = {
  borderRadius: '20px',
  fontFamily: 'GTWalsheim'
};

const buttonStyle = {
  boxShadow: 'none'
};

const logErrorTextLength = 12;

class SubmitterLogModal extends React.Component {
  state = { isOpen: false };
  handleOpen = () => this.setState({ isOpen: true });
  handleClose = () => this.setState({ isOpen: false });
  render() {
    const { submitterLog } = this.props;
    const { isOpen } = this.state;
    const actions = [
        <RaisedButton
          label="Done"
          primary={true}
          buttonStyle={actionButtonStyle}
          style={buttonStyle}
          disableTouchRipple={true}
          onClick={this.handleClose}
        />,
    ];
    return (
      <div>
        <FlatButton
          label={submitterLog.substring(0,logErrorTextLength)}
          disableTouchRipple
          onClick={this.handleOpen}
          style={customStyle}
          hoverColor="transparent"
        />
        <Dialog
          title="Submitter log"
          titleStyle={titleStyle}
          bodyStyle={bodyStyle}
          actionsContainerStyle={actionsContainerStyle}
          actions={actions}
          modal={false}
          open={isOpen}
          onRequestClose={this.handleClose}
        >
          {submitterLog}
        </Dialog>
      </div>
    );
  }
};

SubmitterLogModal.propTypes = {
  submitterLog: string,
};

SubmitterLogModal.defaultProps = {
  submitterLog: '',
};

const SubmitterLog = ({ submitterLog }) => ( isNil(submitterLog) || isEmpty(submitterLog))
  ? '' : <SubmitterLogModal submitterLog={submitterLog} />;

SubmitterLog.propTypes = {
  submitterLog: string,
};

SubmitterLog.defaultProps = {
  submitterLog: '',
};

export default SubmitterLog;
