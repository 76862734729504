import React from 'react';
import { oneOfType, array, bool, number, string, func, shape } from 'prop-types';
import t from 'typy';
import { compose } from 'ramda';
import { withRouter } from 'react-router-dom';

import CustomCard from '../../../../components/CustomCard/Card';
import { Tab } from '../../../../components/LocationTabs';
import { Questions } from './QuestionBank';
import { 
  RequireSpecialRender,
  getApplicationPages,
} from './ApplicationPages';
import withCopy from '../../../../hocs/withCopy';
import SchoolsList from '../../../../components/SchoolsList';
import SimpleField from './SimpleField';
import getDefaultAnswer from './DefaultAnswers';

const schoolsListStyles = {
    columnGap: 70,
    columnCount: 2,
};

const CustomCardRow = ({ inputVal, applicationYear, element, selectedRow, setSelectedRow }) => {
  const eleInfo = Questions(applicationYear)[element];

  // This question does not exist in question bank.
  if(!eleInfo) {
    return null;
  }

  const { label, formatter } = eleInfo;
  let value = inputVal;
  
  // Apply formatter if value and formatter exist
  if (inputVal !== null && formatter) {
    try {
      value = formatter(inputVal) || inputVal;
    } catch(e) {
      console.error(e);
      value = inputVal;
    }
  }

  // NOTE: Values for some questions need to be defaulted to certain values if it's not entered yet from user.
  value = getDefaultAnswer(element, value);

  // Display boolean values to Yes or No.
  if (value === true) {
    value = "Yes"
  } else if (value === false) {
    value = "No"
  }

  return (RequireSpecialRender.find(item => item === element)
    ? (
      <SchoolsList
        style={schoolsListStyles}
        items={value}
        copyButtonProps={{
          text: 'Copy code',
          selectedRow,
          setSelectedRow,
        }}
      />
    ) : (
      <SimpleField
        element={element}
        title={label}
        value={value}
        isSelected={selectedRow === element}
        setSelectedRow={setSelectedRow}
      />
    )
  );  
};

CustomCardRow.propTypes = {
  inputVal: oneOfType([string, number, bool, array]),
  applicationYear: string,
  element: string.isRequired,
  selectedRow: string,
  setSelectedRow: func,
};
CustomCardRow.defaultProps = {
  inputVal: null,
  applicationYear: '',
  selectedRow: '',
  setSelectedRow: () => {},
};

const StudentSummaryTab = ({ label, tabName, record, ...props }) => {
  const { applicationYear } = record;
  return (
    <Tab label={label} tabName={tabName} {...props}>
      {getApplicationPages(record).map((section, index) => {
        const { title, rows, theme } = section;
        return (
          <CustomCard key={`section${index}`} title={title} color={theme}>
            {rows.map(element => (
              <CustomCardRow
                key={element}
                inputVal={t(record, element).safeObject}
                applicationYear={applicationYear}
                element={element}
                {...props}
              />
            ))}
          </CustomCard>
        )
      })}
    </Tab>
  );
};

StudentSummaryTab.propTypes = {
  label: string.isRequired,
  tabName: string.isRequired,
  record: shape({
    applicationYear: string,
  }).isRequired,
};

export default compose(
  withRouter,
  withCopy,
)(StudentSummaryTab);
