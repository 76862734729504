import React, { PureComponent } from 'react';
import { string, func, shape } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'ramda';
import { showNotification as showNotificationAction } from 'admin-on-rest';

import RestCommentsForm from '../../components/RestCommentsForm';
import {
  createApplicationComment,
  getApplicationComments,
  togglePinApplicationComment,
} from '../../api/comments';
import { getEmail } from '../../api/localAuthStorage';
import defaultCommentRenderer from './defaultCommentRenderer';

import './styles.scss';

class CommentsList extends PureComponent {
  static propTypes = {
    applicationType: string.isRequired,
    showNotification: func.isRequired,
    match: shape({
      params: shape({
        id: string,
      })
    }).isRequired,
    commentRenderer: func,
  };

  static defaultProps = {
    commentRenderer: defaultCommentRenderer,
  };

  fetchComments = async () => {
    const { match, showNotification, applicationType } = this.props;

    try {
      const { id } = match.params;

      const { data } = await getApplicationComments(id, applicationType);

      return data;
    } catch (error) {
      showNotification('Something went wrong', 'warning');

      return null;
    }
  };

  saveComment = async (text) => {
    const { match, showNotification, applicationType } = this.props;

    try {
      const { id } = match.params;

      const comment = {
        applicationType,
        text,
        email: getEmail(),
      };

      await createApplicationComment(id, comment);
      showNotification('Your comment has been added!');
    } catch (error) {
      showNotification('Something went wrong', 'warning');
    }
  };

  toggleCommentPin = async (commentId, pinned) => {
    const { match, showNotification, applicationType } = this.props;

    try {
      const { id } = match.params;

      const comment = {
        applicationType,
        pinned,
        id: commentId,
      };

      await togglePinApplicationComment(id, comment);
      showNotification(`Comment has been ${pinned ? 'pinned' : 'unpinned'}!`);
    } catch (error) {
      showNotification('Something went wrong', 'warning');
    }
  };

  render() {
    const { commentRenderer } = this.props;
    return (
      <RestCommentsForm
        fetchComments={this.fetchComments}
        saveComment={this.saveComment}
        toggleCommentPin={this.toggleCommentPin}
        commentRenderer={commentRenderer}
      />
    );
  }
}

const mapDispatchToProps = {
  showNotification: showNotificationAction,
};

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(CommentsList);
