import React from 'react';
import IconButton from 'material-ui/IconButton';

import { getColorHamburgerIcon } from '../../utils/settingsOfHost';

export default props => (
  <IconButton {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="15"
      viewBox="0 0 20 15"
    >
      <g fill="#000" fillRule="evenodd">
        <path fill="#000A46" d="M0 12h20v3H0z" />
        <path fill={getColorHamburgerIcon()} d="M0 6h20v3H0z" />
        <path fill="#000A46" d="M0 0h20v3H0z" />
      </g>
    </svg>
  </IconButton>
);
