import React, { memo } from 'react';
import { arrayOf, string, shape, func } from 'prop-types';

import './styles.scss';

const NumericList = ({ items, renderer, className, ...props }) => (
  <ul className={`numeric-list ${className}`} {...props}>
    {items.map((item, i) => (<li key={i}>{i + 1}.{renderer(item)}</li>))}
  </ul>
);

NumericList.propTypes = {
  className: string,
  items: arrayOf(shape()),
  renderer: func,
};

NumericList.defaultProps = {
  className: '',
  items: [],
  renderer: v => v,
};

export default memo(NumericList);
