import React, { memo } from 'react';
import { string, shape } from 'prop-types';
import { RaisedButton } from 'material-ui';

const BUTTON_BG_COLOR1 = '#2755C4';
const BUTTON_BG_COLOR2 = '#00b291';
const BUTTON_COLOR1 = '#fff';

export { BUTTON_BG_COLOR1, BUTTON_BG_COLOR2, BUTTON_COLOR1 };

const Button = props => <RaisedButton {...props} />;

Button.propTypes = {
  backgroundColor: string,
  disabledBackgroundColor: string,
  labelColor: string,
  labelPosition: string,
  disabledLabelColor: string,
  labelStyle: shape(),
};

Button.defaultProps = {
  backgroundColor: BUTTON_BG_COLOR1,
  disabledBackgroundColor: BUTTON_BG_COLOR2,
  labelColor: BUTTON_COLOR1,
  labelPosition: 'before',
  disabledLabelColor: BUTTON_COLOR1,
  labelStyle: { fontSize: 14 },
};

export default memo(Button);
