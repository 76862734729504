import React from 'react';
import { Labeled } from 'admin-on-rest';
import { func, shape, string, oneOfType } from 'prop-types';

const defaultLabelStyle = {
  paddingTop: '2em',
  height: 'auto',
};

const getLabelValue = (labelStringOrFunc, record) =>
  typeof labelStringOrFunc === 'function'
    ? labelStringOrFunc(record)
    : labelStringOrFunc;

const dynamicLabelField = WrappedComponent => {
    const DynamicLabelField = ({
    label,
    record,
    labelStyle,
    ...props
  }) => {
    const labelValue = getLabelValue(label, record);

    return (
      <Labeled
        label={labelValue}
        record={record}
        labelStyle={labelStyle || defaultLabelStyle}
        {...props}
      >
        <WrappedComponent label={labelValue} record={record} {...props} />
      </Labeled>
    );
  };

  DynamicLabelField.propTypes = {
    label: oneOfType([func, string]),
    labelStyle: shape(),
    record: shape(),
    source: string,
  };

  DynamicLabelField.defaultProps = {
    label: () => {},
    labelStyle: {},
    record: {},
    source: '',
  };

  return DynamicLabelField;
};

export default dynamicLabelField;

