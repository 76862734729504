import React, { PureComponent } from 'react';
import { number, func, arrayOf } from 'prop-types';
import { compose } from 'ramda';
import withWidth from 'material-ui/utils/withWidth';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import withLocationPerPage from '../../hocs/withLocationPerPage';

export const PER_PAGE_ITEMS = [10, 25, 50];

const styles = {
  selectField: { width: 80 },
  icon: { fill: '#2754c4' },
  underline: { borderColor: '#2754c4' },
};

class CustomPerPageSelect extends PureComponent {
  static propTypes = {
    changeLocationPerPage: func.isRequired,
    locationPerPage: number.isRequired,
    options: arrayOf(number),
    total: number,
  };

  static defaultProps = {
    total: 0,
    options: PER_PAGE_ITEMS,
  };

  onPerPageSelect = (e, index, val) => {
    const { changeLocationPerPage } = this.props;
    changeLocationPerPage(val);
  };

  render() {
    const { locationPerPage, total, options } = this.props;
    if (!total) {
      return null;
    }
    const list = options.map(value => (
      <MenuItem value={value} key={value} primaryText={value} />
    ));

    return (
      <SelectField
        autoWidth
        value={locationPerPage}
        onChange={this.onPerPageSelect}
        style={styles.selectField}
        selectedMenuItemStyle={styles.selectedItem}
        iconStyle={styles.icon}
        underlineStyle={styles.underline}
      >
        {list}
      </SelectField>
    );
  }
}

export default compose(
  withWidth(),
  withLocationPerPage(),
)(CustomPerPageSelect);
