import React, { PureComponent } from 'react';

import LocationTabs from '../../../../components/LocationTabs';
import { TAB_NAMES } from '../constants';

import SummaryTab from '../SummaryTab';

// import DetailsTab from '../DetailsTab';
import FullApplicationTab from '../FullApplicationTab';
import SensitiveDataTab from '../SensitiveDataTab';
import StudentTaxesTab from '../StudentTaxesTab';
import ParentTaxesTab from '../ParentTaxesTab';
import './styles.scss';
import {
  hasOnePermission,
  PERMISSION_SENSITIVE_INFO,
  PERMISSION_SUMMARY,
  PERMISSION_TAX
} from "../../../../utils/permissions";

export { TAB_NAMES };

export default class RootTabs extends PureComponent {
  render() {

    const hasSummary = hasOnePermission(PERMISSION_SUMMARY);
    const hasSensitive = hasOnePermission(PERMISSION_SENSITIVE_INFO);
    const hasTax = hasOnePermission(PERMISSION_TAX);

    return (
      <div className="tabs-container">
        <LocationTabs {...this.props}>
          <SummaryTab label="Application Summary" tabName={TAB_NAMES.SUMMARY} />
          {hasTax && <StudentTaxesTab
            label="Student Taxes"
            tabName={TAB_NAMES.STUDENT_TAXES}
            {...this.props}
          />}
          {hasTax && <ParentTaxesTab
            label="Parent Taxes"
            tabName={TAB_NAMES.PARENT_TAXES}
            {...this.props}
          />}
          {hasSensitive && <SensitiveDataTab
            label="Sensitive Data"
            tabName={TAB_NAMES.SENSITIVE_DATA}
            {...this.props}
          />}
          {/* <DetailsTab
            label="Details"
            tabName={TAB_NAMES.DETAILS}
            {...this.props}
          /> */}
          {hasSummary && <FullApplicationTab
            label="Summary Page"
            tabName={TAB_NAMES.FULL_APPLICATION}
            {...this.props}
          />}
        </LocationTabs>
      </div>
    );
  }
}