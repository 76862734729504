import React from 'react';
import { NavLink } from 'react-router-dom';
import { bool, shape } from 'prop-types';

import { getItemLink } from '../../../utils/menuHelpers';
import withMatchLocation from '../../../hocs/withMatchLocation';

const styles = {
  iconDefault: {
    marginRight: 7,
    fill: '#fff',
  },
  iconSelected: {
    marginRight: 7,
    fill: '#1d42a5',
  },
};

const MenuLink = ({ resource, isMatchLocation }) =>
  resource.isLink ? (
    <NavLink
      to={getItemLink(resource)}
      className={`sidebar-link ${
        isMatchLocation ? 'sidebar-link__selected' : ''
      }`}
    >
      <resource.icon
        style={isMatchLocation ? styles.iconSelected : styles.iconDefault}
      />
      {resource.label}
    </NavLink>
  ) : (
    <a href={resource.link} target="__blank" className="sidebar-link">
      <resource.icon style={styles.iconDefault} />
      {resource.label}
    </a>
  );

MenuLink.propTypes = {
  resource: shape().isRequired,
  isMatchLocation: bool.isRequired,
};

export default withMatchLocation(MenuLink);
