import React from 'react';
import { compose } from 'ramda';
import { func, shape, bool } from 'prop-types';
import Popover from 'material-ui/Popover';
import FlatButton from 'material-ui/FlatButton';
import Menu from 'material-ui/Menu';
import Notifications from 'material-ui/svg-icons/social/notifications';

import withAccess from '../../../../../hocs/withAccess';
import withMenu from '../../../../../hocs/withMenu';
import FrankStatusesMenu from './FrankStatusesMenu';
import {PERMISSION_AUTOSUBMIT} from "../../../../../utils/permissions";

const ChangeFrankStatus = ({
  isOpen,
  anchorEl,
  toggle,
  onRequestClose,
  ...props
}) => (
  <>
    <FlatButton
      onClick={toggle}
      primary
      label="Changing Frank status"
      icon={<Notifications />}
      style={{ color: '#fff' }}
    />
    <Popover open={isOpen} anchorEl={anchorEl} onRequestClose={onRequestClose}>
      <Menu>
        <FrankStatusesMenu {...props} />
      </Menu>
    </Popover>
  </>
);

ChangeFrankStatus.propTypes = {
  isOpen: bool.isRequired,
  anchorEl: shape(),
  toggle: func.isRequired,
  onRequestClose: func.isRequired,
};

ChangeFrankStatus.defaultProps = {
  anchorEl: null,
};

export default compose(
  withAccess(PERMISSION_AUTOSUBMIT),
  withMenu,
)(ChangeFrankStatus);
