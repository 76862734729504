import { fetchUtils, simpleRestClient } from 'admin-on-rest';
import { getToken } from './localAuthStorage';

const apiBaseUrl = process.env.REACT_APP_SERVER_URL;

const httpClientWithTokenAuth = (url, options = {}) => {
  const updatedOptions = {...options};

  if (!updatedOptions.headers) {
    updatedOptions.headers = new Headers({ Accept: 'application/json' });
  }
  const token = getToken();
  if (token) {
    updatedOptions.headers.set('Authorization', `Bearer ${token}`);
  }
  return fetchUtils.fetchJson(url, updatedOptions);
};
const restClient = simpleRestClient(apiBaseUrl, httpClientWithTokenAuth);

export default restClient;
