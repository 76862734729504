import React from 'react';
import { string, shape } from 'prop-types';
import AttachFile from 'material-ui/svg-icons/editor/attach-file';

import { apiBaseUrl } from '../api/api';

const styles = {
  attachment: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  attachmentIcon: {
    marginRight: 10,
  },
  link: {
    color: '#2755c4',
    fontWeight: '600',
  },
};

// A component to render a link using the API url prefix
const APIUrlItem = ({ source }) => {
  // TODO: chnage back to normal when we start using seperate bucket for develop enviornment.
  const apiBaseUrlEXT = (document.location.host === 'bo.develop.withfrank.org')
    ? 'https://bo-api.staging.withfrank.org' : apiBaseUrl;
  return source.map(val => (
    <div key={val.name} style={styles.attachment}>
      <AttachFile style={styles.attachmentIcon} />
      <a
        style={styles.link}
        href={val.url && `${apiBaseUrlEXT}${val.url}`}
        rel="noopener noreferrer"
        target="_blank"
      >{`${val.url ? '' : '⚠️'}${val.name}`}</a>      
    </div>
  ));
}


// A component to render a list of urls using the API server
const APIUrls = ({ source, listName, record = {} }) =>
  record[source] && record[source][listName] && record[source][listName].length > 0 ? (
    <APIUrlItem source={record[source][listName]} />
  ) : (
    <i>No files uploaded</i>
  );

APIUrls.propTypes = {
  listName: string,
  source: string,
  record: shape(),
};

APIUrls.defaultProps = {
  listName: '',
  source: '',
  record: {},
};

export default APIUrls;
