import React, { Component } from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import FlatButton from 'material-ui/FlatButton';
import IconButton from 'material-ui/IconButton';
import ChevronLeft from 'material-ui/svg-icons/navigation/chevron-left';
import ChevronRight from 'material-ui/svg-icons/navigation/chevron-right';
import { Toolbar, ToolbarGroup } from 'material-ui/Toolbar';
import withWidth from 'material-ui/utils/withWidth';
import muiThemeable from 'material-ui/styles/muiThemeable';
import compose from 'recompose/compose';
import withTranslate from 'admin-on-rest/lib/i18n/translate';

import CustomPerPageSelect from '../CustomPerPageSelect';
import PageInput from '../Pagination/PageInput';

const styles = {
  button: {
    margin: '10px 0',
  },
  pageInfo: {
    padding: '0 70px 0 20px',
  },
  mobileToolbar: {
    margin: 'auto',
  },
  holder: {
    display: 'flex',
  },
  perPageSelect: {
    paddingLeft: '0 70px 0 20px',
  }
};

class Pagination extends Component {
  getNbPages() {
    return Math.ceil(this.props.total / this.props.perPage) || 1;
  }

  getPaginationData = () => {
    const { page, perPage, total } = this.props;

    const offsetEnd = Math.min(page * perPage, total);
    const offsetBegin = Math.min((page - 1) * perPage + 1, offsetEnd);
    const nbPages = this.getNbPages();

    return { offsetEnd, offsetBegin, nbPages };
  };

  getMobilePagination = () => {
    const { muiTheme, page, total, translate } = this.props;

    const { offsetEnd, offsetBegin, nbPages } = this.getPaginationData();

    return (
      <ToolbarGroup style={styles.mobileToolbar}>
        {page > 1 && (
          <IconButton onClick={this.prevPage}>
            <ChevronLeft
              color={muiTheme.palette.primary1Color}
            />
          </IconButton>
        )}
        <span style={styles.pageInfo}>
                        {translate('aor.navigation.page_range_info', {
                          offsetBegin,
                          offsetEnd,
                          total,
                        })}
                    </span>
        {page !== nbPages && (
          <IconButton onClick={this.nextPage}>
            <ChevronRight
              color={muiTheme.palette.primary1Color}
            />
          </IconButton>
        )}
      </ToolbarGroup>
    );
  };

  getDesktopPagination = () => {
    const { page, total, perPage, setPage, translate } = this.props;

    const { offsetEnd, offsetBegin, nbPages } = this.getPaginationData();
    const maxPage = Math.ceil(total / perPage);

    return (
      <>
        <ToolbarGroup firstChild style={{ marginLeft: 0 }}>
          <div style={styles.perPageSelect}>
            <CustomPerPageSelect {...this.props} />
          </div>
          <span style={styles.pageInfo}>
            {translate('aor.navigation.page_range_info', {
              offsetBegin,
              offsetEnd,
              total,
            })}
          </span>
          <PageInput onChange={setPage} value={page} maxPage={maxPage} />
        </ToolbarGroup>
        {nbPages > 1 && (
          <ToolbarGroup>
            {page > 1 && (
              <FlatButton
                className="previous-page"
                primary
                key="prev"
                label={translate('aor.navigation.prev')}
                icon={<ChevronLeft />}
                onClick={this.prevPage}
                style={styles.button}
              />
            )}
            {this.renderPageNums()}
            {page !== nbPages && (
              <FlatButton
                className="next-page"
                primary
                key="next"
                label={translate('aor.navigation.next')}
                icon={<ChevronRight />}
                labelPosition="before"
                onClick={this.nextPage}
                style={styles.button}
              />
            )}
          </ToolbarGroup>
        )}
      </>
    );
  };

  range() {
    const input = [];
    const { page, perPage, total } = this.props;
    if (Number.isNaN(page)) return input;
    const nbPages = Math.ceil(total / perPage) || 1;

    // display page links around the current page
    if (page > 2) {
      input.push('1');
    }
    if (page === 4) {
      input.push('2');
    }
    if (page > 4) {
      input.push('.');
    }
    if (page > 1) {
      input.push(page - 1);
    }
    input.push(page);
    if (page < nbPages) {
      input.push(page + 1);
    }
    if (page === nbPages - 3) {
      input.push(nbPages - 1);
    }
    if (page < nbPages - 3) {
      input.push('.');
    }
    if (page < nbPages - 1) {
      input.push(nbPages);
    }

    return input;
  }

  gotoPage = event => {
    event.stopPropagation();
    const { page } = event.currentTarget.dataset;
    if (page < 1 || page > this.getNbPages()) {
      throw new Error(
        this.props.translate('aor.navigation.page_out_of_boundaries', {
          page,
        })
      );
    }
    this.props.setPage(page);
  };


  prevPage = event => {
    event.stopPropagation();
    if (this.props.page === 1) {
      throw new Error(
        this.props.translate('aor.navigation.page_out_from_begin')
      );
    }
    this.props.setPage(this.props.page - 1);
  };

  nextPage = event => {
    event.stopPropagation();
    if (this.props.page > this.getNbPages()) {
      throw new Error(
        this.props.translate('aor.navigation.page_out_from_end')
      );
    }
    this.props.setPage(this.props.page + 1);
  };


  renderPageNums() {
    return this.range().map(
      (pageNum, index) =>
        pageNum === '.' ? (
          <span key={`hyphen_${index}`} style={{ padding: '1.2em' }}>
                        &hellip;
                    </span>
        ) : (
          <FlatButton
            className="page-number"
            key={pageNum}
            label={pageNum}
            data-page={pageNum}
            onClick={this.gotoPage}
            primary={pageNum !== this.props.page}
            style={styles.button}
          />
        )
    );
  }

  render() {
    const { total, width } = this.props;

    if (total === 0) return null;

    return (
      <Toolbar>
        {width === 1 ? this.getMobilePagination() : this.getDesktopPagination()}
      </Toolbar>
    );
  }
}

Pagination.propTypes = {
  muiTheme: PropTypes.shape().isRequired,
  setPage: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  page: PropTypes.number,
  perPage: PropTypes.number,
  total: PropTypes.number,
  width: PropTypes.number,
};

Pagination.defaultProps = {
  page: 0,
  perPage: 0,
  total: 0,
  width: 0,
};

const enhance = compose(pure, withTranslate, withWidth(), muiThemeable());

export default enhance(Pagination);
