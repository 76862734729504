export default (items, { direction = 'asc', field } = {}, valueGetter = value => value) => (
  [].concat(items).sort((a, b) => {
    const valueA = field ? a[field] : a;
    const valueB = field ? b[field] : b;

    const valueADirection = valueGetter(valueA) > valueGetter(valueB) ? 1 : -1;

    return direction.toLowerCase() === 'asc' ? valueADirection : (valueADirection * -1);
  })
)
