import { post, get, put } from './api';
import { getToken } from './localAuthStorage';

export const sendApplicationsToSubmitter = ({ ids }) =>
  post(`/submitter/applications`, { ids }, getToken());

export const getSubmitterSettings = () =>
  get('/submitter/settings', getToken());

export const changeSubmitterSettings = ({ setting, value }) =>
  put('/submitter/settings', { setting, value }, getToken());
