import request from './request';

export const getUsers = (params) => request({
  method: 'GET',
  url: `/users`,
  params,
});

export const getAssignUsers = (params) => request({
  method: 'GET',
  url: `/assign_to_users`,
  params,
});

export const getRoles = () => request({
  method: 'GET',
  url: `/roles`,
});
