export const ENTITIES = {
  STUDENT: 'student',
  PARENT: 'parent',
  IMPERSONATE: 'impersonate',
  SUBMITTER: 'submitter',
  OPS: 'ops',
};

export const EVENTS = {
  STUDENT_UPDATE: 'student_update',
  PARENT_UPDATE: 'parent_update',
  SUPPORT_UPDATE: 'support_update',
  TAX_ENTRY: 'tax_entry',
  ASSIGNMENT: 'assignment',
  FRANK_STATUS_CHANGE: 'frank_status_change',
  FLAG_CHANGE: 'flag_change',
  FAFSA_STATUS_CHANGE: 'fafsa_status_change',
};


