const commonLineInputNames = [
  'studentTaxLine1',
  'studentTaxLine2a',
  'studentTaxLine4a',
  'studentTaxLine4b',
  'studentTaxLine7',
  'studentTaxLine13',
];

const schedule1MainLineInputNames = [
  'studentTaxLine12',
  'studentTaxLine18',
  'studentTaxLine28',
];

const schedule1LineInputNames = [
  ...schedule1MainLineInputNames,
  'studentTaxLine32',
];

const schedule2LineInputNames = [
  'studentTaxLine46',
];

const schedule3LineInputNames = [
  'studentTaxLine50',
];

const scheduleK1LineInputNames = [
  'studentTaxBox14CodeA',
];

const taxFilingStatusFieldName = 'studentTaxFilingStatus';
const taxReturnTypeFieldName = 'studentTaxReturnType';
const didFileSchedulesFieldName = 'didStudentFileSchedules';
const cashBalanceFieldName = 'studentCashBalance';
const taxScheduleFieldName = 'studentTaxSchedule';

const taxSchedule1ChecksFieldName = 'studentTaxSchedule1Checks';
const taxSchedule1Line13FieldName = 'studentTaxSchedule1Line13';
const taxSchedule1Line21FieldName = 'studentTaxSchedule1Line21';
const didFileSchedule1AlaskaPermanentFundDividendFieldName = 'didStudentFileSchedule1AlaskaPermanentFundDividend';
const schedule1AdditionsLineInputNames = [
  'studentTaxSchedule1Line13',
  'studentTaxSchedule1Line21',
];

export default {
  taxScheduleFieldName,
  taxFilingStatusFieldName,
  taxReturnTypeFieldName,
  didFileSchedulesFieldName,
  cashBalanceFieldName,
  commonLineInputNames,
  schedule1MainLineInputNames,
  schedule1LineInputNames,
  schedule2LineInputNames,
  schedule3LineInputNames,
  scheduleK1LineInputNames,
  taxSchedule1ChecksFieldName,
  taxSchedule1Line13FieldName,
  taxSchedule1Line21FieldName,
  schedule1AdditionsLineInputNames,
  didFileSchedule1AlaskaPermanentFundDividendFieldName,
};
