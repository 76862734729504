import React, { Fragment } from 'react';
import { bool, shape, string } from 'prop-types';
import { CardActions } from 'material-ui';
import { ListButton, RefreshButton, EditButton } from 'admin-on-rest';

import ImpersonateButton from '../components/ImpersonateButton';
import { SendNotificationButton } from '../components/buttons';
import { lastVisitedListLink } from '../components/CommonList';
import {hasOnePermission, PERMISSION_IMPERSONATE} from "../utils/permissions";

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

const ShowActions = ({ basePath, data, resource, hasEdit, hasList }) => {
  const props = { basePath, resource, record: data };
  const listLinkCache = lastVisitedListLink.get()[resource];

  return (
    <CardActions style={cardActionStyle}>
      {hasEdit && (<Fragment>
        { hasOnePermission(PERMISSION_IMPERSONATE) && <ImpersonateButton {...props} /> }
        <SendNotificationButton {...props} />
        <EditButton {...props} />
      </Fragment>)}
      {hasList && <ListButton {...props} basePath={listLinkCache || basePath} />}
      <RefreshButton />
    </CardActions>
  );
};

ShowActions.propTypes = {
  data: shape(),
  basePath: string,
  resource: string,
  hasEdit: bool,
  hasList: bool,
};

ShowActions.defaultProps = {
  data: {},
  basePath: '',
  resource: '',
  hasEdit: false,
  hasList: false,
};

export default ShowActions;
