import React from 'react';
import { SelectInput } from 'admin-on-rest';
import { DependentInput } from 'aor-dependent-input';
import { isNil } from 'ramda';

import styles from '../common-styles';
import { NumberInput } from '../../fields';

const createSelects = (selectsData, texts) => selectsData.map(({ field, options }) => (
  <SelectInput
    key={field}
    label={texts[field]}
    source={field}
    choices={options}
    elStyle={styles.select}
    allowEmpty
  />
));

const createLineInputs = (
  inputNames, texts, taxAutomationValues
) => inputNames.map(inputSettings => {
  const [inputName, isVisible = () => true] = [].concat(inputSettings);
  const taxAutomationValue = (
    !taxAutomationValues || isNil(taxAutomationValues[inputName])
  ) ? '' : taxAutomationValues[inputName];
  return (
    <DependentInput key={inputName} resolve={isVisible}>
      <NumberInput
        source={inputName}
        taxAutomationValue={taxAutomationValue}
        inline
        group
        label={texts[inputName].label ? texts[inputName].label : texts[inputName]}
        description={texts[inputName].description}
        elStyle={styles.numberInput}
      />
    </DependentInput>
  )
});

export {
  createSelects,
  createLineInputs,
}
