import React, { PureComponent } from 'react';
import {string, func} from 'prop-types';

import { map, flatten, compose, toPairs, prepend } from 'ramda';
import Subheader from 'material-ui/Subheader';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import CircularProgress from 'material-ui/CircularProgress';

import { get } from '../../api/api';
import { getToken } from '../../api/localAuthStorage';

export default class NotificationMessagesDropDownMenu extends PureComponent {
  static propTypes = {
    applicationId: string.isRequired,
    value: string.isRequired,
    onChangedSelection: func.isRequired,
  };

  state = {
    isLoaded: false,
    messages: null,
    error: null,
  };

  componentDidMount() {
    const { applicationId } = this.props;

    get(`/applications/${applicationId}/notificationMessages`, getToken()).then(
      messages => {
        this.setState({ isLoaded: true, messages });
      },
      error => {
        this.setState({ isLoaded: true, error });
      },
    );
  }

  render() {
    const { onChangedSelection, value } = this.props;
    const { messages, error, isLoaded } = this.state;

    const mapCategoryToMenuItems = map(([categoryName, category]) => {
      const mapCategoryToItems = map(([issueTitle, userAndEnglishMessage]) => (
        <MenuItem key={`${categoryName}-${issueTitle}`} primaryText={issueTitle} value={userAndEnglishMessage} />
      ));
      const categoryItems = compose(mapCategoryToItems, toPairs)(category);
      return [<Subheader key={categoryName}>{categoryName}</Subheader>, categoryItems];
    });

    const prependPlaceholder = prepend(
      <MenuItem key='default' disabled={true} primaryText="Select an option" value='' />,
    );

    const menuItems = compose(
      prependPlaceholder,
      flatten,
      mapCategoryToMenuItems,
      toPairs,
    )(messages);

    return (
      <div>
        {isLoaded ? (
          <DropDownMenu value={value} onChange={onChangedSelection}>
            {menuItems}
          </DropDownMenu>
        ) : (
          <p>
            Loading messages... <CircularProgress size={20} />
          </p>
        )}
        {error && <p>Error: {error}</p>}
      </div>
    );
  }
}
