import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { shape, string, func } from 'prop-types';
import { compose } from 'ramda';

import decryptSensitiveField from '../../../../api/decryptSensitiveField';
import { PARENT } from '../../../ApplicationType';
import SensitiveDataList from '../../../../components/SensitiveDataList';
import { Card } from '../../../../components/CustomCard';
import { GREY } from '../../../../components/CustomCard/Card';
import { date, ssnFormatter } from '../../../../utils/formatter';
import {
  DATE_FORMAT_6,
  DATE_FORMAT_7,
} from '../../../../constants/date-formats';
import withCopy from '../../../../hocs/withCopy';

const items = [
  {
    label: 'Parent SSN',
    source: 'encryptedParent1Ssn',
    formatter: v => ssnFormatter(v),
  },
  {
    label: 'Other parent SSN',
    source: 'encryptedParent2Ssn',
    formatter: v => ssnFormatter(v),
  },
  {
    label: 'Student SSN',
    source: 'encryptedStudentSsn',
    formatter: v => ssnFormatter(v),
  },
  {
    label: 'Parent date of birth',
    source: 'encryptedParent1DateOfBirth',
    formatter: v => date(v, DATE_FORMAT_6, DATE_FORMAT_7),
  },
  {
    label: 'Other parent date of birth',
    source: 'encryptedParent2DateOfBirth',
    formatter: v => date(v, DATE_FORMAT_6, DATE_FORMAT_7),
  },
];

class SensitiveDataCard extends PureComponent {
  static propTypes = {
    match: shape().isRequired,
    record: shape(),
    selectedRow: string,
    setSelectedRow: func,
  };

  static defaultProps = {
    record: {},
    selectedRow: '',
    setSelectedRow: () => {},
  };

  decryptField = field => decryptSensitiveField({
    applicationId: this.props.match.params.id,
    field,
    PARENT,
  });

  render() {
    const { selectedRow, setSelectedRow, record } = this.props;

    return (
      <div className="card-container">
        <Card title="SSN + DOB" color={GREY} cover={false}>
          <SensitiveDataList
            setSelectedRow={setSelectedRow}
            selectedRow={selectedRow}
            record={record}
            dataFetcher={this.decryptField}
            items={items}
          />
        </Card>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withCopy,
)(SensitiveDataCard);
