/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { shape, string } from 'prop-types';
import { Chip } from 'material-ui';
import ReactTooltip from 'react-tooltip';

import { taxAutomationStatusForDisplaying } from '../../enumChoices';
import withChipStyles, { getChipColor } from '../../../hocs/withChipStyles';
import colors from '../../../../src/constants/colors'

const getDocumentsHtmlString = (data) => (data ? `
  <b>Documents:</b>
  <ol style="margin: 0;">
    ${Object.entries(data).map(([key, { nameForDisplay }])=>(
      `<li key="${key}">${nameForDisplay || key}</li>`
    )).join('')}
  </ol>
  <br />
` : '');

const getMessageHtmlString = (message) => {
  if (!message) return '';
  const maxMessageStringLength = 70;
  let msg = message;
  const messageStrings =  [];
  while (msg.length > maxMessageStringLength) {
    const splitPosition = msg.lastIndexOf(' ', maxMessageStringLength);
    messageStrings.push(msg.slice(0, splitPosition));
    msg = msg.slice(splitPosition+1);
  }
  messageStrings.push(msg);
  return `
    <b>Message: </b>
    <ul style="margin: 0; list-style: none; margin-left: -1em;">
      ${messageStrings.map(
        msgStr => `<li key="${msgStr}">${msgStr}</li>`
      ).join('')}
    </ul>
    <br />
  `
};

const TaxAutomationStatusTooltipContent = (props) => {
  if(!props) return null;
  const {data, message, processTime} = props;
  return `
    <div>
      ${getDocumentsHtmlString(data)}
      ${getMessageHtmlString(message)}
      <b>Processing time: </b> ${processTime || ''}
    </div>
  `
};

const TaxAutomationStatus = ({ 
  id,
  studentTaxAutomationStatus, 
  parentTaxAutomationStatus,
  studentTaxAutomationProcessing, 
  parentTaxAutomationProcessing,
  styles,
}) => {
  const getTaxAutomationStatus = taxMaster => ({
    taxAutomationStatus: (taxMaster === 'student')
      ? studentTaxAutomationStatus
      : parentTaxAutomationStatus,
    taxAutomationProcessing: (taxMaster === 'student')
      ? studentTaxAutomationProcessing
      : parentTaxAutomationProcessing
  });

  const getChipWithTooltip = (taxMaster) => {
    const { taxAutomationStatus, taxAutomationProcessing } = getTaxAutomationStatus(taxMaster);
    if (!taxAutomationStatus) return null;
    const chipLabel = `
      ${taxMaster === 'parent' ? 'Parent Tax ' : ''}
      ${taxAutomationStatusForDisplaying[taxAutomationStatus]}
    `;

    return (
      <Fragment>
        <Chip
          data-tip={`${taxMaster}#${id}`}
          data-for={`${taxMaster}#${id}`}
          labelStyle={styles.label}
          style={{ ...styles.chip, ...getChipColor(taxAutomationStatus) }}
          className="selectable_chip"
        >
          {chipLabel}
        </Chip>
        <ReactTooltip 
          id={`${taxMaster}#${id}`}
          html 
          place="right"
          type="light"
          effect="solid"
          border
          textColor={colors.navyBlue}
          borderColor={colors.navyBlue}
          getContent={
            ()=>TaxAutomationStatusTooltipContent(taxAutomationProcessing)
          }
        /> 
      </Fragment>
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {getChipWithTooltip('student')}
      {getChipWithTooltip('parent')}
    </div>
  );
};

TaxAutomationStatus.propTypes = {
  studentTaxAutomationStatus: string,
  parentTaxAutomationStatus: string,
  studentTaxAutomationProcessing: shape(),
  parentTaxAutomationProcessing: shape(),
  styles: shape(),
};

TaxAutomationStatus.defaultProps = {
  studentTaxAutomationStatus: '',
  parentTaxAutomationStatus: '',
  studentTaxAutomationProcessing: {},
  parentTaxAutomationProcessing: {},
  styles: {},
};

export default withChipStyles(TaxAutomationStatus);
