import React, { PureComponent } from 'react';
import { shape } from 'prop-types';
import moment from 'moment';

import { date, calendar } from '../../utils/formatter';
import { DATE_FORMAT_9 } from '../../constants/date-formats';

const getLatestUpdatedTimestamp = (...args) => {
  const filteredArgs = args.filter(item => item);

  if (filteredArgs.length < 2) {
    return filteredArgs[0];
  }

  const sortedByGreatest = filteredArgs.sort((a, b) =>
    moment(a) > moment(b) ? -1 : 1,
  );

  return sortedByGreatest[0];
};

const styles = {
  timestamp: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100px',
    lineHeight: '1.5',
  },
  day: {
    fontWeight: 'bold',
  },
};

export default class LatestTimestamp extends PureComponent {
  static propTypes = {
    record: shape(),
  };

  static defaultProps = {
    record: {},
  };

  render() {
    const { record } = this.props;
    const { 
      createdAt, parentConfirmed, studentConfirmed,
    } = record;

    const dataValue = getLatestUpdatedTimestamp(
      createdAt, parentConfirmed, studentConfirmed,
    );

    return (
      <div style={styles.timestamp}>
        <span style={styles.day}>{calendar({ dataValue })}</span>
        <span>{date(dataValue, DATE_FORMAT_9)}</span>
      </div>
    );
  }
}
