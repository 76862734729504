import React, {useEffect, useState} from 'react';
import {
  Edit,
  SimpleForm,
  DisabledInput,
  TextInput,
  RadioButtonGroupInput,
} from 'admin-on-rest';

import {getRoles} from "../api/users";

const UserInvite = props => {
  const [roles, setRoles] = useState([]);

  useEffect(()=>{
    getRoles().then(res => {
      setRoles(res.data);
    });
  }, []);


  return (
      <Edit {...props} title="Edit user">
        <SimpleForm>
          <TextInput label="First name" source="firstName" />
          <TextInput label="Last name" source="lastName" />
          <DisabledInput source="email" />
          <RadioButtonGroupInput
              source="role"
              label="Roles"
              choices={roles}
          />
        </SimpleForm>
      </Edit>
  );
}

export default UserInvite;
