import { either, isNil, isEmpty, complement} from 'ramda';

const noop = () => {};

const jsonParse = json => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return null;
  }
};

const isFunc = func => typeof func === 'function';

const concatItems = ({ items = [], separator = ' ' }) =>
  items.filter(Boolean).join(separator);

const isNilOrEmpty = either(isNil, isEmpty);

const isNotNilOrEmpty = complement(isNilOrEmpty);

export { 
  noop, 
  jsonParse, 
  isFunc, 
  concatItems, 
  isNilOrEmpty, 
  isNotNilOrEmpty,
};
