import React from 'react';
import { string } from 'prop-types';

import { formatYearData } from '../../utils/formatter';

const YearField = ({ year }) => <span>{formatYearData(year)}</span>;

YearField.propTypes = {
  year: string,
};

YearField.defaultProps = {
  year: '',
};

export default YearField;
