import React, { PureComponent } from 'react';
import { string, shape, bool, func } from 'prop-types';
import { Toggle as MuiToggle } from 'material-ui';

import colors from '../../constants/colors';

const styles = {
  labelStyle: {
    fontSize: 14,
    fontWeight: '600',
    color: colors.navyBlue,
  },
  thumbOff: {
    width: 30,
    height: 30,
    top: '5px',
    left: '4px',
    backgroundColor: colors.white,
  },
  trackOff: {
    height: 32,
    width: 65,
    backgroundColor: colors.blueGrey,
  },
  thumbSwitched: {
    marginLeft: '0',
    backgroundColor: colors.white,
  },
  trackSwitched: {
    backgroundColor: colors.jade,
  },
};

class Toggle extends PureComponent {
  static propTypes = {
    value: bool,
    label: string.isRequired,
    helperText: string,
    containerStyle: shape(),
    onToggle: func.isRequired,
  };

  static defaultProps = {
    value: false,
    helperText: '',
    containerStyle: {},
  };

  render() {
    const { value, label, helperText, containerStyle, onToggle } = this.props;

    return (
      <div className="toggle-container" style={containerStyle}>
        <MuiToggle
          label={label}
          thumbStyle={styles.thumbOff}
          trackStyle={styles.trackOff}
          thumbSwitchedStyle={styles.thumbSwitched}
          trackSwitchedStyle={styles.trackSwitched}
          labelStyle={styles.labelStyle}
          toggled={value}
          onToggle={onToggle}
        />
        <span className="toggle-container-helper">{helperText}</span>
      </div>
    );
  }
}

export default Toggle;
