import React from 'react';

import withAccess from '../../../../hocs/withAccess';
import { Tab } from '../../../../components/LocationTabs';
import { TaxForm, parentTaxContent } from '../../../../components/TaxForm';
import { STUDENT, PARENT } from '../../../../Applications/ApplicationType';
import {PERMISSION_TAX} from "../../../../utils/permissions";

const ParentTaxesTab = props => (
  <Tab {...props}>
    <TaxForm
      applicationType={STUDENT}
      taxFormFor={PARENT}
      contentCreator={parentTaxContent}
      {...props}
    />
  </Tab>
);

export default withAccess(PERMISSION_TAX)(ParentTaxesTab);
