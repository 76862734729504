const createListCheckboxesProps = (isCheckboxesEnabled, onRowSelection) => ({
  options: {
    displayRowCheckbox: isCheckboxesEnabled,
    deselectOnClickaway: false,
    multiSelectable: isCheckboxesEnabled,
    onRowSelection,
  },
  headerOptions: {
    adjustForCheckbox: isCheckboxesEnabled,
    displaySelectAll: isCheckboxesEnabled,
  },
  bodyOptions: {
    deselectOnClickaway: false,
    displayRowCheckbox: isCheckboxesEnabled,
  },
  rowOptions: {
    selectable: isCheckboxesEnabled,
  }
});

const processSelectedIds = (selectedIds, allIds) => {
  if (!selectedIds) { return [1]; }
  if (selectedIds === 'none') { return []; }

  const isAllSelected = !Array.isArray(selectedIds);

  return isAllSelected ? allIds : selectedIds.map(row => allIds[row]);
};

export {
  processSelectedIds,
  createListCheckboxesProps,
}
