import React, { PureComponent } from 'react';
import { shape } from 'prop-types';

import { getParentApplication } from '../../api/parrentApplications';

export default () => (Component) => class WithUsers extends PureComponent {
  static propTypes = {
    record: shape().isRequired,
  };
  state = {
    fetched: false,
    parentApplication: {},
  };

  async componentDidMount() {
    const { record } = this.props;

    try {
      const response = await getParentApplication(record.id);
      const data = {
        parentApplication: response.data[0],
      };
      this.setStateData(data);
    } catch (e) {
      console.error(e);
    } finally {
      this.setStateData({fetched: true});
    }
  }

  setStateData(data) {
    this.setState(data);
  }

  render() {
    const { fetched, parentApplication } = this.state;

    return fetched ? <Component {...this.props} parentApplication={parentApplication} /> : null;
  }
};
