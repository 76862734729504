import React, { PureComponent } from 'react';
import { string, arrayOf, oneOfType, func, shape, number } from 'prop-types';

import './styles.scss';

class InfoList extends PureComponent {
  static propTypes = {
    items: arrayOf(
      shape({
        text: string,
        iconComponent: oneOfType([func, shape()]),
        className: string,
      }),
    ),
    schoolInfo: arrayOf(
      shape({
        text: oneOfType([string, shape()]),
        iconComponent: oneOfType([func, shape()]),
      }),
    ),
    className: string,
    iconSize: number,
  };

  static defaultProps = {
    items: [],
    schoolInfo: [],
    className: '',
    iconSize: 20,
  };

  renderList = items => {
    const { className, iconSize } = this.props;

    return (
      <ul className={`info-list ${className}`}>
        {items
          .filter(({ text }) => text)
          .map(
            ({ iconComponent: Icon, text, className: contactStyle }, key) => (
              <li key={key} className={`${contactStyle || ''}`}>
                <div className="info-list__icon">
                  <Icon style={{ width: iconSize, height: iconSize }} />
                </div>
                <div className="info-list__text">
                  <span>{text}</span>
                </div>
              </li>
            ),
          )}
      </ul>
    );
  };

  render() {
    const { items, schoolInfo } = this.props;

    return (
      <div className="summary-info">
        {this.renderList(items)}
        {this.renderList(schoolInfo)}
      </div>
    );
  }
}

export default InfoList;
