import { useState, useEffect } from 'react';

import request from '../../api/request';

const { REACT_APP_PARTNER_API_URL } = process.env;

const FilterWithPartnerChoices = ({ children,  ...props }) => {
  const [partnersList, setPartnersList] = useState([]);

  useEffect(() => {
    const fetchPartnersList = async () => {
      try {
        const url = `${REACT_APP_PARTNER_API_URL}/faas`;
        const { data: { data, success } } = await request({
          method: 'GET',
          url,
        });

        if (success) {
          setPartnersList(data);
        }
      } catch (err) {
        console.log(err);
      }
    }

    fetchPartnersList();
  }, []);

  const processedProps = {
    ...props,
    choices: partnersList.map((partner) => ({
      id: partner.id,
      name: partner.partnerSchoolName || partner.clientName,
    })),
  };

  return children(processedProps);
}

export default FilterWithPartnerChoices;
