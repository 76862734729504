import React, { PureComponent } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { shape } from 'prop-types';

import {
  SimpleForm,
  SelectInput,
  Notification,
  showNotification as showNotificationAction,
} from 'admin-on-rest';
import FlatButton from 'material-ui/FlatButton';

import bulkUpdate from '../api/applications';
import { FrankStatus } from './enumChoices';
import withUsers from '../hocs/withUsers';
import IWithUsers from '../hocs/withUsers/propTypes';
import { getFromLocalStorage } from '../services/localStorage';
import { getUrlQuery } from '../services/url';
import { SUPPORT, SUPPORT_MANAGER } from '../constants';
import { lastVisitedListLink } from '../components/CommonList';

const getIdsFromQuery = (query) => {
  const params = getUrlQuery(query);
  const ids = params['applications-ids'];

  return ids ? ids.split(',') : [];
};

class ApplicationsBulkUpdate extends PureComponent {
  static propTypes = {
    history: shape().isRequired,
    ...IWithUsers,
  };

  state = {
    permissions: '',
    selectedApplicationIds: [],
  };

  componentDidMount() {
    const { showNotification, location } = this.props;

    const permissions = getFromLocalStorage('permissions');
    const selectedApplicationIds = getIdsFromQuery(location.search);

    if (!selectedApplicationIds.length) {
      showNotification( 'Please select applications to assign' );
      this.redirectToApplications();
    }

    this.setInitialState(permissions, selectedApplicationIds);
  };

  get redirectLink() {
    const { resource } = this.props.match.params;
    const lastVisitedListLinkCache = lastVisitedListLink.get();

    return lastVisitedListLinkCache
      ? lastVisitedListLinkCache[resource]
      : `/${resource}`;
  }

  setInitialState(permissions, selectedApplicationIds) {
    this.setState({permissions, selectedApplicationIds});
  }

  redirectToApplications = () => {
    const { history } = this.props;
    const url = this.redirectLink;

    history.push(url);
  };

  saveFormAndRedirect = async (formData) => {
    const { selectedApplicationIds, permissions } = this.state;
    const {
      showNotification,
    } = this.props;

    try {
      const { frankStatus, assignedTo } = formData;

      if (!frankStatus && !assignedTo) { return; }

      const data = {
        applicationsIds: selectedApplicationIds,
        frankStatus,
        assignedTo,
        permissions: permissions.split(','),
      };

      await bulkUpdate(data);

      showNotification('Success update');
      this.redirectToApplications();
    } catch (error) {
      const message = error.message || 'Error while update';
      showNotification(message);
    }
  };

  render() {
    const { permissions } = this.state;
    const { usersList } = this.props;

    return (
      <div>
        <FlatButton primary label="< Go Back" onClick={this.redirectToApplications} />
        <SimpleForm save={this.saveFormAndRedirect}>
          <SelectInput
            label="Frank Status"
            source="frankStatus"
            choices={FrankStatus}
            allowEmpty
          />
          {permissions.includes(SUPPORT_MANAGER) && (
            <SelectInput
              label="Assigned To"
              source="assignedTo"
              choices={usersList}
              optionText="email"
              optionValue="email"
            />
          )}
        </SimpleForm>
        <Notification />
      </div>
    );
  }
}

const params = { where: { permissions: { $contains: [SUPPORT] } } };

const mapDispatchToProps = {
  showNotification: showNotificationAction,
};

export default compose(
  withUsers(params),
  connect(null, mapDispatchToProps)
)(ApplicationsBulkUpdate);
