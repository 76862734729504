import React, { PureComponent } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Menu, FlatButton, MenuItem } from 'material-ui';
import ExpandMore from 'material-ui/svg-icons/navigation/expand-more';

import MenuLink from './MenuLink';

const styles = {
  button: {
    padding: '0 0 0 24px',
    fontSize: 16,
    textAlign: 'left',
    color: '#a2b6e6',
  },
  menuInnerDiv: {
    padding: 0,
  },
};

class GroupMenu extends PureComponent {
  static propTypes = {
    title: string,
    resources: arrayOf(shape()),
  };

  static defaultProps = {
    title: 'Menu',
    resources: [],
  };

  state = { isShow: false };

  onToggle = () => {
    this.setState(prevState => ({ isShow: !prevState.isShow }));
  };

  render() {
    const { isShow } = this.state;
    const { title, resources } = this.props;

    return (
      <>
        <FlatButton
          onClick={this.onToggle}
          icon={<ExpandMore />}
          style={styles.button}
        >
          {title}
        </FlatButton>
        {isShow ? (
          <Menu disableAutoFocus={true}>
            {resources &&
              resources.map(resource => (
                <MenuItem
                  key={resource.name}
                  innerDivStyle={styles.menuInnerDiv}
                >
                  <MenuLink resource={resource} />
                </MenuItem>
              ))}
          </Menu>
        ) : null}
      </>
    );
  }
}

export default GroupMenu;
