import React, { PureComponent } from 'react';
import { shape } from 'prop-types';

import { Card } from '../../components/CustomCard';
import CommentsTabComponent  from '../../Applications/CommentsList';
import HistoryList  from '../../Applications/StudentApplications/StudentApplicationShow/SummaryTab/HistoryList';
import { APPLICATION_TYPES } from '../../constants/comments';
import withParentApplication from '../../hocs/withParentApplication';
import { PARENTS_APPLICATIONS } from '../../constants/routes';
import StudentSummaryCard from './StudentSummaryCard';
import AcademicInformationCard from './AcademicInformationCard';
import ApplicationStatusCard from './ApplicationStatusCard';

import './style.scss';

const getParentApplicationRoute = (id) => (
  `${PARENTS_APPLICATIONS}/${id}`
);

class SummaryView extends PureComponent {
  static propTypes = {
    record: shape().isRequired,
    parentApplication: shape(),
  };

  static defaultProps = {
    parentApplication: {}
  };

  render() {
    const { record, parentApplication } = this.props;
    const {
      id,
      user,
      workingOnDegree,
      studentDependency,
      highSchoolName,
      collegeChoices,
      createdAt,
      parentConfirmed,
      studentConfirmed,
      updatedAt,
      frankStatus,
      fafsaStatus,
      submitterLog,
      fullAddress,
      doesUserHaveFsaId,
      doesUserRememberFsaId,
    } = record;

    const parentApplicationLink = getParentApplicationRoute(parentApplication.id);

    return (
      <div className="card-container summary-view-container">
        <StudentSummaryCard
          {...user}
          address={fullAddress}
          degree={workingOnDegree}
          dependency={studentDependency}
          highSchoolName={highSchoolName}
          parentApplicationLink={parentApplicationLink}
          doesUserHaveFsaId={doesUserHaveFsaId}
          doesUserRememberFsaId={doesUserRememberFsaId}
        />
        <AcademicInformationCard
          collegeChoices={collegeChoices || []}
        />
        <ApplicationStatusCard
          createdAt={createdAt}
          parentApplicationLink={parentApplicationLink}
          parentConfirmed={parentConfirmed}
          studentConfirmed={studentConfirmed}
          updatedAt={updatedAt}
          applicationId={id}
          fafsaStatus={fafsaStatus}
          frankStatus={frankStatus}
          submitterLog={submitterLog}
        />
        <Card title="Comments">
          <CommentsTabComponent applicationType={APPLICATION_TYPES.APPLICATION} />
        </Card>
        <Card title="History">
          <HistoryList />
        </Card>
      </div>
    );
  }
}

export default withParentApplication()(SummaryView)
