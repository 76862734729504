import React, { PureComponent } from 'react';
import { func, shape, string } from 'prop-types';

import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import DateRange from './DateRange';
import {
  types as dateRangeSetTypes,
  defaultLabels as dateRangeSetDefaultLabels,
} from './constants';
import { rangeSets } from './sets';

import { dateRangeSelector } from './selectors';

import './styles.css';

const CUSTOM_DATE_RANGE = 'custom';

const styles = {
  select: {
    width: 210,
  }
};

const dateRangeSetOptions = dateRangeSetTypes.map(type => ({
  value: type,
  primaryText: dateRangeSetDefaultLabels[type],
}));

const selectOptions = [
  ...dateRangeSetOptions,
  { value: CUSTOM_DATE_RANGE, primaryText: 'Custom' }
];

const stringifyRange = range => JSON.stringify(range);

const isRangeSetType = type => dateRangeSetTypes.includes(type);

const getDateRangeByType = (type) => {
  if (isRangeSetType(type)) {
    return rangeSets[type]();
  }

  return null;
};

export default class DateRangePicker extends PureComponent {
  static propTypes = {
    label: string,
    /* eslint-disable react/require-default-props */
    input: shape({
      onChange: func,
    }),
    /* eslint-disable react/require-default-props */
  };

  static defaultProps = {
    label: 'Select date range',
  };

  state = { from: null, to: null, type: null };

  componentDidMount() {
    this.updateRangeSettings(this.props.input.value);
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props;
    const prevValue = prevProps.input && prevProps.input.value;
    const nextValue = nextProps.input && nextProps.input.value;

    const isValueChanged = prevValue !== nextValue;

    if (isValueChanged) {
      this.updateRangeSettings(nextValue);
    }
  }

  onSelectType = (event, index, type) => {
    const range = isRangeSetType(type) ? getDateRangeByType(type) : this.state;

    this.onChange({ ...range, type });
  };

  onChange = (value) => {
    const { input } = this.props;
    const { onChange } = input;

    const rangeData = { ...this.state, ...value };

    onChange(stringifyRange(rangeData));
  };

  get rangeCustom() {
    const { from, to } = this.state;
    const props = { from, to, onChange: this.onChange };

    return <DateRange {...props} />;
  }

  get rangeInput() {
    const { type } = this.state;

    if(type === CUSTOM_DATE_RANGE) {
      return this.rangeCustom;
    }

    return null;
  }

  get select() {
    const { label } = this.props;
    const { type } = this.state;

    const options = selectOptions.map(itemOptions => (
      <MenuItem key={itemOptions.value} {...itemOptions} />
    ));

    return (
      <SelectField
        style={styles.select}
        onChange={this.onSelectType}
        value={type}
        floatingLabelText={label}
      >
        {options}
      </SelectField>
    );
  }

  updateRangeSettings = (rangeJson) => {
    const dateRange = dateRangeSelector(rangeJson);

    this.setState(dateRange);
  };

  render() {
    return (
      <div className="date-range-picker">
        {this.rangeInput}
        {this.select}
      </div>
    );
  }
}
