import { arrayOf, func, shape, string } from 'prop-types';

const IColumn = {
  field: string,
  styles: shape(),
  renderer: func,
};

const IColumns = arrayOf(shape(IColumn));
const IData = arrayOf(shape());

export {
  IColumn,
  IColumns,
  IData,
}
