import {getPermissions} from "../api/localAuthStorage";

const PERMISSION_TAX = "Tax Entry";
const PERMISSION_SUMMARY = "Application Summary Tab";
const PERMISSION_REVIEW = "Review pages";
const PERMISSION_SENSITIVE_INFO = "Sensitive Information";
const PERMISSION_ASSIGN = "Assign To";
const PERMISSION_AUTOSUBMIT = "Autosubmit (send to submitter)";
const PERMISSION_ADMIN = "Admin Panel";
const PERMISSION_IMPERSONATE = "Impersonate Mode";

const hasPermissions = (required = [], or = false) => {
    const currentPermissions = getPermissions() || [];
    const has = required.filter( perm => currentPermissions.includes(perm) );

    return or ? has.length > 0 : has.length === required.length;
}

const hasAnyPermissions = (required) => hasPermissions(required, true);

const hasOnePermission = (permission) => getPermissions().includes(permission);

export {
    PERMISSION_AUTOSUBMIT,
    PERMISSION_IMPERSONATE,
    PERMISSION_ASSIGN,
    PERMISSION_REVIEW,
    PERMISSION_SUMMARY,
    PERMISSION_TAX,
    PERMISSION_SENSITIVE_INFO,
    PERMISSION_ADMIN,
    hasPermissions,
    hasOnePermission,
    hasAnyPermissions,
}
