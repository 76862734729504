import React, { PureComponent } from 'react';
import { func } from 'prop-types';
import MenuItem from 'material-ui/MenuItem';

import { changingFrankStatuses } from '../../../../../enumChoices';
import withMenuActions from '../../../../../../hocs/withMenuActions';
import { ACTIONS } from '../../../../../../hocs/withMenuActions/ActionsMessages';

const styles = {
  menuItem: {
    color: '#1b3a89',
  },
};

class FrankStatusesMenu extends PureComponent {
  static propTypes = {
    handleUpdate: func.isRequired,
  };

  onUpdate = status => {
    const { handleUpdate } = this.props;
    const field = {
      frankStatus: status,
    };
    
    handleUpdate(field, status, ACTIONS.STATUS_CHANGED);
  };

  render() {
    return changingFrankStatuses.map(status => (
      <MenuItem
        style={styles.menuItem}
        key={status.value}
        primaryText={status.label}
        onClick={() => this.onUpdate(status.value)}
      />
    ));
  }
}

export default withMenuActions(FrankStatusesMenu);
