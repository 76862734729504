import React from 'react';
import { string, func } from 'prop-types';
import { connect } from 'react-redux';
import { toggleSidebar as toggleSidebarAction } from 'admin-on-rest';
import MuiAppBar from 'material-ui/AppBar';

import HamburgerButton from './HamburgerButton';
import UserIconMenu from './UserIconMenu';
import getSettingsOfHost from '../../utils/settingsOfHost';

const settingsOfHost = getSettingsOfHost();
const styles = {
  appBar: {
    backgroundColor: settingsOfHost.backgroundColor,
  },
  title: {
    color: '#000a46',
    fontSize: 20,
    fontWeight: 'normal',
  },
  rightIcon: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  },
};

const AppBar = ({ title, toggleSidebar }) => (
  <MuiAppBar
    title={`${title} ${settingsOfHost.title}`}
    style={styles.appBar}
    titleStyle={styles.title}
    iconElementLeft={<HamburgerButton />}
    iconElementRight={<UserIconMenu />}
    iconStyleRight={styles.rightIcon}
    onLeftIconButtonClick={toggleSidebar}
  />
);

AppBar.propTypes = {
  title: string.isRequired,
  toggleSidebar: func,
};

AppBar.defaultProps = {
  toggleSidebar: () => {},
};

const mapDispatchToProps = {
  toggleSidebar: toggleSidebarAction,
};

export default connect(
  null,
  mapDispatchToProps,
)(AppBar);
