import { createFilterQuery } from '../../../services/filters';
import { DEFAULT_PER_PAGE_VAL, FRANK_STATUSES } from '../../../constants';

import { makeUrlCreator, escapeNull } from '../../../services/url';
import { extendsUrlSettingsWithSelectedTabName } from '../../../utils/utils';

const ALL = 'ALL';
const READY = 'READY';
const ESCALATED = 'ESCALATED';
const STUCK = 'STUCK';
const IN_PROGRESS = 'IN_PROGRESS';

const filterPresets = {
  [ALL]: {},
  [READY]: {
    frankStatus: createFilterQuery({
      operator: 'or',
      group: 'and',
      value: [FRANK_STATUSES.DATA_COMPLETE],
    }),
    assignedTo: createFilterQuery({
      operator: 'or',
      group: 'and',
      value: null,
    }),
  },
  [ESCALATED]: {
    escalated: true,
  },
  [STUCK]: {
    frankStatus: createFilterQuery({
      operator: 'or',
      group: 'and',
      value: [FRANK_STATUSES.MISSING_DATA]
    }),
  },
  [IN_PROGRESS]: {
    frankStatus: createFilterQuery({
      operator: 'or',
      group: 'and',
      value: [FRANK_STATUSES.MANUAL_SUBMISSION_IN_PROGRESS, FRANK_STATUSES.SUBMISSION_IN_PROGRESS]
    }),
  },
};

const urlCreator = makeUrlCreator({
  url: 'applications',
});

const linksData = (perPage = DEFAULT_PER_PAGE_VAL) => extendsUrlSettingsWithSelectedTabName([
  {
    label: 'All',
    name: ALL,
    url: urlCreator({
      queryData: {
        filter: JSON.stringify(filterPresets[ALL]),
        perPage,
      }
    })
  },
  {
    label: 'Ready',
    name: READY,
    url: urlCreator({
      queryData: {
        filter: JSON.stringify(escapeNull(filterPresets[READY])),
        perPage,
      }
    })
  },
  {
    label: 'Escalated',
    name: ESCALATED,
    url: urlCreator({
      queryData: {
        filter: JSON.stringify(filterPresets[ESCALATED]),
        perPage,
      }
    }),
  },
  {
    label: 'Stuck',
    name: STUCK,
    url: urlCreator({
      queryData: {
        filter: JSON.stringify(filterPresets[STUCK]),
        perPage: 10,
      }
    }),
  },
  {
    label: 'In progress',
    name: IN_PROGRESS,
    url: urlCreator({
      queryData: {
        filter: JSON.stringify(filterPresets[IN_PROGRESS]),
        perPage: 10,
      }
    }),
  },
]);

const indexLink = (perPage) => linksData(perPage)[0].url;

export {
  filterPresets,
  linksData,
  indexLink,
}
