import React, { memo, useState, useEffect, useRef } from 'react';
import { bool, string, number } from 'prop-types';

import CirclePlaceholder from '../CirclePlaceholder';

const holderStyles = {
  position: 'relative',
};

const getTextStyles = (active) => ({
  visibility: active ? 'visible' : 'hidden',
  color: '#00b291',
  fontWeight: '600',
  letterSpacing: '9px',
});

const getPlaceholderStyles = (active) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  display: active ? 'none' : 'block'
});

const ToggleText = (props) => {
  const { active, maxPlaceholderWidth, text } = props;
  const [holderWidth, setHolderWidth] = useState(0);
  const holderRef = useRef(null);

  useEffect(() => {
    setHolderWidth(holderRef.current.offsetWidth);
  }, []);

  const placeholderWidth = Math.min(holderWidth, maxPlaceholderWidth);

  return (
    <div style={holderStyles} ref={holderRef}>
      <div style={getTextStyles(active)}>
        {text}
      </div>
      <div style={getPlaceholderStyles(active)}>
        <CirclePlaceholder width={placeholderWidth} text={text}/>
      </div>
    </div>
  );
};

ToggleText.propTypes = {
  active: bool,
  text: string,
  maxPlaceholderWidth: number,
};

ToggleText.defaultProps = {
  active: false,
  text: '',
  maxPlaceholderWidth: 250,
};

export default memo(ToggleText);
