import React  from 'react';
import { func, shape, string, oneOfType, arrayOf } from 'prop-types';
import get from 'lodash.get';
import pure from 'recompose/pure';
import { isNil, any, curryN, pipe } from 'ramda';

const ConditionField = ({ source, condition, record, elStyle }) => {
  const getRecordValue = curryN(2, get)(record);
  const values = (
    (typeof source === 'object')
      ? source
      : source.split(',').map(sourceItem => sourceItem.trim())
  );

  const fn = any(pipe(getRecordValue, isNil));

  let out;
  if (fn(values)) out = 'N/A';
  else if (condition(record) === true) out = 'YES';
  else out = 'NO';
  return <span style={elStyle}>{out}</span>;
};

ConditionField.propTypes = {
  source: oneOfType([
    string,
    arrayOf(string),
  ]).isRequired,
  condition: func.isRequired,
  elStyle: shape(),
  record: shape(),
};

ConditionField.defaultProps = {
  record: {},
  elStyle: {},
};

const PureConditionField = pure(ConditionField);

PureConditionField.defaultProps = {
  addLabel: true,
  elStyle: {
    display: 'block',
    margin: 'auto',
  },
};

export default PureConditionField;
