import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'ramda';
import {
  Show,
  FunctionField,
} from 'admin-on-rest';

import CustomersApplicationsTitle from './CustomersApplicationsTitle';
import withProgressIndicator from '../../hocs/withProgressIndicator';
import { isLoadingSelector } from '../../selectors';
import ApplicationsLinks from './ApplicationsLinks';
import { APPLICATIONS } from '../../constants/routes';

import './style.scss';

class CustomersApplicationShow extends PureComponent {
  render() {
    return (
      <Show title={<CustomersApplicationsTitle/>} {...this.props}>
        <FunctionField
          render={({ applicationsList }) =>
            <ApplicationsLinks
              applicationsList={applicationsList}
              basePath={APPLICATIONS}
            />
          }
        />
      </Show>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: isLoadingSelector(state),
});

export default compose(
  connect(mapStateToProps),
  withProgressIndicator({size: 50}),
)(CustomersApplicationShow);
