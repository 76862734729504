import { createSelector } from 'reselect';

const recordFormSelector = (state) => (
  state.form['record-form'] || {}
);

const formInitialValuesSelector = (state) => (
  recordFormSelector(state).initial
);

const formValuesSelector = (state) => (
  recordFormSelector(state).values
);

const adminSelector = state => state.admin;

const isLoadingSelector = createSelector(
  [adminSelector],
  admin => Boolean(admin.loading),
);

const resourcesSelector = createSelector(
  [adminSelector],
  admin => admin.resources,
);

const resourceNameSelector = (_, props) => props.resource;

const resourceSelector = createSelector(
  [resourcesSelector, resourceNameSelector],
  (resources, resourceName) => resources[resourceName],
);

const listIdsSelector = createSelector(
  [resourceSelector],
  applications => applications.list.ids,
);

const clipboardButtonsSelector = state => state.clipboardButtons;
const activeClipboardButtonSelector = createSelector(
  [clipboardButtonsSelector],
  clipboardButtons => clipboardButtons.id,
);

export {
  adminSelector,
  resourceSelector,
  resourcesSelector,
  listIdsSelector,
  resourceNameSelector,
  recordFormSelector,
  formValuesSelector,
  formInitialValuesSelector,
  isLoadingSelector,
  activeClipboardButtonSelector,
}
