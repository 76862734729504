import React, { PureComponent } from 'react';
import { arrayOf, shape } from 'prop-types';

import InfoApplication from '../InfoApplication';
import './style.scss';

export default class HistoryListComponent extends PureComponent {
  static propTypes = {
    historyData: arrayOf(shape()).isRequired,
  };

  historyListItem = (item, i) => {
    const { title, editorInfo, email, updateDate } = item;

    return (
      <div key={i} className="history-list-item">
        <InfoApplication
          email={email}
          bodyText={title}
          editorInfo={editorInfo}
          date={updateDate}
        />
      </div>
    );
  };

  render() {
    const { historyData } = this.props;
    const historyList = historyData.map(this.historyListItem);

    return <div className="history-list-wrapper">{historyList}</div>;
  }
}
