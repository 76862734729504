import { getApplicationStartYear } from '../../../../../src/utils/application';
import { getStateName } from '../../../applicationHelpers';

// Common colors to use for Student or Parent section.
const studentSectionColor = 'blue';
const parentSectionColor = 'red';

const getPreviousApplicationYear = 
    applicationYear => (applicationYear && applicationYear.split('/')[0] - 1);

// Collection of all questions with label and formatter if required.
// Keeping the order of fields from this Google Doc so it will be easy to verify any update.
// https://docs.google.com/spreadsheets/d/1RPqL9Tl5OKQb8zh5SX6e0rfIW6nNtmuiDFjx-P9ja6U/edit#gid=1000726271
const Questions = (appYear) =>  ({
    'user.firstName': { label: 'First Name' },
    'user.lastName': { label: 'Last Name' },

    'user.email': { label: 'Email Address' },
    'user.phone': { label: 'Student\'s phone number' },

    'addressStreetAndNumber': { label: 'Mailing Address' },
    'homeAddressCity': { label: 'City' },
    'homeAddressState': { 
        label: 'State',
        formatter: stateAbbr => getStateName(stateAbbr),
    },
    'homeAddressZipCode': { label: 'Zip Code' },

    'livedInHomeAddressStateAtLeastFiveYears': { label: 'Have you lived in (state) for 5 years?'},
    'legalStateOfResidence': { 
        label: 'What is your legal state of residence?',
        formatter: stateAbbr => getStateName(stateAbbr),
    },
    'movedToStateOfResidenceMonth': { label: 'Legal state of residence Month' },
    'movedToStateOfResidenceYear': { label: 'Legal state of residence Year' },
    'isCitizen': { label: 'Are you a U.S. Citizen?' },
    'immigrationStatus': { label: 'What is your immigration status?' },

    'highSchoolCompletion': { label: 'How will or did you complete high school?' },
    'plannedDegree': { label: 'What degree or certificate are you pursuing?' },
    'willHaveFirstBachelorsBeforeSchoolYear': { label: 'Will you have your first bachelor\'s degree?' },
    'collegeGradeLevel': { label: 'What year of college will you be in?' },

    'gender': { label: 'Are you male or female?' },
    'gavePermissionToRegisterForSelectiveServices': { label: 'Do you want to register with the Selective Service System?' },
    'reasonForNotRegisteringForSelectiveServices': { label: 'Are you already registered with the Selective Service System?' },

    'isFosterIndependentStudent': { label: 'Are you or were you ever in the foster care system?' },
    'highestSchoolCompletedByParent1': { label: 'Highest school completed by parent 1?' },
    'highestSchoolCompletedByParent2': { label: 'Highest school completed by parent 2?' },

    'hadFinancialAidBefore': { label: 'Have you ever received federal aid?' },
    'haveYouBeenConvictedForIlegalDrugs': { label: 'Have you been convicted for the possession or sale of illegal drugs for an offense that occurred while you were receiving federal student aid (grants, work-study, and/or loans)?' },
    'haveYourFederalDrugRelatedBeenRemoved': { label: 'Have all of your federal or state drug-related convictions been removed from your record?' },
    'didPossessingDrugsOccurDuringStudentAid': { label: 'Did the offense for possessing or selling illegal drugs occur during a period of enrollment for which you were receiving federal student aid (grants, work-study, and/or loans)?' },
    'haveYouCompletedADrugRehabilitationProgram': { label: 'Have you completed a drug rehab program?' },

    'highSchoolName': { label: 'What is the name of your high school?' },
    'highSchoolCity': { label: 'In what city is your high school located?' },
    'highSchoolState': {
        label: 'In what state is your high school located?',
        formatter: stateAbbr => getStateName(stateAbbr),
    },

    'collegeChoices': { label: 'College Selection' },

    'studentMaritalStatus': { label: 'Martital status' },
    'studentMaritalStatusMonth': { label: 'Marital Month' },
    'studentMaritalStatusYear': { label: 'Marital Year' },

    'hasChildrenGettingSupport': { label: 'Do you have any children?' },
    'hasDependentsGettingSupport': { label: 'Do you have any dependents?' },
    'supportedChildren': { label: `How many children are you financially supporting in the ${appYear} school year?` },
    'childrenInCollege': { label: `How many of them will be in college during the ${appYear} school year?` },
    'otherSupportedChildren': { label: `How many dependents other than your children are you financially supporting in the ${appYear} school year?` },
    'otherChildrenInCollege': { label: `How many of them will be in college during the ${appYear} school year?` },
    'spouseGoingToCollege': { label: `Is your spouse also attending college in the ${appYear} school year?` },
    'numOfPeopleGoingToCollege': { label: 'How many in college?' },

    'isServingOrServedInArmy': { label: 'Do you currently serve or have you previously served in the U.S. Armed Forces?' },
    'armyServiceType': { label: 'Are you a veteran or currently serving?' },
    'dependentChecklistBothParentsAreDeceased': { label: 'Both parents deceased / foster care / depependent or ward of the court?' },
    'dependentChecklistIsFoster': { label: 'I am or have been in Foster Care since the age of 13' },
    'dependentChecklistIsDependentOrWardOfCourt': { label: 'I am or have been a dependent or ward of the court after the age of 13' },
    'dependentChecklistIsEmancipatedMinor': { label: 'Are you an emancipated minor?' },
    'dependentChecklistHasLegalGuardianNotAParent': { label: 'Do you have a legal guardian?' },
    'legalGuardianAppointedByCourt': { label: 'Is your legal guardian appointed by court?' },
    'dependentChecklistIsNoneOfTheAbove': { label: 'None of the above?' },

    'dependentChecklistIsHomelessOrAtRiskOfBecomingHomeless': { label: 'Are you homeless or at risk of becoming homeless?' },
    'declaredHomelessByHighSchool': {
        label: `At any time on or after July 1, ${getPreviousApplicationYear(appYear)},
            did your high school or school district homeless liaison determine that you were an
            unaccompanied  youth who was homeless or were self-supporting and at risk of being
            homeless?`,
    },
    'declaredHomelessByHUD': {
        label: `At any time on or after July 1, ${getPreviousApplicationYear(appYear)},
            did the director of an emergency shelter or transitional housing program funded by the U.S.
            Department of Housing and Urban Development determine that you were an unaccompanied
            youth who was homeless or were self-supporting and at risk of being homeless?`,
    },
    'declaredHomelessByBasicCenter': { 
        label: `At any time on or after July 1, ${getPreviousApplicationYear(appYear)},
            did the director of a runaway or homeless youth basic center or transitional living program
            determine that you were an unaccompanied youth who was homeless or were self-supporting and at
            risk of being homeless?`,
    },

    'parentsMaritalStatus': { label: 'What is the marital status of your parents?' },
    'parentsMaritalStatusMonth': { label: 'Parent Marital Month' },
    'parentsMaritalStatusYear': { label: 'Parent Marital Year' },

    'parent1FirstName': { label: 'Parent 1 first initial' },
    'parent1LastName': { label: 'Parent 1 last name' },
    'parentType': { label: 'Parent Type' },

    'parent2FirstName': { label: 'Parent 2 first initial' },
    'parent2LastName': { label: 'Parent 2 last name' },

    'parentLivedInHomeAddressStateAtLeastFiveYears': { label: 'Have your parent\'s lived in (state) for 5 years?' },
    'parentLegalStateOfResidence': { 
        label: 'What is your parent\'s legal state of residence?',
        formatter: stateAbbr => getStateName(stateAbbr),
    },
    "parentMovedToStateOfResidenceMonth": { label: 'Parent\'s legal state of residence month' },
    "parentMovedToStateOfResidenceYear": { label: 'Parent\'s legal state of residence year' },

    'howManyChildrenSupportedByParent': { label: 'How many of parents\' other children receive at least half of their financial support from parents?' },
    'howManyOtherSupportedByParent': { label: 'How many other financial dependents do your parents have?' },
    'parentNumOfPeopleGoingToCollege': { label: `Including yourself, how many people in your household will be in college during the ${appYear} school year ?` },

    'didParentCompleteTaxReturn': { label: 'Have your parents completed tax return?' },
    'parentEarningsFromWorkNoTaxReturn' : { label: 'How much did your parent earn from working ?' },
    'otherParentEarningsFromWorkNoTaxReturn': { label: 'How much did your other parent earn from working ?' },
    'parentTaxReturnType': { label: 'What type of income tax return did your parents file?' },
    'parentTaxFilingStatus': { label: 'What is your parents\' tax filing status?' },

    'parentAdjustedGrossIncome': { label: 'What was your parents adjusted gross income?' },

    'parentDislocatedWorker': { label: 'Is your parent a dislocated worker?' },
    'didParentFileSchedule1': { label: 'Did your parents file a Schedule 1?' },
    'parentBenefitMedicaid': { label: 'Medicaid?' },
    'parentBenefitSSI': { label: 'Supplemental Security Income?' },
    'parentBenefitSNAP': { label: 'SNAP?' },
    'parentBenefitLunch': { label: 'Free or reduced price school lunch?' },
    'parentBenefitTANF': { label: 'TANF?' },
    'parentBenefitWIC': { label: 'Special Supplemental...for Women, Infants, Children?' },

    'parentEarningsFromWork': { label: 'How much did parent 1 earn from working?' },

    'parentIncomeTax': { label: 'Parents income tax' },

    'parentCombatPayAmount': { label: 'Parent combat pay or special pay?' },
    'parentCollegeGrantAmount': { label: 'College grant/scholarship reported to IRS?' },
    'parentTaxLine50': { label: 'Parent Education Credits?' },
    'parentUntaxedPortionsOfIRA': { label: '[Parent] Untaxed portions of IRA distributions' },
    'parentIraDeductions': { label: 'IRA Deductions to SEP, SIMPLE, KEOGH...' },
    'parentTaxExemptInterestIncome': { label: '[Parent] Tax Exempt Interest Income' },

    'parentChildSupportPaidAmount': { label: 'Child support paid by parent' },
    'parentEarningsFromCoopWork': { label: 'Earnings from work under Cooperative Education Program' },
    'parentEarningsFromEmploymentPrograms': { label: 'Taxable earnings from need-based employment programs...' },

    'parentChildSupportReceivedAmount': { label: 'Child support received by parent' },
    'parentAllowancesAmount': { label: 'Housing, food, other allowances paid to members of military, clergy, or other' },
    'parentAmountSavedInPensions': { label: 'Payments to tax-deferred pension and retirement savings plans' },
    'otherUntaxedIncomeNotReported': { label: 'Other untaxed income not reported' },
    'parentVeteranNoneducationBenefitsAmount': { label: 'Veterans non-education benefits' },

    'parentCashBalance': { label: 'Parents balance of cash, savings, checking?' },
    'parentNetWorthOfInvestments': { label: 'Net worth of parents investments?' },
    'parentNetWorthOfBusinesses': { label: 'Net worth of parents businesses?' },

    'didStudentCompleteTaxReturn': { label: 'Has the student completed tax return?' },

    'studentEarningsFromWorkNoTaxReturn': { label: 'How much did student earn from working?' },
    'studentPartnerEarningsFromWork': { label: 'How much did student\'s spouse earn from working?' },
    'studentTaxReturnType': { label: 'What income tax return did the student file?' },
    'studentTaxFilingStatus': { label: 'Student\'s tax filing status' },

    'studentAdjustedGrossIncome': { label: 'Student\'s adjusted gross income?' },
    
    'studentDislocatedWorker': { label: 'Are you a dislocated worker?' },
    'didStudentFileSchedule1': { label: 'Did you file a Schedule 1?' },
    'studentBenefitMedicaid': { label: 'Medicaid?' },
    'studentBenefitSSI': { label: 'Supplemental Security Income?' },
    'studentBenefitSNAP': { label: 'SNAP?' },
    'studentBenefitLunch': { label: 'Free or reduced price school lunch?' },
    'studentBenefitTANF': { label: 'TANF?' },
    'studentBenefitWIC': { label: 'Special Supplemental...for Women, Infants, Children?' },

    'studentEarningsFromWork': { label: 'How much did student earn from working?' },

    'studentIncomeTax': { label: 'Student\'s income tax paid' },

    'studentCombatPayAmount': { label: 'Combat pay or special pay?' },
    'studentCollegeGrantAmount': { label: 'College grant/scholarship reported to IRS?' },
    'studentTaxLine50': { label: 'Education Credits?' },
    'studentUntaxedPortionsOfIRA': { label: 'Untaxed portions of IRA distributions' },
    'studentIraDeductions': { label: 'IRA Deductions to SEP, SIMPLE, KEOGH...' },
    'studentTaxExemptInterestIncome': { label: 'Tax Exempt Interest Income' },

    'studentChildSupportPaidAmount': { label: 'Child support paid by student' },
    'studentEarningsFromCoopWork': { label: 'Earnings from work under Cooperative Education Program' },
    'studentEarningsFromEmploymentPrograms': { label: 'Taxable earnings from need-based employment programs...' },

    'studentChildSupportReceivedAmount': { label: 'Child support received by student' },
    'studentAllowancesAmount': { label: 'Housing, food, other allowances paid to members of military, clergy, or other' },
    'studentAmountSavedInPensions': { label: 'Payments to tax-deferred pension and retirement savings plans' },
    'studentVeteranNoneducationBenefitsAmount': { label: 'Veterans non-education benefits' },
    'didYouHaveUntaxedIncomeNotReported': { label: 'Other untaxed income not reported' },
    'howMuchMoneyNotReportedOnThisForm': { label: 'Money received or paid on your behalf not reported elsewhere' },

    'studentCashBalance': { label: 'Student\'s balance of cash, savings, checking' },
    'studentNetWorthOfInvestments': { label: 'Student\'s net worth of investments' },
    'studentNetWorthOfBusinesses': { label: 'Student\'s net worth of businesses' },

    // 2019-20 and prior applications.
    'educationLevel': { label: `What will be your level of education by August ${appYear}?` },
    'otherParentEarningsFromWork': { label: 'How much did parent 2 earn from working?' },
    'parentExemptions': { label: 'Parent tax exemptions' },
    'parentUntaxedPortionsOfPensions': { label: '[Parent] Untaxed portions of pensions' },
    'studentExemptions': { label: 'Student\'s tax exemptions' },
    'studentIsOrWasMarried': { label: 'Are you currently or have you ever been married?' },
    'workingOnDegree': { label: 'Are you pursuing a graduate degree?' },

    // Not used.
    'parentTaxSchedule': { label: 'Did your parents file schedules?' },
});

const isStudentDependent = (studentDependency) => (studentDependency === 'DEPENDENT');

// Summary page questions collection for before application year 2020.
const ApplicationQuestionsBefore2020 = (studentDependency) =>  ([
    [
        'user.firstName',
        'user.lastName',
    ],
    [
        'addressStreetAndNumber',
        'homeAddressCity',
        'homeAddressState',
        'homeAddressZipCode',
        'user.email',
    ],
    [
        'livedInHomeAddressStateAtLeastFiveYears',
        'legalStateOfResidence',
        'movedToStateOfResidenceMonth',
        'movedToStateOfResidenceYear',
    ],
    [
        'gender',
        'user.phone',
    ],
    [
        'studentIsOrWasMarried',
        'studentMaritalStatus',
        'studentMaritalStatusMonth',
        'studentMaritalStatusYear',
    ],
    [
        'isCitizen',
        'immigrationStatus',
        'gavePermissionToRegisterForSelectiveServices',
        'reasonForNotRegisteringForSelectiveServices',
    ],
    [
        'highSchoolCompletion',
        'collegeGradeLevel',
        'plannedDegree',
        'educationLevel',
    ],
    [
        'isFosterIndependentStudent',
        'highestSchoolCompletedByParent1',
        'highestSchoolCompletedByParent2',
    ],
    [
        'hadFinancialAidBefore',
        'haveYouBeenConvictedForIlegalDrugs',
        'haveYourFederalDrugRelatedBeenRemoved',
        'didPossessingDrugsOccurDuringStudentAid',
    ],
    [
        'highSchoolName',
        'highSchoolCity',
        'highSchoolState',    
    ],
    {
        title: 'College Selection',
        rows: ['collegeChoices']
    },
    [
        'workingOnDegree',    
    ],
    [
        'hasChildrenGettingSupport',
        'hasDependentsGettingSupport',
    ],
    [
        'isServingOrServedInArmy',
        'armyServiceType',
        'dependentChecklistBothParentsAreDeceased',
        'dependentChecklistIsFoster',
        'dependentChecklistIsDependentOrWardOfCourt',
        'dependentChecklistIsEmancipatedMinor',
        'dependentChecklistHasLegalGuardianNotAParent',      
        'legalGuardianAppointedByCourt',
        'dependentChecklistIsNoneOfTheAbove',
    ],
    [
        'dependentChecklistIsHomelessOrAtRiskOfBecomingHomeless',
        'declaredHomelessByHighSchool',
        'declaredHomelessByHUD',
        'declaredHomelessByBasicCenter',
    ],
    [
        'parentsMaritalStatus',
        'parentsMaritalStatusMonth',
        'parentsMaritalStatusYear',
    ],
    [
        'parent1FirstName',
        'parent1LastName',
        'parentType',
    ],
    [
        'parent2FirstName',
        'parent2LastName',
    ],
    [
        'parentLivedInHomeAddressStateAtLeastFiveYears',
        'parentLegalStateOfResidence',
        'parentMovedToStateOfResidenceMonth',
        'parentMovedToStateOfResidenceYear',
    ],
    isStudentDependent(studentDependency) ? [
        'howManyChildrenSupportedByParent',
        'howManyOtherSupportedByParent',
        'parentNumOfPeopleGoingToCollege',
    ] : [
        'supportedChildren',
        'childrenInCollege',
        'otherSupportedChildren',
        'otherChildrenInCollege',
        'spouseGoingToCollege',
        'numOfPeopleGoingToCollege',
    ],
    [
        'didParentCompleteTaxReturn',
        'parentTaxReturnType',
        'parentEarningsFromWorkNoTaxReturn',
        'parentTaxFilingStatus',
    ],
    [
        'parentTaxReturnType',
        'parentAdjustedGrossIncome',
    ],
    [
        'parentEarningsFromWork',
        'otherParentEarningsFromWork',
    ],
    {
        title: 'Parent Section Benefits',
        sectionKey: 'parentHasAnyBenefits',
        rows: [
            'parentBenefitMedicaid',
            'parentBenefitSSI',
            'parentBenefitSNAP',
            'parentBenefitLunch',
            'parentBenefitTANF',
            'parentBenefitWIC',
        ],
    },
    {
        title: 'Student Section Benefits',
        sectionKey: 'studentHasAnyBenefits',
        rows: [
            'studentBenefitMedicaid',
            'studentBenefitSSI',
            'studentBenefitSNAP',
            'studentBenefitLunch',
            'studentBenefitTANF',
            'studentBenefitWIC',
        ],
    },
    [
        'parentIncomeTax',
        'parentExemptions',
    ],
    [
        'parentCombatPayAmount',
        'parentCollegeGrantAmount',
        'parentTaxLine50',
        'parentIraDeductions',
        'parentTaxExemptInterestIncome',
        'parentUntaxedPortionsOfIRA',
        'parentUntaxedPortionsOfPensions',
    ],
    [
        'parentChildSupportPaidAmount',
        'parentEarningsFromCoopWork',
        'parentEarningsFromEmploymentPrograms',
    ],
    [
        'parentChildSupportReceivedAmount',
        'parentAllowancesAmount',
        'parentAmountSavedInPensions',
        'otherUntaxedIncomeNotReported',
        'parentVeteranNoneducationBenefitsAmount',
    ],
    [
        'parentCashBalance',
        'parentNetWorthOfInvestments',
        'parentNetWorthOfBusinesses',
    ],
    [
        'didStudentCompleteTaxReturn',
        'studentEarningsFromWorkNoTaxReturn',
        'studentTaxFilingStatus',
        'studentTaxReturnType',
    ],
    [
        'studentTaxReturnType',
        'studentAdjustedGrossIncome',
        'studentEarningsFromWork',
        'studentPartnerEarningsFromWork',
    ],
    [
        'studentIncomeTax',
        'studentExemptions',
    ],
    [
        'studentCombatPayAmount',
        'studentCollegeGrantAmount',
        'studentTaxLine50',
        'studentIraDeductions',
        'studentTaxExemptInterestIncome',
        'studentUntaxedPortionsOfIRA',
        'studentUntaxedPortionsOfPensions',
    ],
    [
        'studentChildSupportPaidAmount',
        'studentEarningsFromCoopWork',
        'studentEarningsFromEmploymentPrograms',
    ],
    [
        'studentChildSupportReceivedAmount',
        'studentAllowancesAmount',
        'studentAmountSavedInPensions',
        'studentVeteranNoneducationBenefitsAmount',
        'didYouHaveUntaxedIncomeNotReported',
        'howMuchMoneyNotReportedOnThisForm',
    ],
    [
        'studentCashBalance',
        'studentNetWorthOfInvestments',
        'studentNetWorthOfBusinesses',
    ]
]);

// Summary page questions collection for after application year 2019.
const ApplicationQuestions = (studentDependency) => ([
    {
        theme: studentSectionColor,
        rows: [
            'user.firstName',
            'user.lastName',
        ],
    },
    {
        theme: studentSectionColor,
        rows: [
            'user.email',
            'user.phone',
        ],
    },
    {
        theme: studentSectionColor,
        rows: [
            'addressStreetAndNumber',
            'homeAddressCity',
            'homeAddressState',
            'homeAddressZipCode',
        ],
    },
    {
        theme: studentSectionColor,
        rows: [
            'livedInHomeAddressStateAtLeastFiveYears',
            'legalStateOfResidence',
            'movedToStateOfResidenceMonth',
            'movedToStateOfResidenceYear',
            'isCitizen',
            'immigrationStatus',
        ],
    },
    {
        theme: studentSectionColor,
        rows: [
            'highSchoolCompletion',
            'plannedDegree',
            'willHaveFirstBachelorsBeforeSchoolYear',
            'collegeGradeLevel',
        ],
    },
    {
        theme: studentSectionColor,
        rows: [
            'gender',
            'gavePermissionToRegisterForSelectiveServices',
            'reasonForNotRegisteringForSelectiveServices',       
        ],
    },
    {
        theme: studentSectionColor,
        rows: [
            'isFosterIndependentStudent',
            'highestSchoolCompletedByParent1',
            'highestSchoolCompletedByParent2',
        ],
    },
    {
        theme: studentSectionColor,
        rows: [
            'hadFinancialAidBefore',
            'haveYouBeenConvictedForIlegalDrugs',
            'haveYourFederalDrugRelatedBeenRemoved',
            'didPossessingDrugsOccurDuringStudentAid',
            'haveYouCompletedADrugRehabilitationProgram',
        ],
    },
    {
        theme: studentSectionColor,
        rows: [
            'highSchoolName',
            'highSchoolCity',
            'highSchoolState',    
        ],
    },
    {
        title: 'College Selection',
        rows: ['collegeChoices']
    },
    {
        theme: studentSectionColor,
        rows: [
            'studentMaritalStatus',
            'studentMaritalStatusMonth',
            'studentMaritalStatusYear',
        ],
    },
    {
        theme: studentSectionColor,
        rows: [
            'hasChildrenGettingSupport',
            'supportedChildren',
            'childrenInCollege',
            'hasDependentsGettingSupport',
            'otherSupportedChildren',
            'otherChildrenInCollege',
            'spouseGoingToCollege',
            'numOfPeopleGoingToCollege',
        ],
    },
    {
        theme: studentSectionColor,
        rows: [
            'isServingOrServedInArmy',
            'armyServiceType',
            'dependentChecklistBothParentsAreDeceased',
            'dependentChecklistIsFoster',
            'dependentChecklistIsDependentOrWardOfCourt',
            'dependentChecklistIsEmancipatedMinor',
            'dependentChecklistHasLegalGuardianNotAParent',   
            'legalGuardianAppointedByCourt',  
            'dependentChecklistIsNoneOfTheAbove',
        ],
    },
    {
        theme: studentSectionColor,
        rows: [
            'dependentChecklistIsHomelessOrAtRiskOfBecomingHomeless',
            'declaredHomelessByHighSchool',
            'declaredHomelessByHUD',
            'declaredHomelessByBasicCenter',
        ],
    },
    {
        theme: parentSectionColor,
        rows: [
            'parentsMaritalStatus',
            'parentsMaritalStatusMonth',
            'parentsMaritalStatusYear',
        ],
    },
    {
        theme: parentSectionColor,
        rows: [
            'parent1FirstName',
            'parent1LastName',
            'parentType',
        ],
    },
    {
        theme: parentSectionColor,
        rows: [
            'parent2FirstName',
            'parent2LastName',
        ],
    },
    {
        theme: parentSectionColor,
        rows: isStudentDependent(studentDependency) ? [
            'parentLivedInHomeAddressStateAtLeastFiveYears',
            'parentLegalStateOfResidence',
            'parentMovedToStateOfResidenceMonth',
            'parentMovedToStateOfResidenceYear',
        ] : [
        ],
    },
    {
        theme: parentSectionColor,
        rows: isStudentDependent(studentDependency) ? [
            'howManyChildrenSupportedByParent',
            'howManyOtherSupportedByParent',
            'parentNumOfPeopleGoingToCollege',
        ] : [
        ],
    },
    {
        theme: parentSectionColor,
        rows: isStudentDependent(studentDependency) ? [
            'didParentCompleteTaxReturn',
            'parentEarningsFromWorkNoTaxReturn',
            'otherParentEarningsFromWorkNoTaxReturn',
            'parentTaxReturnType',
            'parentTaxFilingStatus',
        ] : [
        ],
    },
    {
        theme: parentSectionColor,
        rows: [
            'parentAdjustedGrossIncome',
        ],
    },
    {
        title: 'Parent Section Benefits',
        sectionKey: 'parentHasAnyBenefits',
        theme: parentSectionColor,
        rows: [
            'parentDislocatedWorker',
            'didParentFileSchedule1',
            'parentBenefitMedicaid',
            'parentBenefitSSI',
            'parentBenefitSNAP',
            'parentBenefitLunch',
            'parentBenefitTANF',
            'parentBenefitWIC',
        ],
    },
    {
        theme: parentSectionColor,
        rows: [
            'parentEarningsFromWork',
        ],
    },
    {
        theme: parentSectionColor,
        rows: [
            'parentIncomeTax',
        ],
    },
    {
        theme: parentSectionColor,
        rows: [
            'parentCombatPayAmount',
            'parentCollegeGrantAmount',
            'parentTaxLine50',
            'parentUntaxedPortionsOfIRA',
            'parentIraDeductions',
            'parentTaxExemptInterestIncome',
        ],
    },    
    {
        theme: parentSectionColor,
        rows: [
            'parentChildSupportPaidAmount',
            'parentEarningsFromCoopWork',
            'parentEarningsFromEmploymentPrograms',
        ],
    },
    {
        theme: parentSectionColor,
        rows: [
            'parentChildSupportReceivedAmount',
            'parentAllowancesAmount',
            'parentAmountSavedInPensions',
            'parentVeteranNoneducationBenefitsAmount',
            'otherUntaxedIncomeNotReported',
        ],
    },
    {
        theme: parentSectionColor,
        rows: [
            'parentCashBalance',
            'parentNetWorthOfInvestments',
            'parentNetWorthOfBusinesses',
        ],
    },
    {
        theme: studentSectionColor,
        rows: [
            'didStudentCompleteTaxReturn',
        ],
    },
    {
        theme: studentSectionColor,
        rows: [
            'studentEarningsFromWorkNoTaxReturn',
            'studentPartnerEarningsFromWork',
            'studentTaxReturnType',
            'studentTaxFilingStatus',
        ],
    },
    {
        theme: studentSectionColor,
        rows: [
            'studentAdjustedGrossIncome',
        ],
    },
    {
        title: 'Student Section Benefits',
        sectionKey: 'studentHasAnyBenefits',
        theme: studentSectionColor,
        rows: [
            'studentDislocatedWorker',
            'didStudentFileSchedule1',
            'studentBenefitMedicaid',
            'studentBenefitSSI',
            'studentBenefitSNAP',
            'studentBenefitLunch',
            'studentBenefitTANF',
            'studentBenefitWIC',
        ],
    },
    {
        theme: studentSectionColor,
        rows: [
            'studentEarningsFromWork',
        ],
    },
    {
        theme: studentSectionColor,
        rows: [
            'studentIncomeTax',
        ],
    },
    {
        theme: studentSectionColor,
        rows: [
            'studentCombatPayAmount',
            'studentCollegeGrantAmount',
            'studentTaxLine50',
            'studentUntaxedPortionsOfIRA',
            'studentIraDeductions',
            'studentTaxExemptInterestIncome',
        ],
    },
    {
        theme: studentSectionColor,
        rows: [
            'studentChildSupportPaidAmount',
            'studentEarningsFromCoopWork',
            'studentEarningsFromEmploymentPrograms',
        ],
    },
    {
        theme: studentSectionColor,
        rows: [
            'studentChildSupportReceivedAmount',
            'studentAllowancesAmount',
            'studentAmountSavedInPensions',
            'studentVeteranNoneducationBenefitsAmount',
            'didYouHaveUntaxedIncomeNotReported',
            'howMuchMoneyNotReportedOnThisForm',
        ],
    },
    {
        theme: studentSectionColor,
        rows: [
            'studentCashBalance',
            'studentNetWorthOfInvestments',
            'studentNetWorthOfBusinesses',
        ]
    },
]);

// Get the application related questions for summary page based on an application year.
const getApplicationQuestions = (application) => {
    const appYear = getApplicationStartYear(application)
    if (appYear && appYear < 2020) {
        return ApplicationQuestionsBefore2020(application.studentDependency);
    }
    return ApplicationQuestions(application.studentDependency);
};

export { Questions, getApplicationQuestions };