import isEqual from 'is-equal';

const stringToArray = (string, defaultValue = []) => (typeof string === 'string' ? string.split(',').map(value => value.trim()) : defaultValue);

const isArraysEqual = (array1, array2) => (
  isEqual([...array1].sort(), [...array2].sort())
);

const excludeExtraUndefinedClientValue = (array) => {
  if (!Array.isArray(array)) {
    return array;
  }

  return array.filter(value => value !== 'undefined');
};

export {
  stringToArray,
  isArraysEqual,
  excludeExtraUndefinedClientValue,
};
