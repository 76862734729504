import React, { PureComponent } from 'react';
import { func } from 'prop-types';

import Button, { BUTTON_BG_COLOR2 } from './Button';

export default class ToggleButton extends PureComponent {
  static propTypes = {
    onToggle: func,
    onClick: func,
  };

  static defaultProps = {
    onToggle: () => {},
    onClick: () => {},
  };

  state = { isActive: false };

  onClick = (e) => {
    const { isActive } = this.state;
    const { onToggle, onClick } = this.props;

    const nextIsActive = !isActive;

    this.setState({ isActive: nextIsActive });

    onToggle(nextIsActive);
    onClick(e);
  };

  render() {
    const { isActive } = this.state;
    const style = {
      backgroundColor: isActive ? BUTTON_BG_COLOR2 : undefined,
    };

    return (
      <Button
        {...this.props}
        {...style}
        onClick={this.onClick}
      />
    );
  }
}
