import { PureComponent, Children, cloneElement } from 'react';
import { node } from 'prop-types';

export default class PropsExtender extends PureComponent {
  static propTypes = {
    children: node,
  };

  static defaultProps = {
    children: null,
  };

  render() {
    const { children, ...props } = this.props;

    return (
      Children.map(
        children,
        child => (child ? cloneElement(child, props) : null)
      )
    );
  }
}
