import studentFieldNames2018 from './student-field-names';

const commonLineInputNames = [
  'studentTaxLine1',
  'studentTaxLine2a',
  'studentTaxLine4a',
  'studentTaxLine4b',
  'studentTaxLine4c',
  'studentTaxLine4d',
  'studentTaxLine7',
  'studentTaxLine13',
];

const taxSchedule1Line8FieldName = 'studentTaxSchedule1Line21';

const didFileSchedule1GainsOrLossesFromVirtualCurrencyFieldName = 'didStudentFileSchedule1GainsOrLossesFromVirtualCurrency';

const schedule1AdditionsLineInputNames = [
  'studentTaxSchedule1Line21',
];

export default {
  ...studentFieldNames2018,
  commonLineInputNames,
  taxSchedule1Line8FieldName,
  didFileSchedule1GainsOrLossesFromVirtualCurrencyFieldName,
  schedule1AdditionsLineInputNames,
};
