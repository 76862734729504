import React, { PureComponent } from 'react';
import { string, func, shape } from 'prop-types';
import DatePicker from 'material-ui/DatePicker';
import RemoveIcon from 'material-ui/svg-icons/action/highlight-off';

import { toStartOfDay, toEndOfDay } from '../date';

import './styles.css';

const commonPickerProps = {
  textFieldStyle: { width: 90 },
  autoOk: true,
};

const removeIconStyle = {
  width: 15,
  height: 15,
  cursor: 'pointer',
};

const isFutureDay = date => date > new Date();

const shouldDisableFromDate = (toDate) => (date) => {
  if (isFutureDay(date)) {
    return true;
  }

  if (toDate) {
    return date > toDate;
  }

  return false;
};

const shouldDisableToDate = (fromDate) => (date) => {
  if (isFutureDay(date)) {
    return true;
  }

  if (fromDate) {
    return fromDate > date;
  }

  return false;
};

export default class DateRange extends PureComponent {
  static propTypes = {
    onChange: func.isRequired,
    from: shape(),
    to: shape(),
    fromLabel: string,
    toLabel: string,
  };

  static defaultProps = {
    fromLabel: 'Start date',
    toLabel: 'End date',
    from: null,
    to: null,
  };

  onChange = (value) => {
    const { onChange } = this.props;

    onChange(value);
  };

  onFromChange = (e, value) => {
    const { to } = this.props;

    const data = {
      from: toStartOfDay(value),
      to,
    };

    this.onChange(data);
  };

  onToChange = (e, value) => {
    const { from } = this.props;
    const data = {
      from,
      to: toEndOfDay(value),
    };

    this.onChange(data);
  };

  resetFrom = () => {
    this.onFromChange(null);
  };

  resetTo = () => {
    this.onToChange(null);
  };

  render() {
    const { fromLabel, toLabel } = this.props;
    const { from, to } = this.props;

    const fromProps = {
      ...commonPickerProps,
      hintText: fromLabel,
      onChange: this.onFromChange,
      value: from,
      shouldDisableDate: shouldDisableFromDate(to),
    };

    const toProps = {
      ...commonPickerProps,
      hintText: toLabel,
      onChange: this.onToChange,
      value: to,
      shouldDisableDate: shouldDisableToDate(from),
    };

    return (
      <div className="date-range-picker">
        <div className="date-range-picker__block">
          <div className="date-range-picker__section">
            <div className="date-range-picker__input-holder">
              <DatePicker {...fromProps} />
            </div>
            <RemoveIcon onClick={this.resetFrom} style={removeIconStyle} />
          </div>
          <div className="date-range-picker__section">
            <div className="date-range-picker__input-holder">
              <DatePicker {...toProps} />
            </div>
            <RemoveIcon  onClick={this.resetTo} style={removeIconStyle} />
          </div>
        </div>
      </div>
    );
  }
}
