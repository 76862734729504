import { createSelector } from "reselect";
import * as R from 'ramda';

import { resourceSelector } from '../../../../../selectors';

const selectedApplicationIdsSelector = (_, props) => (props.selectedIds || []);

const hasNonEmptyFafsaFollowUp = R.propSatisfies(
  R.complement(
    R.either(R.isEmpty, R.isNil)
  ), 
  'betaFafsaStatus',
);

const selectedApplicationsDataSelector = createSelector(
  [resourceSelector, selectedApplicationIdsSelector],
  (resource, selectedApplicationIds) => (
    Object
      .values(resource.data)
      .filter(({ id }) => selectedApplicationIds.includes(id))
  ),
);

export const isEnabledSelector = createSelector(
  [selectedApplicationsDataSelector],
  R.all(hasNonEmptyFafsaFollowUp),
);