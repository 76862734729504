import React from 'react';
import { shape, string } from 'prop-types';

const Title = ({ record }) => (
  <span>{`${record.firstName} ${record.lastName}`}</span>
);

Title.propTypes = {
  record: shape({
    firstName: string,
    lastName: string,
  }),
};

Title.defaultProps = {
  record: {
    firstName: '',
    lastName: '',
  }
};

export default Title;
