import React, { memo } from 'react';
import { shape, arrayOf, func } from 'prop-types';
import { ContentSort } from 'material-ui/svg-icons';
import { MenuItem, SelectField } from 'material-ui';

const dropDownMenuProps = {
  labelStyle: { paddingLeft: 25 },
  iconButton:  <ContentSort />,
};

const underlineStyle = {
  borderColor: '#2754c4',
};

const getIconStyle = (direction) => {
  const isDescDirection = direction.toLowerCase() === 'desc';

  return {
    right: 'auto',
    left: 0,
    fill: '#000',
    padding: 0,
    width: 20,
    transform: isDescDirection ? '' : 'rotate(180deg)',
  };
};

const getStyleProps = direction => ({
  dropDownMenuProps,
  underlineStyle,
  iconStyle: getIconStyle(direction),
});

const SortButton = (props) => {
  const { options, value, onChange } = props;

  const list = options.map(option => (
    <MenuItem
      value={option}
      key={option.label}
      primaryText={option.label}
    />
  ));

  const { direction = '' } = value;

  return (
    <SelectField
      value={value}
      onChange={onChange}
      {...getStyleProps(direction)}
    >
      {list}
    </SelectField>
  );
};

SortButton.propTypes = {
  options: arrayOf(shape()).isRequired,
  onChange: func.isRequired,
  value: shape(),
};

SortButton.defaultProps = {
  value: {},
};

export default memo(SortButton);
