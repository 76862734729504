import React from 'react';
import { shape, string } from 'prop-types';

const Partner = ({ faasPartner }) => {
  if (faasPartner) {
    return <span>{faasPartner.name}</span>
  }

  return null;
};

Partner.propTypes = {
  faasPartner: shape({
    name: string,
  }),
};

Partner.defaultProps = {
  faasPartner: {},
};

export default Partner;
