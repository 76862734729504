import React, { PureComponent } from 'react';

export default Component => {
  class WithMenu extends PureComponent {
    state = {
      isOpen: false,
      anchorEl: {},
    };

    onRequestClose = () => {
      this.setState({ isOpen: false });
    };

    toggle = e => {
      const { isOpen } = this.state;

      this.setState({ isOpen: !isOpen, anchorEl: e.currentTarget });
    };

    render() {
      const { isOpen, anchorEl } = this.state;

      return (
        <Component
          isOpen={isOpen}
          anchorEl={anchorEl}
          toggle={this.toggle}
          onRequestClose={this.onRequestClose}
          {...this.props}
        />
      );
    }
  }

  return WithMenu;
};
