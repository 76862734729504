import React from 'react';
import { func, shape, string } from 'prop-types';
import { CardActions } from 'material-ui/Card';

const Actions = props => {
  const {
    resource,
    filters,
    displayedFilters,
    filterValues,
    showFilter,
  } = props;

  return (
    <CardActions>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
    </CardActions>
  );
};

Actions.propTypes = {
  filters: shape(),
  displayedFilters: shape(),
  filterValues: shape(),
  showFilter: func,
  resource: string,
};

Actions.defaultProps = {
  filters: {},
  displayedFilters: {},
  filterValues: {},
  showFilter: () => {},
  resource: '',
};

export default Actions;
