import { createSelector } from 'reselect';

import { resourceSelector } from '../../../../../selectors';
import { frankStatuses } from '../../../../../constants/values';

const selectedApplicationIdsSelector = (_, props) => (props.applicationIds || []);

const isApplicationAllowedForSubmit = ({ frankStatus }) => (
  frankStatus === frankStatuses.DATA_COMPLETE
);

const selectedApplicationsDataSelector = createSelector(
  [resourceSelector, selectedApplicationIdsSelector],
  (resource, selectedApplicationIds) => (
    Object
      .values(resource.data)
      .filter(({ id }) => selectedApplicationIds.includes(id))
  ),
);

export const applicationForSubmitSelector = createSelector(
  [selectedApplicationsDataSelector],
  (selectedApplicationsData) => (
    selectedApplicationsData.filter(isApplicationAllowedForSubmit)
  )
);

export const notAllowedForSubmitApplicationsSelector = createSelector(
  [selectedApplicationsDataSelector],
  selectedApplicationsData => (
    selectedApplicationsData.filter(application => (
      !isApplicationAllowedForSubmit(application)
    ))
  ),
);

