import React, { PureComponent } from 'react';
import { string, number } from 'prop-types';

export default class CirclePlaceholder extends PureComponent {
  static propTypes = {
    color: string,
    size: number,
    margin: number,
    text: string.isRequired,
  };

  static defaultProps = {
    color: '#000a46',
    size: 10,
    margin: 10,
  };

  getItemStyle = (isLast) => {
    const { size, color, margin } = this.props;

    return {
      width: size,
      height: size,
      marginRight: isLast ? 0 : margin,
      background: color,
      borderRadius: size / 2,
    };
  };

  render() {
    const { text } = this.props;
    const textLength = text.length;

    const circles = Array.from({ length: textLength }, (_, i) => (
      <div key={i} style={this.getItemStyle(i === (textLength - 1))} />
    ));

    return (
      <div style={{ display: 'flex' }}>
        {circles}
      </div>
    );
  }
}
