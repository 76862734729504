import React, { memo } from 'react';
import { string, shape } from 'prop-types';

import SystemUpdateAvatar from './SystemUpdateAvatar';
import Avatar from '../Avatar';
import { getDateInfoObject } from '../../Applications/applicationHelpers';

import './styles.scss';

export const INFO_TYPE = {
  COMMENT: 'comment',
  HISTORY: 'history',
};

const InfoApplication = ({
  email,
  editorInfo,
  date,
  bodyText,
  infoType,
  children,
  commentClass,
}) => {
  const dateInfo = getDateInfoObject(date);

  return (
    <>
      <div className="info-application-date">
        <span>{dateInfo.date}</span>
        <span>{dateInfo.time}</span>
      </div>
      {infoType === INFO_TYPE.COMMENT ? (
        <Avatar userName={email} />
      ) : (
        <SystemUpdateAvatar email={email} />
      )}
      <div className="info-application">
        <span className="info-application-by">{editorInfo}</span>
        <div className={`info-application-body ${commentClass}`}>
          {bodyText}
        </div>
        {children}
      </div>
    </>
  );
};

InfoApplication.propTypes = {
  date: string.isRequired,
  bodyText: string.isRequired,
  email: string,
  editorInfo: string,
  infoType: string,
  children: shape(),
  commentClass: string,
};

InfoApplication.defaultProps = {
  email: '',
  editorInfo: '',
  infoType: INFO_TYPE.HISTORY,
  children: null,
  commentClass: '',
};

export default memo(InfoApplication);
