import React, { PureComponent } from 'react';
import { string } from 'prop-types';

import {
  Datagrid,
  TextField,
  DateField,
  ShowButton,
  FunctionField,
} from 'admin-on-rest';

import ApplicationFilter from '../../Filter';
import Actions from '../../../components/Actions';
import {
  getParentEscalatedStatus,
  getFullNameFromParentApplication,
  getInParentApplicationOrUser,
  getParentInviteStatus,
} from '../../applicationHelpers';
import { getApplicationShowUrl } from '../../../services/url';
import CommentsButton from '../../../components/buttons/CommentsButton';
import CommonList from '../../../components/CommonList';
import withPermissions from '../../../hocs/withPermissions';
import { TAB_NAMES } from '../ParentApplicationShow';
import NewTabLink from '../../../components/NewTabLink';

import '../../style.scss';

class ParentApplicationList extends PureComponent {
  static propTypes = {
    permissions: string.isRequired,
  };

  render() {
    return (
      <CommonList
        {...this.props}
        actions={<Actions />}
        filters={<ApplicationFilter />}
        title="Parent Applications"
        listClassName="applications-list"
      >
        <Datagrid>
          <DateField
            source="updatedAt"
            label="Updated"
            showTime
          />
          <FunctionField
            source="application.user.email"
            label="Student Email"
            render={getInParentApplicationOrUser('email')}
          />
          <TextField source="applicationYear" label="Year" />
          <FunctionField
            source="application.user.firstName"
            label="Name"
            render={getFullNameFromParentApplication}
          />
          <FunctionField
            label="Student Application"
            source="applicationId"
            render={({ applicationId }) => (
              <NewTabLink to={getApplicationShowUrl('/applications', applicationId)}>
                {applicationId}
              </NewTabLink>
            )}
          />
          <FunctionField
            label="Parent Invite Status"
            source="parentInvite"
            render={getParentInviteStatus}
          />
          <FunctionField
            label="Escalated"
            sortable={false}
            render={getParentEscalatedStatus}
          />
          <ShowButton />
          <FunctionField
            render={({ id, commentsCount }) => (
              <CommentsButton
                href={`#/parents-applications/${id}/show?tab=${TAB_NAMES.COMMENTS}`}
                amount={commentsCount}
              />
            )}
          />
        </Datagrid>
      </CommonList>
    )
  }
}

export default withPermissions(ParentApplicationList);
