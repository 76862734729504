import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { string, shape } from 'prop-types';
import { compose } from 'ramda';
import { Datagrid, FunctionField } from 'admin-on-rest';

// import { SUPPORT } from '../../../constants';
import CommonList from '../../../components/CommonList';
import withPermissions from '../../../hocs/withPermissions';
import Actions from './Actions';
import ApplicationFilter from '../../Filter';
import {
  getFullNameFromApplication,
  getInApplicationOrUser,
  getUserPhoneNumber,
  getFrankBetaStatus,
  getFafsaBetaStatus,
} from '../../applicationHelpers';
import SubmitterLog from '../../../components/columns/SubmitterLog';
import LatestTimestamp from '../../../components/columns/LatestTimestamp';
import YearField from '../../../components/columns/YearField';
import FrankStatus from './FrankStatus';
import FafsaStatus from './FafsaStatus';
import TaxAutomationStatus from './TaxAutomationStatus';
import Flags from './Flags';
import NewTabLink from '../../../components/NewTabLink';
import { getApplicationShowUrl } from '../../../services/url';
import Avatar from '../../../components/Avatar';
import Partner from '../../../components/columns/Partner';

import '../../style.scss';

const styles = {
  chip: {
    margin: 4,
  },
  chipsList: {
    flexDirection: 'column',
    alignItems: 'center',
    padding: '5px 0',
  },
  leftAlignHeader: {
    textAlign: 'left',
  },
  leftAlignColumn: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
};

class StudentApplicationsList extends PureComponent {
  static propTypes = {
    permissions: string,
    title: string,
    location: shape(),
  };

  static defaultProps = {
    permissions: '',
    title: 'Student Applications',
    location: {},
  };

  render() {
    const { permissions, location } = this.props;
    // Note: Keeping it open to all authenticated users.
    // const isSupport = permissions.includes(SUPPORT);
    const isSupport = true;

    const props = {
      ...this.props,
      actions: <Actions />,
      filters: <ApplicationFilter isStudentApplications />,
    };



    return (
      <CommonList
        {...props}
        displayCheckboxes={isSupport}
        permissions={permissions}
        listClassName="applications-list student-applications"
      >
        <Datagrid {...props}>
          <FunctionField
            label="Partner"
            source="faasPartner.name"
            headerStyle={styles.leftAlignHeader}
            elStyle={styles.leftAlignColumn}
            render={({ faasPartner }) => <Partner faasPartner={faasPartner} />}
          />
          <FunctionField
            label="ID"
            source="id"
            render={({ id }) => (
              <NewTabLink to={getApplicationShowUrl(location.pathname, id)}>
                {id}
              </NewTabLink>
            )}
          />
          <FunctionField
            label="Updated"
            source="lastSignificantUpdate"
            headerStyle={styles.leftAlignHeader}
            elStyle={styles.leftAlignColumn}
            render={record => <LatestTimestamp record={record} />}
          />
          <FunctionField
            label="Year"
            source="applicationYear"
            headerStyle={styles.leftAlignHeader}
            elStyle={styles.leftAlignColumn}
            render={({ applicationYear }) => (
              <YearField year={applicationYear} />
            )}
          />
          <FunctionField
            label="Student"
            source="user.firstName"
            headerStyle={styles.leftAlignHeader}
            elStyle={styles.leftAlignColumn}
            render={record => (
              <div className="student-info">
                <div>{getFullNameFromApplication(record)}</div>
                <div>{getUserPhoneNumber(record)}</div>
                <div>{getInApplicationOrUser('email', record)}</div>
              </div>
            )}
          />
          <FunctionField
            label="Assigned to"
            source="assignedTo"
            render={({ assignedTo }) => <Avatar userName={assignedTo || ''} />}
          />
          <FunctionField
            label="Tax Automation Status"
            sortable={false}
            render={TaxAutomationStatus}
          />
          <FunctionField
            label="Frank Status"
            source="frankStatus"
            render={FrankStatus}
          />
          <FunctionField
            label="Flags"
            source="flags"
            elStyle={styles.chipsList}
            render={Flags}
          />
          <FunctionField
            label="FAFSA® Status"
            render={FafsaStatus}
          />
          <FunctionField
            label="Frank Follow UP"
            source="betaFrankStatus"
            render={getFrankBetaStatus}
          />
          <FunctionField
            label="FAFSA Follow UP"
            source="betaFafsaStatus"
            render={getFafsaBetaStatus}
          />
          <FunctionField
            label="Submitter Log"
            sortable={false}
            render={SubmitterLog}
          />
        </Datagrid>
      </CommonList>
    );
  }
}

export default compose(
  withRouter,
  withPermissions,
)(StudentApplicationsList);
