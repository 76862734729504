import { curry, find, propEq } from 'ramda';

import statesMapping from './statesMapping';
import { phoneFormatter, date } from '../utils/formatter';

import { PARENT_INVITE_STATUS } from '../constants';
import { DATE_FORMAT_10, DATE_FORMAT_9 } from '../constants/date-formats';

const getInApplicationOrUserImpl = curry((isParentApplication, key, rec) => {
  const application = isParentApplication ? rec.application : rec;

  const applicationUser = application.user && application.user[key];
  return application[key] || applicationUser;
});

const getFullName = curry((isParentApplication, r) => {
  const firstName = getInApplicationOrUserImpl(
    isParentApplication,
    'firstName',
    r,
  );
  const lastName = getInApplicationOrUserImpl(
    isParentApplication,
    'lastName',
    r,
  );
  return `${firstName} ${lastName}`;
});

export const getFullNameFromApplication = getFullName(false);
export const getFullNameFromParentApplication = getFullName(true);

export const getInApplicationOrUser = getInApplicationOrUserImpl(false);
export const getInParentApplicationOrUser = getInApplicationOrUserImpl(true);

export const getStudentReview = ({ studentReview }) => (studentReview ? 'YES' : 'NO');
export const getEscalatedStatus = ({ escalated }) => (escalated ? 'YES' : 'NO');
export const getParentEscalatedStatus = ({ application }) => getEscalatedStatus(application);
export const getFrankBetaStatus = ({ betaFrankStatus }) => betaFrankStatus;
export const getFafsaBetaStatus = ({ betaFafsaStatus }) => betaFafsaStatus;

export const getParentInviteStatus = (record) => {
  const { application, didParentAcceptInvite } = record;
  const { parentConfirmed, didInviteParent } = application;

  if (!didInviteParent && !parentConfirmed) { return null; }
  if (parentConfirmed) { return PARENT_INVITE_STATUS.SUBMITTED; }
  if (didParentAcceptInvite) { return PARENT_INVITE_STATUS.ACCEPTED; }

  return PARENT_INVITE_STATUS.INVITED;
};

export const getUserPhoneNumber = ({ user }) => {
  const { phone } = user;

  if (!phone) { return null; }

  return phoneFormatter(phone)
};

export const getDateInfoObject = value => {
  const dateValue = date(value, DATE_FORMAT_10);
  const timeValue = date(value, DATE_FORMAT_9);

  return { date: dateValue, time: timeValue};
};

export const getStateName = (stateAbbr) => {
  const searchStateByAbbreviation = propEq('abbreviation', stateAbbr);

  const state = find(searchStateByAbbreviation, statesMapping);

  return state && state.name;
};
