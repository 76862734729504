import React, { PureComponent } from 'react';
import { arrayOf, oneOfType, string, number, func, shape } from 'prop-types';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { FlatButton } from 'material-ui';
import ImageFlashOn from 'material-ui/svg-icons/image/flash-on';

import {
  Notification,
  showNotification as showNotificationAction,
  refreshView as refreshViewAction,
} from 'admin-on-rest';

import Confirmation from './Confirmation';
import withAccess from '../../../../../hocs/withAccess';
import {
  applicationForSubmitSelector,
  notAllowedForSubmitApplicationsSelector,
} from './selectors';
import { sendApplicationsToSubmitter } from '../../../../../api/submitter';
import {PERMISSION_AUTOSUBMIT} from "../../../../../utils/permissions";

const styles = {
  activeLabel: {
    color: '#ffffff',
  },
  disabledLabel: {
    color: '#f5f5f5',
  },
};

const getSuccessMessage = submittedApplications =>
  `${submittedApplications.length} application${
    submittedApplications.length > 1 ? 's' : ''
  } sent to submitter`;

class SendApplicationsToSubmitterButton extends PureComponent {
  static propTypes = {
    showNotification: func.isRequired,
    applicationIds: arrayOf(oneOfType([string, number])).isRequired,
    label: string,
    applicationForSubmit: arrayOf(shape()),
    notAllowedForSubmitApplications: arrayOf(shape()),
    refreshView: func,
    resetSelectedIds: func,
  };

  static defaultProps = {
    label: 'Autosubmit',
    applicationForSubmit: [],
    notAllowedForSubmitApplications: [],
    refreshView: () => {},
    resetSelectedIds: () => {},
  };

  state = { isConfirmationOpen: false };

  openConfirmation = () => {
    this.setState({ isConfirmationOpen: true });
  };

  closeConfirmation = () => {
    this.setState({ isConfirmationOpen: false });
  };

  sendApplicationsToSubmitter = async () => {
    const {
      applicationIds,
      showNotification,
      refreshView,
      resetSelectedIds,
    } = this.props;

    try {
      const { submitted } = await sendApplicationsToSubmitter({
        ids: applicationIds,
      });

      showNotification(getSuccessMessage(submitted));
    } catch (err) {
      showNotification('Update Failed!!', 'warning');
    }

    this.closeConfirmation();
    refreshView();
    resetSelectedIds();
  };

  render() {
    const {
      label,
      applicationForSubmit,
      notAllowedForSubmitApplications,
    } = this.props;
    const { isConfirmationOpen } = this.state;

    const isEnabled = applicationForSubmit.length && !notAllowedForSubmitApplications.length;
    const customIconStyle = {
      marginRight: "-8px"
    };
    const autoSubmitIcon = <ImageFlashOn color={isEnabled ? 'white' : '#b1b1b1' } style={customIconStyle}/>;

    return [
      <FlatButton
        key="button"
        disabled={!isEnabled}
        primary
        label={label}
        onClick={this.openConfirmation}
        icon={autoSubmitIcon}
        style={isEnabled ? styles.activeLabel : styles.disabledLabel}
      />,
      <Confirmation
        key="confirmation"
        isOpen={isConfirmationOpen}
        confirm={this.sendApplicationsToSubmitter}
        cancel={this.closeConfirmation}
      />,
      <Notification key="notification" />,
    ];
  }
}

const mapStateToProps = (state, props) => ({
  applicationForSubmit: applicationForSubmitSelector(state, props),
  notAllowedForSubmitApplications: notAllowedForSubmitApplicationsSelector(
    state,
    props,
  ),
});

const mapDispatchToProps = {
  showNotification: showNotificationAction,
  refreshView: refreshViewAction,
};

export default compose(
  withAccess(PERMISSION_AUTOSUBMIT),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SendApplicationsToSubmitterButton);
