import React, { PureComponent } from 'react';
import { string, shape, func, bool } from 'prop-types';

import Unfilled from '../Unfilled';
import { ToolBarRaisedButton } from '../buttons';

export default class ActionButtonField extends PureComponent {
  static propTypes = {
    onButtonClick: func.isRequired,
    source: string,
    record: shape(),
    addLabel: bool,
  };

  static defaultProps = {
    source: '',
    record: {},
    addLabel: true,
  };

  onButtonClick = () => {
    const { source, onButtonClick } = this.props;

    onButtonClick(source);
  };

  render() {
    const { source, record, addLabel } = this.props;

    if (!record[source]) return <Unfilled />;

    return (
      <ToolBarRaisedButton
        label="Decrypt"
        onClick={this.onButtonClick}
        addLabel={addLabel}
      />
    );
  }
}
