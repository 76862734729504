import React from 'react';

import classNames from 'classnames';

import { TYPES } from '../../constants/comments';
import { TogglePinButton } from '../../components/buttons';
import InfoApplication from '../../components/InfoApplication';

import './styles.scss';

export default comment => {
  const {
    id,
    createdAt,
    text,
    email,
    type,
    pinned,
    toggleCommentPin,
  } = comment;

  const isEscalatedComment = type === TYPES.ESCALATED;

  const commentTextClassName = classNames('comment-container', {
    red: isEscalatedComment,
  });

  const infoText = isEscalatedComment ? (
    <div className="comments-list__text info red">
      This comment was added as part of an escalation.
    </div>
  ) : null;

  return (
    <li key={id} className="comments-list-item">
      <div className="comment-wrapper">
        <InfoApplication
          email={email}
          date={createdAt}
          bodyText={text}
          editorInfo={email}
          infoType="comment"
          commentClass={commentTextClassName}
        >
          {infoText}
        </InfoApplication>
      </div>
      <TogglePinButton active={Boolean(pinned)} onChange={toggleCommentPin} />
    </li>
  );
};
