import React from 'react';
import { DisabledInput, SelectInput } from 'admin-on-rest';
import { DependentInput } from 'aor-dependent-input';

import {
  TaxFilingStatus,
  TaxReturnType,
} from '../../../Applications/enumChoices';
import { NumberInput } from '../../fields';
import styles from '../common-styles';
import labels from './labels';
import helperText from './helper-text';


export default [
  <DisabledInput
    key="dependencyStatus"
    source="studentDependency"
    label="Dependency Status"
    elStyle={styles.textInput}
  />,
  <DependentInput
    key="studentDependency"
    source="studentDependency"
    dependsOn="studentDependency"
  >
    <SelectInput
      label="Filing status"
      source="studentTaxFilingStatus"
      choices={TaxFilingStatus}
      elStyle={styles.select}
      allowEmpty
    />
    <SelectInput
      label="Tax return type"
      source="studentTaxReturnType"
      choices={TaxReturnType}
      elStyle={styles.select}
      allowEmpty
    />
    <NumberInput
      source="studentAdjustedGrossIncome"
      label={labels.studentAdjustedGrossIncome}
      helperText={helperText.studentAdjustedGrossIncome}
      elStyle={styles.numberInput}
      altFieldStyles='true'
    />
    <NumberInput
      source="studentEarningsFromWork"
      label={labels.studentEarningsFromWork}
      customLabel={labels.studentAdjustedGrossIncome}
      helperText={helperText.studentEarningsFromWork}
      elStyle={styles.numberInput}
      altFieldStyles='true'
    />
    <NumberInput
      source="studentIncomeTax"
      label={labels.studentIncomeTax}
      helperText={helperText.studentIncomeTax}
      elStyle={styles.numberInput}
      altFieldStyles='true'
    />
    <NumberInput
      source="studentIraDeductions"
      label={labels.studentIraDeductions}
      helperText={helperText.studentIraDeductions}
      elStyle={styles.numberInput}
      altFieldStyles='true'
    />
    <NumberInput
      source="studentTaxExemptInterestIncome"
      label={labels.studentTaxExemptInterestIncome}
      helperText={helperText.studentTaxExemptInterestIncome}
      elStyle={styles.numberInput}
      altFieldStyles='true'
    />
    <NumberInput
      source="studentUntaxedPortionsOfIRA"
      label={labels.studentUntaxedPortionsOfIRA}
      helperText={helperText.studentUntaxedPortionsOfIRA}
      elStyle={styles.numberInput}
      altFieldStyles='true'
    />
    <NumberInput
      source="studentCashBalance"
      label={labels.studentCashBalance}
      helperText={helperText.studentCashBalance}
      elStyle={styles.numberInput}
      altFieldStyles='true'
    />
  </DependentInput>,
];
