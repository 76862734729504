import React from 'react';
import { isApplicationUseTax2018 } from '../../utils/application';

export default (validator, taxForm, tax2018Form, formType) => (record) => {
  const validationMessages = validator(record);
  
  const isValid = !validationMessages.length;
  const validContent = isApplicationUseTax2018(record) ? tax2018Form(record, false) : taxForm;

  const taxReturnType = `${formType}TaxReturnType`

  const standard1040Form = record[taxReturnType]

  const taxReturnTypeNotSelected = standard1040Form === null;

  const formBasedOnTaxReturnType = ((standard1040Form && standard1040Form.includes('IRS_1040')) 
    || taxReturnTypeNotSelected) ? validContent : taxForm

  const content = isValid ?
  formBasedOnTaxReturnType :
    validationMessages.map(message => <div key={message}>{message}</div>);

  return {
    isValid,
    content,
  };
}
