import { date } from '../../utils/formatter';
import { DATE_FORMAT_5 } from '../../constants/date-formats';

const getString = (...args) => args.filter(item => item).join(', ');

export const getAddress = (record) => {
  const {
    addressStreetAndNumber,
    addressCityAndCountry,
    homeAddressState,
    homeAddressZipCode,
    homeAddressCountry,
  } = record;

  return getString(
      addressStreetAndNumber,
      addressCityAndCountry,
      homeAddressState,
      homeAddressZipCode,
      homeAddressCountry,
    );
};

export const getTime = (timestamp) => {
  if (!timestamp) { return null; }

  return date(timestamp, DATE_FORMAT_5)
};

export const getFsaIdText = ({ doesUserHaveFsaId, doesUserRememberFsaId }) => {
  if (doesUserHaveFsaId === 'YES') {
    return `Has a FSA ID and ${doesUserRememberFsaId ? 'remembers it' : 'doesn\'t remember it'}`;
  }

  return 'Doesn\'t have a FSA ID';
}
