import React, { Fragment } from 'react';

import parentTexts2019 from './parent-texts-2019';

export default {
  ...parentTexts2019,
  parentTaxLine7: { label: '11', description: 'Adjusted gross income' },
  parentTaxLine13: { label: '22', description: 'Subtract line 12 from line 11 (income tax after credits)' },
  parentTaxLine4c: {label: '5a', description: 'Pensions and annuities'},
  parentTaxLine4d: {label: '5b', description: 'Taxable amount of pensions and annuities'},
  didFileSchedule1AlaskaPermanentFundDividendLabel: (
    <Fragment>
      Is Line 8 of your parent&apos;s <u>Schedule 1</u> only used to report your parent&apos;s Alaska Permanent Fund dividend and/or their Unemployment Compensation Exception (UCE)?
    </Fragment>
  ),
};
