import React from 'react';
import { string, element, arrayOf, oneOfType } from 'prop-types';
import { SimpleForm } from 'admin-on-rest';

import ResourcePropertyEditToolbar from '../../../../Applications/ResourcePropertyEditToolbar';
import { STUDENT } from '../../../../Applications/ApplicationType';
import './styles.scss';

const TaxesForm = ({ id, content, ...props }) => (
        <SimpleForm
            toolbar={
                <ResourcePropertyEditToolbar
                    applicationType={STUDENT}
                    id={id}
                    {...props}
                />
            }
            {...props}
        >
            {content}
        </SimpleForm>
    );

TaxesForm.propTypes = {
  id: string.isRequired,
  content: oneOfType([element, arrayOf(element)]).isRequired,
  label: string.isRequired,
};

export default TaxesForm;
