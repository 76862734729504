import React from 'react';
import { shape } from 'prop-types';
import { getFullNameFromParentApplication } from '../applicationHelpers';

const Title = ({ record: r }) => (
  <span>{`${getFullNameFromParentApplication(r)}'s ${
    r.applicationYear
  } Parent Application`}</span>
);

Title.propTypes = {
  record: shape(),
};

Title.defaultProps = {
  record: {}
};

export default Title;
