import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { string, shape, func } from 'prop-types';
import Toggle from '../../../components/Toggle';

import { updateSubmitterSettings as updateSubmitterSettingsAction } from '../../reducer';
import { changeSubmitterSettings } from '../../../api/submitter';
import { timeToUTC, utcToLocal } from '../../../utils/formatter';

const SubmitterSettingsToggleTimeInputs = ({
  setting,
  updateSubmitterSettings,
  label,
  helperText,
  containerStyle,
  timeStartSetting,
  timeEndSetting,
}) => {
  const [startInputValue, setStartInputValue] = useState('');
  const [endInputValue, setEndInputValue] = useState('');
  const [isToggled, setIsToggled] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (startInputValue && endInputValue) {
      setIsDisabled(false);
    };
  }, [startInputValue, endInputValue]);

  useEffect(() => {
    if (setting.value === "true") {
      setIsToggled(true);
      const localStartInputTime = utcToLocal(timeStartSetting.value);
      const localEndInputTime = utcToLocal(timeEndSetting.value);
      setStartInputValue(localStartInputTime);
      setEndInputValue(localEndInputTime);
    }
  }, [timeStartSetting.value, timeEndSetting.value, setting.value])

  const onToggle = async () => {
    if (isToggled === true) {
      const data = await changeSubmitterSettings({
        ...setting,
        value: "false",
      });
      updateSubmitterSettings({ [setting.setting]: data });
    }

    setIsToggled(!isToggled)
  };

  const onClick = async () => {
    const utcStartTime = timeToUTC(startInputValue);
    const utcEndTime = timeToUTC(endInputValue);
    console.log(utcStartTime);

    const data = await changeSubmitterSettings({
      ...setting,
      value: "true",
    });
    await changeSubmitterSettings({
      ...timeStartSetting,
      value: utcStartTime,
    });
    await changeSubmitterSettings({
      ...timeEndSetting,
      value: utcEndTime,
    });

    updateSubmitterSettings({ [setting.setting]: data });
  };

  const updateStartInputValue = (evt) => {
    setStartInputValue(evt.target.value);
  };

  const updateEndInputValue = (evt) => {
    setEndInputValue(evt.target.value);
  };

  return (
    <div>
      <Toggle
        label={label}
        value={isToggled}
        onToggle={onToggle}
        helperText={helperText}
        containerStyle={containerStyle}
      />
      {isToggled && 
        <>
        <input 
          type="time" 
          name={timeStartSetting.setting}
          value={startInputValue}
          onChange={evt => updateStartInputValue(evt)}
        />
        <input 
          type="time" 
          name={timeEndSetting.setting}
          value={endInputValue}
          onChange={evt => updateEndInputValue(evt)}
        />
        <button onClick={() => {onClick()}} disabled={isDisabled}>Update</button>
        </>
      }
    </div>
  );
};

SubmitterSettingsToggleTimeInputs.propTypes = {
  setting: shape(),
  updateSubmitterSettings: func,
  label: string.isRequired,
  helperText: string,
  containerStyle: shape(),
  timeStartSetting: shape(),
  timeEndSetting: shape(),
};

SubmitterSettingsToggleTimeInputs.defaultProps = {
  setting: {},
  updateSubmitterSettings: () => {},
  helperText: '',
  containerStyle: {},
  timeStartSetting: {},
  timeEndSetting: {},
};


const mapDispatchToProps = {
    updateSubmitterSettings: updateSubmitterSettingsAction,
};

export default connect(
    null,
    mapDispatchToProps,
)(SubmitterSettingsToggleTimeInputs);