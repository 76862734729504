import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { shape, func } from 'prop-types';

import { Card } from '../components/CustomCard';
import SubmitterSettingsToggle from './components/SubmitterSettingsToggle';
import SubmitterSettingToggleTimeInputs from './components/SubmitterSettingToggleTimeInputs';
import Maintenance from './components/Maintenance';
import { labels, helperText } from './helpers';
import {
  setSubmitterSettings as setSubmitterSettingsAction,
  setMaintenanceSetting as setMaintenanceSettingAction,
} from './reducer';
import { getSubmitterSettings } from '../api/submitter';
import { getMaintenanceSetting } from '../api/maintenance';
import './styles.scss';

const styles = {
  containerStyle: {
    marginBottom: 30,
  },
};

class AdminPanel extends PureComponent {
  static propTypes = {
    submitterSettings: shape(),
    maintenanceSetting: shape(),
    setSubmitterSettings: func,
    setMaintenanceSetting: func,
  };

  static defaultProps = {
    submitterSettings: {},
    maintenanceSetting: {},
    setSubmitterSettings: () => {},
    setMaintenanceSetting: () => {},
  };

  componentDidMount() {
    this.getInitialSettings();
  }

  getInitialSettings = async () => {
    const { setSubmitterSettings, setMaintenanceSetting } = this.props;
    const submitter = await getSubmitterSettings();
    const maintenance = await getMaintenanceSetting();

    const submitterSettings = submitter.reduce(
      (acc, item) => ({
        ...acc,
        [item.setting]: { ...item },
      }),
      {},
    );
    const maintenanceSetting = maintenance[0];

    setSubmitterSettings(submitterSettings);
    setMaintenanceSetting(maintenanceSetting);
  };

  render() {
    const { submitterSettings, maintenanceSetting } = this.props;

    return (
      <div className="card-container">
        <Card title="Submitter Configuration">
          <SubmitterSettingsToggle
            label={labels.submitCompletedApplications}
            helperText={helperText.submitCompletedApplications}
            containerStyle={styles.containerStyle}
            setting={submitterSettings.ACTIVE}
          />
          <SubmitterSettingsToggle
            label={labels.submitFAFSA}
            helperText={helperText.submitFAFSA}
            setting={submitterSettings.ACTUALLY_SUBMIT}
          />
          <SubmitterSettingToggleTimeInputs
            label={labels.timeWindowStatus}
            setting={submitterSettings.USE_TIME_WINDOW_CHECK_STATUS}
            timeStartSetting={submitterSettings.TIME_WINDOW_START_CHECK_STATUS}
            timeEndSetting={submitterSettings.TIME_WINDOW_END_CHECK_STATUS}
          />
          <SubmitterSettingToggleTimeInputs
            label={labels.timeWindowSubmit}
            setting={submitterSettings.USE_TIME_WINDOW_SUBMIT}
            timeStartSetting={submitterSettings.TIME_WINDOW_START_SUBMIT}
            timeEndSetting={submitterSettings.TIME_WINDOW_END_SUBMIT}
          />
        </Card>
        <Card title="School Recommendation">
          <SubmitterSettingsToggle
            label={labels.schoolRecommended}
            setting={submitterSettings.RECOMMENDATIONS}
          />
        </Card>
        <Card title="Engineering">
          <SubmitterSettingsToggle
            label={labels.sqsRead}
            setting={submitterSettings.SHOULD_PROCESS_SUBMIT}
          />
          <SubmitterSettingsToggle
            label={labels.sqsProcess}
            setting={submitterSettings.SHOULD_PROCESS_CHECK_STATUS}
          />
        </Card>
        <Card title="Maintenance">
          <Maintenance setting={maintenanceSetting} />
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  submitterSettings: state.adminPanel.submitterSettings,
  maintenanceSetting: state.adminPanel.maintenanceSetting,
});

const mapDispatchToProps = {
  setSubmitterSettings: setSubmitterSettingsAction,
  setMaintenanceSetting: setMaintenanceSettingAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminPanel);
