import {
  string,
  number,
  oneOfType,
  arrayOf,
  shape,
  bool,
} from 'prop-types';

export const IComment = {
  id: oneOfType([string, number]),
  text: string,
  email: string,
  type: string,
  pinned: bool,
};

export const ICommentsList = arrayOf(shape(IComment));
