import React from 'react';
import { shape, string } from 'prop-types';
import get from 'lodash.get';
import pure from 'recompose/pure';

const YesNoBooleanField = ({ source, record, elStyle }) => {
  const value = get(record, source);
  let out;
  if (value === true) out = 'YES';
  else if (value === false) out = 'NO';
  else out = '(unfilled)';
  return <span style={elStyle}>{out}</span>;
};

YesNoBooleanField.propTypes = {
  source: string.isRequired,
  elStyle: shape(),
  record: shape(),
};

YesNoBooleanField.defaultProps = {
  elStyle: {},
  record: {},
};

const PureYesNoBooleanField = pure(YesNoBooleanField);

PureYesNoBooleanField.defaultProps = {
  addLabel: true,
  elStyle: {
    display: 'block',
    margin: 'auto',
  },
};

export default PureYesNoBooleanField;
