export default {
  studentAdjustedGrossIncome: ({ backofficeData: { taxYear } }) =>
    `What was your adjusted gross income for ${taxYear}?`,
  studentEarningsFromWork: ({ backofficeData: { taxYear } }) =>
    `How much did you earn from working (wages, salaries, tips, etc.) in ${taxYear}?`,
  studentIncomeTax: ({ backofficeData: { taxYear } }) =>
    `How much income tax did you pay in ${taxYear}?`,
  studentIraDeductions: () =>
    'Did you receive IRA deductions or payments to self-employed SEP, SIMPLE, Keogh, or other qualified plans?',
  studentTaxExemptInterestIncome: ({ backofficeData: { taxYear } }) =>
    `Did you have tax-exempt interest income in ${taxYear}?`,
  studentUntaxedPortionsOfIRA: ({ backofficeData: { taxYear } }) =>
    `Did you have untaxed portions of your pensions and/or IRA distributions in ${taxYear}?`,
  studentCashBalance: () => 
    `What is your current balance of cash, savings, and checking accounts?`
  };
