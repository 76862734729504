import React from 'react';
import { shape, string } from 'prop-types';

import Unfilled from '../Unfilled';

const PartialDateField = ({ source, record }) => {
  if (!record[source]) return <Unfilled />;

  const [year, month] = record[source].split('-');

  return (
    <span>{month}/●●/{year}</span>
  );
};

PartialDateField.propTypes = {
  source: string,
  record: shape(),
};

PartialDateField.defaultProps = {
  source: '',
  record: {},
};

export default PartialDateField;
