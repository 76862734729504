export default (proxyUrls = {}) => {
  const originalFetch = window.fetch;

  const proxyUrlsRegexp = new RegExp(`${Object.keys(proxyUrls).join('|')}`);

  const getProxyPathname = (pathname) => (
    pathname.replace(proxyUrlsRegexp, (field) => proxyUrls[field])
  );

  window.fetch = ( url, ...opts ) => {
    const urlData = new URL( url );
    const { pathname } = urlData;

    const proxyUrl = getProxyPathname(pathname);

    let processedUrl = url;

    if ( proxyUrl ) {
      urlData.pathname = proxyUrl;

      processedUrl = urlData.toString();
    }

    return originalFetch(processedUrl, ...opts);
  };
}
