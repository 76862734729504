import React, { memo } from 'react';

import { getEmail } from '../api/localAuthStorage';

export default Component => {
  const WithUserName = () => {
    const userName = getEmail();

    return <Component userName={userName} />;
  };

  return memo(WithUserName);
};
