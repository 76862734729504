import React from 'react';
import { string, shape } from 'prop-types';
import { FormTab } from 'admin-on-rest';

import { studentTaxContent } from '../../../../components/TaxForm';

const StudentTaxDataTab = ({ label, ...props }) => (
  <FormTab label={label} {...props}>
    {studentTaxContent(props.record).content}
  </FormTab>
);

StudentTaxDataTab.propTypes = {
  label: string.isRequired,
  record: shape(),
};

StudentTaxDataTab.defaultProps = {
  record: {},
};

export default StudentTaxDataTab;
