import React, { Fragment } from 'react';

import parentTexts2018 from './parent-texts';
import { taxSchedule1LinesValues2019 } from '../../../Applications/enumChoices';

const { LINE_8 } = taxSchedule1LinesValues2019;

export default {
  ...parentTexts2018,
  parentTaxLine7: { label: '8b', description: 'Adjusted gross income' },
  parentTaxLine12: { label: '3', description: 'Business income or loss' },
  parentTaxLine13: { label: '14', description: 'Subtract line 12 from line 11 (income tax after credits)' },
  parentTaxLine18: { label: '6', description: 'Farm income or loss' },
  parentTaxLine28: { label: '15', description: 'Self-employed SEP, SIMPLE, and qualified plans' },
  parentTaxLine32: { label: '19', description: 'IRA deduction' },
  parentTaxLine46: { label: '2', description: 'Excess advance premium tax credit repayment' },
  parentTaxLine50: { label: '3', description: 'Education credits' },
  parentTaxLine4c: {label: '4c', description: 'Pensions and annuities'},
  parentTaxLine4d: {label: '4d', description: 'Taxable amount of pensions and annuities'},
  parentTaxSchedule1Line21: 'Line 8',
  getSchedule1TaxLinesQuestion: selectedTaxLines => selectedTaxLines.includes(LINE_8) 
    && <Fragment>Please enter the value of Line 8 of your parent&apos;s <u>Schedule 1</u>. </Fragment>,
  didFileSchedule1GainsOrLossesFromVirtualCurrencyLabel: (
    <Fragment>
      Was Line 4 of your parent&apos;s <u>Schedule 1</u> only filled out to report your parent&apos;s gains or losses from Virtual Currency
    </Fragment>
  ),
  didFileSchedule1AlaskaPermanentFundDividendLabel: (
    <Fragment>
      Is Line 8 of your parent&apos;s <u>Schedule 1</u> only used to report your parent&apos;s Alaska Permanent Fund dividend?
    </Fragment>
  ),
};
