import React from 'react';
import { SelectInput } from 'admin-on-rest';
import { DependentInput } from 'aor-dependent-input';

import APIUrls from '../../APIUrls';
import { TaxFilingStatus, TaxReturnType } from '../../enumChoices';
import { NumberInput } from '../../../components/fields';
import FieldsHolder from '../../../components/fields/FieldsHolder';

const styles = {
  input: { width: 800 },
};

const checkParentTaxFilingStatus = (status) => status !== 'SINGLE';

const TaxBefore2018Fields = (props) => (
  <FieldsHolder {...props}>
    <APIUrls
      key="backofficeData"
      source="backofficeData"
      listName="parentTaxFileUrls"
      {...props}
    />
    <SelectInput
      label="Tax Filing Status"
      key="parentTaxFilingStatus"
      source="parentTaxFilingStatus"
      choices={TaxFilingStatus}
      elStyle={styles.input}
      allowEmpty
    />
    <SelectInput
      label="Tax Return Type"
      key="parentTaxReturnType"
      source="parentTaxReturnType"
      choices={TaxReturnType}
      elStyle={styles.input}
      allowEmpty
    />
    <NumberInput
      key="parentAdjustedGrossIncome"
      source="parentAdjustedGrossIncome"
      label={({ backofficeData: { taxYear } }) =>
        `What was your adjusted gross income for ${taxYear}`
      }
      helperText="Please enter a dollar amount or zero for no income. This amount is found on IRS Form 1040-line 37, Form 1040A-line 21, or Form 1040EZ-line 4."
      elStyle={styles.input}
    />
    <NumberInput
      key="parentEarningsFromWork"
      source="parentEarningsFromWork"
      label={({ backofficeData: { taxYear } }) =>
        `How much did you earn from working (wages, salaries, tips, etc.) in ${taxYear}?`
      }
      helperText="Please enter a dollar amount or zero for no income. This amount is found on IRS Form 1040-line 7+12+18, Form 1040A-line 7, or Form 1040EZ-line 1."
      elStyle={styles.input}
    />
    <DependentInput key="parentTaxFilingStatus2" dependsOn="parentTaxFilingStatus" resolve={checkParentTaxFilingStatus}>
      <NumberInput
        source="otherParentEarningsFromWork"
        label={({ backofficeData: { taxYear } }) =>
          `How much did your spouse/other parent earn from working (wages, salaries, tips, etc.) in ${taxYear}?`
        }
        helperText="Please enter a dollar amount or zero for no income. This amount is found on IRS Form 1040-line 7+12+18, Form 1040A-line 7, or Form 1040EZ-line 1."
        elStyle={styles.input}
      />
    </DependentInput>
    <NumberInput
      key="parentIncomeTax"
      source="parentIncomeTax"
      label={({ backofficeData: { taxYear } }) =>
        `How much income tax did you pay in ${taxYear}?`
      }
      helperText="Please enter a dollar amount or zero. This amount is found on IRS Form 1040-lines (56 minus 46), Form 1040A-line (28 minus line 36), or Form 1040EZ-line 10."
      elStyle={styles.input}
    />
    <NumberInput
      key="parentExemptions"
      source="parentExemptions"
      label={({ backofficeData: { taxYear } }) =>
        `How many tax exemptions did you have in ${taxYear}?`
      }
      helperText="Please enter a number or zero for no exemptions. This amount is found on IRS Form 1040-line 6d, Form 1040A-line 6d, or Form 1040EZ-see Help & Hints. This number can include up to 2 digits."
      elStyle={styles.input}
    />
    <NumberInput
      key="parentIraDeductions"
      source="parentIraDeductions"
      label={() =>
        'Did you receive IRA deductions or payments to self-employed SEP, SIMPLE, Keogh, or other qualified plans?'
      }
      helperText="Please enter a number or zero. This amount is found on IRS Form 1040-line 28+32, or Form 1040A-line 17. If you filled a 1040EZ enter zero."
      elStyle={styles.input}
    />
    <NumberInput
      key="parentTaxExemptInterestIncome"
      source="parentTaxExemptInterestIncome"
      label={({ backofficeData: { taxYear } }) =>
        `Did you have tax-exempt interest income in ${taxYear}?`
      }
      helperText="Please enter a number or zero. This amount is found on IRS Form 1040/1040A - line 8b. If you filled a 1040EZ enter zero."
      elStyle={styles.input}
    />
    <NumberInput
      key="parentUntaxedPortionsOfIRA"
      source="parentUntaxedPortionsOfIRA"
      label={({ backofficeData: { taxYear } }) =>
        `Did you have untaxed portions of your IRA distributions in ${taxYear}?`
      }
      helperText="Please enter a number or zero. This amount is found on IRS Form 1040 - lines (15a minus 15b), or Form 1040A - line (11a minus 11b). If you filled a 1040EZ enter zero."
      elStyle={styles.input}
    />
    <NumberInput
      key="parentUntaxedPortionsOfPensions"
      source="parentUntaxedPortionsOfPensions"
      label={({ backofficeData: { taxYear } }) =>
        `Did you have untaxed portions of your pensions in ${taxYear}?`
      }
      helperText="Please enter a number or zero. This amount is found on IRS Form 1040 - lines (16a minus 16b), or Form 1040A - line (12a minus 12b). If you filled a 1040EZ enter zero."
      elStyle={styles.input}
    />
  </FieldsHolder>
);

export default TaxBefore2018Fields;
