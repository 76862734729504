import { createSelector } from 'reselect';

const parseRangeJson = (rangeJson) => {
  let data = {};

  if (!rangeJson) {
    return data;
  }

  try {
    data = JSON.parse(rangeJson);
  } catch (err) {
    console.error(err);
  }

  return data;
};

const dateRangeSelector = createSelector(
  parseRangeJson,
  (parsedRange) => {
    const { from, to, type } = parsedRange;
    const range = { from: null, to: null, type };

    if (from) {
      range.from = new Date(from);
    }
    if (to) {
      range.to = new Date(to);
    }

    return range;
  },
);

export {
  dateRangeSelector,
}
