import { post } from './api';

export default (newPassword, token, push, showNotification) => {
  post('/auth/change-password', { token, newPassword })
    .then(() => {
      showNotification('Password successfully updated');
      push('/login');
    })
    .catch(error => {
      showNotification(error.message || 'Unkown error');
    });
};
