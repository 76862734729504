export default (record) => {
  const {
    didStudentCompleteTaxReturn,
    studentEarningsFromWorkNoTaxReturn,
    studentPartnerEarningsFromWorkNoTaxReturn,
    studentTaxFilingStatus,
    backofficeData: { taxYear },
  } = record;
  const messages = [];

  const isStudentHasPartner = studentTaxFilingStatus && studentTaxFilingStatus !== 'SINGLE';
  const isStudentNoTaxNoEarn =
    !didStudentCompleteTaxReturn &&
    !studentEarningsFromWorkNoTaxReturn &&
    (!studentPartnerEarningsFromWorkNoTaxReturn || !isStudentHasPartner);
  const isStudentNoTaxHasEarn = studentEarningsFromWorkNoTaxReturn > 0;
  const isPartnerHasEarn = isStudentHasPartner && studentPartnerEarningsFromWorkNoTaxReturn > 0;

  if (isStudentNoTaxNoEarn) {
    messages.push(
      `Student did not file taxes for ${taxYear} and did not earn money`
    );

    return messages;
  }

  if (!didStudentCompleteTaxReturn) {
    messages.push(
      `Student did not file taxes for ${taxYear}`
    );

    if (isStudentNoTaxHasEarn) {
      messages.push(
        `Student earned ${studentEarningsFromWorkNoTaxReturn}`
      );
    }

    if (isPartnerHasEarn) {
      messages.push(
        `Student's partner earned ${studentPartnerEarningsFromWorkNoTaxReturn}`
      );
    }
  }

  return messages;
};
