import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  bool,
  func,
  number,
  string,
  shape,
  arrayOf,
  any,
  node,
  oneOfType,
} from 'prop-types';
import { compose } from 'ramda';

import { changeListParams as changeListParamsAction } from 'admin-on-rest';

import { processSelectedIds } from './helper';
import PureList from './PureList';
import { isLoadingSelector, listIdsSelector } from '../../selectors';
import withLocationPerPage from '../../hocs/withLocationPerPage';
import {
  CommonListSelectionProvider,
  CommonListSelectionConsumer,
} from './context';
import lastVisitedListLink from './lastVisitedListLink';
import withProgressIndicator from '../../hocs/withProgressIndicator';
import Pagination from '../../components/Pagination';

const DISABLED_PERMANENT_SORTING = {};

class CommonList extends PureComponent {
  static propTypes = {
    resource: string.isRequired,
    changeListParams: func.isRequired,
    locationPerPage: number.isRequired,
    ids: arrayOf(any),
    displayCheckboxes: bool,
    onRowSelection: func,
    additionalActions: oneOfType([func, node]),
    listClassName: string,
    pagination: shape(),
  };

  static defaultProps = {
    ids: [],
    displayCheckboxes: false,
    onRowSelection: () => {},
    additionalActions: null,
    listClassName: '',
    pagination: <Pagination />,
  };

  state = {
    selectedApplicationsIds: [],
    prevLocation: window.location.href,
  };

  componentDidUpdate() {
    const isQueryUrlChanged = (
      this.state.prevLocation !== window.location.href
    );

    if (isQueryUrlChanged) {
      this.resetSelectedIds();
      this.updatePrevLocation();
    }
  }

  componentWillUnmount() {
    const { changeListParams, resource } = this.props;

    changeListParams(resource, {})
  }

  handleRowClick = (selectedApplicationsIds) => {
    const { ids } = this.props;

    const processedSelectedApplicationsIds = (
      processSelectedIds(selectedApplicationsIds, ids)
    );

    this.setState({ selectedApplicationsIds: processedSelectedApplicationsIds });
  };

  updatePrevLocation = () => {
    this.setState({ prevLocation: window.location.href });
  };

  resetSelectedIds = () => {
    this.setState({ selectedApplicationsIds: [] });
  };

  render() {
    const { locationPerPage, listClassName } = this.props;
    const { selectedApplicationsIds } = this.state;

    const props = {
      ...this.props,
      handleRowClick: this.handleRowClick,
      sort: DISABLED_PERMANENT_SORTING,
      perPage: locationPerPage,
    };

    return (
      <CommonListSelectionProvider value={{ selectedApplicationsIds, resetSelectedIds: this.resetSelectedIds }}>
        <div className={`${listClassName} frank-list-view`}>
          {this.props.additionalActions}
          <PureList key={locationPerPage} {...props} />
        </div>
      </CommonListSelectionProvider>
    );
  }
}

const mapStateToProps = (state, props) => ({
  ids: listIdsSelector(state, props),
  total: listIdsSelector(state, props).length,
  isLoading: isLoadingSelector(state),
});

const mapDispatchToProps = {
  changeListParams: changeListParamsAction,
};

export {
  CommonListSelectionConsumer,
  CommonListSelectionProvider,
  lastVisitedListLink,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProgressIndicator(),
  withLocationPerPage(),
)(CommonList);
