import React, { PureComponent } from 'react';
import { func, string } from 'prop-types';
import { ActionSearch } from 'material-ui/svg-icons';

class MenuSearch extends PureComponent {
  static propTypes = {
    className: string.isRequired,
    onChange: func.isRequired,
  };

  onChange = e => {
    const { onChange } = this.props;
    const { value } = e.target;

    onChange(value);
  };

  render() {
    const { className } = this.props;

    return (
      <label htmlFor="assignTo" className={className}>
        <span>Assign to:</span>
        <input
          id="assignTo"
          type="text"
          autoComplete="off"
          onChange={this.onChange}
        />
        <ActionSearch />
      </label>
    );
  }
}

export default MenuSearch;
