import { contains } from 'ramda';

import colors from '../../constants/colors';

const greenChips = [
  'COMPLETED', // taxAutomationStatus
  'DATA_COMPLETE',
  'SUBMITTED',
  'PRE_SUBMITTED',
  'SELF_SUBMITTED',
  'MANUALLY_SUBMITTED',
  'PROCESSED_SUCCESSFULLY',
];

const blueChips = [
  'QUEUED',  // taxAutomationStatus
  'NEW',
  'TESTING',
  'OUTDATED',
  'NONE',
];

const redChips = [
  'ERROR',
  'HELD_BY_SUPPORT',
  'PENDING_FSA_ID',
  'ACTION_REQUIRED',
  'MULTIPLE_APPLICATIONS',
  'CORRECTION_IN_PROGRESS',
];

export const getChipColor = (value) => {
  if (contains(value, greenChips)) {
    return { borderColor: colors.jade, backgroundColor: colors.lighterGreen };
  }

  if (contains(value, blueChips)) {
    return { borderColor: colors.cobaltBlue, backgroundColor: colors.lighterBlue };
  }

  if (contains(value, redChips)) {
    return { borderColor: colors.red, backgroundColor: colors.lighterRed };
  }

  return { borderColor: colors.yellow, backgroundColor: colors.lighterYellow };
};
