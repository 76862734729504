import React from 'react';
import { Avatar } from 'material-ui';

const SystemUpdateIcon = () => (
  <Avatar size={30} className="user-avatar">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="20"
      viewBox="0 0 12 20"
    >
      <g fill="none" fillRule="nonzero">
        <path
          fill="#000"
          d="M9.4 6.858a6.695 6.695 0 0 0-.685-1.172c-.486-.685-.685-1.542-.429-2.342 0-.03 0-.057.03-.057.028-.086.028-.143.056-.23 0-.028.03-.085.03-.113 0-.086.028-.172.028-.257V2.344c0-.057 0-.114-.029-.172v-.086a2.647 2.647 0 0 0-.97-1.6C7.287.371 7.115.285 6.944.2h-.03C6.83.17 6.745.114 6.63.086a.825.825 0 0 0-.256-.057h-.086C6.174 0 6.058 0 5.916 0c-.114 0-.257 0-.372.029a2.231 2.231 0 0 0-.743.2s-.029 0-.029.029c-.2.086-.4.229-.571.371-.03.03-.057.03-.057.057l-.172.172-.03.03a1.629 1.629 0 0 1-1.199.542c-.914 0-1.828.314-2.571.857h1.2c.886 0 1.685.485 2.113 1.228.172.772 0 1.572-.485 2.23a8.908 8.908 0 0 0-.628 1.028c-.343.714-.715 1.4-1.2 2.029A5.669 5.669 0 0 0 0 12.259c0 .914.229 1.8.6 2.572.2.4.744.371.914-.03l.114-.256c.486 2.057 1.486 3.685 2.743 4.428a.962.962 0 0 1-.485.143H2.457a.842.842 0 0 0-.857.857h4v-.6c.086 0 .2.029.286.029.086 0 .2 0 .286-.03V20h4a.842.842 0 0 0-.858-.857H7.886A.959.959 0 0 1 7.4 19c1.2-.714 2.172-2.229 2.685-4.2l.057.143c.172.4.714.4.914.03a5.68 5.68 0 0 0 .685-2.686c-.084-1.286-.513-2.486-1.198-3.43a10.649 10.649 0 0 1-1.143-2z"
        />
        <path
          fill="#FFF"
          d="M5.257 1.43a.572.572 0 0 1 0 1.142.572.572 0 0 1 0-1.142zM2.057 12.143c0-1.77.428-3.342 1.086-4.514.4-.657 1.171-1.029 1.914-.857.256.057.514.086.77.086.257 0 .543-.03.8-.086.801-.172 1.601.229 2 .943.658 1.143 1.058 2.714 1.058 4.428-.028 3.542-1.742 6.428-3.828 6.428-2.114 0-3.8-2.886-3.8-6.428z"
        />
      </g>
    </svg>
  </Avatar>
);

export default SystemUpdateIcon;
