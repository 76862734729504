import { createFilterQuery } from '../../services/filters';
import { DEFAULT_PER_PAGE_VAL, FRANK_STATUSES, NULL_VALUE } from '../../constants';

import { getEmail } from '../../api/localAuthStorage';
import { makeUrlCreator } from '../../services/url';
import { extendsUrlSettingsWithSelectedTabName } from '../../utils/utils';

const email = getEmail() || NULL_VALUE;

const IN_PROGRESS_NAME = 'IN-PROGRESS';
const TO_SUBMIT_NAME = 'TO-SUBMIT';
const IRS_VALIDATION_NAME = 'IRS-VALIDATION';
const ERROR_REVIEW_NAME = 'ERROR-REVIEW';

const filterPresets = {
  [IN_PROGRESS_NAME]: {
    frankStatus: createFilterQuery({
      operator: 'or',
      group: 'and',
      value: [FRANK_STATUSES.MANUAL_SUBMISSION_IN_PROGRESS],
    }),
    assignedTo: createFilterQuery({
      operator: 'or',
      group: 'and',
      value: email,
    }),
  },
  [TO_SUBMIT_NAME]: {
    frankStatus: createFilterQuery({
      operator: 'or',
      group: 'and',
      value: [FRANK_STATUSES.DATA_COMPLETE],
    }),
    assignedTo: createFilterQuery({
      operator: 'or',
      group: 'and',
      value: email,
    }),
  },
  [IRS_VALIDATION_NAME]: {
    isPendingIRS: true,
    isPendingIRSForParent: true,
    assignedTo: createFilterQuery({
      operator: 'or',
      group: 'and',
      value: email,
    }),
  },
  [ERROR_REVIEW_NAME]: {
    frankStatus: createFilterQuery({
      operator: 'or',
      group: 'and',
      value: [FRANK_STATUSES.ERROR]
    }),
    assignedTo: createFilterQuery({
      operator: 'or',
      group: 'and',
      value: email,
    }),
  },
};

const urlCreator = makeUrlCreator({
  url: 'my-dashboard',
});

const linksData = (perPage = DEFAULT_PER_PAGE_VAL) => extendsUrlSettingsWithSelectedTabName([
  {
    label: 'IN PROGRESS',
    name: IN_PROGRESS_NAME,
    url: urlCreator({
      queryData: {
        filter: JSON.stringify(filterPresets[IN_PROGRESS_NAME]),
        perPage,
      }
    })
  },
  {
    label: 'TO SUBMIT',
    name: TO_SUBMIT_NAME,
    url: urlCreator({
      queryData: {
        filter: JSON.stringify(filterPresets[TO_SUBMIT_NAME]),
        perPage,
      }
    })
  },
  {
    label: 'IRS VALIDATION',
    name: IRS_VALIDATION_NAME,
    url: urlCreator({
      queryData: {
        filter: JSON.stringify(filterPresets[IRS_VALIDATION_NAME]),
        perPage,
      }
    }),
  },
  {
    label: 'ERROR REVIEW',
    name: ERROR_REVIEW_NAME,
    url: urlCreator({
      queryData: {
        filter: JSON.stringify(filterPresets[ERROR_REVIEW_NAME]),
        perPage: 10,
      }
    }),
  },
]);

const emptyStateMessages = {
  [IN_PROGRESS_NAME]: 'You have no FAFSAs you are currently processing.',
  [TO_SUBMIT_NAME]: 'There aren\'t any FAFSAs assigned to you to submit.',
  [IRS_VALIDATION_NAME]: 'There aren\'t any FAFSAs assigned to you for this section.',
  [ERROR_REVIEW_NAME]: 'There aren\'t any FAFSAs assigned to you for this section.',
};

const indexLink = (perPage) => linksData(perPage)[0].url;

export {
  emptyStateMessages,
  filterPresets,
  linksData,
  indexLink,
}
