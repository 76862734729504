import React, { Fragment } from 'react';

import studentTexts2018 from './student-texts';
import { taxSchedule1LinesValues2019 } from '../../../Applications/enumChoices';

const { LINE_8 } = taxSchedule1LinesValues2019;

export default {
  ...studentTexts2018,
  studentTaxLine7: { label: '8b', description: 'Adjusted gross income' },
  studentTaxLine12: { label: '3', description: 'Business income or loss' },
  studentTaxLine13: { label: '14', description: 'Subtract line 12 from line 11 (income tax after credits)' },
  studentTaxLine18: { label: '6', description: 'Farm income or loss' },
  studentTaxLine28: { label: '15', description: 'Self-employed SEP, SIMPLE, and qualified plans' },
  studentTaxLine32: { label: '19', description: 'IRA deduction' },
  studentTaxLine46: { label: '2', description: 'Excess advance premium tax credit repayment' },
  studentTaxLine50: { label: '3', description: 'Education credits' },
  studentTaxLine4c: {label: '4c', description: 'Pensions and annuities'},
  studentTaxLine4d: {label: '4d', description: 'Taxable amount of pensions and annuities'},
  studentTaxSchedule1Line21: 'Line 8',
  getSchedule1TaxLinesQuestion: selectedTaxLines => selectedTaxLines.includes(LINE_8) 
    && <Fragment>Please enter the value of Line 8 of your <u>Schedule 1</u>. </Fragment>,
  didFileSchedule1GainsOrLossesFromVirtualCurrencyLabel: (
    <Fragment>
      Was Line 4 of your <u>Schedule 1</u> only filled out to report your gains or losses from Virtual Currency
    </Fragment>
  ),
  didFileSchedule1AlaskaPermanentFundDividendLabel: (
    <Fragment>
      Is Line 8 of your <u>Schedule 1</u> only used to report your Alaska Permanent Fund dividend?
    </Fragment>
  ),
};
