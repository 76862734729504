import { keys, values } from 'ramda';

import { EVENTS } from '../../../../../constants/history';
import { SYSTEM_USER_EMAIL } from '../../../../../constants';
import { DATE_FORMAT_2 } from '../../../../../constants/date-formats';
import { date } from '../../../../../utils/formatter';

const getChangedBy = (name) => (name !== SYSTEM_USER_EMAIL ? `${name}` : 'Frank System');
const getChangedDataValue = (obj = {}) => (values(obj).join(', '));
const getFlagsString = (arr) => arr.join(' ,');
const getChangedFlagsTitle = (obj = {}) => {
    const flags = keys(obj)
      .reduce((result, item) => {
        if (obj[item]) {
          result.added.push(item);
        } else {
          result.removed.push(item);
        }

        return result
      }, {added: [], removed: []});

    const valuesAdded = flags.added.length ? `added: ${getFlagsString(flags.added)}` : '';
    const valuesRemoved = flags.removed.length ? `removed: ${getFlagsString(flags.removed)}` : '';
    const separator = valuesAdded && valuesRemoved ? '; ' : '';

    return `Flags ${valuesAdded}${separator}${valuesRemoved}`
};

export default ({ editor, event, after, updatedAt }) => {
  const { email, firstName, lastName } = editor;
  const fullName = firstName && lastName && `${firstName} ${lastName}`;
  const updateDate = date(updatedAt, DATE_FORMAT_2);
  const changedValue = getChangedDataValue(after);

  switch (event) {
    case EVENTS.STUDENT_UPDATE:
      return {
        title: `Student Update`,
        editorInfo: fullName,
        email,
        updateDate,
      };
    case EVENTS.PARENT_UPDATE:
      return {
        title: `Parent Update`,
        editorInfo: fullName,
        email,
        updateDate,
      };
    case EVENTS.SUPPORT_UPDATE:
      return {
        title: `Support Update`,
        editorInfo: getChangedBy(email),
        email,
        updateDate,
      };
    case EVENTS.TAX_ENTRY:
      return {
        title: 'Tax Entry',
        editorInfo: getChangedBy(email),
        email,
        updateDate,
      };
    case EVENTS.ASSIGNMENT:
      return {
        title: `${changedValue ? `Assigned to ${changedValue}` : 'Unassigned'}`,
        editorInfo: getChangedBy(email),
        email,
        updateDate,
      };
    case EVENTS.FRANK_STATUS_CHANGE:
      return {
        title:`Frank status change: ${changedValue}`,
        editorInfo: getChangedBy(email),
        email,
        updateDate,
      };
    case EVENTS.FAFSA_STATUS_CHANGE:
      return {
        title: `FAFSA status change: ${changedValue}`,
        updateDate,
      };
    case EVENTS.FLAG_CHANGE:
      return {
        title: getChangedFlagsTitle(after),
        editorInfo: getChangedBy(email),
        email,
        updateDate,
      };
    default:
      return null;
  }
};
