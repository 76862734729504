import React, { memo } from 'react';
import { arrayOf, shape, string, func } from 'prop-types';

import SchoolsList from '../SchoolsList';
import Card from '../CustomCard/Card';
import withCopy from '../../hocs/withCopy';

const schoolsListStyles = {
  columnGap: 70,
  columnCount: 2,
};

const AcademicInformationCard = ({
  selectedRow,
  setSelectedRow,
  collegeChoices,
}) => {
  const copyButtonProps = {
    text: 'Copy code',
    selectedRow,
    setSelectedRow,
  };

  return (
    <Card title="Schools">
      <SchoolsList
        style={schoolsListStyles}
        items={collegeChoices}
        copyButtonProps={copyButtonProps}
      />
    </Card>
  );
};

AcademicInformationCard.propTypes = {
  collegeChoices: arrayOf(shape()),
  selectedRow: string,
  setSelectedRow: func,
};

AcademicInformationCard.defaultProps = {
  collegeChoices: null,
  selectedRow: '',
  setSelectedRow: () => {},
};

export default withCopy(memo(AcademicInformationCard));
