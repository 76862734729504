import React, { PureComponent } from 'react';
import { arrayOf, shape, string } from 'prop-types';

import Navigation from '../../components/Navigation';
import withSelectedTabName from '../../hocs/withSelectedTabName';

const processLinksData = (links, selectedTabName) => (
  links.map((link) => ({
      ...link,
      selected: selectedTabName === link.name
    })
  )
);

class ListNavigation extends PureComponent {
  static propTypes = {
    items: arrayOf(shape()),
    selectedTabName: string,
  };

  static defaultProps = {
    items: [],
    selectedTabName: '',
  };

  render() {
    const { items, selectedTabName } = this.props;
    const processedLinksData = processLinksData(items, selectedTabName);

    return (
      <Navigation items={processedLinksData} />
    );
  }
}

export default withSelectedTabName(ListNavigation);
