import React, { Fragment } from 'react';

import studentTexts2019 from './student-texts-2019';

export default {
  ...studentTexts2019,
  studentTaxLine7: { label: '11', description: 'Adjusted gross income' },
  studentTaxLine13: { label: '22', description: 'Subtract line 12 from line 11 (income tax after credits)' },
  studentTaxLine4c: {label: '5a', description: 'Pensions and annuities'},
  studentTaxLine4d: {label: '5b', description: 'Taxable amount of pensions and annuities'},
  didFileSchedule1AlaskaPermanentFundDividendLabel: (
    <Fragment>
      Is Line 8 of your <u>Schedule 1</u> only used to report your Alaska Permanent Fund dividend and/or your Unemployment Compensation Exception (UCE)?
    </Fragment>
  ),
};