import React, { PureComponent } from 'react';
import { shape } from 'prop-types';

import { Datagrid, FunctionField, TextField } from 'admin-on-rest';

import CommonList from '../../components/CommonList';
import Filters from './Filters';
import { phoneFormatter } from '../../utils/formatter';
import Actions from '../../components/Actions';
import NewTabLink from '../../components/NewTabLink';
import { getApplicationShowUrl } from '../../services/url';
import { DEPENDENCIES_SHOW } from '../../constants';
import Partner from '../../components/columns/Partner';

const getDependencyShow = dependencies => {
  const showDependencies = dependencies.map(
    dependency => DEPENDENCIES_SHOW[dependency],
  );

  return showDependencies.join(', ');
};

class CustomersList extends PureComponent {
  render() {
    const { location, ...props } = this.props;

    return (
      <CommonList
        {...props}
        actions={<Actions />}
        title="Customers"
        listClassName="applications-list"
        filters={<Filters />}
      >
        <Datagrid {...props}>
        <FunctionField
            label="Partner"
            source="faasPartner.name"
            render={({ faasPartner }) => <Partner faasPartner={faasPartner} />}
          />
          <FunctionField
            label="First Name"
            source="firstName"
            render={({ firstName, id }) => (
              <NewTabLink to={getApplicationShowUrl(location.pathname, id)}>
                {firstName}
              </NewTabLink>
            )}
          />
          <TextField label="Last Name" source="lastName" />
          <TextField label="Email" source="email" />
          <FunctionField
            source="phone"
            label="Phone"
            render={record => phoneFormatter(record.phone || '')}
          />
          <FunctionField
            label="Dependency"
            source="customerRole"
            render={({ customerRole }) =>
              getDependencyShow([...new Set(customerRole)])
            }
          />
        </Datagrid>
      </CommonList>
    );
  }
}

CustomersList.propTypes = {
  location: shape(),
};

CustomersList.defaultProps = {
  location: {},
};

export default CustomersList;
