import React from 'react';
import { shape, string } from 'prop-types';
import { Chip } from 'material-ui';

import { fafsaStatusForDisplaying } from '../../enumChoices';
import withChipStyles, { getChipColor } from '../../../hocs/withChipStyles';

const FafsaStatus = ({ fafsaStatus, styles }) => (
  <Chip
    labelStyle={styles.label}
    style={{ ...styles.chip, ...getChipColor(fafsaStatus) }}
    className="selectable_chip"
  >
    {fafsaStatusForDisplaying[fafsaStatus]}
  </Chip>
);

FafsaStatus.propTypes = {
  fafsaStatus: string,
  styles: shape(),
};

FafsaStatus.defaultProps = {
  fafsaStatus: '',
  styles: {},
};

export default withChipStyles(FafsaStatus);
