import React from 'react';
import { shape, string, bool } from 'prop-types';
import { CardActions } from 'material-ui';
import { ShowButton, ListButton, RefreshButton } from 'admin-on-rest';

import ImpersonateButton from '../components/ImpersonateButton';
import { SendNotificationButton } from '../components/buttons';
import { lastVisitedListLink } from '../components/CommonList';
import {hasOnePermission, PERMISSION_IMPERSONATE} from "../utils/permissions";

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

const EditActions = ({ basePath, data, resource, hasShow, hasList }) => {
  const props = { basePath, resource, record: data };
  const listLinkCache = lastVisitedListLink.get()[resource];

  return (
    <CardActions style={cardActionStyle}>
      {hasOnePermission(PERMISSION_IMPERSONATE) && <ImpersonateButton {...props} /> }
      <SendNotificationButton {...props} />
      {hasShow && <ShowButton {...props} />}
      {hasList && <ListButton {...props} basePath={listLinkCache || basePath} />}
      <RefreshButton />
    </CardActions>
  );
};

EditActions.propTypes = {
  data: shape(),
  basePath: string,
  resource: string,
  hasShow: bool,
  hasList: bool,
};

EditActions.defaultProps = {
  data: {},
  basePath: '',
  resource: '',
  hasShow: false,
  hasList: false,
};

export default EditActions;
