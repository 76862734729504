import React, { PureComponent } from 'react';
import { string } from 'prop-types';

import './styles.scss';

export default class Textarea extends PureComponent {
  static propTypes = {
    className: string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    const { className, ...props } = this.props;

    return (
      <textarea
        className={`textarea ${className}`}
        {...props}
      />
    );
  }
}
