import React, { memo } from 'react';
import { string, func, shape, arrayOf } from 'prop-types';

import SensitiveDataRow from './SensitiveDataRow';
import PropsExtender from '../PropsExtender';

const IItemProps = {
  label: string,
  source: string,
};

const SensitiveDataList = ({
  selectedRow,
  setSelectedRow,
  items,
  dataFetcher,
  record,
}) => {
  const rows = items.map(({source, ...other}) => (
    <SensitiveDataRow
      key={source}
      isSelected={selectedRow === source}
      setSelectedRow={setSelectedRow}
      source={source}
      getData={dataFetcher}
      {...other}
    />
  ));

  return <PropsExtender record={record}>{rows}</PropsExtender>;
};

SensitiveDataList.propTypes = {
  dataFetcher: func.isRequired,
  record: shape().isRequired,
  items: arrayOf(shape(IItemProps)),
  selectedRow: string,
  setSelectedRow: func.isRequired,
};

SensitiveDataList.defaultProps = {
  items: [],
  selectedRow: '',
};

export default memo(SensitiveDataList);
