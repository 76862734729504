import React, { PureComponent } from 'react';
import { string, shape, func } from 'prop-types';
import { connect } from 'react-redux';
import { BooleanInput, FormTab, SelectInput } from 'admin-on-rest';
import { compose } from 'ramda';

import { validationFrankStatuses } from '../../../enumChoices';
import Textarea from '../../../../components/form/Textarea';
import withPermissions from '../../../../hocs/withPermissions';
import withUsers, { IWithUser } from '../../../../hocs/withUsers';
import { formInitialValuesSelector, formValuesSelector } from '../../../../selectors';
import { hasOnePermission, PERMISSION_ASSIGN } from "../../../../utils/permissions";

const styles = {
  input: { width: 800 },
};

class GeneralTab extends PureComponent {
  static propTypes = {
    label: string.isRequired,
    record: shape().isRequired,
    permissions: string.isRequired,
    comment: string.isRequired,
    onCommentChange: func.isRequired,
    ...IWithUser,
  };

  onCommentChange = (e) => {
    const { onCommentChange } = this.props;
    const { value } = e.target;

    onCommentChange(value);
  };

  shouldDisplayCommentField = () => {
    const { values, initialValues } = this.props;

    if (!values || !initialValues) {
      return false;
    }

    return !initialValues.escalated && values.escalated;
  };

  render() {
    const { label, usersList, permissions, record, comment } = this.props;
    const hasAssignToPermission = hasOnePermission(PERMISSION_ASSIGN);
    const isDisabledWhenSupportUserTryToUnset = !hasAssignToPermission && record.escalated;

    return (
      <FormTab label={label} {...this.props}>
        <SelectInput
          label="Frank Status"
          source="frankStatus"
          choices={validationFrankStatuses(record.frankStatus)}
          elStyle={styles.input}
          allowEmpty
        />
        <BooleanInput
          source="isWaitingForCustomer"
          label="Waiting For Customer"
          elStyle={styles.input}
        />
        <BooleanInput
          source="escalated"
          label="Escalate Application"
          elStyle={styles.input}
          options={{disabled: isDisabledWhenSupportUserTryToUnset}}
        />
        {this.shouldDisplayCommentField() && (
          <Textarea
            value={comment}
            onChange={this.onCommentChange}
          />
        )}
        {permissions.includes('support_manager') && (
          <SelectInput
            label="Assigned To"
            source="assignedTo"
            choices={usersList}
            optionText="email"
            optionValue="email"
          />
        )}
      </FormTab>
    );
  }
}


const params = { where: { permissions: { $contains: ['support'] } } };

const mapStateToProps = state => ({
  values: formValuesSelector(state),
  initialValues: formInitialValuesSelector(state),
});

export default compose(
  withPermissions,
  withUsers(params),
  connect(mapStateToProps),
)(GeneralTab);
