import React from 'react';
import { Route } from 'react-router-dom';
import RegisterFromInvite from './pages/RegisterFromInvite';
import ChangePassword from './pages/ChangePassword';
import ForgotPassword from './pages/ForgotPassword';
import SendNotificationPage from './pages/SendNotificationPage';
import ApplicationsBulkUpdate from './Applications/ApplicationsBulkUpdate';
import ConfirmApplicationPage from './pages/ConfirmApplicationPage';

export default [
  <Route
    exact
    path="/signup-from-invite"
    component={RegisterFromInvite}
    noLayout
  />,
  <Route exact path="/reset-password" component={ChangePassword} noLayout />,
  <Route exact path="/forgot-password" component={ForgotPassword} noLayout />,
  <Route
    path="/applications/:id/notification"
    component={SendNotificationPage}
  />,
  <Route path="/:type(applications|parents-applications)/:id/confirm" component={ConfirmApplicationPage} />,
  <Route path="/:resource/assign-to" component={ApplicationsBulkUpdate} />,
];
