import React from 'react';

import StudentApplicationTabs from '../StudentApplicationsTabs';
import { linksData, filterPresets, indexLink } from './settings';
import withCounter from '../../../hocs/withCounter';

const StudentApplicationsTabsNonAdmin = ({ ...props }) => (
  <StudentApplicationTabs
    linksData={linksData}
    filterPresets={filterPresets}
    indexLink={indexLink}
    {...props}
  />
);

const filters = linksData().map(({ name }) => filterPresets[name]);

export { indexLink };

export default withCounter({ filters })(StudentApplicationsTabsNonAdmin);
