import React, { PureComponent } from 'react';
import LocationTabs from '../../../../components/LocationTabs';

import { TAB_NAMES } from '../constants';
import StudentSummaryTab from './StudentSummaryTab';
import withPermissions from '../../../../hocs/withPermissions';

export { TAB_NAMES };

class SummaryTabs extends PureComponent {
  render() {
    return (
        <LocationTabs {...this.props}>
          <StudentSummaryTab label="Student Application" tabName={TAB_NAMES.STUDENT_SUMMARY} />
        </LocationTabs>
    );
  }
}

export default withPermissions(SummaryTabs);

