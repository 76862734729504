import axios from 'axios';
import { apiBaseUrl } from './api';
import { getToken } from './localAuthStorage';
import { urlWithResolveValues } from '../services/url';
import { jsonParse } from '../utils/helper';

const resolveParamsValues = (params) => {
  const encodedParams = encodeURIComponent(JSON.stringify(params));
  const parsedParams = jsonParse(urlWithResolveValues(encodedParams));

  return parsedParams || params;
};

axios.interceptors.request.use(
  (config) => {
    const token = getToken();

    if (!token) {
      return config;
    }

    const resolvedParams = resolveParamsValues(config.params);

    const newConfig = {
      headers: {},
      ...config,
      params: resolvedParams,
    };

    newConfig.headers.Authorization = `Bearer ${token}`;
    return newConfig;
  },
  (e) => Promise.reject(e)
);

axios.defaults.baseURL = apiBaseUrl;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Content-Type'] = 'application/json';

export default (requestOptions) => axios(requestOptions);
