import React from 'react';
import { shape } from 'prop-types';

import {
  Filter,
  SelectArrayInput,
} from 'admin-on-rest';

import FilterWithOperator, {
  OVERLAP_OPERATOR,
} from '../../components/FilterWithOperator';
import { customerRoles } from '../../Applications/enumChoices';
import Search from '../../components/Search';

const CustomerFilter = (props) => (
  <Filter {...props}>
    <Search label="Search" source="q" alwaysOn />
    <FilterWithOperator
      label="Dependency"
      source="customerRole"
      choices={customerRoles}
      operator={OVERLAP_OPERATOR}
      optionText="name"
      optionValue="name"
    >
      {fieldProps => <SelectArrayInput {...fieldProps} />}
    </FilterWithOperator>
  </Filter>
);

CustomerFilter.propTypes = {
  filterValues: shape(),
};

CustomerFilter.defaultProps = {
  filterValues: {},
};

export default CustomerFilter;
