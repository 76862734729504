import React, { PureComponent } from 'react';
import { isNil, assocPath, pathOr, split, pathSatisfies } from 'ramda';
import { grey400 } from 'material-ui/styles/colors';
import { shape, string, bool, oneOfType, func } from 'prop-types';

const withDefault = WrappedComponent => {
  class WithDefault extends PureComponent {
    static propTypes = {
      source: string,
      defaultValue: oneOfType([string, shape(), func]),
      record: shape(),
      addLabel: bool,
    };

    static defaultProps = {
      source: '',
      defaultValue: '(unfilled)',
      record: {},
      addLabel: true,
    };

    render() {
      const {
        defaultValue,
        source,
        record,
        ...props
      } = this.props;

      const sourcePath = split('.', source);
      const missing = pathSatisfies(isNil, sourcePath, record);
      const computedValue = typeof defaultValue === "function" ? defaultValue(record) : defaultValue;
      if (missing && React.isValidElement(computedValue)) {
        const { showTime, addLabel, basePath, ...pureProps} = props;
        return React.cloneElement(computedValue, {
          ...pureProps,
          style: { ...computedValue.props.style, ...props.style, color: grey400, fontStyle: 'italic' },
        });
      }
      const value = pathOr(computedValue, sourcePath, record);
      const updatedRecord = assocPath(sourcePath, value, record);

      return (
        <WrappedComponent
          source={source}
          record={updatedRecord}
          missing={missing}
          style={{ backgroundColor: 'blue' }}
          elStyle={missing ? { color: grey400, fontStyle: 'italic' } : {}}
          {...props}
        />
      );
    }
  }

  return WithDefault;
};

export default withDefault;
