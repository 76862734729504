import React from 'react';
import { func, shape, string } from 'prop-types';
import { CardActions } from 'material-ui/Card';

import SendApplicationsToSubmitterButton from './SendApplicationsToSubmitterButton';
import AssignToButton from './AssignToButton';
import ChangeFrankStatus from './ChangeFrankStatus';
import WaitingForCustomer from './WaitingForCustomer';
import RefreshFollowUps from './RefreshFollowUps';
import Escalate from './Escalate';
import { CommonListSelectionConsumer } from '../../../../components/CommonList';

import './styles.scss';

const getLabelSelectedApplications = countApplications =>
  `${countApplications} application${
    countApplications > 1 ? 's' : ''
  } selected`;

const Actions = props => {
  const {
    resource,
    filters,
    displayedFilters,
    filterValues,
    showFilter,
  } = props;

  return (
    <CardActions style={{ position: 'initial' }}>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CommonListSelectionConsumer>
        {({ selectedApplicationsIds, resetSelectedIds }) =>
          selectedApplicationsIds.length ? (
            <div className="applications-actions">
              <span>
                {getLabelSelectedApplications(selectedApplicationsIds.length)}
              </span>
              <AssignToButton
                selectedIds={selectedApplicationsIds}
                resetSelectedIds={resetSelectedIds}
              />
              <SendApplicationsToSubmitterButton
                resource={resource}
                applicationIds={selectedApplicationsIds}
                resetSelectedIds={resetSelectedIds}
              />
              <ChangeFrankStatus
                selectedIds={selectedApplicationsIds}
                resetSelectedIds={resetSelectedIds}
              />
              <WaitingForCustomer
                selectedIds={selectedApplicationsIds}
                resetSelectedIds={resetSelectedIds}
              />
              <RefreshFollowUps
                resource={resource}
                selectedIds={selectedApplicationsIds}
                resetSelectedIds={resetSelectedIds}
              />
              <Escalate
                selectedIds={selectedApplicationsIds}
                resetSelectedIds={resetSelectedIds}
              />
            </div>
          ) : null
        }
      </CommonListSelectionConsumer>
    </CardActions>
  );
};

Actions.propTypes = {
  filters: shape(),
  displayedFilters: shape(),
  filterValues: shape(),
  showFilter: func,
  resource: string,
};

Actions.defaultProps = {
  filters: {},
  displayedFilters: {},
  filterValues: {},
  showFilter: () => {},
  resource: '',
};

export default Actions;
