import React, { PureComponent } from 'react';
import { string, shape, func, bool } from 'prop-types';
import { connect } from 'react-redux'; 
import { withRouter } from 'react-router-dom';
import { compose } from 'ramda';
import {
  Toolbar,
  showNotification as showNotificationAction,
  refreshView as refreshViewAction,
} from 'admin-on-rest';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';

import { resourceNameFor } from '../api/api';
import request from '../api/request';
import { createApplicationComment } from '../api/comments';
import { getEmail } from '../api/localAuthStorage';
import { TYPES, APPLICATION_TYPES } from '../constants/comments';
import { formValuesSelector } from '../selectors/index';
import { lastVisitedListLink } from '../components/CommonList';
import { ToolBarFlatButton, ToolBarRaisedButton } from '../components/buttons';
import getTaxAutomationValues from '../components/TaxForm/tax-2018/getTaxAutomationValues';

const baseStyle = {
  borderRadius: 20,
  height: 40,
  fontFamily: 'GTWalsheim',
}
const styles = {
  raisedButtonRoot: {
    ...baseStyle,
    marginRight: 40,
    flexShrink: 0,
  },
  raisedButton: baseStyle,
  flatButton: {
    ...baseStyle,
    marginRight: 40,
    border: '2px solid #000a46',
    backgroundColor: '#fff',
  },
  labelButton: {
    color: '#000a46',
  }
};

class ResourcePropertyEditToolbar extends PureComponent {
  static propTypes = {
    resource: string.isRequired,
    applicationType: string.isRequired,
    refreshView: func.isRequired,
    id: string.isRequired,
    showNotification: func.isRequired,
    history: shape({
      push: func,
    }).isRequired,
    values: shape(),
    comment: string,
    redirection: bool,
    record: shape().isRequired,
    taxFormFor: string.isRequired,
  };

  static defaultProps = {
    values: {},
    comment: '',
    redirection: true,
  };

  state = {
    dialog: {
      open: false,
    },
  }

  onSave = async () => {
    try {
      await this.updateApplication();
      await this.saveComment();
      this.onComplete();
    } catch (error) {
      const { showNotification } = this.props;
      showNotification('The update couldn’t be completed. Please try again', 'warning');
    }
  };

  onSaveAndMarkAsComplete = async (enhancedData) => {
    try {
      await this.saveAndMarkAsCompleteApplication(enhancedData);
      await this.saveComment();
      this.onComplete();
    } catch (error) {
      const { showNotification } = this.props;
      showNotification('The update couldn’t be completed. Please try again', 'warning');
    }
  };

  onSaveAndAcceptTaxAutomation = () => {
    this.openDialog({
      title: 'Save & accept tax automation',
      action: this.onSaveAndAcceptTaxAutomationContinue,
    });
  };

  onSaveAndAcceptTaxAutomationContinue = () => {
    const { taxFormFor, record, showNotification } = this.props;
    const isStudent = taxFormFor === 'STUDENT';
    const taxAutomationValues =  getTaxAutomationValues(isStudent, record);
    if (taxAutomationValues) {
      const enhancedData = {};
      Object.entries(taxAutomationValues).forEach(
        ([key, value]) => {
          enhancedData[key] = value;
        }
      );
      const fildName = isStudent
        ? 'studentTaxAutomationOperationCheck' 
        : 'parentTaxAutomationOperationCheck';
      enhancedData[fildName] = true;
      this.onSaveAndMarkAsComplete(enhancedData);
    } else {
      showNotification('Tax automation data are absent', 'warning');
    }
    this.closeDialog();
  };

  onSaveAndRejectTaxAutomation = () => {
    this.openDialog({
      title: 'Save & reject tax automation',
      action: this.onSaveAndRejectTaxAutomationContinue,
    });
  };

  onSaveAndRejectTaxAutomationContinue = () => {
    const { taxFormFor } = this.props;
    const fildName = taxFormFor === 'STUDENT' 
      ? 'studentTaxAutomationOperationCheck' 
      : 'parentTaxAutomationOperationCheck';
    const enhancedData = {[fildName]: false}
    this.onSaveAndMarkAsComplete(enhancedData);
    this.closeDialog();
  };

  onComplete = () => {
    const { redirection, showNotification, refreshView } = this.props;
    showNotification('Application has been updated!');
    if (redirection) {
      this.redirectToList();
      return;
    }
    refreshView();
  };

  get redirectLink() {
    const { resource } = this.props;
    const lastVisitedListLinkCache = lastVisitedListLink.get();
    return lastVisitedListLinkCache[resource];
  }

  updateApplication = () => {
    const { applicationType, id, values } = this.props;
    const url = `/${resourceNameFor(applicationType)}/${id}`;
    return request({
      url,
      method: 'PUT',
      data: values,
    })
  };

  saveAndMarkAsCompleteApplication = (enhancedData={}) => {
    const { applicationType, id, values } = this.props;
    const url = `/${resourceNameFor(applicationType)}/${id}/confirm`;
    return request({
      url,
      method: 'POST',
      data: {...values, ...enhancedData},
    })
  };

  saveComment = async () => {
    const { comment, id, showNotification } = this.props;

    if (!comment) {
      return;
    }

    const commentData = {
      applicationType: APPLICATION_TYPES.APPLICATION,
      text: comment,
      type: TYPES.ESCALATED,
      email: getEmail(),
    };

    await createApplicationComment(id, commentData);
    showNotification('Your comment has been added!');
  };

  redirectToList = () => {
    const { applicationType, history } = this.props;
    const url = this.redirectLink || `/${resourceNameFor(applicationType)}`;
    history.push(url);
  };

  openDialog = (dialogParams) => this.setState({ dialog: {
    open: true,
    title: 'Confirmation',
    message: 'Are you sure?',
    action: () => {},
    ...dialogParams,
  } });

  closeDialog = () => this.setState({ dialog: {
    open: false,
  } });

  render() {
    const { applicationType, id, ...props } = this.props;
    const { dialog } = this.state;
    return (
      <Toolbar {...props} >
        <ToolBarFlatButton
          primary
          style={styles.flatButton}
          labelStyle={styles.labelButton}
          label="Save"
          onClick={this.onSave}
        />
        <ToolBarRaisedButton
          primary
          style={styles.raisedButtonRoot}
          buttonStyle={styles.raisedButton}
          rippleStyle={styles.raisedButton}
          overlayStyle={styles.raisedButton}
          type="button"
          label="Save & accept tax automation"
          onClick={this.onSaveAndAcceptTaxAutomation}
        />
        <ToolBarRaisedButton
          primary
          style={styles.raisedButtonRoot}
          buttonStyle={styles.raisedButton}
          rippleStyle={styles.raisedButton}
          overlayStyle={styles.raisedButton}
          type="button"
          label="Save & reject tax automation"
          onClick={this.onSaveAndRejectTaxAutomation}
        />
        <Dialog
          title={dialog.title}
          onRequestClose={this.closeDialog}
          actions={[
            <FlatButton
              label="Cancel"
              primary={true}
              onClick={this.closeDialog}
            />,
            <FlatButton
              label="Ok"
              primary={true}
              onClick={dialog.action}
            />,
          ]}
          open={dialog.open}
        >
          {dialog.message}
        </Dialog>
      </Toolbar>
    );
  }
}

const mapStateToProps = state => ({
  values: formValuesSelector(state),
});

const mapDispatchToProps = {
  showNotification: showNotificationAction,
  refreshView: refreshViewAction,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ResourcePropertyEditToolbar);
