import { escapeValue } from '../services/url';

export const SUPPORT = 'support';
export const SUPPORT_MANAGER = 'support_manager';

export const PARENT_INVITE_STATUS = {
  INVITED: 'Invited',
  ACCEPTED: 'Accepted',
  SUBMITTED: 'Submitted',
};

export const NULL_VALUE = escapeValue(null);

export const FRANK_STATUSES = {
  DATA_COMPLETE: 'DATA_COMPLETE',
  MANUAL_SUBMISSION_IN_PROGRESS: 'MANUAL_SUBMISSION_IN_PROGRESS',
  SUBMISSION_IN_PROGRESS: 'SUBMISSION_IN_PROGRESS',
  ERROR: 'ERROR',
  MISSING_DATA: 'MISSING_DATA',
};

export const APPLICATION_FIELDS = {
  FRANK_STATUS: { student: 'frankStatus', parent: 'frank_status' },
  FRANK_BETA_STATUS: { student: 'betaFrankStatus' },
  FAFSA_STATUS: { student: 'fafsaStatus', parent: 'fafsa_status' },
  FAFSA_BETA_STATUS: { student: 'betaFafsaStatus' },
  STUDENT_DEPENDENCY: { student: 'studentDependency', parent: 'dependency' },
  IS_PENDING_PARENT: {
    student: 'isPendingParent',
    parent: 'is_pending_parent',
  },
  IS_PENDING_IRS: { student: 'isPendingIRS', parent: 'is_pending_irs' },
  IS_PENDING_IRS_FOR_PARENT: {
    student: 'isPendingIRSForParent',
    parent: 'is_pending_irs_for_parent',
  },
  IS_WAITING_FOR_CUSTOMER: {
    student: 'isWaitingForCustomer',
    parent: 'is_waiting_for_customer',
  },
  ASSIGNED_TO: { student: 'assignedTo', parent: 'assigned_to' },
  ESCALATED: { student: 'escalated', parent: 'escalated' },
};

export const GROUP_OPERATOR_FIELD = '$group';
export const OPERATOR_FIELD = '$operator';
export const VALUE_FIELD = '$value';

export const DEFAULT_PER_PAGE_VAL = 10;
export const SYSTEM_USER_EMAIL = 'devops@withfrank.org';
export const DEPENDENCIES_SHOW = {
  DEPENDENT: 'Dependent',
  INDEPENDENT: 'Independent',
};

export const MENU_GROUPS = {
  customersExperience: 'customerExperience',
  cxLead: 'cxLead',
  engineering: 'engineering',
};

