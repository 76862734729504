import whatShouldBeDisplayed from './whatShouldBeDisplayed';
import { getApplicationQuestions } from './QuestionBank';

const RequireSpecialRender = [
    'collegeChoices'
];

const getApplicationPages = (application) => {
    const result = [];
    const fieldsVisibility = whatShouldBeDisplayed(application);
    const sectionsAndFields = getApplicationQuestions(application);
    
    sectionsAndFields.forEach(section => {
        const { title, sectionKey, theme } = section;
        // There are some sections we are showing/hiding.
        // For example studentHasAnyBenefits, parentHasAnyBenefits, 
        if (sectionKey && fieldsVisibility[sectionKey] === false) return;

        const rows = section.rows || section;
        const visibleRows = []
        rows.forEach(rowKey => {
            if (fieldsVisibility[rowKey] !== false) {
                visibleRows.push(rowKey);
            }
        });

        if (visibleRows.length) {
            result.push({sectionKey, title, rows: visibleRows, theme })
        }
    });
    return result;
};

export {
    RequireSpecialRender,
    getApplicationPages,
}
