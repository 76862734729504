import { post } from './api';

export default (email, push, showNotification) => {
  post('/auth/send-password-reset-email', { email })
    .then(() => {
      showNotification('Sent password reset email, check your inbox.');
      push('/login');
    })
    .catch(error => {
      showNotification(error.message || 'Unkown error');
    });
};
