import React, { memo } from 'react';
import { arrayOf, shape, func } from 'prop-types';

import NumericList from '../NumericList';
import defaultRenderer from './defaultRenderer';

import './styles.scss';

const SchoolsList = ({ items, renderer, copyButtonProps, ...props }) => (
  <NumericList
    className="schools-list"
    items={items}
    renderer={item =>
      renderer({
        ...item,
        copyButtonProps: { ...copyButtonProps, source: item.id },
      })
    }
    {...props}
  />
);

SchoolsList.propTypes = {
  items: arrayOf(shape()),
  renderer: func,
  copyButtonProps: shape(),
};

SchoolsList.defaultProps = {
  items: [],
  renderer: defaultRenderer,
  copyButtonProps: {},
};

export default memo(SchoolsList);
