import JaimeJpg from '../../assets/images/InternalUsers/Jaime.JPG';
import TiaraJpg from '../../assets/images/InternalUsers/Tiara.JPG';
import JoseJpg from '../../assets/images/InternalUsers/Jose.JPG';

const TIARA = 'tiara@withfrank.org';
const JOSE = 'jose@withfrank.org';
const JAIME = 'jaime@withfrank.org';

const INTERNAL_USERS_AVATAR = {
  [TIARA]: TiaraJpg,
  [JAIME]: JaimeJpg,
  [JOSE]: JoseJpg,
};

export default email => INTERNAL_USERS_AVATAR[email];
