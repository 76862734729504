import React from 'react';
import { shape, bool, string } from 'prop-types';
import { Chip } from 'material-ui';

import withChipStyles from '../../../hocs/withChipStyles';
import colors from '../../../constants/colors';

const Flags = ({
  isPendingParent,
  parentConfirmed,
  inviteParentToken,
  isPendingIRS,
  isPendingIRSForParent,
  isWaitingForCustomer,
  escalated,
  styles,
}) => [
  isPendingParent && (
    <Chip
      key="isPendingParent"
      labelStyle={styles.label}
      backgroundColor={colors.lighterBlue}
      style={{ ...styles.chip, borderColor: colors.cobaltBlue }}
      className="selectable_chip"
    >
      Pending Parent
    </Chip>
  ),
  parentConfirmed && (
    <Chip
      key="parentConfirmed"
      labelStyle={styles.label}
      backgroundColor={colors.lighterGreen}
      style={{
        ...styles.chip,
        cursor: 'pointer',
        borderColor: colors.jade,
      }}
      title={`${parentConfirmed}`}
      href={`#/parents-applications/${inviteParentToken}/show`}
      target="_blank"
      className="selectable_chip"
    >
      Parent Submitted
    </Chip>
  ),
  isPendingIRS && (
    <Chip
      key="isPendingIRS"
      labelStyle={styles.label}
      backgroundColor={colors.lighterRed}
      style={{ ...styles.chip, borderColor: colors.red }}
      className="selectable_chip"
    >
      Pending IRS
    </Chip>
  ),
  isPendingIRSForParent && (
    <Chip
      key="isPendingIRSForParent"
      labelStyle={styles.label}
      backgroundColor={colors.lighterRed}
      style={{ ...styles.chip, borderColor: colors.red }}
      className="selectable_chip"
    >
      Pending IRS for Parent
    </Chip>
  ),
  isWaitingForCustomer && (
    <Chip
      key="isWaitingForCustomer"
      labelStyle={styles.label}
      backgroundColor={colors.lighterYellow}
      style={{ ...styles.chip, borderColor: colors.yellow }}
      className="selectable_chip"
    >
      Waiting For Customer
    </Chip>
  ),
  escalated && (
    <Chip
      key="escalated"
      labelStyle={styles.label}
      backgroundColor={colors.lighterRed}
      style={{ ...styles.chip, borderColor: colors.red }}
      className="selectable_chip"
    >
      Escalated
    </Chip>
  ),
];

Flags.propTypes = {
  isPendingParent: bool,
  parentConfirmed: string,
  inviteParentToken: string,
  isPendingIRS: bool,
  isPendingIRSForParent: bool,
  isWaitingForCustomer: bool,
  escalated: bool,
  styles: shape(),
};

Flags.defaultProps = {
  isPendingParent: false,
  parentConfirmed: '',
  inviteParentToken: '',
  isPendingIRS: false,
  isPendingIRSForParent: false,
  isWaitingForCustomer: false,
  escalated: false,
  shape: {},
};

export default withChipStyles(Flags);
