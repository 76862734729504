import React, { PureComponent } from 'react';
import { oneOfType, number, string } from 'prop-types';

import './style.scss';

class NewTabLink extends PureComponent {
  static propTypes = {
    to: string.isRequired,
    children: oneOfType([number, string]).isRequired,
    className: string,
  }
  
  static defaultProps = {
    className: 'new-tab-link',
  };

  onClick = e => e.stopPropagation();

  render() {
    const { to, children, className } = this.props;

    return (
      <a
        className={className}
        rel="noopener noreferrer"
        target="_blank"
        href={to}
        onClick={this.onClick}
      >
        {children}
      </a>
    );
  }
}

export default NewTabLink;
