import React, { PureComponent } from 'react';
import { shape } from 'prop-types';
import { Edit, TabbedForm } from 'admin-on-rest';
import compose from 'recompose/compose';

import ApplicationTitle from '../ApplicationTitle';
import numberScrollProtector from '../../../components/numberScrollProtector';
import ResourcePropertyEditToolbar from '../../ResourcePropertyEditToolbar';
import { STUDENT } from '../../ApplicationType';

import EditActions from '../../EditActions';
import GeneralTab from './GeneralTab';
import StudentTaxDataTab from './StudentTaxDataTab';
import ParentTaxDataTab from './ParentTaxDataTab';
import {hasOnePermission, PERMISSION_TAX} from "../../../utils/permissions";

class TabbedFormComponent extends PureComponent {
  static propTypes = {
    match: shape().isRequired,
    record: shape(),
  };

  static defaultProps = {
    record: { backofficeData: {} },
  };

  state = { comment: '' };

  onCommentChange = (comment) => {
    this.setState({ comment })
  };

  getIsStudentDependency = () => {
    const { record } = this.props;
    return record.studentDependency === 'DEPENDENT';
  };

  render() {
    const { match } = this.props;
    const { comment } = this.state;

    return (
      <TabbedForm
        toolbar={
          <ResourcePropertyEditToolbar
            applicationType={STUDENT}
            taxFormFor={STUDENT}
            id={match.params.id}
            comment={comment}
            {...this.props}
          />
        }
        {...this.props}
      >
        <GeneralTab
          label="General"
          onCommentChange={this.onCommentChange}
          comment={comment}
          {...this.props}
        />
        {hasOnePermission(PERMISSION_TAX) && (
            <StudentTaxDataTab label="Student Tax Data" {...this.props} />
        )}
        {hasOnePermission(PERMISSION_TAX) && this.getIsStudentDependency() && (
          <ParentTaxDataTab label="Parent Tax Data" {...this.props} />
        )}
      </TabbedForm>
    )
  }
}

const StudentApplicationEdit = (props) => (
  <Edit title={<ApplicationTitle />} actions={<EditActions />} {...props} >
    {() =>
      <TabbedFormComponent
        {...props}
      />
    }
  </Edit>
);

export default compose(
  numberScrollProtector,
)(StudentApplicationEdit);
