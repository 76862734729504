import { post } from './api';
import { updateLocalStorageAuthData } from './localAuthStorage';

export default (inviteToken, password, push, showNotification) => {
  post('/auth/register-from-invite', {
    token: inviteToken,
    password,
  })
    .then(({ token: loginToken }) => {
      updateLocalStorageAuthData(loginToken);
      showNotification('Registeration successful');
      push('/');
    })
    .catch(error => {
      showNotification(error.message || 'Unkown error');
    });
};
