import React, { PureComponent } from 'react';
import { bool } from 'prop-types';
import CircularProgress from 'material-ui/CircularProgress';

import './style.scss';

export default ({size = 100} = {}) => (Component) => {
  class WithProcessingIndicator extends PureComponent {
    static propTypes = {
      isLoading: bool,
    };

    static defaultProps = {
      isLoading: false,
    };

    render() {
      const { isLoading, ...props } = this.props;
      const className = `progress-indicator${isLoading ? '__active' : ''}`;

      return (
        <div className="progress-indicator-wrapper">
          <div className={className}>
            <CircularProgress size={size} />
          </div>
          <Component {...props} />
        </div>)
    }
  }

  return WithProcessingIndicator;
};
