import React, { PureComponent } from 'react';
import { shape, func } from 'prop-types';
import { connect } from 'react-redux';

import RaisedButton from 'material-ui/RaisedButton';
import SendIcon from 'material-ui/svg-icons/content/send';
import { Card } from 'material-ui/Card';
import { push as pushAction } from 'react-router-redux';
import {
  ViewTitle,
  showNotification as showNotificationAction,
  Notification,
} from 'admin-on-rest';

import NotificationMessagesDropDownMenu from './notifications/NotificationMessagesDropDownMenu';
import NotificationMessagePreview from './notifications/NotificationMessagePreview';
import sendNotificationToUser from '../api/sendNotificationToUser';

const styles = {
  button: { margin: 12, width: 205 },
  page: { margin: 20 },
};

const getNotificationId = location =>
  location.pathname.match(/\/applications\/(\d+)\/notification/)[1];

class SendNotificationPage extends PureComponent {
  static propTypes = {
    location: shape().isRequired,
    push: func.isRequired,
    showNotification: func.isRequired,
  };
  state = {
    selectedMessage: '',
    sendInProgress: false,
  };

  onChangedSelection = (event, key, value) => {
    this.setState({ selectedMessage: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { push, showNotification, location } = this.props;
    this.setState({ sendInProgress: true });
    sendNotificationToUser({
      applicationId: this.applicationId,
      message: this.state.selectedMessage.userMessage,
    })
      .then(() => {
        this.setState({ sendInProgress: false });
        showNotification('Notification sent');
        push(`/applications/${getNotificationId(location)}`);
      })
      .catch(error => {
        this.setState({ sendInProgress: false });
        showNotification(error.message || 'Unkown error');
      });
  };

  render() {
    const { location } = this.props;
    const { selectedMessage, sendInProgress } = this.state;
    this.applicationId = getNotificationId(location);
    return (
      <Card>
        <ViewTitle title="Send a notification" />
        <NotificationMessagesDropDownMenu
          applicationId={this.applicationId}
          value={selectedMessage}
          onChangedSelection={this.onChangedSelection}
        />
        {selectedMessage && (
          <div style={styles.page}>
            <NotificationMessagePreview
              userMessage={selectedMessage.userMessage}
              englishMessage={selectedMessage.englishMessage}
            />
            <RaisedButton
              label={sendInProgress ? 'Sending...' : 'Confirm and send'}
              labelPosition="before"
              icon={!sendInProgress && <SendIcon />}
              type="submit"
              style={styles.button}
              primary={true}
              onClick={this.handleSubmit}
              disabled={sendInProgress}
            />
          </div>
        )}
        <Notification />
      </Card>
    );
  }
}

export default connect(null, {
  showNotification: showNotificationAction,
  push: pushAction,
})(SendNotificationPage);
