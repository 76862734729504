import {
  assoc, keys, pipe, path, reduce,
} from 'ramda';

const studentTaxAutomationPathes = {
  studentTaxLine1: ['doc_1040', 'student_tax_line_1'],
  studentTaxLine2a: ['doc_1040', 'student_tax_line_2a'],
  studentTaxLine4a: ['doc_1040', 'student_tax_line_4a'],
  studentTaxLine4b: ['doc_1040', 'student_tax_line_4b'],
  studentTaxLine4c: ['doc_1040', 'student_tax_line_4c'],
  studentTaxLine4d: ['doc_1040', 'student_tax_line_4d'],
  studentTaxLine7: ['doc_1040', 'student_tax_line_7'],
  studentTaxLine13: ['doc_1040', 'student_tax_line_13'],
  studentTaxLine12: ['doc_schedule_1', 'student_tax_line_12'],
  studentTaxSchedule1Line13: ['doc_schedule_1', 'student_tax_schedule_1_line_13'],
  studentTaxLine18: ['doc_schedule_1', 'student_tax_line_18'],
  studentTaxSchedule1Line21: ['doc_schedule_1', 'student_tax_schedule_1_line_21'],
  studentTaxLine28: ['doc_schedule_1', 'student_tax_line_28'],
  studentTaxLine32: ['doc_schedule_1', 'student_tax_line_32'],
  studentTaxLine46: ['doc_schedule_2', 'student_tax_line_46'],
  studentTaxLine50: ['doc_schedule_3', 'student_tax_line_50'],
  studentTaxBox14CodeA: ['doc_schedule_k1', 'student_tax_schedule_k1_box14_code_a'],
};
const parentTaxAutomationPathes = {
  parentTaxLine1: ['doc_1040', 'parent_tax_line_1'],
  parentTaxLine2a: ['doc_1040', 'parent_tax_line_2a'],
  parentTaxLine4a: ['doc_1040', 'parent_tax_line_4a'],
  parentTaxLine4b: ['doc_1040', 'parent_tax_line_4b'],
  parentTaxLine4c: ['doc_1040', 'parent_tax_line_4c'],
  parentTaxLine4d: ['doc_1040', 'parent_tax_line_4d'],
  parentTaxLine7: ['doc_1040', 'parent_tax_line_7'],
  parentTaxLine13: ['doc_1040', 'parent_tax_line_13'],
  parentTaxLine12: ['doc_schedule_1', 'parent_tax_line_12'],
  parentTaxSchedule1Line13: ['doc_schedule_1', 'parent_tax_schedule_1_line_13'],
  parentTaxLine18: ['doc_schedule_1', 'parent_tax_line_18'],
  parentTaxSchedule1Line21: ['doc_schedule_1', 'parent_tax_schedule_1_line_21'],
  parentTaxLine28: ['doc_schedule_1', 'parent_tax_line_28'],
  parentTaxLine32: ['doc_schedule_1', 'parent_tax_line_32'],
  parentTaxLine46: ['doc_schedule_2', 'parent_tax_line_46'],
  parentTaxLine50: ['doc_schedule_3', 'parent_tax_line_50'],
  parentTaxBox14CodeA: ['doc_schedule_k1', 'parent_tax_schedule_k1_box14_code_a'],
};

const getTaxAutomationValues = (isStudent, record) => {
  const dataPath = [`${isStudent ? 'student' : 'parent'}TaxAutomationProcessing`, 'data'];
  const data = path(dataPath, record);
  if (!data) return null;

  const taxAutomationPathes = isStudent
    ? studentTaxAutomationPathes
    : parentTaxAutomationPathes;

  const iterator = (acc, key) => {
    const autoValue = pipe(
      path(taxAutomationPathes[key]),
      x => Number.parseInt(x, 10),
    )(data);
    if (!autoValue && autoValue !== 0) return acc;
    return assoc(key, autoValue, acc);
  };

  return pipe(
    keys,
    reduce(iterator, {}),
  )(taxAutomationPathes);
};

export default getTaxAutomationValues;
