export default (record) => {
  const {
    didParentCompleteTaxReturn,
    parentEarningsFromWorkNoTaxReturn,
    otherParentEarningsFromWorkNoTaxReturn,
    parentTaxFilingStatus,
    backofficeData: { taxYear },
  } = record;
  const messages = [];

  const isOtherParent = parentTaxFilingStatus && parentTaxFilingStatus !== 'SINGLE';
  const isParentNoTaxNoEarn =
    !didParentCompleteTaxReturn &&
    !parentEarningsFromWorkNoTaxReturn &&
    (!otherParentEarningsFromWorkNoTaxReturn || !isOtherParent);
  const isParentNoTaxHasEarn = parentEarningsFromWorkNoTaxReturn > 0;
  const isOtherParentHasEarn = isOtherParent && otherParentEarningsFromWorkNoTaxReturn > 0;

  if (isParentNoTaxNoEarn) {
    messages.push(`Parent did not file taxes for ${taxYear} and did not earn money`);

    return messages;
  }

  if (!didParentCompleteTaxReturn) {
    messages.push(`Parent did not file taxes for ${taxYear}`);

    if (isParentNoTaxHasEarn) {
      messages.push(`Parent earned ${parentEarningsFromWorkNoTaxReturn}`);
    }

    if (isOtherParentHasEarn) {
      messages.push(`Other parent earned ${otherParentEarningsFromWorkNoTaxReturn}`);
    }
  }

  return messages;
};
