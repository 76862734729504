import { pipe, reject, equals, length, contains, isEmpty } from 'ramda';

const CONVICTIONS_COUNTS = ['1', '2', '3+'];

export default  (application) => {
  const IsDependent = application.studentDependency === 'DEPENDENT';
  const IsInDependent = application.studentDependency === 'INDEPENDENT';
  const IsPreDependent = application.studentPreDependency === 'DEPENDENT';

  const workingOnDegree = true;
  const program = true;
  const educationLevel = true;
  const collegeYear = true;
  const homeAddressNumberAndStreet = true;
  const homeAddressCity = true;
  const homeAddressState = true;
  const homeAddressZipCode = true;
  const homeAddressCountry = true;
  const dateOfBirth = true;
  const gender = true;
  const studentIsOrWasMarried = true;
  const studentMaritalStatus = true;
  const studentMaritalStatusMonth =
      application.studentIsOrWasMarried && !!(application.studentMaritalStatus);

  const studentMaritalStatusYear =
      application.studentIsOrWasMarried && !!(application.studentMaritalStatus);
  const spouseGoingToCollege =
      application.studentIsOrWasMarried
      && application.studentMaritalStatus === 'MARRIED_OR_REMARRIED';
  const hasChildrenOrDependents = application.studentMaritalStatus === 'MARRIED_OR_REMARRIED';
  const hasChildrenGettingSupport = true;
  const supportedChildren = application.hasChildrenOrDependents && application.hasChildrenGettingSupport;
  const childrenInCollege = application.hasChildrenOrDependents && application.hasChildrenGettingSupport;
  const hasDependentsGettingSupport = true;
  const otherSupportedChildren =
      application.hasChildrenOrDependents && application.hasDependentsGettingSupport;
  const otherChildrenInCollege =
      application.hasChildrenOrDependents && application.hasDependentsGettingSupport;
  const numOfPeopleGoingToCollege = IsInDependent;
  const highSchoolCompletion = true;
  const highSchoolName = application.highSchoolCompletion === 'HIGH_SCHOOL_DIPLOMA';
  const highSchoolCity = application.highSchoolCompletion === 'HIGH_SCHOOL_DIPLOMA';
  const highSchoolState = application.highSchoolCompletion === 'HIGH_SCHOOL_DIPLOMA';
  const associateDegreeType = application.workingOnDegree === 'ASSOCIATE_DEGREE';
  const isCertificateOrDiplomaTwoYearsOrMore = application.workingOnDegree === 'CERTIFICATE_OR_DIPLOMA';
  const currentlyAtSchool = application.collegeChoices && application.collegeChoices.length;
  const collegeChoices = true;
  const attendedCollegeBefore =
      application.collegeYear === 'FIRST'
      && application.workingOnDegree !== 'GRADUATE_PROFESSIONAL_OR_DOCTORAL_DEGREE';
  const hadFinancialAidBefore = true;
  const haveYouBeenConvictedForAnOffense = hadFinancialAidBefore  && application.hadFinancialAidBefore;
  const haveYouBeenConvictedForIlegalDrugs =
    hadFinancialAidBefore  && application.hadFinancialAidBefore;
  const haveYourFederalDrugRelatedBeenRemoved =
      haveYouBeenConvictedForIlegalDrugs 
      && application.haveYouBeenConvictedForIlegalDrugs;
  const didPossessingDrugsOccurDuringStudentAid =
      haveYourFederalDrugRelatedBeenRemoved 
      && application.haveYouBeenConvictedForIlegalDrugs;
  const haveYouCompletedADrugRehabilitationProgram =
      didPossessingDrugsOccurDuringStudentAid 
      && application.didPossessingDrugsOccurDuringStudentAid;
  const howManyFederalOrStateConvictionsForPossessingDrugs =
      haveYouCompletedADrugRehabilitationProgram 
      && !application.haveYouCompletedADrugRehabilitationProgram;
  const dateOfLastConvictionForPossessingDrugs =
      howManyFederalOrStateConvictionsForPossessingDrugs 
      && contains(application.howManyFederalOrStateConvictionsForPossessingDrugs , CONVICTIONS_COUNTS);
  const howManyFederalOrStateConvictionsForSellingDrugs =
      haveYouCompletedADrugRehabilitationProgram 
      && !application.haveYouCompletedADrugRehabilitationProgram;
  const dateOfLastConvictionForSellingDrugs =
      howManyFederalOrStateConvictionsForSellingDrugs 
      && contains(application.howManyFederalOrStateConvictionsForSellingDrugs , CONVICTIONS_COUNTS);
  const basedOnTheAnsRegardingDrugRelatedConvictions =
      (!application.haveYouBeenConvictedForIlegalDrugs
        && application.haveYouBeenConvictedForIlegalDrugs)
      || application.haveYourFederalDrugRelatedBeenRemoved
      || (!application.didPossessingDrugsOccurDuringStudentAid
        && application.didPossessingDrugsOccurDuringStudentAid)
      || application.haveYouCompletedADrugRehabilitationProgram;

  const willHaveFirstBachelorsBeforeSchoolYear =
      application.attendedCollegeBefore
      || application.collegeYear !== 'FIRST'
      || !attendedCollegeBefore;

  const studentDislocatedWorker = IsInDependent;

  const isServingOrServedInArmy = true;
  const armyServiceType = application.isServingOrServedInArmy;
  const dependentCheckListLength = pipe(
    reject(equals(false)),
    length,
  )([
    application.dependentChecklistBothParentsAreDeceased,
    application.dependentChecklistIsFoster,
    application.dependentChecklistIsDependentOrWardOfCourt,
    application.dependentChecklistHasLegalGuardianNotAParent,
    application.dependentChecklistIsEmancipatedMinor,
    application.dependentChecklistIsHomelessOrAtRiskOfBecomingHomeless,
    application.dependentChecklistIsNoneOfTheAbove,
  ]);
  const isSupportedByOneOfBiologicalParents = IsPreDependent && IsDependent;
  const studentIsOrWasAdopted = IsPreDependent && application.isSupportedByOneOfBiologicalParents === false;
  const dependentChecklistBothParentsAreDeceased = IsPreDependent;
  const dependentChecklistIsFoster = IsPreDependent;
  const dependentChecklistIsDependentOrWardOfCourt = IsPreDependent;
  const dependentChecklistHasLegalGuardianNotAParent = IsPreDependent;
  const dependentChecklistIsEmancipatedMinor = IsPreDependent;
  const dependentChecklistIsHomelessOrAtRiskOfBecomingHomeless = IsPreDependent;
  const dependentChecklistIsNoneOfTheAbove = IsPreDependent;
  const declaredHomelessByHighSchool =
      application.dependentChecklistIsHomelessOrAtRiskOfBecomingHomeless
      && dependentCheckListLength === 1;
  const declaredHomelessByHUD =
      application.dependentChecklistIsHomelessOrAtRiskOfBecomingHomeless
      && dependentCheckListLength === 1
      && !application.declaredHomelessByHighSchool;
  const declaredHomelessByBasicCenter =
      application.dependentChecklistIsHomelessOrAtRiskOfBecomingHomeless
      && dependentCheckListLength === 1
      && !application.declaredHomelessByHighSchool
      && !application.declaredHomelessByHUD;
  const legalGuardianAppointedByCourt =
      application.dependentChecklistHasLegalGuardianNotAParent
      && dependentCheckListLength === 1;
  const dependentCheckListItemsThatMakeDependent = pipe(
    reject(equals(false)),
    length,
  )([
    application.dependentChecklistBothParentsAreDeceased,
    application.dependentChecklistIsFoster,
    application.dependentChecklistIsDependentOrWardOfCourt,
    application.dependentChecklistIsEmancipatedMinor,
  ]);

  /* Dependency calculated realtime on Dependency page based on answers */
  const syncIndependent = (
    application.isServingOrServedInArmy
    || dependentCheckListItemsThatMakeDependent > 0
    || (declaredHomelessByHighSchool  && application.declaredHomelessByHighSchool)
    || (declaredHomelessByHUD  && application.declaredHomelessByHUD)
    || (declaredHomelessByBasicCenter 
          && application.declaredHomelessByBasicCenter)
    || (legalGuardianAppointedByCourt 
          && application.legalGuardianAppointedByCourt)
  );

  const isFosterIndependentStudent = true;
  const livedInHomeAddressStateAtLeastFiveYears = true;
  const legalStateOfResidence = !application.livedInHomeAddressStateAtLeastFiveYears && application.livedInHomeAddressStateAtLeastFiveYears === false;
  const movedToStateOfResidenceMonth = !application.livedInHomeAddressStateAtLeastFiveYears && application.livedInHomeAddressStateAtLeastFiveYears === false;
  const movedToStateOfResidenceYear = !application.livedInHomeAddressStateAtLeastFiveYears && application.livedInHomeAddressStateAtLeastFiveYears === false;
  const isCitizen = true;
  const immigrationStatus = !application.isCitizen;
  const studentArn =
      !application.isCitizen
      && application.immigrationStatus !== 'NONE_OF_THE_ABOVE'
      && !!(application.immigrationStatus);

  let gavePermissionToRegisterForSelectiveServices;
  let reasonForNotRegisteringForSelectiveServices;
  const birthYear = application.partialBirthday 
    && Number(application.partialBirthday.substr(0, 4));
  if (birthYear) {
    const actualYear = new Date().getFullYear();
    const minYear = actualYear - 26;
    const maxYear = actualYear - 17;
    gavePermissionToRegisterForSelectiveServices = 
      birthYear >= minYear && birthYear <= maxYear && application.gender === 'MALE';
    reasonForNotRegisteringForSelectiveServices =
        gavePermissionToRegisterForSelectiveServices
        && !application.gavePermissionToRegisterForSelectiveServices;
  }

  const didStudentEarnMoney = IsInDependent && application.didStudentCompleteTaxReturn === false;
  const studentEarningsFromWork = application.didStudentCompleteTaxReturn === true;
  const didStudentCompleteTaxReturn = true;
  const studentTaxFiles = didStudentCompleteTaxReturn && application.didStudentCompleteTaxReturn;
  const parentType =
      application.parentsMaritalStatus
      && !(
        application.parentsMaritalStatus === 'UNMARRIED_AND_BOTH_PARENTS_LIVING_TOGETHER'
        || application.parentsMaritalStatus === 'MARRIED_OR_REMARRIED'
      );
  const highestSchoolCompletedByParent1 = true;
  const highestSchoolCompletedByParent2 = !parentType;
  const studentTaxSetup = application.didStudentCompleteTaxReturn && IsInDependent;
  const studentTaxFilingStatus = application.didStudentCompleteTaxReturn === true;
  const studentTaxReturnType = application.didStudentCompleteTaxReturn === true;
  const studentAdjustedGrossIncome = application.didStudentCompleteTaxReturn === true;
  const studentPartnerEarningsFromWork = !application.didStudentCompleteTaxReturn === true && IsInDependent;
  const studentIncomeTax = application.didStudentCompleteTaxReturn === true;
  const studentExemptions = IsInDependent;
  const studentIraDeductions = true;
  const studentTaxExemptInterestIncome = true;
  const studentUntaxedPortionsOfIRA = true;
  const studentUntaxedPortionsOfPensions = IsInDependent;
  const studentEarningsFromWorkNoTaxReturn = application.didStudentCompleteTaxReturn === false;
  const didStudentPartnerEarnMoney =
    IsInDependent && application.studentMaritalStatus === 'MARRIED_OR_REMARRIED'
    && application.studentTaxFilingStatus !== 'MARRIED_FILED_JOINT_RETURN'
    && application.didStudentCompleteTaxReturn === false;
  const studentPartnerEarningsFromWorkNoTaxReturn = application.didStudentCompleteTaxReturn === false && IsInDependent;
  const parentCashBalance = IsDependent;
  const studentHasInvestmentsBusinessOrRealEstate = true;
  const studentNetWorthOfInvestments = true;
  const studentHasBusiness = studentHasInvestmentsBusinessOrRealEstate  === true;
  const studentNetWorthOfBusinesses = true;
  const studentHasPensionSavings = true;
  const studentAmountSavedInPensions = true;
  const studentDidPayOrReceiveChildSupport = true;
  const studentChildSupportPaidAmount = true;
  const studentChildSupportReceivedAmount = true;

  const studentHasAnyBenefits = IsInDependent;
  const studentHasArmyOrVeteranBenefits =
      (IsDependent
        && application.studentIsOrWasMarried
        && application.studentMaritalStatus  === 'DIVORCED_OR_WIDOWED')
      || (IsInDependent && application.studentHasAnyBenefits);
  const studentCombatPayAmount = true;
  const studentAllowancesAmount = true;
  const studentVeteranNoneducationBenefitsAmount = true;
  const studentHasEducationBenefits =
      (IsDependent && application.hadFinancialAidBefore)
      || (IsInDependent && application.studentHasAnyBenefits);
  const studentEarningsFromEmploymentPrograms = true;
  const studentCollegeGrantAmount = true;
  const studentEarningsFromCoopWork = true;
  const studentEducationCreditsAmount =
      studentHasEducationBenefits  && application.studentHasEducationBenefits;
  const studentTaxLine50 = true;

  const studentHasFoodIncomeOrHealthBenefits = studentHasAnyBenefits  && application.studentHasAnyBenefits;
  const parentHasInvestmentsBusinessOrRealEstate = IsDependent;
  const parentNetWorthOfInvestments = IsDependent;
  const parentHasBusiness = application.parentHasInvestmentsBusinessOrRealEstate && IsDependent;
  const parentNetWorthOfBusinesses = IsDependent;
  const parentHasPensionSavings = IsDependent;
  const parentAmountSavedInPensions = IsDependent;
  const parentDidPayOrReceiveChildSupport = IsDependent;
  const parentChildSupportPaidAmount = IsDependent;
  const parentChildSupportReceivedAmount = IsDependent;

  const parentHasAnyBenefits = IsDependent;
  const parentHasArmyOrVeteranBenefits = application.parentHasAnyBenefits && IsDependent;
  const parentCombatPayAmount = IsDependent;
  const parentAllowancesAmount = IsDependent;
  const parentVeteranNoneducationBenefitsAmount = IsDependent;
  const parentHasEducationBenefits = IsDependent && application.parentHasAnyBenefits;
  const parentEarningsFromEmploymentPrograms = IsDependent;
  const parentCollegeGrantAmount = IsDependent;
  const parentEarningsFromCoopWork = IsDependent;
  const parentEducationCreditsAmount = true;
  const parentHasFoodIncomeOrHealthBenefits = IsDependent && application.parentHasAnyBenefits;
  const untaxedIncomeNotReported = IsDependent;
  const otherUntaxedIncomeNotReported = IsDependent;
  const didReceiveMoneyNotReportedOnThisForm = true;
  const howMuchMoneyNotReportedOnThisForm = true;
  const didYouHaveUntaxedIncomeNotReported = true;
  const doYouHaveAnyCash = IsDependent;
  const studentCashBalance = true;
  const didParentCompleteTaxReturn = IsDependent;
  const parentTaxSetup = application.didParentCompleteTaxReturn && IsDependent;
  const parentEarningsFromWork = parentTaxSetup;
  const parentTaxFiles =
      IsDependent
      && parentTaxSetup 
      && application.parentTaxSetup;
  const parentTaxFilingStatus = parentTaxSetup;
  const parentTaxReturnType = parentTaxSetup;
  const parentAdjustedGrossIncome = parentTaxSetup;
  const otherParentEarningsFromWork = IsDependent
      && (application.parentsMaritalStatus === 'MARRIED_OR_REMARRIED'
        || application.parentsMaritalStatus === 'UNMARRIED_AND_BOTH_PARENTS_LIVING_TOGETHER')
      && application.didParentCompleteTaxReturn
      && !application.parentTaxSetup;
  const parentIncomeTax = parentTaxSetup;
  const parentExemptions = parentTaxSetup  && !application.parentTaxSetup && IsDependent;
  const parentIraDeductions = IsDependent;
  const parentTaxExemptInterestIncome = IsDependent;
  const parentUntaxedPortionsOfIRA = IsDependent;
  const parentUntaxedPortionsOfPensions = parentTaxSetup  && !application.parentTaxSetup && IsDependent;
  const parentEarningsFromWorkNoTaxReturn = !application.didParentCompleteTaxReturn === true && IsDependent;
  const otherParentEarningsFromWorkNoTaxReturn = IsDependent
      && !application.didParentCompleteTaxReturn
      && (application.parentsMaritalStatus === 'UNMARRIED_AND_BOTH_PARENTS_LIVING_TOGETHER'
        || application.parentsMaritalStatus === 'MARRIED_OR_REMARRIED');
  const parentLivedInHomeAddressStateAtLeastFiveYears = IsDependent;
  const parentLegalStateOfResidence = IsDependent;
  const parentMovedToStateOfResidenceMonth = !application.parentLivedInHomeAddressStateAtLeastFiveYears && IsDependent;

  const parentMovedToStateOfResidenceYear = !application.parentLivedInHomeAddressStateAtLeastFiveYears && IsDependent;
  const studentSsn = true;
  const parentsMaritalStatus = IsDependent;

  const parentsMaritalStatusMonth =
      !isEmpty(application.parentsMaritalStatus)
      && parentsMaritalStatus
      && application.parentsMaritalStatus !== 'NEVER_MARRIED'
      && application.parentsMaritalStatus !== 'UNMARRIED_AND_BOTH_PARENTS_LIVING_TOGETHER';
  const parentsMaritalStatusYear =
      !isEmpty(application.parentsMaritalStatus)
      && parentsMaritalStatus      
      && application.parentsMaritalStatus !== 'NEVER_MARRIED'
      && application.parentsMaritalStatus !== 'UNMARRIED_AND_BOTH_PARENTS_LIVING_TOGETHER';
  const parent1FirstName = IsDependent;
  const parent1LastName = IsDependent;
  const parent1Ssn = IsDependent;
  const parent1DateOfBirth = IsDependent;
  const parentPersonal1Ssn = IsDependent;
  const parentPersonal1DateOfBirth = IsDependent;
  const parent2FirstName = !parentType && IsDependent;
  const parent2LastName = !parentType && IsDependent;
  const parent2Ssn = !parentType && IsDependent;
  const parent2DateOfBirth = !parentType && IsDependent;
  const parentPersonal2Ssn = !parentType && IsDependent;
  const parentPersonal2DateOfBirth = !parentType && IsDependent;
  const howManyChildrenSupportedByParent = IsDependent;
  const howManyOtherSupportedByParent = IsDependent;
  const parentNumOfPeopleGoingToCollege = IsDependent;
  const parentFirstName = IsDependent;
  const parentLastName = IsDependent;
  const parentEmail = IsDependent;
  const parentPhone = IsDependent;

  const parentDislocatedWorker = IsDependent;
  const didParentFileSchedule1 = IsDependent;
  const parentBenefitMedicaid = IsDependent;
  const parentBenefitSSI = IsDependent;
  const parentBenefitSNAP = IsDependent;
  const parentBenefitLunch = IsDependent;
  const parentBenefitTANF = IsDependent;
  const parentBenefitWIC = IsDependent;
  const parentTaxLine50 = IsDependent;

  const whatShouldBeDisplayed = {
    workingOnDegree,
    collegeYear,
    homeAddressNumberAndStreet,
    homeAddressCity,
    homeAddressState,
    homeAddressZipCode,
    homeAddressCountry,
    dateOfBirth,
    gender,
    studentIsOrWasMarried,
    studentMaritalStatus,
    studentMaritalStatusMonth,
    studentMaritalStatusYear,
    spouseGoingToCollege,
    hasChildrenOrDependents,
    hasChildrenGettingSupport,
    supportedChildren,
    childrenInCollege,
    hasDependentsGettingSupport,
    otherSupportedChildren,
    otherChildrenInCollege,
    numOfPeopleGoingToCollege,
    highSchoolCompletion,
    highSchoolName,
    highSchoolCity,
    highSchoolState,
    associateDegreeType,
    isCertificateOrDiplomaTwoYearsOrMore,
    currentlyAtSchool,
    collegeChoices,
    attendedCollegeBefore,
    hadFinancialAidBefore,
    haveYouBeenConvictedForAnOffense,
    haveYouBeenConvictedForIlegalDrugs,
    haveYourFederalDrugRelatedBeenRemoved,
    didPossessingDrugsOccurDuringStudentAid,
    haveYouCompletedADrugRehabilitationProgram,
    howManyFederalOrStateConvictionsForPossessingDrugs,
    dateOfLastConvictionForPossessingDrugs,
    howManyFederalOrStateConvictionsForSellingDrugs,
    dateOfLastConvictionForSellingDrugs,
    basedOnTheAnsRegardingDrugRelatedConvictions,
    willHaveFirstBachelorsBeforeSchoolYear,
    studentDislocatedWorker,
    isServingOrServedInArmy,
    armyServiceType,
    isSupportedByOneOfBiologicalParents,
    studentIsOrWasAdopted,
    dependentChecklistBothParentsAreDeceased,
    dependentChecklistIsFoster,
    dependentChecklistIsDependentOrWardOfCourt,
    dependentChecklistHasLegalGuardianNotAParent,
    dependentChecklistIsEmancipatedMinor,
    dependentChecklistIsHomelessOrAtRiskOfBecomingHomeless,
    dependentChecklistIsNoneOfTheAbove,
    declaredHomelessByHighSchool,
    declaredHomelessByHUD,
    declaredHomelessByBasicCenter,
    legalGuardianAppointedByCourt,
    syncIndependent,
    isFosterIndependentStudent,
    livedInHomeAddressStateAtLeastFiveYears,
    legalStateOfResidence,
    movedToStateOfResidenceMonth,
    movedToStateOfResidenceYear,
    isCitizen,
    immigrationStatus,
    studentArn,
    gavePermissionToRegisterForSelectiveServices,
    reasonForNotRegisteringForSelectiveServices,
    didStudentEarnMoney,
    studentEarningsFromWork,
    didStudentCompleteTaxReturn,
    studentTaxFiles,
    highestSchoolCompletedByParent1,
    highestSchoolCompletedByParent2,
    studentTaxSetup,
    studentTaxFilingStatus,
    studentTaxReturnType,
    studentAdjustedGrossIncome,
    studentPartnerEarningsFromWork,
    studentIncomeTax,
    studentExemptions,
    studentIraDeductions,
    studentTaxExemptInterestIncome,
    studentUntaxedPortionsOfIRA,
    studentUntaxedPortionsOfPensions,
    studentEarningsFromWorkNoTaxReturn,
    didStudentPartnerEarnMoney,
    studentPartnerEarningsFromWorkNoTaxReturn,
    studentCashBalance,
    parentCashBalance,
    studentHasInvestmentsBusinessOrRealEstate,
    studentNetWorthOfInvestments,
    studentHasBusiness,
    studentNetWorthOfBusinesses,
    studentHasPensionSavings,
    studentAmountSavedInPensions,
    studentDidPayOrReceiveChildSupport,
    studentChildSupportPaidAmount,
    studentChildSupportReceivedAmount,
    studentHasAnyBenefits,
    studentHasArmyOrVeteranBenefits,
    studentCombatPayAmount,
    studentAllowancesAmount,
    studentVeteranNoneducationBenefitsAmount,
    studentHasEducationBenefits,
    studentEarningsFromEmploymentPrograms,
    studentCollegeGrantAmount,
    studentEarningsFromCoopWork,
    studentEducationCreditsAmount,
    studentHasFoodIncomeOrHealthBenefits,
    studentTaxLine50,
    parentHasInvestmentsBusinessOrRealEstate,
    parentNetWorthOfInvestments,
    parentHasBusiness,
    parentNetWorthOfBusinesses,
    parentHasPensionSavings,
    parentAmountSavedInPensions,
    parentDidPayOrReceiveChildSupport,
    parentChildSupportPaidAmount,
    parentChildSupportReceivedAmount,
    parentHasAnyBenefits,
    parentHasArmyOrVeteranBenefits,
    parentCombatPayAmount,
    parentAllowancesAmount,
    parentVeteranNoneducationBenefitsAmount,
    parentHasEducationBenefits,
    parentEarningsFromEmploymentPrograms,
    parentCollegeGrantAmount,
    parentEarningsFromCoopWork,
    parentEducationCreditsAmount,
    parentHasFoodIncomeOrHealthBenefits,
    didReceiveMoneyNotReportedOnThisForm,
    howMuchMoneyNotReportedOnThisForm,
    didYouHaveUntaxedIncomeNotReported,
    doYouHaveAnyCash,
    untaxedIncomeNotReported,
    otherUntaxedIncomeNotReported,
    didParentCompleteTaxReturn,
    parentTaxSetup,
    parentTaxFiles,
    parentTaxFilingStatus,
    parentTaxReturnType,
    parentAdjustedGrossIncome,
    parentEarningsFromWork,
    otherParentEarningsFromWork,
    parentIncomeTax,
    parentExemptions,
    parentIraDeductions,
    parentTaxExemptInterestIncome,
    parentUntaxedPortionsOfIRA,
    parentUntaxedPortionsOfPensions,
    parentEarningsFromWorkNoTaxReturn,
    otherParentEarningsFromWorkNoTaxReturn,
    parentLivedInHomeAddressStateAtLeastFiveYears,
    parentLegalStateOfResidence,
    parentMovedToStateOfResidenceMonth,
    parentMovedToStateOfResidenceYear,
    studentSsn,
    parentsMaritalStatus,
    parentsMaritalStatusMonth,
    parentsMaritalStatusYear,
    parent1FirstName,
    parent1LastName,
    parent1Ssn,
    parent1DateOfBirth,
    parentPersonal1Ssn,
    parentPersonal1DateOfBirth,
    parentType,
    parent2FirstName,
    parent2LastName,
    parent2Ssn,
    parent2DateOfBirth,
    parentPersonal2Ssn,
    parentPersonal2DateOfBirth,
    howManyChildrenSupportedByParent,
    howManyOtherSupportedByParent,    
    parentNumOfPeopleGoingToCollege,
    parentFirstName,
    parentLastName,
    parentEmail,
    parentPhone,
    program,
    educationLevel,
    parentDislocatedWorker,
    didParentFileSchedule1,
    parentBenefitMedicaid,
    parentBenefitSSI,
    parentBenefitSNAP,
    parentBenefitLunch,
    parentBenefitTANF,
    parentBenefitWIC,
    parentTaxLine50,
  };
  // Convert each to boolean before use.
  Object.keys(whatShouldBeDisplayed).forEach((key) => {
    whatShouldBeDisplayed[key] = Boolean(whatShouldBeDisplayed[key]);
    // whatShouldBeDisplayed[key] = true;
  });
  return whatShouldBeDisplayed;
};
