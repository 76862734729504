import React, { PureComponent } from 'react';
import { string } from 'prop-types';

import { Tab } from '../../../../components/LocationTabs';
import SensitiveDataCard from '../SensitiveDataCard';

class SensitiveDataTab extends PureComponent {
  static propTypes = {
    label: string.isRequired,
    tabName: string.isRequired,
  };

  render() {
    const { label, tabName, ...props } = this.props;

    return (
      <Tab label={label} tabName={tabName} {...props}>
        <SensitiveDataCard />
      </Tab>
    );
  }
}

export default SensitiveDataTab;
