import React, { Fragment } from 'react';

import { taxSchedule1LinesValues } from '../../../Applications/enumChoices';

const { LINE_13, LINE_21 } = taxSchedule1LinesValues;

const createSchedule1TaxLinesQuestion = (selectedTaxLines, line13Question, line21Question) => {
  const line13Text = selectedTaxLines.includes(LINE_13) ? line13Question : null;
  const line21Text = selectedTaxLines.includes(LINE_21) ? line21Question : null;

  return (
    <Fragment>
      {line13Text}
      {line21Text}
    </Fragment>
  );
};

export {
  createSchedule1TaxLinesQuestion,
};
