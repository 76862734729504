export default {
  studentAdjustedGrossIncome:
    'Please convert from local currency to USD. Enter 0 if no value was reported.',
  studentEarningsFromWork:
    'Please convert from local currency to USD. Enter 0 if no value was reported.',
  studentIncomeTax:
    'Please convert from local currency to USD. Enter 0 if no value was reported.',
  studentExemptions:
    'Please convert from local currency to USD. Enter 0 if no value was reported.',
  studentIraDeductions:
    'Please convert from local currency to USD. Enter 0 if no value was reported.',
  studentTaxExemptInterestIncome:
    'Please convert from local currency to USD. Enter 0 if no value was reported.',
  studentUntaxedPortionsOfIRA:
    'Please convert from local currency to USD. Enter 0 if no value was reported.',
  studentUntaxedPortionsOfPensions:
    'Please convert from local currency to USD. Enter 0 if no value was reported.',
  studentPartnerEarningsFromWork: 
    'Please convert from local currency to USD. Enter 0 if no value was reported.',
  studentCashBalance:
    'All the money in your accounts today. Do not include financial aid.'
  };
