import React from 'react';
import { connect } from 'react-redux';
import { push as pushAction } from 'react-router-redux';
import { shape, func } from 'prop-types';

import { CircularProgress } from 'material-ui';
import { showNotification as showNotificationAction } from 'admin-on-rest';

import confirmApplication from '../api/confirmApplication';

class ConfirmApplicationPage extends React.Component {
  static propTypes = {
    showNotification: func.isRequired,
    match: shape().isRequired,
    push: shape().isRequired,
  };
  componentDidMount() {
    const {
      match: { params: { type: applicationType, id: applicationId } },
      showNotification,
      push,
    } = this.props;

    confirmApplication({ applicationType, applicationId })
      .then(({ message }) => {
        const elementName = applicationType === 'applications' ? 'Application' : 'Parent application';
        showNotification(`${elementName} marked as complete: ${message}`, 'confirm');
        push(`/${applicationType}`);
      })
      .catch(e => {
        showNotification(`Marking as complete failed: ${e.message}`, 'warning');
        push(`/${applicationType}/${applicationId}`);
      });
  }

  render() {
    return (
      <div style={{ textAlign: 'center' }} >
        <h2>Marking as complete...</h2>
        <CircularProgress size={50} />
      </div>
    );
  }
}

export default connect(null, {
  showNotification: showNotificationAction,
  push: pushAction,
})(ConfirmApplicationPage);
