import React from 'react';

import withAccess from '../../../../hocs/withAccess';
import { Tab } from '../../../../components/LocationTabs';
import { TaxForm, studentTaxContent } from '../../../../components/TaxForm';
import { STUDENT } from '../../../../Applications/ApplicationType';
import {PERMISSION_TAX} from "../../../../utils/permissions";

const StudentTaxesTab = (props) => (
  <Tab {...props}>
    <TaxForm
      applicationType={STUDENT}
      taxFormFor={STUDENT}
      contentCreator={studentTaxContent}
      {...props} />
  </Tab>
);

export default withAccess(PERMISSION_TAX)(StudentTaxesTab)
