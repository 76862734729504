const { concat, contains } = require('ramda');

export const choices = strings => strings.map(name => ({ id: name, name }));
const pair = (id, name) => ({ id, name });

export const UpdateableFrankStatus = choices([
  'DATA_COMPLETE',
  'DELETE',
  'MANUALLY_SUBMITTED',
  'HELD_BY_SUPPORT',
  'TESTING',
  'SUBMISSION_IN_PROGRESS',
]);

export const customerRoles = choices([
  'DEPENDENT',
  'INDEPENDENT',
]);

export const taxAutomationStatusForDisplaying = {
  QUEUED: 'Queued',
  VALIDATING: 'Validating',
  VALIDATION_SUCCEEDED: 'Validation Success',
  VALIDATION_FAILED: 'Validation Failed',
  PROCESSING: 'Processing',
  ERROR: 'Error',
  COMPLETED: 'Completed',
};

export const FrankStatus = concat(
  choices([
    'NEW',
    'MISSING_DATA',
    'ERROR',
    'SUBMITTED',
    'PRE_SUBMITTED',
    'SELF_SUBMITTED',
    'OUTDATED',
    'MANUAL_SUBMISSION_IN_PROGRESS',
  ]),
  UpdateableFrankStatus,
);

export const frankStatusForDisplaying = {
  NEW: 'New',
  MISSING_DATA: 'Missing Data',
  DATA_COMPLETE: 'Data complete',
  ERROR: 'Error',
  HELD_BY_SUPPORT: 'Held by support',
  DELETE: 'Delete',
  TESTING: 'Testing',
  SUBMITTED: 'Submitted',
  PRE_SUBMITTED: 'Pre submitted',
  SELF_SUBMITTED: 'Self submitted',
  OUTDATED: 'Outdated',
  MANUALLY_SUBMITTED: 'Manually submitted',
  SUBMISSION_IN_PROGRESS: 'Submission in progress',
  MANUAL_SUBMISSION_IN_PROGRESS: 'Manual submission in progress',
  PREFILED: 'Prefiled',
};

export const changingFrankStatuses = [
  { value: 'NEW', label: 'New' },
  { value: 'MISSING_DATA', label: 'Missing Data' },
  { value: 'DATA_COMPLETE', label: 'Data Complete' },
  { value: 'MANUAL_SUBMISSION_IN_PROGRESS', label: 'Manual Submission in Progress' },
  { value: 'SUBMISSION_IN_PROGRESS', label: 'Submission in Progress' },
  { value: 'PRE_SUBMITTED', label: 'Pre Submitted' },
  { value: 'MANUALLY_SUBMITTED', label: 'Manually Submitted' },
  { value: 'SUBMITTED', label: 'Submitted' },
  { value: 'SELF_SUBMITTED', label: 'Self submitted' },
  { value: 'ERROR', label: 'Error' },
  { value: 'HELD_BY_SUPPORT', label: 'Held by support' },
  { value: 'TESTING', label: 'Testing' },
  { value: 'DELETE', label: 'Delete' },
  { value: 'OUTDATED', label: 'Outdated' },
  { value: 'PREFILED', label: 'Prefiled' },
];

const defaultFrankStatuses = [
  'SELF_SUBMITTED',
  'HELD_BY_SUPPORT',
  'DELETE',
  'TESTING',
  'OUTDATED',
];

const extendWithDefaultStatuses = (statuses = []) => concat(statuses, defaultFrankStatuses);
const makeFrankStatusesOptions = (statuses) => (choices(extendWithDefaultStatuses(statuses)));

export const validationFrankStatuses = currentFrankStatus => {
  if (contains(currentFrankStatus, defaultFrankStatuses)) {
    return makeFrankStatusesOptions();
  }

  switch (currentFrankStatus) {
    case 'DATA_COMPLETE':
      return makeFrankStatusesOptions([currentFrankStatus, 'MANUAL_SUBMISSION_IN_PROGRESS', 'MISSING_DATA']);
    case 'MANUAL_SUBMISSION_IN_PROGRESS':
      return makeFrankStatusesOptions([currentFrankStatus, 'DATA_COMPLETE', 'MISSING_DATA', 'MANUALLY_SUBMITTED', 'PRE_SUBMITTED']);
    case 'SUBMISSION_IN_PROGRESS':
      return makeFrankStatusesOptions([currentFrankStatus, 'PRE_SUBMITTED']);
    case 'PRE_SUBMITTED':
      return makeFrankStatusesOptions([currentFrankStatus, 'SUBMITTED']);
    case 'ERROR':
      return makeFrankStatusesOptions([currentFrankStatus, 'DATA_COMPLETE', 'MISSING_DATA']);
    case 'MISSING_DATA':
      return makeFrankStatusesOptions([currentFrankStatus, 'DATA_COMPLETE', 'MANUAL_SUBMISSION_IN_PROGRESS']);
    default:
      return makeFrankStatusesOptions([currentFrankStatus, 'MISSING_DATA']);
  }
};

export const FafsaStatus = choices([
  'NONE',
  'PROCESSED_SUCCESSFULLY',
  'PENDING_FSA_ID',
  'ACTION_REQUIRED',
  'PROCESSING',
  'MULTIPLE_APPLICATIONS',
  'NOT_RECOGNIZED',
  'CORRECTION_IN_PROGRESS',
]);

export const fafsaStatusForDisplaying = {
  NONE: 'None',
  PROCESSED_SUCCESSFULLY: 'Processed successfully',
  PENDING_FSA_ID: 'Pending FSA ID',
  ACTION_REQUIRED: 'Action required',
  PROCESSING: 'Processing',
  MULTIPLE_APPLICATIONS: 'Multiple applications',
  NOT_RECOGNIZED: 'Not recognized',
  CORRECTION_IN_PROGRESS: 'Correction in progress',
};

export const TaxFilingStatus = [
  pair('SINGLE', 'Single'),
  pair('HEAD_OF_HOUSEHOLD', 'Head of Household'),
  pair('MARRIED_FILED_JOINT_RETURN', 'Married-Filed Joint Return'),
  pair('MARRIED_FILED_SEPARATE_RETURN', 'Married-Filed Separate Return'),
  pair('QUALIFYING_WIDOW', 'Qualifying Widow(er)'),
  pair('DONT_KNOW', "Don't Know"),
];

export const TaxReturnType = [
  pair('IRS_1040', 'IRS 1040'),
  pair('IRS_1040A_OR_1040EZ', 'IRS 1040A or IRS 1040EZ'),
  pair('FOREIGN_TAX_RETURN', 'Foreign Tax Return'),
  pair(
    'TAX_RETURN_WITH_PUERTO_RICO_OR_US_TERRITORY_OR_FREELY_ASSOCIATED_STATE',
    'Puerto Rico/US Territory/Freely Associated State Tax Return',
  ),
];

export const Dependency = [
  pair('DEPENDENT', 'Dependent'),
  pair('INDEPENDENT', 'Independent'),
];

const currentYear = new Date().getFullYear();
const years = [];
let year = 2018;
while (year < currentYear + 2) {
  years.push(`${year}/${year + 1}`);
  year += 1;
}

export const ApplicationYears = choices(years);

export const didFileSchedules = [
  pair(true, 'Yes'),
  pair(false, 'No'),
];

export const didFileSchedule1AlaskaPermanentFundDividend = [
  pair(true, 'Yes'),
  pair(false, 'No'),
];

export const didFileSchedule1GainsOrLossesFromVirtualCurrency = [
  pair(true, 'Yes'),
  pair(false, 'No'),
];

export const tax2018ScheduleValues = Object.freeze({
  TAX_SCHEDULE_1: 'Schedule 1',
  TAX_SCHEDULE_2: 'Schedule 2',
  TAX_SCHEDULE_3: 'Schedule 3',
  TAX_SCHEDULE_K1: 'Schedule K1',
});

export const tax2018ScheduleOptions = [
  pair(tax2018ScheduleValues.TAX_SCHEDULE_1, 'Schedule 1'),
  pair(tax2018ScheduleValues.TAX_SCHEDULE_2, 'Schedule 2'),
  pair(tax2018ScheduleValues.TAX_SCHEDULE_3, 'Schedule 3'),
  pair(tax2018ScheduleValues.TAX_SCHEDULE_K1, 'Schedule K1'),
];

export const taxSchedule1LinesValues = Object.freeze({
  LINE_10: 'Line 10',
  LINE_11: 'Line 11',
  LINE_13: 'Line 13',
  LINE_14: 'Line 14',
  LINE_17: 'Line 17',
  LINE_21: 'Line 21',
  LINE_24: 'Line 24',
  LINE_25: 'Line 25',
  LINE_26: 'Line 26',
  LINE_27: 'Line 27',
  LINE_29: 'Line 29',
  LINE_30: 'Line 30',
  LINE_31A: 'Line 31a'
});

export const taxSchedule1LinesValues2019 = Object.freeze({
  LINE_1: 'Line 1',
  LINE_2A: 'Line 2a',
  LINE_3: 'Line 3',
  LINE_4: 'Line 4',
  LINE_5: 'Line 5',
  LINE_6: 'Line 6',
  LINE_8: 'Line 8',
  LINE_11: 'Line 11',
  LINE_12: 'Line 12',
  LINE_13: 'Line 13',
  LINE_14: 'Line 14',
  LINE_15: 'Line 15',
  LINE_16: 'Line 16',
  LINE_17: 'Line 17',
  LINE_18A: 'Line 18A',
  LINE_21: 'Line 21',
});

export const tax2018Schedule1LinesOptions = [
  pair(taxSchedule1LinesValues.LINE_10, 'Line 10'),
  pair(taxSchedule1LinesValues.LINE_11, 'Line 11'),
  pair(taxSchedule1LinesValues.LINE_13, 'Line 13'),
  pair(taxSchedule1LinesValues.LINE_14, 'Line 14'),
  pair(taxSchedule1LinesValues.LINE_17, 'Line 17'),
  pair(taxSchedule1LinesValues.LINE_21, 'Line 21'),
  pair(taxSchedule1LinesValues.LINE_24, 'Line 24'),
  pair(taxSchedule1LinesValues.LINE_25, 'Line 25'),
  pair(taxSchedule1LinesValues.LINE_26, 'Line 26'),
  pair(taxSchedule1LinesValues.LINE_27, 'Line 27'),
  pair(taxSchedule1LinesValues.LINE_29, 'Line 29'),
  pair(taxSchedule1LinesValues.LINE_30, 'Line 30'),
  pair(taxSchedule1LinesValues.LINE_31A, 'Line 31a'),
];

export const tax2019Schedule1LinesOptions = [
  pair(taxSchedule1LinesValues2019.LINE_1, 'Line 1'),
  pair(taxSchedule1LinesValues2019.LINE_2A, 'Line 2a'),
  pair(taxSchedule1LinesValues2019.LINE_3, 'Line 3'),
  pair(taxSchedule1LinesValues2019.LINE_4, 'Line 4'),
  pair(taxSchedule1LinesValues2019.LINE_5, 'Line 5'),
  pair(taxSchedule1LinesValues2019.LINE_6, 'Line 6'),
  pair(taxSchedule1LinesValues2019.LINE_8, 'Line 8'),
  pair(taxSchedule1LinesValues2019.LINE_11, 'Line 11'),
  pair(taxSchedule1LinesValues2019.LINE_12, 'Line 12'),
  pair(taxSchedule1LinesValues2019.LINE_13, 'Line 13'),
  pair(taxSchedule1LinesValues2019.LINE_14, 'Line 14'),
  pair(taxSchedule1LinesValues2019.LINE_15, 'Line 15'),
  pair(taxSchedule1LinesValues2019.LINE_16, 'Line 16'),
  pair(taxSchedule1LinesValues2019.LINE_17, 'Line 17'),
  pair(taxSchedule1LinesValues2019.LINE_18A, 'Line 18a'),
  pair(taxSchedule1LinesValues2019.LINE_21, 'Line 21'),
];
