import request from './request';

const createApplicationCommentsUrl = applicationId => (
  `/comments/applications/${applicationId}`
);

const updateApplicationCommentsUrl = applicationId => (
  `/comments/applications/${applicationId}/pinned`
);

export const getApplicationComments = (applicationId, applicationType) => (
  request(`${createApplicationCommentsUrl(applicationId)}?applicationType=${applicationType}`)
);

export const createApplicationComment = (applicationId, data) => (
  request({
    url: createApplicationCommentsUrl(applicationId),
    method: 'POST',
    data,
  })
);

export const togglePinApplicationComment = (applicationId, data) => (
  request({
    url: updateApplicationCommentsUrl(applicationId),
    method: 'PUT',
    data,
  })
);
