import React from 'react';
import { TextInput } from 'admin-on-rest';

const Search = props => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <TextInput {...props} elStyle={{ width: '100%' }} />
  </div>
);

export default Search;
