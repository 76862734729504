import React, { PureComponent } from 'react';
import { func, string } from 'prop-types';
import { FlatButton } from 'material-ui';

import Textarea from '../../form/Textarea';

import './styles.scss';

export default class CommentInput extends PureComponent {
  static propTypes = {
    submit: func.isRequired,
    placeholder: string,
    buttonText: string,
  };

  static defaultProps = {
    placeholder: 'Type to comment...',
    buttonText: 'Comment',
  };

  state = { value: '' };

  onChange = e => {
    const { value } = e.target;

    this.setState({ value });
  };

  onSubmit = async e => {
    try {
      e.preventDefault();

      const { submit } = this.props;
      const { value } = this.state;

      await submit(value);

      this.reset();
    } catch (error) {
      console.error(error);
    }
  };

  reset = () => {
    this.setState({ value: '' });
  };

  render() {
    const { buttonText, placeholder } = this.props;
    const { value } = this.state;

    return (
      <div className="comments-input">
        <Textarea
          className="comments-input__textarea"
          placeholder={placeholder}
          value={value}
          onChange={this.onChange}
        />
        <FlatButton
          className="comments-input__button"
          disabled={!value}
          label={buttonText}
          onClick={this.onSubmit}
        />
      </div>
    );
  }
}
