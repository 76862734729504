const localStoragePropName = 'lastVisitedListLink';

const get = () => {
  try {
    return JSON.parse(localStorage.getItem(localStoragePropName));
  } catch (error) {
    return {};
  }
};

const set = (resource, link) => {
  localStorage.setItem(localStoragePropName, JSON.stringify({ [resource]: link }));
};

export default {
  get,
  set,
};

