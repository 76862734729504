const commonLineInputNames = [
  'parentTaxLine1',
  'parentTaxLine2a',
  'parentTaxLine4a',
  'parentTaxLine4b',
  'parentTaxLine7',
  'parentTaxLine13',
];

const schedule1MainLineInputNames = [
  'parentTaxLine12',
  'parentTaxLine18',
  'parentTaxLine28',
];

const schedule1LineInputNames = [
  ...schedule1MainLineInputNames,
  'parentTaxLine32',
];

const schedule2LineInputNames = [
  'parentTaxLine46',
];

const schedule3LineInputNames = [
  'parentTaxLine50',
];

const scheduleK1LineInputNames = [
  'parentTaxBox14CodeA',
];

const taxFilingStatusFieldName = 'parentTaxFilingStatus';
const taxReturnTypeFieldName = 'parentTaxReturnType';
const didFileSchedulesFieldName = 'didParentFileSchedules';
const cashBalanceFieldName = 'parentCashBalance';
const taxScheduleFieldName = 'parentTaxSchedule';

const taxSchedule1ChecksFieldName = 'parentTaxSchedule1Checks';
const taxSchedule1Line13FieldName = 'parentTaxSchedule1Line13';
const taxSchedule1Line21FieldName = 'parentTaxSchedule1Line21';
const didFileSchedule1AlaskaPermanentFundDividendFieldName = 'didParentFileSchedule1AlaskaPermanentFundDividend';
const schedule1AdditionsLineInputNames = [
  'parentTaxSchedule1Line13',
  'parentTaxSchedule1Line21',
];

export default {
  taxScheduleFieldName,
  taxFilingStatusFieldName,
  taxReturnTypeFieldName,
  didFileSchedulesFieldName,
  cashBalanceFieldName,
  commonLineInputNames,
  schedule1MainLineInputNames,
  schedule1LineInputNames,
  schedule2LineInputNames,
  schedule3LineInputNames,
  scheduleK1LineInputNames,
  taxSchedule1ChecksFieldName,
  taxSchedule1Line13FieldName,
  taxSchedule1Line21FieldName,
  schedule1AdditionsLineInputNames,
  didFileSchedule1AlaskaPermanentFundDividendFieldName,
};
