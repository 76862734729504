import React, { PureComponent } from 'react';

import { getUsers } from '../../api/users';
import IWithUser from './propTypes';

export { IWithUser };

export default (params) => (Component) => class WithUsers extends PureComponent {
  state = {
    fetched: false,
    usersList: [],
  };

  async componentDidMount() {
    try {
      const response = await getUsers(params);
      const data = {
        usersList: response.data,
      };
      this.setStateData(data);
    } catch (e) {
      console.error(e);
    } finally {
      this.setStateData({fetched: true});
    }
  }

  setStateData(data) {
    this.setState(data);
  }

  render() {
    const { fetched, usersList } = this.state;

    return fetched ? <Component {...this.props} usersList={usersList} /> : null;
  }
};
