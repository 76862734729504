import React, { PureComponent } from 'react';
import { number, arrayOf, shape } from 'prop-types';
import { withRouter } from 'react-router-dom';

import ListNavigation from '../components/ListNavigation';

class DashboardNavigation extends PureComponent {
  static propTypes = {
    items: arrayOf(shape()),
    counters: arrayOf(number),
  };

  static defaultProps = {
    items: [],
    counters: [],
  };

  render() {
    const { items, counters } = this.props;
    const itemsWithCounts = items.map(({ label, ...item }, i) => ({
      ...item,
      label,
      counter: counters[i],
      isDisabled: counters[i] === 0,
    }));

    return (
      <ListNavigation items={itemsWithCounts} />
    );
  }
}

export default withRouter(DashboardNavigation);
