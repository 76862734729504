import { createFilterQuery } from '../../../services/filters';
import { DEFAULT_PER_PAGE_VAL, FRANK_STATUSES, NULL_VALUE } from '../../../constants';

import { getEmail } from '../../../api/localAuthStorage';
import { makeUrlCreator, escapeNull } from '../../../services/url';
import { extendsUrlSettingsWithSelectedTabName } from '../../../utils/utils';

const TO_SUBMIT = 'TO_SUBMIT';
const TO_TRANSFER_TAXES = 'TO_TRANSFER_TAXES';
const TO_ERROR_CHECK = 'TO_ERROR_CHECK';
const IN_PROGRESS = 'IN_PROGRESS';

const email = getEmail() || NULL_VALUE;

const filterPresets = {
  [TO_SUBMIT]: {
    frankStatus: createFilterQuery({
      operator: 'or',
      group: 'and',
      value: [FRANK_STATUSES.DATA_COMPLETE],
    }),
    assignedTo: createFilterQuery({
      operator: 'or',
      group: 'and',
      value: email,
    }),
  },
  [TO_TRANSFER_TAXES]: {
    isPendingIRS: true,
    isPendingIRSForParent: true,
    assignedTo: createFilterQuery({
      operator: 'or',
      group: 'and',
      value: email,
    }),
  },
  [TO_ERROR_CHECK]: {
    frankStatus: createFilterQuery({
      operator: 'or',
      group: 'and',
      value: [FRANK_STATUSES.ERROR],
    }),
    assignedTo: createFilterQuery({
      operator: 'or',
      group: 'and',
      value: email,
    }),
  },
  [IN_PROGRESS]: {
    frankStatus: createFilterQuery({
      operator: 'or',
      group: 'and',
      value: [FRANK_STATUSES.MANUAL_SUBMISSION_IN_PROGRESS]
    }),
    assignedTo: createFilterQuery({
      operator: 'or',
      group: 'and',
      value: email,
    }),
  },
};

const urlCreator = makeUrlCreator({
  url: 'applications',
});

const linksData = (perPage = DEFAULT_PER_PAGE_VAL) => extendsUrlSettingsWithSelectedTabName([
  {
    label: 'To submit',
    name: TO_SUBMIT,
    url: urlCreator({
      queryData: {
        filter: JSON.stringify(filterPresets[TO_SUBMIT]),
        perPage,
      }
    })
  },
  {
    label: 'To transfer taxes',
    name: TO_TRANSFER_TAXES,
    url: urlCreator({
      queryData: {
        filter: JSON.stringify(escapeNull(filterPresets[TO_TRANSFER_TAXES])),
        perPage,
      }
    })
  },
  {
    label: 'To error check',
    name: TO_ERROR_CHECK,
    url: urlCreator({
      queryData: {
        filter: JSON.stringify(filterPresets[TO_ERROR_CHECK]),
        perPage,
      }
    }),
  },
  {
    label: 'In progress',
    name: IN_PROGRESS,
    url: urlCreator({
      queryData: {
        filter: JSON.stringify(filterPresets[IN_PROGRESS]),
        perPage: 10,
      }
    }),
  },
]);

const indexLink = (perPage) => linksData(perPage)[0].url;

export {
  filterPresets,
  linksData,
  indexLink,
}
