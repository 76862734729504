import {
  TextField as TextField_,
  NumberField as NumberField_,
  NumberInput as NumberInput_,
  DateField as DateField_,
} from 'admin-on-rest';
import { compose } from 'ramda';
import YesNoBooleanField_ from './YesNoBooleanField';
import dynamicLabelField from '../dynamicLabelField';
import withLabelAndHelperText from '../withLabelAndHelperText';
import withDefault from '../withDefault';

export {
  default as YesOrNothingBooleanField,
} from './YesOrNothingBooleanField';
export { default as ConditionField } from './ConditionField';
export { default as ActionButtonField } from './ActionButtonField';
export { default as PartialDateField } from './PartialDateField';

const enhanceField = compose(dynamicLabelField, withDefault);

export const TextField = enhanceField(TextField_);
export const YesNoBooleanField = enhanceField(YesNoBooleanField_);
export const NumberField = enhanceField(NumberField_);
export const NumberInput = withLabelAndHelperText(NumberInput_);
export const DateField = withDefault(DateField_);
