const toStartOfDay = (dateObj) => {
  if (!dateObj) {
    return dateObj;
  }

  const year = dateObj.getFullYear();
  const month = dateObj.getMonth();
  const date = dateObj.getDate();

  return new Date(year, month, date);
};

const toEndOfDay = (dateObj) => {
  if (!dateObj) {
    return dateObj;
  }

  const year = dateObj.getFullYear();
  const month = dateObj.getMonth();
  const date = dateObj.getDate();
  const hours = 24;
  const nextDay = new Date(year, month, date, hours);

  return new Date(nextDay - 1);
};

export {
  toStartOfDay,
  toEndOfDay,
};
