import away from 'away';
import { getToken, clearLocalStorageAuthData } from './api/localAuthStorage';

const IDLE_TIMEOUT = 30 * 60 * 1000;

const setupIdleTimer = () => {
  const timer = away(IDLE_TIMEOUT);
  timer.on('idle', () => {
    if (getToken()) {
      clearLocalStorageAuthData();
      window.location.href = '/#/login';
    }
  });
};

export default setupIdleTimer;
