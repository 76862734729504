import React, { PureComponent } from 'react';
import { func } from 'prop-types';

import { ICommentsList } from '../propTypes';

import './styles.scss';

export default class CommentsList extends PureComponent {
  static propTypes = {
    commentRenderer: func.isRequired,
    items: ICommentsList,
  };

  static defaultProps = {
    items: [],
  };

  render() {
    const { items, commentRenderer } = this.props;
    const list = items.map(commentRenderer);

    return (
      <ul className="comments-list">
        {list}
      </ul>
    );
  }
}
