import React from 'react';
import { shape } from 'prop-types';

import TextField from 'material-ui/TextField';

const renderInput = ({
                       meta: { touched, error } = {},
                       input: { ...inputProps },
                       ...props
                     }) => (
  <TextField
    errorText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

renderInput.propTypes = {
  meta: shape(),
  input: shape(),
};

renderInput.defaultProps = {
  meta: {},
  input: {},
};

export default renderInput;
