const STAGING = 'bo.staging.withfrank.org';
const DEVELOP = 'bo.develop.withfrank.org';

const STAGING_SETTINGS = {
  title: 'Staging',
  borderColor: '#00b291',
  backgroundColor: '#00b291',
};

const DEVELOP_SETTINGS = {
  title: 'Develop',
  borderColor: '#f2b4c3',
  backgroundColor: '#f2b4c3',
};

const DEFAULT_SETTINGS = {
  title: '',
  borderColor: '#f8db1c',
  backgroundColor: '#fff',
};

export const getColorHamburgerIcon = () => {
  const currentHost = window.location.hostname;

  return currentHost === STAGING || currentHost === DEVELOP
    ? '#000A46'
    : '#2755C4';
};

export default () => {
  const currentHost = window.location.hostname;

  switch (currentHost) {
    case STAGING:
      return STAGING_SETTINGS;
    case DEVELOP:
      return DEVELOP_SETTINGS;
    default:
      return DEFAULT_SETTINGS;
  }
};
