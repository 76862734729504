export const ACTIONS = {
  ASSIGN_TO: 'assigned to',
  UNASSIGNED: 'unassigned',
  STATUS_CHANGED: 'status changed to',
  DEFAULT: 'changed to',
};

export const TYPE_MESSAGE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const getMessage = (
  countApplications,
  changedValue,
  typeMessage,
  action = ACTIONS.DEFAULT,
) => {
  if (typeMessage === TYPE_MESSAGE.ERROR) {
    return 'Update Failed!!';
  }

  const message = `${countApplications} application${
    countApplications > 1 ? 's' : ''
  } `;

  return changedValue
    ? message.concat(`${action} "${changedValue}"`)
    : message.concat(ACTIONS.UNASSIGNED);
};
