import React, { PureComponent } from 'react';
import { Show } from 'admin-on-rest';

import ApplicationTitle from '../ApplicationTitle';
import ShowActions from '../../ShowActions';
import { TAB_NAMES } from './constants';

import RootTabs from './RootTabs';

export { TAB_NAMES };

export default class StudentApplicationShow extends PureComponent {
  render() {
    return (
      <Show
        title={<ApplicationTitle />}
        actions={<ShowActions />}
        {...this.props}
      >
        <RootTabs {...this.props} />
      </Show>
    );
  }
}
