import React from 'react';
import { shape } from 'prop-types';
import { Edit, SimpleForm } from 'admin-on-rest';

import ParentApplicationTitle from '../ParentApplicationTitle';
import numberScrollProtector from '../../../components/numberScrollProtector';
import { PARENT } from '../../ApplicationType';
import ResourcePropertyEditToolbar from '../../ResourcePropertyEditToolbar';
import EditActions from '../../EditActions';
import EditForm from './EditForm';

const ApplicationEdit = (props) => (
  <Edit title={<ParentApplicationTitle />} actions={<EditActions />} {...props}>
    {permissions =>
      permissions.includes('data_entry') && (
        <SimpleForm
          toolbar={
            <ResourcePropertyEditToolbar
              applicationType={PARENT}
              id={props.match.params.id}
              {...props}
            />
          }>
          <EditForm />
        </SimpleForm>
      )
    }
  </Edit>
);

ApplicationEdit.propTypes = {
  match: shape().isRequired,
};

export default numberScrollProtector(ApplicationEdit);
