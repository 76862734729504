import { createSelector } from 'reselect';

const getUsersList = usersList => usersList;

export default createSelector(
  getUsersList,
  usersList => {
    const processedList = usersList.map(user => ({ label: user.email, value: user.email }));
    processedList.push({ label: 'Unassigned', value: null });

    return processedList;
  }
);