import React from 'react';
import { string } from 'prop-types';

import './styles.scss';

import GridList,
{
  IColumns,
  IData,
} from '../GridList';

const DescriptionList = props => (
  <GridList {...props} />
);

GridList.propTypes = {
  title: string,
  className: string,
  columns: IColumns,
  data: IData,
};

GridList.defaultProps = {
  title: null,
  columns: [
    {
      field: 'label',
      styles: { fontWeight: 'bold' },
    },
    {
      field: 'value',
      renderer: (value) => (
        <div className="description-list__value">
          {value || <span>&mdash;</span>}
        </div>
      ),
    },
  ],
  data: [],
  className: 'description-list',
};

export default DescriptionList;
