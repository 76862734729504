import React from 'react';
import { shape } from 'prop-types';
import { withRouter } from 'react-router-dom';

import { getIsMatchLocation } from '../utils/menuHelpers';

const withMatchLocation = Component => {
  const WithMatchLocation = ({ resource, location, ...props }) => {
    const isMatchLocation = getIsMatchLocation(resource, location.pathname);

    return (
      <Component
        isMatchLocation={isMatchLocation}
        resource={resource}
        {...props}
      />
    );
  };

  WithMatchLocation.propTypes = {
    location: shape(),
    resource: shape().isRequired,
  };

  WithMatchLocation.defaultProps = {
    location: {},
  };

  return withRouter(WithMatchLocation);
};

export default withMatchLocation;
