import React, { PureComponent } from 'react';
import { string } from 'prop-types';
import { intersection } from 'ramda';

import withPermissions from './withPermissions';

export default (accessPermissions = []) => Component => {
  class WithAccess extends PureComponent {
    static propTypes = {
      permissions: string.isRequired,
    };

    render() {
      const { permissions } = this.props;
      const intersectedRoles = (
        intersection(permissions.split(','), [].concat(accessPermissions))
      );

      const hasAccess = Boolean(intersectedRoles.length);

      return hasAccess ? <Component {...this.props} /> : null;
    }
  }

  return withPermissions(WithAccess);
};
