import parentFieldNames from '../tax-2018/parent-field-names';

const commonLineInputNames = [
  'parentTaxLine1',
  'parentTaxLine2a',
  'parentTaxLine4a',
  'parentTaxLine4b',
  'parentTaxLine4c',
  'parentTaxLine4d',
  'parentTaxLine7',
  'parentTaxLine13',
];

const taxSchedule1Line8FieldName = 'parentTaxSchedule1Line21';

const didFileSchedule1GainsOrLossesFromVirtualCurrencyFieldName = 'didParentFileSchedule1GainsOrLossesFromVirtualCurrency';

const schedule1AdditionsLineInputNames = [
  'parentTaxSchedule1Line21'
];

export default {
  ...parentFieldNames,
  commonLineInputNames,
  taxSchedule1Line8FieldName,
  didFileSchedule1GainsOrLossesFromVirtualCurrencyFieldName,
  schedule1AdditionsLineInputNames,
};
