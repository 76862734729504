import React from 'react';
import { string, number } from 'prop-types';
import { Avatar as MuiAvatar } from 'material-ui';

import AvatarPlaceholder from './AvatarPlaceholder';
import getInternalUserAvatar from './internalUsersAvatarHelper';
import './styles.scss';

const Avatar = ({ userName, size }) => {
  if (!userName) {
    return <AvatarPlaceholder />;
  }

  const avatar = getInternalUserAvatar(userName);
  const displayedLetter = userName.slice(0, 2);

  return avatar ? (
    <MuiAvatar src={avatar} className="user-avatar-internal" size={size} />
  ) : (
    <MuiAvatar size={size} className="user-avatar">
      {displayedLetter}
    </MuiAvatar>
  );
};

Avatar.propTypes = {
  userName: string,
  size: number,
};

Avatar.defaultProps = {
  userName: '',
  size: 40,
};

export default Avatar;
