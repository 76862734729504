import React, { PureComponent } from 'react';

const withCopy = Component => {
  class WithCopy extends PureComponent {
    state = {
      selectedRow: null,
    };

    componentDidMount() {
      document.addEventListener('copy', this.onCopy);
    }

    componentWillUnmount() {
      document.removeEventListener('copy', this.onCopy);
    }

    onCopy = () => {
      this.setState({ selectedRow: null });
    };

    setSelectedRow = selectedRow => {
      this.setState({ selectedRow });
    };

    render() {
      return (
        <Component
          setSelectedRow={this.setSelectedRow}
          selectedRow={this.state.selectedRow}
          {...this.props}
        />
      );
    }
  }

  return WithCopy;
};

export default withCopy;
