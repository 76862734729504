const TAB_NAMES = {
  APPLICATION_STATUS: 'application-status',
  BACKGROUND_INFO: 'background-info',
  DEPENDENCY_STATUS: 'dependency-status',
  FAMILY_INFO: 'family-info',
  FINANCIAL_INFO: 'financial-info',
  SENSITIVE_DATA: 'sensitive-data',
  COMMENTS: 'comments',
  HISTORY: 'history',
  SUMMARY: 'summary',
  DETAILS: 'details',
  SENSITIVE_INFO: 'sensitive-info',
  STUDENT_TAXES: 'student-taxes',
  PARENT_TAXES: 'parent-taxes',
  FULL_APPLICATION: 'app-summary',
  STUDENT_SUMMARY: 'student-summary',
  PARENT_SUMMARY: 'parent-summary',
};

export {
  TAB_NAMES,
};
