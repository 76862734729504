import React, { createElement } from 'react';
import { oneOfType, func, shape, string, node, bool, number } from 'prop-types';
import { connect } from 'react-redux';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import autoprefixer from 'material-ui/utils/autoprefixer';
import CircularProgress from 'material-ui/CircularProgress';
import withWidth from 'material-ui/utils/withWidth';
import compose from 'recompose/compose';

import {
  Sidebar,
  Menu,
  Notification,
  defaultTheme,
  setSidebarVisibility as setSidebarVisibilityAction,
} from 'admin-on-rest';

import AppBar from './components/AppBar';
import StoreSynchronizer from './components/StoreSynchronizer';

const styles = {
  wrapper: {
    // Avoid IE bug with Flexbox, see #467
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  body: {
    backgroundColor: '#edecec',
    display: 'flex',
    flex: '1 1 auto',
    overflowY: 'hidden',
    overflowX: 'scroll',
  },
  bodySmall: {
    backgroundColor: '#fff',
  },
  content: {
    flex: 1,
  },
  contentSmall: {
    flex: 1,
    paddingTop: '3em',
  },
  loader: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: 16,
    zIndex: 1200,
  },
};

const prefixedStyles = {};

const Layout = ({
      children,
      isLoading,
      menu,
      theme,
      title,
      width,
    }) => {

    const muiTheme = getMuiTheme(theme);
    if (!prefixedStyles.main) {
      // do this once because user agent never changes
      const prefix = autoprefixer(muiTheme);
      prefixedStyles.wrapper = prefix(styles.wrapper);
      prefixedStyles.main = prefix(styles.main);
      prefixedStyles.body = prefix(styles.body);
      prefixedStyles.bodySmall = prefix(styles.bodySmall);
      prefixedStyles.content = prefix(styles.content);
      prefixedStyles.contentSmall = prefix(styles.contentSmall);
    }
    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <div style={prefixedStyles.wrapper}>
          <div style={prefixedStyles.main}>
            {width !== 1 && <AppBar title={title} />}
            <div
              className="body"
              style={
                width === 1 ? prefixedStyles.bodySmall : prefixedStyles.body
              }
            >
              <div
                style={
                  width === 1
                    ? prefixedStyles.contentSmall
                    : prefixedStyles.content
                }
              >
                {children}
              </div>
              <Sidebar>
                {createElement(menu || Menu)}
              </Sidebar>
            </div>
            <Notification />
            {isLoading && (
              <CircularProgress
                className="app-loader"
                color="#fff"
                size={width === 1 ? 20 : 30}
                thickness={2}
                style={styles.loader}
              />
            )}
            <StoreSynchronizer />
          </div>
        </div>
      </MuiThemeProvider>
    );
  };

  Layout.propTypes = {
    children: oneOfType([func, node]).isRequired,
    menu: oneOfType([func, string]).isRequired,
    isLoading: bool.isRequired,
    title: node.isRequired,
    theme: shape(),
    width: number,
  };

  Layout.defaultProps = {
    theme: defaultTheme,
    width: 0,
  };

function mapStateToProps(state) {
  return {
    isLoading: state.admin.loading > 0,
  };
}

const enhance = compose(
  connect(mapStateToProps, {
    setSidebarVisibility: setSidebarVisibilityAction,
  }),
  withWidth(),
);

export default enhance(Layout);
