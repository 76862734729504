import React, { PureComponent } from 'react';
import { arrayOf, shape, func } from 'prop-types';
import MenuItem from 'material-ui/MenuItem';

import withMenuActions from '../../../../../../hocs/withMenuActions';
import { ACTIONS } from '../../../../../../hocs/withMenuActions/ActionsMessages';

class UsersListMenu extends PureComponent {
  static propTypes = {
    list: arrayOf(shape()).isRequired,
    handleUpdate: func,
    menuStyles: shape(),
    itemStyles: shape(),
  };

  static defaultProps = {
    handleUpdate: () => {},
    menuStyles: {
      overflowY: 'scroll',
      height: 250,
    },
    itemStyles: {
      color: '#1b3a89',
    },
  };

  onUpdate = user => {
    const { handleUpdate } = this.props;
    const field = {
      assignedTo: user,
    };

    handleUpdate(field, user, ACTIONS.ASSIGN_TO);
  };

  render() {
    const { list, menuStyles, itemStyles } = this.props;

    const menuItems = list.map(item => (
      <MenuItem
        style={itemStyles}
        key={item.value}
        primaryText={item.label}
        onClick={() => this.onUpdate(item.value)}
      />
    ));

    return <div style={menuStyles}>{menuItems}</div>;
  }
}

export default withMenuActions(UsersListMenu);
