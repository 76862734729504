import React, { PureComponent } from 'react';
import { string, bool, arrayOf, shape } from 'prop-types';
import { NavLink } from 'react-router-dom';
import getSettingsOfHost from '../../utils/settingsOfHost';

import './styles.scss';

const settingsOfHost = getSettingsOfHost();
const styles = {
  active: {
    borderBottom: '2px solid',
    borderColor: settingsOfHost.borderColor,
  },
};

const IItem = {
  url: string,
  label: string,
  selected: bool,
};

export default class Navigation extends PureComponent {
  static propTypes = {
    items: arrayOf(shape(IItem)),
  };

  static defaultProps = {
    items: [],
  };

  render() {
    const { items } = this.props;
    const list = items.map(({ url, label, counter, selected, isDisabled }) => (
      <NavLink
        key={url}
        to={url}
        className={`navigation__item ${isDisabled ? 'disabled' : ''}`}
        style={selected ? styles.active : {}}
      >
        {`${label} ${counter || ''}`}
      </NavLink>
    ));

    return <div className="navigation">{list}</div>;
  }
}
