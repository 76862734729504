// based on https://github.com/marmelab/admin-on-rest/blob/master/src/mui/auth/Login.js
import React, { Component } from 'react';
import { shape } from 'prop-types';
import { propTypes, reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
  showNotification as showNotificationAction,
  defaultTheme,
  translate,
  Notification,
} from 'admin-on-rest';
import { push as pushAction } from 'react-router-redux';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { Card, CardActions } from 'material-ui/Card';
import Avatar from 'material-ui/Avatar';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';
import LockIcon from 'material-ui/svg-icons/action/lock-outline';
import { cyan500, pinkA200 } from 'material-ui/styles/colors';

import sendPasswordResetEmail from '../api/sendPasswordResetEmail';
import renderInput from './renderInput';

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    minWidth: 300,
  },
  avatar: {
    margin: '1em',
    textAlign: 'center ',
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    display: 'flex',
  },
};

function getColorsFromTheme(theme) {
  if (!theme) return { primary1Color: cyan500, accent1Color: pinkA200 };
  const { palette: { primary1Color, accent1Color } } = theme;
  return { primary1Color, accent1Color };
}

class ChangePassword extends Component {
  static propTypes = {
    ...propTypes,
    theme: shape,
  };

  static defaultProps = {
    theme: defaultTheme,
  };

  submit = e => {
    e.preventDefault();
    const { push, showNotification } = this.props;
    const email = this.email.value;
    sendPasswordResetEmail(email, push, showNotification);
  };

  render() {
    const { isLoading, theme } = this.props;
    const muiTheme = getMuiTheme(theme);
    const { primary1Color, accent1Color } = getColorsFromTheme(muiTheme);
    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <div style={{ ...styles.main, backgroundColor: primary1Color }}>
          <Card style={styles.card}>
            <div style={styles.avatar}>
              <Avatar
                backgroundColor={accent1Color}
                icon={<LockIcon />}
                size={60}
              />
            </div>
            <form onSubmit={this.submit}>
              <div style={styles.form}>
                <div style={styles.input}>
                  <Field
                    name="email"
                    component={renderInput}
                    floatingLabelText="Email"
                    disabled={isLoading}
                    ref={input => {
                      this.email = input;
                    }}
                  />
                </div>
              </div>
              <CardActions>
                <RaisedButton
                  type="submit"
                  primary
                  disabled={isLoading}
                  icon={
                    isLoading && <CircularProgress size={25} thickness={2} />
                  }
                  label="Send Password Reset Email"
                  fullWidth
                />
              </CardActions>
            </form>
          </Card>
          <Notification />
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });

const enhance = compose(
  translate,
  reduxForm({
    form: 'changePassword',
    validate: (values, props) => {
      const errors = {};
      if (!values.password)
        errors.password = props.translate('aor.validation.required');
      return errors;
    },
  }),
  connect(mapStateToProps, {
    showNotification: showNotificationAction,
    push: pushAction,
  }),
);

export default enhance(ChangePassword);
