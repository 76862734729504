import moment from 'moment';
import {
  DATE_FORMAT_4,
  DATE_FORMAT_8,
  DATE_FORMAT_10,
} from '../constants/date-formats';

const { DateTime } = require("luxon");

const phoneFormatter = value =>
  String(value).replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, '$1-$2-$3');

const date = (dataValue, format = DATE_FORMAT_4, fromFormat) =>
  fromFormat
    ? moment(dataValue, fromFormat).format(format)
    : moment(new Date(dataValue)).format(format);

const getDefaultOptions = format => ({
  sameDay: '[Today]',
  lastDay: '[Yesterday]',
  lastWeek: format,
  sameElse(now) {
    return this.year() === now.year() ? format : DATE_FORMAT_10;
  },
});

const calendar = ({
  dataValue,
  format = DATE_FORMAT_8,
  fromFormat,
  options,
}) => {
  const timestamp = new Date(dataValue);
  const processedOptions = options || getDefaultOptions(format);

  return moment(timestamp, fromFormat).calendar(null, processedOptions);
};

const formatYearData = year => {
  if (!year) {
    return '—';
  }

  const [startYear, endYear] = year.split('/');

  return `${startYear}-${endYear.slice(-2)}`;
};

const ssnFormatter = value =>
  String(value).replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');

const timeToUTC = time => {
  // Converts hh:mm local time to hh:mm UTC time
  const hour = time.split(':')[0];
  const mins = time.split(':')[1];
  // eslint-disable-next-line radix
  const utcDateTime =  DateTime.now().toLocal().set({ hour: parseInt(hour) }).set({ minutes: parseInt(mins) });
  return utcDateTime.toUTC().toFormat("HH:mm");
}

const utcToLocal = time => {
  // Converts hh:mm utc time to hh:mm local time
  const hour = time.split(':')[0];
  const mins = time.split(':')[1];
  // eslint-disable-next-line radix
  const utcDateTime = DateTime.now().toUTC().set({ hour: parseInt(hour) }).set({ minutes: parseInt(mins) });
  return utcDateTime.toLocal().toFormat("HH:mm");
}

export { date, phoneFormatter, calendar, formatYearData, ssnFormatter, timeToUTC, utcToLocal };
