import React, { PureComponent } from 'react';
import { compose } from 'ramda';
import { func } from 'prop-types';
import { FlatButton } from 'material-ui';
import ActionReportProblem from 'material-ui/svg-icons/action/report-problem';
import withAccess from '../../../../../hocs/withAccess';
import withMenuActions from '../../../../../hocs/withMenuActions';
import {PERMISSION_REVIEW} from "../../../../../utils/permissions";

class Escalate extends PureComponent {
  static propTypes = {
    handleUpdate: func.isRequired,
  };

  onUpdate = () => {
    const { handleUpdate } = this.props;
    const field = {
      escalated: true,
    };

    handleUpdate(field, 'Escalated');
  };

  render() {
    return (
      <FlatButton
        onClick={this.onUpdate}
        primary
        label="Escalate"
        icon={<ActionReportProblem />}
        style={{ color: '#fff' }}
      />
    );
  }
}

export default compose(
  withAccess(PERMISSION_REVIEW),
  withMenuActions,
)(Escalate);
