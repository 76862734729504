import {  SET_INITIAL_DATA_FROM_STORAGE } from '../StoreSynchronizer/reducer';

export const SET_APPLICATIONS_PER_PAGE = 'SET_APPLICATIONS_PER_PAGE';
export const SET_PARENT_APPLICATIONS_PER_PAGE = 'SET_PARENT_APPLICATIONS_PER_PAGE';

const INIT = {
    applicationsPerPage: 10,
    parentApplicationsPerPage: 10,
};

const setApplicationsPerPage = (state, payload) => ({
    ...state,
    applicationsPerPage: payload,
});

const setParentApplicationsPerPage = (state, payload) => ({
    ...state,
    parentApplicationsPerPage: payload,
});

const setInitialData = (state, payload) => {
  if (!payload || !payload.pagination) { return state; }

  return {
    ...state,
    ...payload.pagination,
  }
};

export default function pagination(state = INIT, action) {
    const { type, payload } = action;

    switch (type) {
    case SET_APPLICATIONS_PER_PAGE:
        return setApplicationsPerPage(state, payload);
    case SET_PARENT_APPLICATIONS_PER_PAGE:
        return setParentApplicationsPerPage(state, payload);
    case SET_INITIAL_DATA_FROM_STORAGE:
        return setInitialData(state, payload);
    default:
        return state;
    }
}
