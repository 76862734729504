import React from 'react';
import TextField from 'material-ui/TextField';
import { oneOfType, bool, node, string, shape, func } from 'prop-types';

import { ClipboardButton } from '../../../../../src/components/buttons';

const styles = {
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  longLabelText: {
    top: '2em',
    width: '130%',
  },
  copiedField: {
    fontWeight: '600',
    color: '#00b291',
  },
};

const SimpleField = ({element, title, value, isSelected, setSelectedRow}) => {
  const simpleFieldInputStyle = { width: 600 };
  const LabelTextStyle = (title.length > 80) ? { ...styles.longLabelText } : {};
  if (title.length > 160) {
    simpleFieldInputStyle.height = '5em';
    simpleFieldInputStyle.marginTop = '2em';
    LabelTextStyle.top = '0em';
  }

  return (
    <div style={styles.rowContainer} key={element}>
      <div style={simpleFieldInputStyle}>
        <TextField
          id={element}
          floatingLabelText={title}
          floatingLabelStyle={LabelTextStyle}
          defaultValue={value}
          floatingLabelFixed
          inputStyle={(isSelected) ? styles.copiedField : {}}
          fullWidth /> 
      </div>   
      {(value !== null) &&
      <ClipboardButton
        copyData={String(value)}
        source={element}
        setSelectedRow={setSelectedRow}
        active={isSelected}
        className="sensitive-data-row__button"
      />}
    </div>
  )
}

SimpleField.propTypes = {
  value: oneOfType([bool, node, shape()]),
  element: string.isRequired,
  title: string.isRequired,
  isSelected: bool.isRequired,
  setSelectedRow: func,
};

SimpleField.defaultProps = {
  value: null,
  setSelectedRow: () => {},
};

export default SimpleField;
