import React, { PureComponent } from 'react';
import { number, arrayOf, string } from 'prop-types';
import { Redirect } from 'react-router-dom';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withCounter from '../../hocs/withCounter';
import withSelectedTabName from '../../hocs/withSelectedTabName';

import {
  linksData,
  filterPresets,
  emptyStateMessages,
  indexLink,
} from './settings';
import Dashboard from '../Dashboard';
import { applicationPerPageSelector } from '../../components/CustomPerPageSelect/selectors';

class MyDashboard extends PureComponent {
  static propTypes = {
    applicationsCount: arrayOf(number),
    perPage: number,
    selectedTabName: string,
  };

  static defaultProps = {
    applicationsCount: [],
    perPage: 10,
    selectedTabName: '',
  };

  render() {
    const { applicationsCount, perPage, selectedTabName } = this.props;

    return (
      <>
        {!selectedTabName && <Redirect to={indexLink(perPage)} />}
        <Dashboard
          emptyStateMessages={emptyStateMessages}
          linksData={linksData(perPage)}
          applicationsCount={applicationsCount}
          emptyStateTitle='My Dashboard'
          emptyStateMessage={'There aren\'t any FAFSAs assigned to you. Check back another time.'}
          resource="my-dashboard"
          title="My Dashboard"
          {...this.props}
        />
      </>
    )
  }
}

const filters = linksData().map(({ name }) => filterPresets[name]);

export { indexLink };

const mapStateToProps = (state) => ({
  perPage: applicationPerPageSelector(state),
});

export default compose(
  withSelectedTabName,
  withCounter({ filters }),
  connect(mapStateToProps),
)(MyDashboard);
