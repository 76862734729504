const getApplicationStartYear = (application) => {
  const { applicationYear } = application;

  return applicationYear ? Number(applicationYear.split('/')[0]) : applicationYear;
};

const isApplicationUseTax2018 = (application) => {
  const applicationStartYear = getApplicationStartYear(application);

  return applicationStartYear >= 2020;
};

export {
  getApplicationStartYear,
  isApplicationUseTax2018,
}
