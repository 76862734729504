import React, { Fragment } from 'react';

import { createSchedule1TaxLinesQuestion } from './texts-helper';

export default {
  studentTaxFilingStatus: 'Filing status',
  studentTaxReturnType: 'Tax return type',
  didStudentFileSchedules: 'Did file schedules',
  studentTaxLine1: {label: '1', description: 'Wages, salaries, tips, etc.'},
  studentTaxLine2a: {label: '2a', description: 'Tax-exempt interest'},
  studentTaxLine4a: {label: '4a', description: 'IRA, pensions, and annuities (Sum of total IRA AND total pensions and annuities)'},
  studentTaxLine4b: {label: '4b', description: 'Taxable amount of IRAs pensions, and annuities (Sum of taxable IRA distributions AND taxable pension and annuity amount)'},
  studentTaxLine7: {label: '7', description: 'Adjusted gross income'},
  studentTaxLine12: {label: '12', description: 'Business income or (loss)'},
  studentTaxLine13: {label: '13', description: 'Income tax'},
  studentTaxBox14CodeA: {label: '14', description: 'Box 14 (Code A), self employed earnings (loss)'},
  studentTaxLine18: {label: '18', description: 'Farm income or (loss)'},
  studentTaxLine28: {label: '28', description: 'KEOGH/SEP contribution deduction '},
  studentTaxLine32: {label: '32', description: 'IRA deduction'},
  studentTaxLine46: {label: '46', description: 'Excess advance premium tax credit repayment'},
  studentTaxLine50: {label: '50', description: 'Education credits'},
  taxSchedule: 'Which of the following Schedules did your file? (Select all Schedule apply)',
  taxCommonTitle: 'Please use your IRS Form 1040 to answer the following questions',
  taxCommonDescription: 'Frank uses these answers to do your tax math for you',
  taxSchedule1Title: <Fragment>Please use your <u>Schedule 1</u> to answer the following questions</Fragment>,
  taxSchedule1Description: 'Frank uses these answers to do your tax math for you',
  taxSchedule2Title: <Fragment>Please use your <u>Schedule 2</u> to answer the following questions</Fragment>,
  taxSchedule2Description: 'Frank uses these answers to do your tax math for you',
  taxSchedule3Title: <Fragment>Please use your <u>Schedule 3</u> to answer the following questions</Fragment>,
  taxSchedule3Description: 'Frank uses these answers to do your tax math for you',
  taxScheduleK1Title: <Fragment>Please use your <u>Schedule K-1</u> to answer the following questions</Fragment>,
  taxScheduleK1Description: 'Frank uses these answers to do your tax math for you',
  cashBalanceTitle: <Fragment>What is your current balance of cash, savings, and checking accounts?</Fragment>,
  cashBalanceDescription: 'All the money in your accounts today. Do not include student financial aid.',
  studentTaxSchedule1Line13: 'Line 13',
  studentTaxSchedule1Line21: 'Line 21',
  schedule1LinesPickerLabel1: (
    <Fragment>Do any of these lines in your <u>Schedule 1</u> have a value that is <u>not zero</u>? Check all that apply. </Fragment>
  ),
  getSchedule1TaxLinesQuestion: selectedTaxLines => (
    createSchedule1TaxLinesQuestion(
      selectedTaxLines,
      <Fragment>Please enter the value of Line 13 of your <u>Schedule 1</u>. </Fragment>,
      <Fragment>Please enter the value of Line 21 of your <u>Schedule 1</u>. </Fragment>,
    )
  ),
  didFileSchedule1AlaskaPermanentFundDividendLabel: (
    <Fragment>
      Is Line 21 of your <u>Schedule 1</u> only used to report your Alaska Permanent Fund dividend?
    </Fragment>
  ),
};
