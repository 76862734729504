import { Children } from 'react';
import { number, shape, string, oneOfType } from 'prop-types';
import { TabbedShowLayout } from 'admin-on-rest';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import Tab from './Tab';

const NESTED_TAB_NAME_SEPARATOR = '.';

class LocationTabs extends TabbedShowLayout {
  static propTypes = {
    ...(TabbedShowLayout.propTypes || {}),
    location: shape({
      search: string,
    }).isRequired,
    initialTabParam: oneOfType([string, number]),
    tabNameProp: string,
  };

  static defaultProps = {
    ...(TabbedShowLayout.defaultProps || {}),
    initialTabParam: 'tab',
    tabNameProp: 'tabName',
  };

  componentDidMount() {
    this.selectInitialTab();
  }

  selectInitialTab = () => {
    const { initialTabParam, tabNameProp, location, children } = this.props;

    const parsedLocationQuery = queryString.parse(location.search);

    const initialTabName = parsedLocationQuery[initialTabParam] || '';

    const childrenPropsList = Children.toArray(children);
    const initialTabNameParts = initialTabName.split(NESTED_TAB_NAME_SEPARATOR);

    const initialTabIndex = childrenPropsList.reduce((result, { props }, i) => (
      initialTabNameParts.includes(props[tabNameProp]) ? i : result
    ), 0);

    this.selectTab(initialTabIndex);
  };

  selectTab = (value) => {
    this.setState({ value });
  }
}

export { Tab, NESTED_TAB_NAME_SEPARATOR };
export default withRouter(LocationTabs);
