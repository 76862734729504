import React from 'react';
import { shape } from 'prop-types';
import { path } from 'ramda';

import { getFullNameFromApplication } from '../applicationHelpers';

const styles = {
  badge: {
    border: '1px solid #4bb391',
    padding: '4px 10px',
    marginRight: '10px',
  },
  label: {
    textTransform: 'capitalize',
  },
};

const Title = ({ record: r }) => {
  const partner = path(['faasPartner', 'name'], r);

  return (
    <>
    {partner && (
      <>
        <span style={styles.badge}>{partner}</span>
        {' '}
      </>
    )}
    <span style={styles.label}>
      {`${
      r.applicationYear || ''
    } FAFSA® ${getFullNameFromApplication(r)}`}
    </span>
    </>
  );
};

Title.propTypes = {
  record: shape(),
};

Title.defaultProps = {
  record: {},
};

export default Title;
