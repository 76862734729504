import { get, put } from './api';
import { getToken } from './localAuthStorage';

export const getMaintenanceSetting = () => get('/maintenance', getToken());

export const changeMaintenanceSetting = ({
  type,
  maintenanceAt,
  maintenanceMode,
}) => put('/maintenance', { type, maintenanceAt, maintenanceMode }, getToken());
