/* eslint-disable no-dupe-keys */
import React from 'react';
import { shape } from 'prop-types';

import {
  Filter,
  BooleanInput,
  SelectInput,
  SelectArrayInput,
} from 'admin-on-rest';
import compose from 'recompose/compose';

import FilterWithOperator, {
  OR_OPERATOR,
} from '../components/FilterWithOperator';
import DateRangePicker from '../components/fields/DateRangePicker';
import Search from '../components/Search';
import { FrankStatus, FafsaStatus, Dependency, ApplicationYears } from './enumChoices';
import withAssignToUsers from '../hocs/withAssignToUsers';
import IWithUser from '../hocs/withUsers/propTypes';
import { encodeSymbolsInFilterNestedName, getUsersListWithUnassigned } from '../services/filters';
import { APPLICATION_FIELDS } from '../constants';
import FilterWithPartnerChoices from '../components/FilterWithPartnerChoices';

const getSourceForUserOrParent = (isStudentApplications, source) => (
  isStudentApplications ? source.student : encodeSymbolsInFilterNestedName(`application.${source.parent}`)
);

const ApplicationFilter = ({ isStudentApplications, ...props }) => (
  <Filter {...props}>
    <Search label="Search" source="q" alwaysOn />
    <FilterWithOperator
      label="Frank Status"
      source={getSourceForUserOrParent(isStudentApplications, APPLICATION_FIELDS.FRANK_STATUS)}
      choices={FrankStatus}
      operator={OR_OPERATOR}
      optionText="name"
      optionValue="name"
    >
      {fieldProps => <SelectArrayInput {...fieldProps} />}
    </FilterWithOperator>
    <SelectInput
      label="FAFSA Status"
      source={getSourceForUserOrParent(isStudentApplications, APPLICATION_FIELDS.FAFSA_STATUS)}
      choices={FafsaStatus}
    />
    <FilterWithOperator
      label="Frank Follow UP"
      source={getSourceForUserOrParent(isStudentApplications, APPLICATION_FIELDS.FRANK_BETA_STATUS)}
      choices={FrankStatus}
      operator={OR_OPERATOR}
      optionText="name"
      optionValue="name"
    >
      {fieldProps => <SelectArrayInput {...fieldProps} />}
    </FilterWithOperator>
    <SelectInput
      label="FAFSA Follow UP"
      source={getSourceForUserOrParent(isStudentApplications, APPLICATION_FIELDS.FAFSA_BETA_STATUS)}
      choices={FafsaStatus}
    />

    <SelectInput
      label="Dependency Status"
      source={getSourceForUserOrParent(isStudentApplications, APPLICATION_FIELDS.STUDENT_DEPENDENCY)}
      choices={Dependency}
    />
    <BooleanInput
      label="Pending Parent"
      source={getSourceForUserOrParent(isStudentApplications, APPLICATION_FIELDS.IS_PENDING_PARENT)}
      defaultValue={true}
    />
    <BooleanInput
      label="Pending IRS"
      source={getSourceForUserOrParent(isStudentApplications, APPLICATION_FIELDS.IS_PENDING_IRS)}
      defaultValue={true}
    />
    <BooleanInput
      label="Pending IRS For Parent"
      source={getSourceForUserOrParent(isStudentApplications, APPLICATION_FIELDS.IS_PENDING_IRS_FOR_PARENT)}
      defaultValue={true}
    />
    {
      isStudentApplications ? (
        <DateRangePicker
          label="Last Updated"
          source="lastSignificantUpdate"
        />
      ) : (
        <DateRangePicker
          label="Last Updated"
          source="updatedAt"
        />
      )
    }
    {
      isStudentApplications && (
        <DateRangePicker
          label="Last Updated By Student"
          source="studentConfirmed"
        />
      )
    }
    {
      isStudentApplications && (
        <DateRangePicker
          label="Last Updated By Parent"
          source="parentConfirmed"
        />
      )
    }
    {Boolean(props.usersList.length) &&
      < FilterWithOperator
        label="Assigned To"
        source={getSourceForUserOrParent(isStudentApplications, APPLICATION_FIELDS.ASSIGNED_TO)}
        choices={getUsersListWithUnassigned(props.usersList)}
        operator={OR_OPERATOR}
        optionText="name"
        optionValue="value"
      >
        {fieldProps => <SelectInput {...fieldProps} allowEmpty={false} />}
      </FilterWithOperator>
    }
    <BooleanInput
      label="Escalated"
      source={getSourceForUserOrParent(isStudentApplications, APPLICATION_FIELDS.ESCALATED)}
      defaultValue={true}
    />
    <SelectInput
      label="Application Year"
      source="applicationYear"
      choices={ApplicationYears}
    />
    {
      isStudentApplications &&
      <BooleanInput
        label="Partnerships"
        source="isFaasPartner"
        defaultValue={true}
      />
    }
    {
      isStudentApplications &&
      <FilterWithPartnerChoices
        label="Partner"
        source={encodeSymbolsInFilterNestedName('faasPartner.acronym')}
        operator={OR_OPERATOR}
      >
        {fieldProps =>
          <FilterWithOperator {...fieldProps}>
            {partnerFieldProps => <SelectArrayInput {...partnerFieldProps} />}
          </FilterWithOperator>
        }

      </FilterWithPartnerChoices>
    }
  </Filter >
);

ApplicationFilter.propTypes = {
  filterValues: shape(),
  ...IWithUser,
};

ApplicationFilter.defaultProps = {
  filterValues: {},
};

// NOTE: We now want to display all users in assign to filter
const params = {};

export default compose(
  withAssignToUsers(params),
)(ApplicationFilter);
