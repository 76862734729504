export default {
  parentAdjustedGrossIncome: ({ backofficeData: { taxYear } }) =>
    `What was your parents' adjusted gross income for ${taxYear}?`,
  parentEarningsFromWork: ({ backofficeData: { taxYear } }) =>
    `How much did your parent earn from working (wages, salaries, tips, etc.) in ${taxYear}?`,
  parentIncomeTax: ({ backofficeData: { taxYear } }) =>
    `How much income tax did your parents' pay in ${taxYear}?`,
  parentIraDeductions: () =>
    `Did your parents' receive IRA deductions or payments to self-employed SEP, SIMPLE, Keogh, or other qualified plans?`,
  parentTaxExemptInterestIncome: ({ backofficeData: { taxYear } }) =>
    `Did your parents' have tax-exempt interest income in ${taxYear}?`,
  parentUntaxedPortionsOfIRA: ({ backofficeData: { taxYear } }) =>
    `Did your parents' have untaxed portions of pensions and/or IRA distributions in ${taxYear}?`,
  parentCashBalance:  () =>
    `What is your parent’s current balance of cash, savings, and checking accounts?`,
};
