import React, { PureComponent } from 'react';
import { func } from 'prop-types';

import CommentsForm from '../CommentsForm';

export default class RestCommentsForm extends PureComponent {
  static propTypes = {
    fetchComments: func.isRequired,
    saveComment: func.isRequired,
    commentRenderer: func.isRequired,
    toggleCommentPin: func.isRequired,
    pinnedCommentsHolderComponent: CommentsForm.propTypes.pinnedCommentsHolderComponent,
  };

  static defaultProps = {
    pinnedCommentsHolderComponent: CommentsForm.defaultProps.pinnedCommentsHolderComponent,
  };

  state = { comments: [] };

  componentDidMount() {
    this.fetchComments();
  }

  fetchComments = async () => {
    try {
      const { fetchComments } = this.props;

      const comments = await fetchComments();

      if (!comments) {
        return;
      }

      this.setState({ comments });
    } catch (error) {
      console.error(error);
    }
  };

  toggleCommentPin = async (id, pinned) => {
    try {
      const { toggleCommentPin } = this.props;

      await toggleCommentPin(id, pinned);
      await this.fetchComments();
    } catch (error) {
      console.error(error);
    }
  };

  submit = async (value) => {
    try {
      const { saveComment } = this.props;

      await saveComment(value);
      await this.fetchComments();
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { comments } = this.state;
    const { commentRenderer, pinnedCommentsHolderComponent } = this.props;

    return (
      <CommentsForm
        pinnedCommentsHolderComponent={pinnedCommentsHolderComponent}
        comments={comments}
        toggleCommentPin={this.toggleCommentPin}
        submit={this.submit}
        commentRenderer={commentRenderer}
      />
    );
  }
}
