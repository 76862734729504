import React, { memo } from 'react';
import { string } from 'prop-types';

import GridListRow from './GridListRow';
import { IColumns, IData } from './propTypes';

import './styles.scss';

const GridList = (props) => {
  const { columns, data, title, className } = props;

  const rows = data.map((dataItem, i) => (
    <GridListRow
      key={i}
      columns={columns}
      dataItem={dataItem}
    />
  ));

  return (
    <>
      {title ? <h3 className="description-list-title">{title}</h3> : null}
      <div className={`grid-list ${className}`}>
        {rows}
      </div>
    </>
  );
};

GridList.propTypes = {
  title: string,
  className: string,
  columns: IColumns,
  data: IData,
};

GridList.defaultProps = {
  title: null,
  columns: [],
  data: [],
  className: '',
};

export { IColumns, IData };
export default memo(GridList);
