import React, { PureComponent } from 'react';
import { getFromLocalStorage } from '../services/localStorage';

export default Component => (
  class WithPermissions extends PureComponent {
    render() {
      const permissions = getFromLocalStorage('permissions') || '';

      return <Component {...this.props} permissions={permissions} />;
    }
  }
);
