import decodeJwt from 'jwt-decode';
import {
  setToStorage,
  getFromLocalStorage,
  removeStorageData,
} from '../services/localStorage';

const TOKEN = 'token';
const PERMISSIONS = 'permissions';
const EMAIL = 'email';

export const updateLocalStorageAuthData = (token, email) => {
  const decodedToken = decodeJwt(token);

  setToStorage(TOKEN, token);
  setToStorage(PERMISSIONS, decodedToken.permissions);
  setToStorage(EMAIL, email);
};

export const clearLocalStorageAuthData = () => {
  removeStorageData(TOKEN);
  removeStorageData(PERMISSIONS);
  removeStorageData(EMAIL);
};

export const getToken = () => getFromLocalStorage(TOKEN);
export const getPermissions = () => getFromLocalStorage(PERMISSIONS) || [];
export const getEmail = () => getFromLocalStorage(EMAIL);
