// based on https://github.com/marmelab/admin-on-rest/blob/master/src/mui/auth/Login.js
import React, { PureComponent } from 'react';
import { shape, func } from 'prop-types';
import { propTypes, reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Link } from 'react-router-dom';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { Card, CardActions } from 'material-ui/Card';
import FlatButton from 'material-ui/FlatButton';
import CircularProgress from 'material-ui/CircularProgress';
import { cyan500, pinkA200 } from 'material-ui/styles/colors';
import {
  defaultTheme,
  translate,
  Notification,
  userLogin as userLoginAction,
} from 'admin-on-rest';

import renderInput from '../renderInput';
import './styles.scss';

const styles = {
  button: {
    marginRight: 0,
    minWidth: 187,
    backgroundColor: '#2755c4',
    borderRadius: 17,
  },
  buttonLabel: {
    fontSize: 14,
    color: '#fff',
    textTransform: 'none',
  },
};

function getColorsFromTheme(theme) {
  if (!theme) return { primary1Color: cyan500, accent1Color: pinkA200 };
  const {
    palette: { primary1Color, accent1Color },
  } = theme;
  return { primary1Color, accent1Color };
}

class Login extends PureComponent {
  static propTypes = {
    ...propTypes,
    translate: func.isRequired,
    userLogin: func.isRequired,
    theme: shape(),
  };

  static defaultProps = {
    theme: defaultTheme,
  };

  login = auth =>
    this.props.userLogin(
      auth,
      this.props.location.state ? this.props.location.state.nextPathname : '/',
    );

  render() {
    const { handleSubmit, isLoading, theme } = this.props;
    const muiTheme = getMuiTheme(theme);
    const { primary1Color, accent1Color } = getColorsFromTheme(muiTheme);

    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <div className="login" style={{ backgroundColor: primary1Color }}>
          <Card className="login-card" style={{ borderColor: accent1Color }}>
            <span className="login-card-title">Welcome back</span>
            <form onSubmit={handleSubmit(this.login)}>
              <div className="login-card-form">
                <div className="login-card-input">
                  <Field
                    name="username"
                    component={renderInput}
                    floatingLabelText="Email"
                    disabled={isLoading}
                  />
                </div>
                <div className="login-card-input">
                  <Field
                    name="password"
                    component={renderInput}
                    floatingLabelText={this.props.translate(
                      'aor.auth.password',
                    )}
                    type="password"
                    disabled={isLoading}
                  />
                </div>
              </div>
              <CardActions className="login-card-actions">
                <FlatButton
                  type="submit"
                  disabled={isLoading}
                  icon={
                    isLoading && <CircularProgress size={25} thickness={2} />
                  }
                  label="Let's go"
                  style={styles.button}
                  labelStyle={styles.buttonLabel}
                />
              </CardActions>
            </form>
            <div className="login-help">
              <span>Not sure what your password is? </span>
              <Link to="/forgot-password" className="login-link">
                Let us help
              </Link>
            </div>
          </Card>
          <Notification />
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });

const enhance = compose(
  translate,
  reduxForm({
    form: 'signIn',
    validate: (values, props) => {
      const errors = {};
      if (!values.username)
        errors.username = props.translate('aor.validation.required');
      if (!values.password)
        errors.password = props.translate('aor.validation.required');
      return errors;
    },
  }),
  connect(
    mapStateToProps,
    { userLogin: userLoginAction },
  ),
);

export default enhance(Login);
