import React from 'react';
import { string } from 'prop-types';

import Avatar from '../../Avatar';
import SystemUpdateIcon from './SystemUpdateIcon';
import { SYSTEM_USER_EMAIL } from '../../../constants';

const isSystemUpdate = email => email === SYSTEM_USER_EMAIL;

const SystemUpdateAvatar = ({ email }) =>
  !isSystemUpdate(email) ? <Avatar userName={email} /> : <SystemUpdateIcon />;

SystemUpdateAvatar.propTypes = {
  email: string.isRequired,
};

export default SystemUpdateAvatar;
