import React, { PureComponent } from 'react';
import { bool, element, func } from 'prop-types';
import { List } from 'admin-on-rest';

import { createListCheckboxesProps } from './helper';
import lastVisitedListLink from './lastVisitedListLink';

const datagridStyles = {
  table: {
    tableLayout: 'auto',
  },
  header: {
    th: {
      padding: '0',
      width: 'auto',
      textAlign: 'center',
    },
    'th:first-child': {
      padding: '0',
      textAlign: 'center',
    },
  },
  cell: {
    td: {
      padding: '10px',
    },
    'td:first-child': {
      padding: '10px',
    },
  },
};

export default class PureList extends PureComponent {
  static propTypes = {
    children: element.isRequired,
    displayCheckboxes: bool.isRequired,
    handleRowClick: func.isRequired,
  };

  constructor(props) {
    super(props);

    const { displayCheckboxes, handleRowClick } = this.props;

    this.dataGridCheckboxesProps = createListCheckboxesProps(
      displayCheckboxes,
      handleRowClick,
    );
  }

  componentDidMount() {
    this.updateLastVisitedLink(this.props);
  }

  componentDidUpdate(nextProps) {
    this.updateLastVisitedLink(nextProps);
  }

  updateLastVisitedLink = props => {
    const { resource } = props;

    const { location } = window;
    const { href, origin } = location;
    const link = href.replace(new RegExp(`^${origin}(/#)?`), '');

    lastVisitedListLink.set(resource, link);
  };

  render() {
    const { children } = this.props;

    return (
      <List {...this.props}>
        {React.cloneElement(children, {
          styles: datagridStyles,
          ...this.dataGridCheckboxesProps,
        })}
      </List>
    );
  }
}
