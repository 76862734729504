import React from 'react';
import { CheckboxGroupInput, SelectInput } from 'admin-on-rest';
import { DependentInput } from 'aor-dependent-input';
import { curry } from 'ramda';

import {
  TaxFilingStatus,
  TaxReturnType,
  didFileSchedules,
  tax2018ScheduleOptions,
  didFileSchedule1AlaskaPermanentFundDividend as didFileSchedule1AlaskaPermanentFundDividendOptions,
  didFileSchedule1GainsOrLossesFromVirtualCurrency as didFileSchedule1GainsOrLossesFromVirtualCurrencyOptions,
  tax2018Schedule1LinesOptions,
  taxSchedule1LinesValues,
  tax2019Schedule1LinesOptions,
  taxSchedule1LinesValues2019,
} from '../../../Applications/enumChoices';
import FormGroupLabel from '../../FormGroupLabel';
import { NumberInput } from '../../fields';
import styles from '../common-styles';
import { createSelects, createLineInputs } from './group-creators';
import FieldsHolder from '../../fields/FieldsHolder';
import APIUrls from '../../../Applications/APIUrls';
import {
  getFieldNames,
  getTexts,
  has2018TaxYear,
  isMainVisible,
  isTaxLinesVisible,
  isAlaskaPermanentFundDividendSwitcherVisible,
  isDidFileSchedule1GainsOrLossesFromVirtualCurrencyVisible,
} from './helpers';
import getTaxAutomationValues from './getTaxAutomationValues';


export default curry(
  (isStudent, record, showFileLinks) => {
    const { backofficeData: { taxYear }} = record;

    const fieldNames = getFieldNames(isStudent, taxYear);
    const texts = getTexts(isStudent, taxYear)

    const {
      taxFilingStatusFieldName,
      taxReturnTypeFieldName,
      didFileSchedulesFieldName,
      taxScheduleFieldName,
      commonLineInputNames,
      cashBalanceFieldName,
      schedule1LineInputNames,
      schedule2LineInputNames,
      schedule3LineInputNames,
      scheduleK1LineInputNames,
    } = fieldNames;

    const taxSelectsData = [
      { field: taxFilingStatusFieldName, options: TaxFilingStatus },
      { field: taxReturnTypeFieldName, options: TaxReturnType },
      { field: didFileSchedulesFieldName, options: didFileSchedules },
    ];

    const selects = createSelects(taxSelectsData, texts);

    const taxAutomationValues =  getTaxAutomationValues(isStudent, record);
    const commonLineInputs = createLineInputs(commonLineInputNames, texts, taxAutomationValues);
    const schedule1LineInput = createLineInputs(schedule1LineInputNames, texts, taxAutomationValues);
    const schedule2LineInput = createLineInputs(schedule2LineInputNames, texts, taxAutomationValues);
    const schedule3LineInput = createLineInputs(schedule3LineInputNames, texts, taxAutomationValues);
    const scheduleK1LineInput = createLineInputs(scheduleK1LineInputNames, texts, taxAutomationValues);

    const {
      taxSchedule1Line13FieldName,
      taxSchedule1Line21FieldName,
      taxSchedule1Line8FieldName,
      taxSchedule1ChecksFieldName,
      schedule1AdditionsLineInputNames,
      didFileSchedule1AlaskaPermanentFundDividendFieldName,
      didFileSchedule1GainsOrLossesFromVirtualCurrencyFieldName,
    } = fieldNames;
    const {
      schedule1LinesPickerLabel1,
      didFileSchedule1AlaskaPermanentFundDividendLabel,
      didFileSchedule1GainsOrLossesFromVirtualCurrencyLabel,
    } = texts;
  
    const schedule1AdditionsLinesMapping = has2018TaxYear(taxYear) ? {
      [taxSchedule1Line13FieldName]: taxSchedule1LinesValues.LINE_13,
      [taxSchedule1Line21FieldName]: taxSchedule1LinesValues.LINE_21,
    } : {
      [taxSchedule1Line8FieldName]: taxSchedule1LinesValues2019.LINE_8,
    };
  
    const getSchedule1ChecksFromRecord = ({ [taxSchedule1ChecksFieldName]: schedule1Checks }) => (
      schedule1Checks || []
    );
  
    const isSchedule1AdditionalLineVisible = fieldName => rec => (
      getSchedule1ChecksFromRecord(rec).includes(schedule1AdditionsLinesMapping[fieldName])
    );
  
    const processedSchedule1AdditionsLineInputNames = (
      schedule1AdditionsLineInputNames.map(inputName => [
        inputName,
        isSchedule1AdditionalLineVisible(inputName),
      ])
    );
  
    const schedule1Additions1LineInputs = (
      createLineInputs(processedSchedule1AdditionsLineInputNames, texts)
    );

    const selectedSchedule1TaxLines = record[taxSchedule1ChecksFieldName] || [];

    return (
      <FieldsHolder>
        {showFileLinks && <APIUrls
          key="backofficeData"
          source="backofficeData"
          listName={isStudent ? "studentTaxFileUrls" : "parentTaxFileUrls"}
          record={record}
        />}
        {selects}
        <FormGroupLabel
          className="padding-top-20"
          title={texts.taxCommonTitle}
          description={texts.taxCommonDescription}
        />
        <CheckboxGroupInput
          source={taxScheduleFieldName}
          label=""
          choices={tax2018ScheduleOptions}
        />
        <FormGroupLabel
          className="padding-top-20"
          title={texts.taxCommonTitle}
          description={texts.taxCommonDescription}
        />
        {commonLineInputs}
        <FormGroupLabel
          className="padding-top-20"
          title={texts.taxSchedule1Title}
          description={texts.taxSchedule1Description}
        />
        {schedule1LineInput}
        <DependentInput resolve={isMainVisible(fieldNames)}>
      <FormGroupLabel
        className="padding-top-20"
        title={schedule1LinesPickerLabel1}
      />
      <CheckboxGroupInput
        source={taxSchedule1ChecksFieldName}
        label=""
        choices={has2018TaxYear(taxYear) ? tax2018Schedule1LinesOptions : tax2019Schedule1LinesOptions}
      />
      <DependentInput resolve={isTaxLinesVisible(fieldNames)}>
        <FormGroupLabel
          className="padding-top-20"
          title={texts.getSchedule1TaxLinesQuestion(selectedSchedule1TaxLines)}
        />
        {schedule1Additions1LineInputs}
      </DependentInput>
      <DependentInput resolve={isDidFileSchedule1GainsOrLossesFromVirtualCurrencyVisible(fieldNames)}>
        <FormGroupLabel
          className="padding-top-20"
          title={didFileSchedule1GainsOrLossesFromVirtualCurrencyLabel}
        />
        <SelectInput
          label=""
          source={didFileSchedule1GainsOrLossesFromVirtualCurrencyFieldName}
          choices={didFileSchedule1GainsOrLossesFromVirtualCurrencyOptions}
          elStyle={styles.select}
          allowEmpty
        />
      </DependentInput>
      <DependentInput resolve={isAlaskaPermanentFundDividendSwitcherVisible(fieldNames)}>
        <FormGroupLabel
          className="padding-top-20"
          title={didFileSchedule1AlaskaPermanentFundDividendLabel}
        />
        <SelectInput
          label=""
          source={didFileSchedule1AlaskaPermanentFundDividendFieldName}
          choices={didFileSchedule1AlaskaPermanentFundDividendOptions}
          elStyle={styles.select}
          allowEmpty
        />
      </DependentInput>
    </DependentInput>
        <FormGroupLabel
          className="padding-top-20"
          title={texts.taxSchedule2Title}
          description={texts.taxSchedule2Description}
        />
        {schedule2LineInput}
        <FormGroupLabel
          className="padding-top-20"
          title={texts.taxSchedule3Title}
          description={texts.taxSchedule3Description}
        />
        {schedule3LineInput}
        <FormGroupLabel
          className="padding-top-20"
          title={texts.taxScheduleK1Title}
          description={texts.taxScheduleK1Description}
        />
        {scheduleK1LineInput}
        <FormGroupLabel
          className="padding-top-20"
          title={texts.cashBalanceTitle}
          description={texts.cashBalanceDescription}
        />
        <NumberInput
          source={cashBalanceFieldName}
          elStyle={styles.numberInput}
        />
      </FieldsHolder>
    );
  }
);
