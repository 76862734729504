import { put, post } from './api';
import { getToken } from './localAuthStorage';
import request from './request';

export const getCountApplications = (params) => request({
  method: 'GET',
  url: `/counts/applications`,
  params,
});

export const refreshFollowUps = data => post(
  '/refresh-applications',
  data,
  getToken()
);

export default (data) =>
  put(`/applications`, data, getToken());
