import React, { PureComponent } from 'react';
import { Show, Tab, FunctionField } from 'admin-on-rest';

import ParentApplicationTitle from '../ParentApplicationTitle';
import {
  YesNoBooleanField,
  TextField,
  PartialDateField,
} from '../../../components/fields';

import ShowActions from '../../ShowActions';
import CommentsList from '../../CommentsList';
import LocationTabs from '../../../components/LocationTabs';
import { APPLICATION_TYPES } from '../../../constants/comments';
import SensitiveDataCard from './SensitiveDataCard';

const TAB_NAMES = {
  BACKGROUND_INFO: 'background-info',
  FINANCIAL_INFO: 'financial-info',
  SENSITIVE_DATA: 'sensitive-data',
  COMMENTS: 'comments',
};

class ParentApplicationShow extends PureComponent {
  render() {
    return (
      <Show
        title={<ParentApplicationTitle />}
        actions={<ShowActions />}
        {...this.props}
      >
        {permissions => (
          <LocationTabs>
            <Tab label="Background info" tabName={TAB_NAMES.BACKGROUND_INFO}>
              <PartialDateField
                source="parent1PartialBirthday"
                label="Parent date of birth (partial)"
              />
              <PartialDateField
                source="parent2PartialBirthday"
                label="Other parent date of birth (partial)"
              />
              <TextField source="applicationYear" label="Application Year" />
              <FunctionField
                label="Parent name"
                render={({ parent1FirstName, parent1LastName }) =>
                  parent1FirstName && `${parent1FirstName} ${parent1LastName}`
                }
              />
              <FunctionField
                label="Other parent name"
                render={({ parent2FirstName, parent2LastName }) =>
                  parent2FirstName && `${parent2FirstName} ${parent2LastName}`
                }
              />
            </Tab>
            <Tab label="Financial info" tabName={TAB_NAMES.FINANCIAL_INFO}>
              <YesNoBooleanField
                source="didParentCompleteTaxReturn"
                label={({ backofficeData }) =>
                  `Did your parent complete a tax return for ${
                    backofficeData.taxYear
                  }`
                }
              />
              <YesNoBooleanField
                source="parentTaxSetup"
                label="Parent uploaded tax files?"
              />
            </Tab>
            {permissions.includes('sensitive_data') && (
              <Tab label="Sensitive data" tabName={TAB_NAMES.SENSITIVE_DATA}>
                <SensitiveDataCard />
              </Tab>
            )}
            <Tab label="comments" tabName={TAB_NAMES.COMMENTS}>
              <CommentsList
                applicationType={APPLICATION_TYPES.PARENT_APPLICATION}
              />
            </Tab>
          </LocationTabs>
        )}
      </Show>
    );
  }
}

export { TAB_NAMES };

export default ParentApplicationShow;
