import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'ramda';
import { func, number, arrayOf, shape, string } from 'prop-types';

import { StudentApplicationsList } from '../Applications/StudentApplications'
import EmptyState from '../components/EmptyState';
import DashboardNavigation from './DashboardNavigation';
import withSelectedTabName from '../hocs/withSelectedTabName';

class Dashboard extends PureComponent {
  static propTypes = {
    linksData: arrayOf(shape()).isRequired,
    dispatch: func.isRequired,
    filterForm: shape(),
    applicationsCount: arrayOf(number),
    perPage: number,
    emptyStateTitle: string,
    emptyStateMessage: string,
    selectedTabName: string,
    emptyStateMessages: shape(),
  };

  static defaultProps = {
    filterForm: null,
    applicationsCount: [],
    perPage: 10,
    emptyStateTitle: '',
    emptyStateMessage: '',
    selectedTabName: '',
    emptyStateMessages: {},
  };

  getSelectedTabIndex = () => {
    const { linksData, selectedTabName } = this.props;
    const selectedTabIndex = linksData.findIndex(({ name }) => (
      name === selectedTabName
    ));

    return selectedTabIndex === -1 ? null : selectedTabIndex;
  };

  renderApplicationsList() {
    const {
      applicationsCount,
      emptyStateMessages,
      selectedTabName,
    } = this.props;

    const selectedTabIndex = this.getSelectedTabIndex();
    const currentListCount = applicationsCount[selectedTabIndex];
    const listContainerClassName = !currentListCount ? 'hidden' : '';

    return (
      <div>
        <div className={listContainerClassName}>
          <StudentApplicationsList {...this.props} />
        </div>
        <EmptyState
          display={!currentListCount}
          message={emptyStateMessages[selectedTabName]}
        />
      </div>
    )
  }

  render() {
    const {
      applicationsCount,
      emptyStateTitle,
      emptyStateMessage,
      linksData,
    } = this.props;

    const isAllAssigned = applicationsCount.every(item => !item);
    const applicationsTabsContainerClassName = isAllAssigned ? 'hidden' : '';

    return (
      <div className="dashboard-container">
        <div className={applicationsTabsContainerClassName}>
          <DashboardNavigation
            counters={applicationsCount}
            items={linksData}
          />
          {this.renderApplicationsList()}
        </div>
        <EmptyState
          display={isAllAssigned}
          title={emptyStateTitle}
          message={emptyStateMessage}
        />
      </div>
    );
  }
}

export default compose(
  withSelectedTabName,
  connect(),
)(Dashboard);
