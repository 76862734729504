import React, { memo } from 'react';
import { string, bool } from 'prop-types';
import {
  SocialPerson,
  CommunicationEmail,
  CommunicationPhone,
  ActionHome,
  SocialSchool,
  ActionAccountBalance,
  SocialPeople,
  EditorInsertDriveFile,
  ActionLock,
} from 'material-ui/svg-icons';
import { Link } from 'react-router-dom';

import InfoList from '../InfoList';
import Card from '../CustomCard/Card';
import { phoneFormatter } from '../../utils/formatter';
import { getFsaIdText } from './service';

const StudentSummaryCard = props => {
  const {
    firstName,
    lastName,
    email,
    phone,
    address,
    degree,
    highSchoolName,
    dependency,
    parentApplicationLink,
    doesUserHaveFsaId,
    doesUserRememberFsaId,
  } = props;

  const items = [
    {
      text: `${firstName} ${lastName}`,
      iconComponent: SocialPerson,
    },
    {
      text: email,
      iconComponent: CommunicationEmail,
      className: 'info-list__contact',
    },
    {
      text: phoneFormatter(phone),
      iconComponent: CommunicationPhone,
      className: 'info-list__contact',
    },
    {
      text: address,
      iconComponent: ActionHome,
    },
  ];

  const schoolInfo = [
    {
      text: degree,
      iconComponent: ActionAccountBalance,
    },
    {
      text: highSchoolName,
      iconComponent: SocialSchool,
    },
    {
      text: dependency,
      iconComponent: SocialPeople,
    },
    {
      text: (
        <Link
          to={parentApplicationLink}
        >
          Parent application
        </Link>
      ),
      iconComponent: EditorInsertDriveFile,
      className: 'info-list__contact',
    },
    {
      text: getFsaIdText({ doesUserHaveFsaId, doesUserRememberFsaId }),
      iconComponent: ActionLock,
    }
  ];

  return (
    <Card title="Student Summary">
      <InfoList items={items} schoolInfo={schoolInfo} />
    </Card>
  );
};

StudentSummaryCard.propTypes = {
  firstName: string.isRequired,
  lastName: string.isRequired,
  email: string.isRequired,
  phone: string.isRequired,
  address: string.isRequired,
  degree: string,
  highSchoolName: string,
  dependency: string,
  parentApplicationLink: string,
  doesUserHaveFsaId: string,
  doesUserRememberFsaId: bool,
};

StudentSummaryCard.defaultProps = {
  degree: null,
  highSchoolName: null,
  dependency: null,
  parentApplicationLink: null,
  doesUserHaveFsaId: null,
  doesUserRememberFsaId: null,
};

export default memo(StudentSummaryCard);
