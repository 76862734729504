import React, { PureComponent } from 'react';
import { string, oneOf, node, bool } from 'prop-types';

import './style.scss';

export const GREY = 'grey';
export const GREEN = 'green';
export const YELLOW = 'yellow';
export const BLUE = 'blue';
export const RED = 'red';

export default class Card extends PureComponent {
  static propTypes = {
    children: node.isRequired,
    title: string,
    color: oneOf([GREY, GREEN, YELLOW, BLUE, RED]),
    cover: bool,
  };

  static defaultProps = {
    color: BLUE,
    cover: true,
    title: '',
  };

  render() {
    const { children, title, color, cover } = this.props;

    const wrapperClassName = `summary-card-wrapper ${color}`;
    const containerClassName = `summary-card-container ${cover ? 'cover' : ''}`;

    return (
      <div className={wrapperClassName}>
        { title && <h3 className="summary-card-header">{title}</h3> }
        <div className={containerClassName} >
          {children}
        </div>
      </div>
    );
  }
}
