import React from 'react';
import { string, shape,  } from 'prop-types';

import ClipboardButton from '../buttons/ClipboardButton';

const defaultRenderer = ({ name, id, copyButtonProps: {selectedRow, source, ...restCopyButtonProps} }) => (
  <div className="schools-list__item">
    <div>
      {name}
    </div>
    <div className="right-block">
      <ClipboardButton
        copyData={id}
        active={selectedRow === source}
        selectedRow={selectedRow}
        source={source}
        {...restCopyButtonProps}
      />
    </div>
  </div>
);

defaultRenderer.propTypes = {
  name: string.isRequired,
  id: string.isRequired,
  copyButtonProps: shape().isRequired,
};

export default defaultRenderer;
