import { createContext } from 'react';

const context = createContext([]);

const {
  Consumer: CommonListSelectionConsumer,
  Provider: CommonListSelectionProvider,
} = context;

export { CommonListSelectionConsumer, CommonListSelectionProvider };

export default context;

