import React, { memo } from 'react';
import { bool } from 'prop-types';
import { MenuItem, IconMenu, IconButton } from 'material-ui';
import { NavigationArrowDropDown } from 'material-ui/svg-icons';

const TogglePinButton = ({ active, ...props }) => {
  const itemPrimaryText = active ? 'Unpin from the top' : 'Pin to the top';
  const itemValue = !active;
  const iconButtonElement = (
    <IconButton>
      <NavigationArrowDropDown />
    </IconButton>
  );

  return (
    <IconMenu
      iconButtonElement={iconButtonElement}
      value={active}
      {...props}
    >
      <MenuItem value={itemValue} primaryText={itemPrimaryText} />
    </IconMenu>
  );
};

TogglePinButton.propTypes = {
  active: bool.isRequired,
};

export default memo(TogglePinButton);
