import React, { PureComponent } from 'react';
import { string, bool, func } from 'prop-types';
import { FlatButton, Dialog } from 'material-ui';

const defaultTitle = (
  'Are you sure you want to send these applications to the submitter?'
);
const defaultText = (
  'Once these applications are sent to the submitter, '+
  'they will be marked "Submission_in_Progress" '+
  'and may not be editable by support'
);

export default class Confirmation extends PureComponent {
  static propTypes = {
    confirm: func.isRequired,
    cancel: func.isRequired,
    isOpen: bool.isRequired,
    title: string,
    text: string,
  };

  static defaultProps = {
    title: defaultTitle,
    text: defaultText,
  };

  render() {
    const { confirm, cancel, isOpen, title, text } = this.props;

    return (
      <Dialog
        title={title}
        actions={[
          <FlatButton
            label="Cancel"
            onClick={cancel}
          />,
          <FlatButton
            label="Send"
            primary
            onClick={confirm}
          />,
        ]}
        modal
        this={this}
        open={isOpen}
      >
        {text}
      </Dialog>
    );
  }
}
