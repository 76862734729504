import React from 'react';
import { Admin, Resource, Delete } from 'admin-on-rest';

import getMuiTheme from 'material-ui/styles/getMuiTheme';
import {
  ActionDashboard,
  SocialPerson,
  SocialPeople,
  ActionList,
} from 'material-ui/svg-icons';

import theme from './theme';
import './utils/requestQueryModifier';
import proxyRequests from './utils/proxyRequest';
import restClient from './api/restClient';
import authClient from './api/authClient';

import {
  StudentApplicationShow,
  StudentApplicationEdit,
} from './Applications/StudentApplications';
import {
  ParentApplicationsList,
  ParentApplicationShow,
  ParentApplicationEdit,
} from './Applications/ParentApplications';

import UserList from './Users/UserList';
import UserInvite from './Users/UserInvite';
import UserEdit from './Users/UserEdit';
import customRoutes from './customRoutes';
import LoginPage from './pages/LoginPage';
import Menu from './components/Menu';
import Layout from './Layout';
import customReducers from './customReducers';
import TeamLeadDashboard, {
  indexLink as teamLeadDashboardIndexLink,
} from './Dashboard/TeamLeadDashboard';
import MyDashboard, {
  indexLink as myDashboardIndexLink,
} from './Dashboard/MyDashboard';
import { CustomersList, CustomersApplicationShow } from './Customers';
import { MENU_GROUPS } from './constants';
import AdminPanel from './AdminPanel';
import {
  hasAnyPermissions,
  PERMISSION_ADMIN,
  PERMISSION_IMPERSONATE,
  PERMISSION_SUMMARY,
} from "./utils/permissions";
import StudentApplications, {indexLink} from "./Applications/StudentApplications/StudentApplicationsTabsAdmin";

proxyRequests({
  '/team-lead-dashboard': '/applications',
  '/my-dashboard': '/applications',
});

const App = () => (<Admin
      title="Back Office"
      restClient={restClient}
      authClient={authClient}
      customRoutes={customRoutes}
      customReducers={customReducers}
      loginPage={LoginPage}
      appLayout={Layout}
      menu={Menu}
      theme={getMuiTheme(theme)}
    >
      {permissions => {
          const hasAdminPermissions = permissions.includes(PERMISSION_ADMIN);
          const isTeamleadOrHigher = hasAnyPermissions([PERMISSION_ADMIN, PERMISSION_IMPERSONATE])

        return [
          <Resource
              name="applications"
              groupName={MENU_GROUPS.customersExperience}
              isLink={true}
              label="Student Applications"
              icon={SocialPerson}
              list={StudentApplications}
              link={indexLink()}
              show={StudentApplicationShow}
              edit={StudentApplicationEdit}
          />,
          <Resource
              name="customers"
              groupName={MENU_GROUPS.customersExperience}
              isLink={true}
              label="Customers"
              icon={ActionList}
              list={CustomersList}
              show={CustomersApplicationShow}
          />,
          <Resource
              name="parents-applications"
              groupName={MENU_GROUPS.customersExperience}
              isLink={true}
              label="Parent Applications"
              icon={SocialPeople}
              list={ParentApplicationsList}
              show={ParentApplicationShow}
              edit={permissions.includes(PERMISSION_SUMMARY) ? ParentApplicationEdit : null}
          />,
          <Resource
              name="my-dashboard"
              groupName={MENU_GROUPS.customersExperience}
              isLink={true}
              label="My Dashboard"
              icon={ActionDashboard}
              list={MyDashboard}
              link={myDashboardIndexLink()}
              show={StudentApplicationShow}
              edit={permissions.includes(PERMISSION_SUMMARY) ? StudentApplicationEdit : null}
          />,
          isTeamleadOrHigher ? (
              <Resource
                  name="team-lead-dashboard"
                  groupName={MENU_GROUPS.customersExperience}
                  isLink={true}
                  label="Team Lead Dashboard"
                  icon={ActionDashboard}
                  list={TeamLeadDashboard}
                  link={teamLeadDashboardIndexLink()}
                  show={StudentApplicationShow}
                  edit={permissions.includes(PERMISSION_SUMMARY) ? StudentApplicationEdit : null}
              />
          ) : null,
          hasAdminPermissions ? (
              <Resource
                  name="users"
                  groupName={MENU_GROUPS.cxLead}
                  isLink={true}
                  label="Backoffice Users"
                  icon={ActionList}
                  list={UserList}
                  create={UserInvite}
                  edit={UserEdit}
                  remove={Delete}
              />
          ) : null,
          hasAdminPermissions ? (
              <Resource
                  name="admin"
                  groupName={MENU_GROUPS.cxLead}
                  isLink={true}
                  label="Admin Panel"
                  icon={ActionList}
                  list={AdminPanel}
              />
          ) : null,
        ]
      }}
    </Admin>
)


export default App;
