import { typeNames } from './constants';
import { toStartOfDay, toEndOfDay } from './date';

const {
  LAST_DAY,
  LAST_WEEK,
  LAST_MONTH,
  LAST_YEAR,
} = typeNames;

const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;
const MILLISECONDS_PER_WEEK = MILLISECONDS_PER_DAY * 7;
const MILLISECONDS_PER_MONTH = MILLISECONDS_PER_DAY * 30;
const MILLISECONDS_PER_YEAR = MILLISECONDS_PER_DAY * 365;

const setCreator = diff => () => {
  const currentDate = new Date();

  return {
    from: toStartOfDay(new Date(currentDate - diff)),
    to: toEndOfDay(currentDate),
  };
};

const lastDaySet = setCreator(MILLISECONDS_PER_DAY);
const lastWeekSet = setCreator(MILLISECONDS_PER_WEEK);
const lastMonthSet = setCreator(MILLISECONDS_PER_MONTH);
const lastYearSet = setCreator(MILLISECONDS_PER_YEAR);

const rangeSets = {
  [LAST_DAY]: lastDaySet,
  [LAST_WEEK]: lastWeekSet,
  [LAST_MONTH]: lastMonthSet,
  [LAST_YEAR]: lastYearSet,
};

export {
  rangeSets,
};
