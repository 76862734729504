export const getFromLocalStorage = (key) => {
  try {
    return localStorage.getItem(key)
  } catch (error) {
    return null;
  }
};

export const setToStorage = (key, data) => {
  try {
    localStorage.setItem(key, data);
  } catch (error) {
    console.error(error)
  }
};

export const setToStorageData = (key, data) => {
  const stringifiedData = JSON.stringify(data);

  setToStorage(key, stringifiedData);
};

export const removeStorageData = (key) => {
  try {
    return localStorage.removeItem(key)
  } catch (error) {
    return null;
  }
};

export const getParsedDataFromStorage = key => {
  try {
    return JSON.parse(getFromLocalStorage(key));
  } catch (e) {
    return null;
  }
};
