import React from 'react';
import { compose } from 'ramda';
import { FlatButton } from 'material-ui';
import ActionUpdate from 'material-ui/svg-icons/action/update';
import { func, arrayOf, oneOfType, string, number, bool } from 'prop-types';
import {
  showNotification as showNotificationAction,
  refreshView as refreshViewAction,
} from 'admin-on-rest';
import { connect } from 'react-redux';

import withAccess from '../../../../../hocs/withAccess';
import { refreshFollowUps } from '../../../../../api/applications';
import { isEnabledSelector } from './selectors';
import {PERMISSION_REVIEW} from "../../../../../utils/permissions";

function RefreshFollowUps({
  isEnabled,
  selectedIds,
  showNotification,
  refreshView,
  resetSelectedIds
}) {
  const refreshApplications = async () => {
    const data = {
      applications: selectedIds
    };

    try {
      await refreshFollowUps(data);

      showNotification(
        `${selectedIds.length} application${selectedIds.length > 1 ? 's' : ''} follow up refreshed`
      );
    } catch(err) {
      showNotification('Refresh Failed!', 'warning');
    }

    refreshView();
    resetSelectedIds();
  }

  return (
    <FlatButton
      disabled={!isEnabled}
      onClick={refreshApplications}
      primary
      label="Refresh Follow Ups"
      icon={<ActionUpdate />}
      style={{ color: '#fff' }}
    />
  )
};

RefreshFollowUps.propTypes = {
  isEnabled: bool.isRequired,
  showNotification: func.isRequired,
  selectedIds: arrayOf(oneOfType([string, number])).isRequired,
  refreshView: func.isRequired,
  resetSelectedIds: func.isRequired,
};

const mapStateToProps = (state, props) => ({
  isEnabled: isEnabledSelector(state, props),
});

const mapDispatchToProps = {
  showNotification: showNotificationAction,
  refreshView: refreshViewAction,
};

export default compose(
  withAccess(PERMISSION_REVIEW),
  connect(mapStateToProps, mapDispatchToProps)
)(RefreshFollowUps);
