import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { shape } from 'prop-types';
import queryString from 'query-string';

export const SELECTED_TAB_QUERY_NAME = 'selected-tab';

export const extendUrlWithSelectedTabName = (url, name) => {
  const isUrlHasQueryParams = url.includes('?');
  const selectedTabQueryParam = queryString.stringify({
    [SELECTED_TAB_QUERY_NAME]: name,
  });

  const concatenationSymbol = isUrlHasQueryParams ? '&' : '?';

  return `${url}${concatenationSymbol}${selectedTabQueryParam}`;
};

export default (Component) => {
  class WithSelectedTabName extends PureComponent {
    static propTypes = {
      location: shape({
        query: shape()
      }).isRequired,
    };

    render() {
      const { search } = this.props.location;

      const query = queryString.parse(search);
      const selectedTabName = query[SELECTED_TAB_QUERY_NAME];

      const props = {
        ...this.props,
        selectedTabName,
      };

      return <Component {...props} />;
    }
  }

  return withRouter(WithSelectedTabName);
};
