import React, { PureComponent } from 'react';

import { string, arrayOf, node } from 'prop-types';

export default class ValuesList extends PureComponent {
  static propTypes = {
    label: string.isRequired,
    children: arrayOf(node),
    additionClass: string,
  };

  static defaultProps = {
    additionClass: '',
    children: []
  };

  render() {
    const { label, children, additionClass } = this.props;

    const processedLabel = children ? label : `${label}: - `;

    return (
      <div className={`value-list-container ${additionClass}`}>
        <div className="value-list-label">{processedLabel}</div>
        <div className={`${additionClass}-items`}> 
          {children}
        </div>
      </div>
    );
  }
}
