import { createSelector } from 'reselect';

export const paginationSelector = state => state.pagination;

export const applicationPerPageSelector = createSelector(
  [paginationSelector],
  pagination => pagination.applicationsPerPage
);

export const parentApplicationsPerPageSelector = createSelector(
  [paginationSelector],
  pagination => pagination.parentApplicationsPerPage
);
