import React, { PureComponent } from 'react';
import { number, string, oneOfType } from 'prop-types';
import { CommunicationComment } from 'material-ui/svg-icons';
import { FlatButton } from 'material-ui';

export default class CommentsButton extends PureComponent {
  static propTypes = {
    amount: oneOfType([number, string]),
  };

  static defaultProps = {
    amount: 0,
  };

  render() {
    const { amount, ...props } = this.props;

    return (
      <FlatButton
        label={amount || ''}
        primary
        icon={<CommunicationComment />}
        {...props}
      />
    );
  }
}
