import React, { PureComponent } from 'react';
import { func, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import FlatButton from 'material-ui/FlatButton';
import EmailIcon from 'material-ui/svg-icons/communication/email';
import { showNotification as showNotificationAction } from 'admin-on-rest';
import { push as pushAction } from 'react-router-redux';

class SendNotificationButton extends PureComponent {
  static propTypes = {
    push: func.isRequired,
    record: shape(),
    resource: string,
  };

  static defaultProps = {
    record: {},
    resource: '',
  };

  handleClick = () => {
    const { push, record } = this.props;
    push(`/applications/${record.id}/notification`);
  };

  render() {
    if (this.props.resource !== 'applications') {
      return null;
    }
    return (
      <FlatButton
        label="Send Notification"
        onClick={this.handleClick}
        primary={true}
        icon={<EmailIcon />}
      />
    );
  }
}

export default connect(null, {
  showNotification: showNotificationAction,
  push: pushAction,
})(SendNotificationButton);
