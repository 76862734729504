import React, { PureComponent } from 'react';
import { shape, string, func } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { parse, stringify } from 'query-string';

export default ({
                   queryPerPageParamName = 'perPage',
                   queryPageParamName = 'page',
                   defaultValue = 10,
                 } = {}) => (Component) => {
  class WithLocationPerPage extends PureComponent {
    static propTypes = {
      location: shape({
        search: string,
      }).isRequired,
      history: shape({
        push: func,
        replace: func,
      }).isRequired,
    };

    get locationPerPage() {
      const { location } = this.props;
      const { search } = location;

      const query = parse(search);

      return Number(query[queryPerPageParamName] || defaultValue);
    }

    changeLocationPerPage = (perPage, shouldReplaceUrl = false) => {
      const { location, history } = this.props;
      const { push, replace } = history;

      const parsed = parse(location.search);

      const stringifiedSearchParams = `?${stringify({
        ...parsed,
        [queryPageParamName]: 1,
        [queryPerPageParamName]: perPage,
      })}`;

      const isQueryChanged = location.search !== stringifiedSearchParams;

      if (!isQueryChanged) {
        return;
      }

      const url = `${location.pathname}${stringifiedSearchParams}`;

      if (shouldReplaceUrl) {
        replace(url);
        return;
      }

      push(url);
    };

    render() {
      const props = {
        ...this.props,
        locationPerPage: this.locationPerPage,
        changeLocationPerPage: this.changeLocationPerPage,
      };

      return <Component {...props} />;
    }
  }

  return withRouter(WithLocationPerPage);
};
