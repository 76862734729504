const STUDENT_APPLICATIONS = 'applications';
const PARENT_APPLICATIONS = 'parents-applications';
const TEAM_LEAD_DASHBOARD = 'team-lead-dashboard';
const MY_DASHBOARD = 'my-dashboard';

const IMPERSONATE_ABLE_RESOURCES = [
  STUDENT_APPLICATIONS,
  PARENT_APPLICATIONS,
  TEAM_LEAD_DASHBOARD,
  MY_DASHBOARD,
];

export {
  STUDENT_APPLICATIONS,
  PARENT_APPLICATIONS,
  TEAM_LEAD_DASHBOARD,
  MY_DASHBOARD,
  IMPERSONATE_ABLE_RESOURCES,
};
