import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { string } from 'prop-types';

const capitalize = (str) => (
  str.charAt(0).toUpperCase() + str.slice(1)
);

const normalizeValue = (value) => {
  const toLowerCaseValue = String(value).toLocaleLowerCase();
  const withReplacedSymbols = toLowerCaseValue.replace(/[_]/g, " ");

  return capitalize(withReplacedSymbols);
};

export default class ValuePresenter extends PureComponent {
  static propTypes = {
    label: string.isRequired,
    value: string,
    type: string,
    separator: string,
    prefix: string,
    additionalValueClassName: string,
    url: string,
  };

  static defaultProps = {
    type: 'text',
    separator: ':',
    prefix: '',
    value: '',
    additionalValueClassName: '',
    url: '',
  };

  getValueByType = (type, value) => {
    switch (type) {
      case 'phone':
        return value.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, "$1-$2-$3");
      case 'email':
      case 'address':
        return value;
      default:
        return normalizeValue(value);
    }
  };

  elementRender = () => {
    const { value, type, additionalValueClassName, url } = this.props;
    const processedValue = value ? this.getValueByType(type, value) : ' - ';

    switch (type) {
      case 'link':
        return (
          <Link
            to={url}
            target="_blank"
            className={additionalValueClassName}
          >
            {processedValue}
          </Link>);
      default:
        return (
          <span
            className={additionalValueClassName}
          >
            {processedValue}
          </span>);
    }
  };

  render() {
    const { label, separator, prefix } = this.props;

    return (
      <div className="value-presenter-container">
        {prefix && <span>{prefix} </span>}
        <span>{label}{separator} </span>
        {this.elementRender()}
      </div>
    );
  }
}
