import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { arrayOf, func, number } from 'prop-types';
import {
  refreshView as refreshViewAction,
  showNotification as showNotificationAction,
} from 'admin-on-rest';

import bulkUpdate from '../../api/applications';
import { getMessage, TYPE_MESSAGE } from './ActionsMessages';

export default Component => {
  class WithMenuActions extends PureComponent {
    static propTypes = {
      selectedIds: arrayOf(number),
      refreshView: func,
      resetSelectedIds: func,
      showNotification: func,
    };

    static defaultProps = {
      selectedIds: [],
      refreshView: () => {},
      resetSelectedIds: () => {},
      showNotification: () => {},
    };

    notifyUser = (value, action, typeMessage, typeNotification = '') => {
      const { selectedIds, showNotification } = this.props;
      const countUpdatedApplications = selectedIds.length;
      const message = getMessage(
        countUpdatedApplications,
        value,
        typeMessage,
        action,
      );

      return showNotification(message, typeNotification);
    };

    handleUpdate = async (fieldForUpdate, value, action) => {
      const { refreshView, resetSelectedIds, selectedIds } = this.props;
      const data = {
        applicationsIds: selectedIds,
        ...fieldForUpdate,
      };

      try {
        await bulkUpdate(data);
        refreshView();

        this.notifyUser(value, action, TYPE_MESSAGE.SUCCESS);
      } catch {
        this.notifyUser(value, action, TYPE_MESSAGE.ERROR, 'warning');
      }
      
      resetSelectedIds();
    };

    render() {
      return <Component handleUpdate={this.handleUpdate} {...this.props} />;
    }
  }

  const mapDispatchToProps = {
    refreshView: refreshViewAction,
    showNotification: showNotificationAction,
  };

  return connect(
    null,
    mapDispatchToProps,
  )(WithMenuActions);
};
