import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';

import { func, string } from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import colors from '../../constants/colors';
import { isNotNilOrEmpty } from '../../utils/helper';

const styles = {
  arrow: {
    fontSize: 30,
    fontWeight: 600,
    color: colors.navyBlue,
  },
  arrowButton: {
    height: 'unset',
    minWidth: 50,
  },
  taxAutomatedBox: {
    width: 76,
    height: '1em',
    flexShrink: 0,
    borderBottom: '1px solid #e0e0e0',
    textAlign: 'right',
    padding: '6px 12px 6px 0',
  },
  taxAutomated: {
    color: 'grey',
    fontWeight: 500,
  },
};

const CopyTaxAutomationValueButton = ({
  source, taxAutomationValue, value, setField,
}) => {
  const copyToRecord = () => {
    if (
      isNotNilOrEmpty(taxAutomationValue)
      && taxAutomationValue !== value
    ) {
      setField(source, taxAutomationValue);
    }
  };

  return (
  <Fragment>
    <FlatButton
      style={styles.arrowButton}
      onClick={copyToRecord}
    >
      <span style={styles.arrow}>
        &#8249;&#8249;
      </span>
    </FlatButton>
    <div  style={styles.taxAutomatedBox}> 
      <span  style={styles.taxAutomated}> 
        {taxAutomationValue} 
      </span>
    </div>
  </Fragment>
  );
};

CopyTaxAutomationValueButton.propTypes = {
  source: string.isRequired,
  taxAutomationValue: string,
  value: string,
  setField: func.isRequired,
};

CopyTaxAutomationValueButton.defaultProps = {
  taxAutomationValue: '',
  value: '',
};

const mapDispatchToProps = dispatch => ({
  setField: (fieldName, value) => dispatch(change('record-form', fieldName, value)),
});

export default connect(null, mapDispatchToProps)(CopyTaxAutomationValueButton);
