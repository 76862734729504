const white = '#fff';
const navyBlue = '#000a46';
const blueGrey = '#9e9ea0';
const jade = '#00b291';
const cobaltBlue = '#2755c4';
const lighterBlue = '#f7fdff';
const red = '#f93434';
const yellow = '#f1c906';
const lighterYellow = '#fffef8';
const lighterRed = '#fffbfe';
const lighterGreen = '#f8fffd';

export default {
  white,
  navyBlue,
  blueGrey,
  jade,
  cobaltBlue,
  lighterBlue,
  red,
  yellow,
  lighterYellow,
  lighterRed,
  lighterGreen,
};
