import { PureComponent } from 'react';
import { shape, func, oneOf } from 'prop-types';
import { either, isEmpty, isNil } from 'ramda';

import {GROUP_OPERATOR_FIELD, OPERATOR_FIELD, VALUE_FIELD} from '../constants'

const isEmptyValue = either(isNil, isEmpty);


const OR_OPERATOR = 'or';
const AND_OPERATOR = 'and';
const CONTAINS_OPERATOR = 'contains';
const OVERLAP_OPERATOR = 'overlap';

export {
  OR_OPERATOR,
  AND_OPERATOR,
  CONTAINS_OPERATOR,
  OVERLAP_OPERATOR,
};

export default class FilterWithOperator extends PureComponent {
  static propTypes = {
    operator: oneOf([OR_OPERATOR, AND_OPERATOR, CONTAINS_OPERATOR, OVERLAP_OPERATOR]).isRequired,
    children: func.isRequired,
    groupOperator: oneOf([OR_OPERATOR, AND_OPERATOR, CONTAINS_OPERATOR, OVERLAP_OPERATOR]),
    /* eslint-disable react/require-default-props */
    input: shape({
      onChange: func,
    }),
    /* eslint-enable react/require-default-props */
  };

  static defaultProps = {
    groupOperator: AND_OPERATOR,
  };

  onInputChange = (value) => {
    const { operator, groupOperator, input } = this.props;

    if (input && input.onChange) {
      const processedValue = (
        !isEmptyValue(value)
          ? ({
              [OPERATOR_FIELD]: operator,
              [GROUP_OPERATOR_FIELD]: groupOperator,
              [VALUE_FIELD]: value,
          })
          : null
      );

      input.onChange(processedValue);
    }
  };

  render() {
    const { children, input, ...props } = this.props;
    const processedProps = {
      ...props,
      input: {
        ...input,
        value: input && input.value && input.value[VALUE_FIELD],
        onChange: this.onInputChange,
      },
    };

    return children(processedProps);
  }
}
