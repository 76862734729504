import { PureComponent } from 'react';
import { func, shape } from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import {
  setToStorageData,
  getParsedDataFromStorage,
} from '../../services/localStorage';
import { synchronizeSeletor } from './selectors';
import { setInitialDataFromStorageAction } from './reducer';

const STORE = 'store';

class StoreSynchronizer extends PureComponent {
  static propTypes = {
    setInitialDataFromStorage: func.isRequired,
    dataToSync: shape({
      pagination: shape(),
    }).isRequired,
  };

  componentDidMount() {
    const dataFromStorage = getParsedDataFromStorage(STORE);

    if (!dataFromStorage) { return; }

    this.props.setInitialDataFromStorage(dataFromStorage)
  }

  componentDidUpdate( prevProps ) {
    if (prevProps.dataToSync !== this.props.dataToSync) {
      setToStorageData(STORE, this.props.dataToSync)
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  dataToSync: synchronizeSeletor(state),
});

const mapDispatchToProps = {
  setInitialDataFromStorage: setInitialDataFromStorageAction,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(StoreSynchronizer);
