import React from 'react';
import { shape, string } from 'prop-types';
import get from 'lodash.get';
import pure from 'recompose/pure';

const YesOrNothingBooleanField = ({source, record, elStyle}) =>
  <span style={elStyle}>{get(record, source) === true ? "YES" : ""}</span>;

YesOrNothingBooleanField.propTypes = {
  source: string.isRequired,
  elStyle: shape(),
  record: shape(),
};

YesOrNothingBooleanField.defaultProps = {
  elStyle: {},
  record: {},
};

const PureYesOrNothingBooleanField = pure(YesOrNothingBooleanField);

PureYesOrNothingBooleanField.defaultProps = {
  addLabel: true,
  elStyle: {
    display: 'block',
    margin: 'auto',
  },
};

export default PureYesOrNothingBooleanField;
