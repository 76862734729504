import React, { PureComponent } from 'react';
import { compose } from 'ramda';
import { func } from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import ActionWatchLater from 'material-ui/svg-icons/action/watch-later';

import withAccess from '../../../../../hocs/withAccess';
import withMenuActions from '../../../../../hocs/withMenuActions';
import {PERMISSION_REVIEW} from "../../../../../utils/permissions";

class WaitingForCustomer extends PureComponent {
  static propTypes = {
    handleUpdate: func,
  };

  static defaultProps = {
    handleUpdate: () => {},
  };

  onUpdate = () => {
    const { handleUpdate } = this.props;
    const field = {
      isWaitingForCustomer: true,
    };

    handleUpdate(field, 'Waiting for customer');
  };

  render() {
    return (
      <FlatButton
        onClick={this.onUpdate}
        primary
        label="Waiting for customer"
        icon={<ActionWatchLater />}
        style={{ color: '#fff' }}
      />
    );
  }
}

export default compose(
  withAccess(PERMISSION_REVIEW),
  withMenuActions,
)(WaitingForCustomer);
