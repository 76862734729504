const LAST_DAY = 'day';
const LAST_WEEK = 'week';
const LAST_MONTH = 'month';
const LAST_YEAR = 'year';

const typeNames = {
  LAST_DAY: 'day',
  LAST_WEEK: 'week',
  LAST_MONTH: 'month',
  LAST_YEAR: 'year',
};

const defaultLabels = {
  [LAST_DAY]: 'Last day',
  [LAST_WEEK]: 'Last week',
  [LAST_MONTH]: 'Last month',
  [LAST_YEAR]: 'Last year',
};

const types = Object.values(typeNames);

export {
  typeNames,
  defaultLabels,
  types,
};
