import React, { PureComponent } from 'react';
import {
  string,
  func,
  shape,
  oneOfType,
  arrayOf,
} from 'prop-types';

import sorter from '../../utils/sorter';

import { ICommentsList } from './propTypes';

import CommentsList from './CommentsList';
import CommentInput from './CommentInput';
import PinnedCommentsHolderComponent from './PinnedCommentsHolder';
import { SortButton } from '../buttons';

import './styles.scss';

const defaultSortOptions = [
  {
    label: 'Newest to Oldest',
    field: 'createdAt',
    direction: 'DESC',
  },
  {
    label: 'Oldest to Newest',
    field: 'createdAt',
    direction: 'ASC',
  }
];

export default class CommentsForm extends PureComponent {
  static propTypes = {
    commentRenderer: func.isRequired,
    pinnedCommentsHolderComponent: oneOfType([shape(), func]),
    comments: ICommentsList,
    submit: func,
    toggleCommentPin: func,
    sortOptions: arrayOf(shape({
      label: string,
      field: string,
      direction: string,
    })),
  };

  static defaultProps = {
    pinnedCommentsHolderComponent: PinnedCommentsHolderComponent,
    comments: [],
    submit: () => {},
    toggleCommentPin: () => {},
    sortOptions: defaultSortOptions,
  };

  state = { selectedSort: defaultSortOptions[1] };

  onSortChange = (evt, key, selectedSort) => {
    this.setState({ selectedSort });
  };

  get pinnedComments() {
    const { comments } = this.props;

    return comments.filter(({ pinned }) => pinned);
  }

  get pinnedCommentsView() {
    const { pinnedComments } = this;
    const { pinnedCommentsHolderComponent: PinnedCommentsHolder } = this.props;

    if (!pinnedComments.length || !PinnedCommentsHolder) {
      return null;
    }

    return (
      <PinnedCommentsHolder>
        <CommentsList
          items={pinnedComments}
          commentRenderer={this.commentRenderer}
        />
      </PinnedCommentsHolder>
    );
  }

  get allCommentsView() {
    const { comments } = this.props;
    const { selectedSort } = this.state;
    const { direction, field } = selectedSort;
    const sortedAllComments = sorter(comments, { field, direction });

    return (
      <CommentsList
        items={sortedAllComments}
        commentRenderer={this.commentRenderer}
      />
    );
  }

  get sortButton() {
    const { selectedSort } = this.state;
    const { sortOptions, comments } = this.props;

    if (!comments.length) {
      return null;
    }

    return (
      <SortButton
        options={sortOptions}
        onChange={this.onSortChange}
        value={selectedSort}
      />
    );
  }

  get commentsCount() {
    const { comments } = this.props;
    const commentsCount = comments && comments.length ? comments.length : 0;
    const customStyle = {
      paddingLeft: '25px',
      marginTop: '50px',
      marginBottom: '0px',
      fontSize: '20px'
    };
    if (commentsCount) {
      return (
        <h3 style={customStyle}>{commentsCount} comments</h3>
      );
    }
    return null;
  }

  commentRenderer = (commentData) => {
    const { toggleCommentPin, commentRenderer } = this.props;

    return commentRenderer({
      ...commentData,
      toggleCommentPin: () => toggleCommentPin(commentData.id, !commentData.pinned),
    });
  };

  render() {
    const { submit } = this.props;

    return (
      <div className="comments-form">
        <CommentInput submit={submit} />
        {this.pinnedCommentsView}
        {this.commentsCount}
        {/* {this.sortButton} */}
        {this.allCommentsView}
      </div>
    );
  }
}
