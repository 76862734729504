import React from 'react';
import { string, node, oneOfType } from 'prop-types';
import classNames from 'classnames';

import './styles.scss';

const FormGroupLabel = ({ title, description, className }) => {
  const formGroupLabelClassName = classNames('form-group-label', {
    [className]: className,
  });

  return (
    <div className={formGroupLabelClassName}>
      {title ? <div className="form-group-label__title">{title}</div> : null}
      {description ? <div className="form-group-label__description">{description}</div> : null}
    </div>
  );
};

FormGroupLabel.propTypes = {
  title: oneOfType([string, node]),
  description: oneOfType([string, node]),
  className: string,
};

FormGroupLabel.defaultProps = {
  title: '',
  description: '',
  className: '',
};

export default FormGroupLabel;
