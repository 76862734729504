import { extendUrlWithSelectedTabName } from '../hocs/withSelectedTabName';

const extendsUrlSettingsWithSelectedTabName = (urlsSettings) => (
  urlsSettings.map(({ url, name, ...data }) => ({
    ...data,
    name,
    url: extendUrlWithSelectedTabName(url, name)
  }))
);

export {
  extendsUrlSettingsWithSelectedTabName,
}
