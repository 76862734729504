import React, { PureComponent } from 'react';
import { arrayOf, shape, bool, func } from 'prop-types';
import { compose } from 'ramda';
import Popover from 'material-ui/Popover';
import FlatButton from 'material-ui/FlatButton';
import Menu from 'material-ui/Menu';
import SocialPerson from 'material-ui/svg-icons/social/person';

import withAccess from '../../../../../hocs/withAccess';
import withUsers from '../../../../../hocs/withUsers';
import withMenu from '../../../../../hocs/withMenu';
import MenuSearch from './MenuSearch';
import UsersListMenu from './UsersListMenu';
import getProcessedList from './selector';

import './style.scss';
import {PERMISSION_ASSIGN} from "../../../../../utils/permissions";

class AssignToButton extends PureComponent {
  static propTypes = {
    usersList: arrayOf(shape()),
    isOpen: bool.isRequired,
    anchorEl: shape(),
    toggle: func.isRequired,
    onRequestClose: func.isRequired,
  };

  static defaultProps = {
    usersList: [],
    anchorEl: {},
  };

  state = {
    searchValue: '',
  };

  onSearchChange = searchValue => {
    this.setState({ searchValue });
  };

  get items() {
    const { searchValue } = this.state;
    const { usersList } = this.props;
    const processedList = getProcessedList(usersList);

    return searchValue
      ? processedList.filter(item => item.label.includes(searchValue))
      : processedList;
  }

  handleRequestClose = () => {
    const { onRequestClose } = this.props;
    onRequestClose();
    this.resetSearch();
  };

  resetSearch = () => {
    this.setState({ searchValue: '' });
  };

  render() {
    const { isOpen, anchorEl, toggle } = this.props;

    return (
      <>
        <FlatButton
          onClick={toggle}
          primary
          label="Assign to"
          icon={<SocialPerson />}
          style={{ color: '#fff' }}
        />
        <Popover
          open={isOpen}
          anchorEl={anchorEl}
          onRequestClose={this.handleRequestClose}
        >
          <Menu>
            <MenuSearch
              key={String(isOpen)}
              onChange={this.onSearchChange}
              className="assign-to-search"
            />
            <UsersListMenu list={this.items} {...this.props} />
          </Menu>
        </Popover>
      </>
    );
  }
}

export default compose(
  withAccess(PERMISSION_ASSIGN),
  withUsers(),
  withMenu,
)(AssignToButton);
