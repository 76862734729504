import React, { PureComponent } from 'react';
import { string } from 'prop-types';

import EmptyState from '../../../../components/EmptyState';
import SummaryView from '../../../../components/SummaryView';
import { Tab } from '../../../../components/LocationTabs';

export default class SummaryTab extends PureComponent {
  static propTypes = {
    label: string.isRequired,
    tabName: string.isRequired,
  };

  renderEmptyState = () => (
    <EmptyState
      title="No information for current application"
    />
  );

  render() {
    const { label, tabName, ...props } = this.props;

    return (
      <Tab label={label} tabName={tabName} {...props}>
        <SummaryView {...props}/>
      </Tab>
    );
  }
}
