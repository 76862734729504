import React from 'react';
import { shape } from 'prop-types';
import SocialPeople from 'material-ui/svg-icons/social/people';

import { concatItems } from '../../../utils/helper';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  parentsInfo: {
    marginLeft: 12,
    fontWeight: '600',
    textTransform: 'capitalize',
  },
};

const getParentsInfo = ({
  parent1FirstName,
  parent1LastName,
  parent2FirstName,
  parent2LastName,
}) => {
  const parent1Info = concatItems({
    items: [parent1FirstName, parent1LastName],
  });
  const parent2Info = concatItems({
    items: [parent2FirstName, parent2LastName],
  });

  return concatItems({ items: [parent1Info, parent2Info], separator: ' and ' });
};

const ParentInfo = ({ record }) => {
  const parentsInfo = getParentsInfo(record);

  return parentsInfo ? (
    <div style={styles.container}>
      <SocialPeople />
      <span style={styles.parentsInfo}>{parentsInfo}</span>
    </div>
  ) : null;
};

ParentInfo.propTypes = {
  record: shape(),
};

ParentInfo.defaultProps = {
  record: {},
};

export default ParentInfo;
