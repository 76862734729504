export default {
  parentAdjustedGrossIncome:
    'Please convert from local currency to USD. Enter 0 if no value was reported.',
  parentEarningsFromWork:
    'Please convert from local currency to USD. Enter 0 if no value was reported.',
  parentIncomeTax:
    'Please convert from local currency to USD. Enter 0 if no value was reported.',
  parentIraDeductions:
    'Please convert from local currency to USD. Enter 0 if no value was reported.',
  parentTaxExemptInterestIncome:
    'Please convert from local currency to USD. Enter 0 if no value was reported.',
  parentUntaxedPortionsOfIRA:
    'Please convert from local currency to USD. Enter 0 if no value was reported.',
  parentUntaxedPortionsOfPensions:
    'Please convert from local currency to USD. Enter 0 if no value was reported.',
  otherParentEarningsFromWork:
    'Please convert from local currency to USD. Enter 0 if no value was reported.',
  parentCashBalance:
    'All the money in your accounts today. Do not include student financial aid.',
};
