import React, { PureComponent } from 'react';
import { shape, string, func, bool } from 'prop-types';
import compose from 'recompose/compose';

import numberScrollProtector from '../../../components/numberScrollProtector';
import { Card } from '../../CustomCard';
import withPermissions from '../../../hocs/withPermissions';
import APIUrls from '../../../Applications/APIUrls';
import TaxesForm from './TaxesForm';
import { STUDENT } from '../../../Applications/ApplicationType';

const getTitleTaxesCard = applicationType =>
  applicationType === STUDENT ? 'Student Taxes' : 'Parent Taxes';

class TaxForm extends PureComponent {
  static propTypes = {
    match: shape().isRequired,
    permissions: string.isRequired,
    record: shape(),
    contentCreator: func.isRequired,
    redirection: bool,
    applicationType: string,
    taxFormFor: string,
  };

  static defaultProps = {
    record: {},
    redirection: false,
    applicationType: STUDENT,
    taxFormFor: STUDENT,
  };

  render() {
    const { match, record, contentCreator, taxFormFor } = this.props;
    const { isValid, content } = contentCreator(record);

    if (!isValid) {
      return content;
    }

    return (
      <div className="card-container">
        <Card title="Attachments">
          <APIUrls
            key="backofficeData"
            source="backofficeData"
            listName={taxFormFor === 'PARENT' ? 'parentTaxFileUrls' : 'studentTaxFileUrls'}
            record={record}
          />
        </Card>
        <Card title={getTitleTaxesCard(taxFormFor)}>
          <TaxesForm
              content={content}
              id={match.params.id}
              {...this.props}
          />
        </Card>
      </div>
    );
  }
}

export default compose(
  numberScrollProtector,
  withPermissions,
)(TaxForm);
