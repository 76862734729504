import { isEmpty, difference, path } from 'ramda';

import studentFieldNames from './student-field-names';
import studentFieldNames2019 from './student-field-names-2019';
import parentFieldNames from './parent-field-names';
import parentFieldNames2019 from './parent-field-names-2019';
import studentTexts from './student-texts';
import studentTexts2019 from './student-texts-2019';
import studentTexts2020 from './student-texts-2020';
import parentTexts from './parent-texts';
import parentTexts2019 from './parent-texts-2019';
import parentTexts2020 from './parent-texts-2020';
import {
  tax2018ScheduleValues,
  taxSchedule1LinesValues,
  taxSchedule1LinesValues2019,
} from '../../../Applications/enumChoices';
import { isPositiveNumber, isZero } from '../../../utils/is';
import {
  stringToArray,
  isArraysEqual,
  excludeExtraUndefinedClientValue,
} from '../../../utils/array';

const has2018TaxYear = taxYear => taxYear === 2018;
const has2019TaxYear = taxYear => taxYear === 2019;
const has2020TaxYear = taxYear => taxYear === 2020;

const fieldsNames = {
  student: {
    2018: studentFieldNames,
    2019: studentFieldNames2019,
    2020: studentFieldNames2019,
  },
  parent: {
    2018: parentFieldNames,
    2019: parentFieldNames2019,
    2020: parentFieldNames2019,
  }
}

const texts = {
  student: {
    2018: studentTexts,
    2019: studentTexts2019,
    2020: studentTexts2020,
  },
  parent: {
    2018: parentTexts,
    2019: parentTexts2019,
    2020: parentTexts2020,
  }
}

const getFieldNames = (isStudent, taxYear) => { 
  if (isStudent) {
    return fieldsNames.student[taxYear];
  }

  return fieldsNames.parent[taxYear];
}

const getTexts = (isStudent, taxYear) => {
    if (isStudent) { 
      return texts.student[taxYear];
    }
    return texts.parent[taxYear];
};

const { TAX_SCHEDULE_1 } = tax2018ScheduleValues;
const { LINE_13, LINE_21 } = taxSchedule1LinesValues;
const {LINE_4, LINE_8 } = taxSchedule1LinesValues2019;

const isMainVisible = fieldNames => (record) => {
  if (!record) {
    return false;
  }

  const {
    taxScheduleFieldName,
    didFileSchedulesFieldName,
    schedule1MainLineInputNames,
  } = fieldNames;

  const isAllSchedule1MainTaxLinesZero = schedule1MainLineInputNames.every(schedule1LineInputName => (
    isZero(record[schedule1LineInputName])
  ));

  if (!isAllSchedule1MainTaxLinesZero) {
    return false;
  }

  const {
    [taxScheduleFieldName]: taxSchedule,
    [didFileSchedulesFieldName]: didFileAnySchedules,
  } = record;
  const selectedSchedules = stringToArray(taxSchedule, taxSchedule);
  const isSchedule1Selected = selectedSchedules.includes(TAX_SCHEDULE_1);

  return Boolean(didFileAnySchedules && isSchedule1Selected);
};

const isTaxLinesVisible = fieldNames => (record) => {
  if (!record || !isMainVisible(fieldNames)(record)) {
    return false;
  }

  const { backofficeData: { taxYear } } = record;

  const { taxSchedule1ChecksFieldName } = fieldNames;
  const { [taxSchedule1ChecksFieldName]: taxSchedule1Checks } = record;

  const rawSelectedTaxLines = stringToArray(taxSchedule1Checks, taxSchedule1Checks);
  let selectedTaxLines = excludeExtraUndefinedClientValue(rawSelectedTaxLines);
  if (selectedTaxLines === null) selectedTaxLines = [];

  const allowedTaxLines = has2018TaxYear(taxYear)
  ? [LINE_13, LINE_21]
  : [LINE_4, LINE_8];

  const notAllowedSelectedTaxLines = difference(selectedTaxLines, allowedTaxLines);

  return !notAllowedSelectedTaxLines.length;
};

const isAlaskaPermanentFundDividendSwitcherVisible = fieldNames => (record) => {
  if (!record || !isMainVisible(fieldNames)(record)) {
    return false;
  }

  const { backofficeData: { taxYear } } = record;

  const {
    taxSchedule1ChecksFieldName,
    taxSchedule1Line13FieldName,
  } = fieldNames;

  const {
    [taxSchedule1ChecksFieldName]: taxSchedule1Checks,
    [taxSchedule1Line13FieldName]: taxSchedule1Line13,
  } = record;

  if (!isTaxLinesVisible(record, fieldNames)) {
    return false;
  }

  const rawSelectedTaxLines = stringToArray(taxSchedule1Checks, taxSchedule1Checks);
  let selectedTaxLines = excludeExtraUndefinedClientValue(rawSelectedTaxLines);
  if (selectedTaxLines === null) selectedTaxLines = [];

  if (has2018TaxYear(taxYear)) {
    const isOnlyLine21Selected = isArraysEqual(selectedTaxLines, [LINE_21]);

    if (isOnlyLine21Selected) {
      return true;
    }
  
    const isLine13And21Selected = isArraysEqual(selectedTaxLines, [LINE_13, LINE_21]);
  
    return Boolean(
      isLine13And21Selected
      && (isPositiveNumber(taxSchedule1Line13) || isEmpty(taxSchedule1Line13)),
    );
  }

  if (has2019TaxYear(taxYear) || has2020TaxYear(taxYear)) {
    const isOnlyLine8Selected = isArraysEqual(selectedTaxLines, [LINE_8]);

    if (isOnlyLine8Selected) {
      return true;
    }

    const isLine4And8Selected = isArraysEqual(selectedTaxLines, [LINE_4, LINE_8]);

    return isLine4And8Selected;
  }

  return false;
};

const isDidFileSchedule1GainsOrLossesFromVirtualCurrencyVisible = fieldNames => (record) => {
  if (!record || !isMainVisible(fieldNames)(record)) {
    return false;
  }

  const { backofficeData: { taxYear } } = record;

  const {
    taxSchedule1ChecksFieldName,
  } = fieldNames;

  const {
    [taxSchedule1ChecksFieldName]: taxSchedule1Checks,
  } = record;

  if (!has2019TaxYear(taxYear) || !isTaxLinesVisible(record, fieldNames)) {
    return false;
  }

  const rawSelectedTaxLines = stringToArray(taxSchedule1Checks, taxSchedule1Checks);
  let selectedTaxLines = excludeExtraUndefinedClientValue(rawSelectedTaxLines);
  if (selectedTaxLines === null) selectedTaxLines = [];

  const isOnlyLine4Selected = isArraysEqual(selectedTaxLines, [LINE_4]);

  if (isOnlyLine4Selected) {
    return true;
  }

  const isLine4And8Selected = isArraysEqual(selectedTaxLines, [LINE_4, LINE_8]);

  return Boolean(
    isLine4And8Selected
  );
};

const getTaxYear = path(['backofficeData', 'taxYear']);


export {
  has2018TaxYear,
  has2019TaxYear,
  has2020TaxYear,
  getFieldNames,
  getTexts,
  isMainVisible,
  isTaxLinesVisible,
  isAlaskaPermanentFundDividendSwitcherVisible,
  isDidFileSchedule1GainsOrLossesFromVirtualCurrencyVisible,
  getTaxYear,
}