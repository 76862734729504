import React, {useEffect, useState} from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  email,
  required,
  RadioButtonGroupInput,
} from 'admin-on-rest';

import {getRoles} from "../api/users";

const UserCreateToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="Send invite" redirect={false} submitOnEnter={false} />
  </Toolbar>
);

const UserInvite = props => {
  const [roles, setRoles] = useState([]);

  useEffect(()=>{
    getRoles().then(res => {
      setRoles(res.data);
    });
  }, []);

  return (
      <Create {...props} title="Invite user">
        <SimpleForm toolbar={<UserCreateToolbar />}>
          <TextInput source="email" validate={[email, required]} />
          <RadioButtonGroupInput
              label="Roles"
              source="role"
              choices={roles}
          />
        </SimpleForm>
      </Create>
  );
}

export default UserInvite;
