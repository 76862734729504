import React, { PureComponent } from 'react';
import { shape, string, func, bool } from 'prop-types';

import { ClipboardButton } from '../../buttons';
import ToggleText from '../../ToggleText';
import Unfilled from '../../Unfilled';

import './styles.scss';

export default class SensitiveDataRow extends PureComponent {
  static propTypes = {
    source: string.isRequired,
    notEncrypted: bool,
    getData: func.isRequired,
    record: shape(),
    label: string,
    formatter: func,
    setSelectedRow: func.isRequired,
    isSelected: bool,
  };

  static defaultProps = {
    notEncrypted: false,
    record: {},
    label: '',
    formatter: v => v,
    isSelected: false,
  };

  state = {
    sensitiveData: null,
  };

  componentDidMount() {
    this.fetchSensitiveData();
  }

  fetchSensitiveData = async () => {
    try {
      const { getData, record, source, notEncrypted } = this.props;
      const sourceData = record[source];
      if (!sourceData) {
        return;
      } else if (notEncrypted) {
        this.setState({ sensitiveData: sourceData });
        return;
      } 
      const sensitiveData = await getData(source);
      this.setState({ sensitiveData });
    } catch (err) {
      console.error(err);
    }
  };

  isSensitiveDataExists = () => {
    const { sensitiveData } = this.state;

    return sensitiveData !== null;
  };

  sensitiveDataPresentation = () => {
    const { sensitiveData } = this.state;
    const { formatter, isSelected } = this.props;

    return this.isSensitiveDataExists() ? (
      <ToggleText active={isSelected} text={formatter(sensitiveData)} />
    ) : (
      <Unfilled />
    );
  };

  copyButton = () => {
    const { sensitiveData } = this.state;
    const { formatter, source, isSelected, setSelectedRow } = this.props;

    return this.isSensitiveDataExists() ? (
      <ClipboardButton
        copyData={formatter(sensitiveData)}
        source={source}
        setSelectedRow={setSelectedRow}
        active={isSelected}
        className="sensitive-data-row__button"
      />
    ) : null;
  };

  render() {
    const { label } = this.props;

    return (
      <div className="sensitive-data-row">
        <div className="sensitive-data-row__holder">
          <div className="sensitive-data-row__label">{label}</div>
          <div>{this.sensitiveDataPresentation()}</div>
        </div>
        {this.copyButton()}
      </div>
    );
  }
}
