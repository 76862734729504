import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { shape, arrayOf } from 'prop-types';
import { getResources } from 'admin-on-rest';

import { version as appVersion } from '../../../package.json';
import GroupMenu from './components/GroupMenu';
import { MENU_GROUPS } from '../../constants';
import { getResourcesByGroup } from '../../utils/menuHelpers';
import './styles.scss';

const version = <div className="sidebar-version">Backoffice {appVersion}</div>;

class Menu extends PureComponent {
  static propTypes = {
    resources: arrayOf(shape()),
  };

  static defaultProps = {
    resources: [],
  };

  render() {
    const { resources } = this.props;

    const itemsCustomerExp = getResourcesByGroup(
      resources,
      MENU_GROUPS.customersExperience,
    );
    const itemsCxLead = getResourcesByGroup(resources, MENU_GROUPS.cxLead);

    return (
      <div className="sidebar">
        <div className="sidebar-menu">
          {itemsCustomerExp.length > 0 && <GroupMenu title="Customer Experience" resources={itemsCustomerExp} />}
          {itemsCxLead.length > 0 && <GroupMenu title="CX Lead" resources={itemsCxLead} />}
          {version}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  resources: getResources(state),
});

export default connect(mapStateToProps)(Menu);
