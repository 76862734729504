import React, { memo } from 'react';
import { shape } from 'prop-types';
import { IColumns } from './propTypes';

const defaultRenderer = value => value;

const GridListRow = ({ columns, dataItem }) => {
  const cells = columns.map(({ field, styles = {}, renderer = defaultRenderer }) => (
    <div key={field} className="grid-list__cell" style={styles}>
      <div className="grid-list__cell__holder">
        {renderer(dataItem[field], field, dataItem)}
      </div>
    </div>
  ));

  return (
    <div className="grid-list__row">
      {cells}
    </div>
  );
};

GridListRow.propTypes = {
  columns: IColumns.isRequired,
  dataItem: shape().isRequired,
};

export default memo(GridListRow);
