import { NULL_VALUE } from '../constants';

const OPERATOR_FIELD = '$operator';
const GROUP_OPERATOR_FIELD = '$group';
const VALUE_FIELD = '$value';

export const createFilterQuery = ({ value, operator, group }) => ({
  [OPERATOR_FIELD]: operator,
  [GROUP_OPERATOR_FIELD]: group,
  [VALUE_FIELD]: value,
});

export const getUsersListWithUnassigned = (usersList) => ([
  { name: 'unassigned', value: NULL_VALUE },
  ...(usersList.map(user => ({ name: user.email, value: user.email })))
]);

const asciiMapping = {
  '.': '%2E',
};

const asciiSymbolsRegexp = new RegExp(`[${Object.keys(asciiMapping).join(',')}]`, 'gi');

export const encodeSymbolsInFilterNestedName = (filterKey) => (
  filterKey.replace(asciiSymbolsRegexp, (symbolToReplace) => asciiMapping[symbolToReplace])
);
