import queryString from 'query-string';
import { is, map } from 'ramda';

const getUrlQuery = (query) => queryString.parse(query);

const createUrl = ({ url = '', queryData = {}, defaults = {}}) => {
  const query = queryString.stringify({ ...queryData, ...defaults });

  return `${url}${query ? `?${query}` : ''}`;
};

const makeUrlCreator = defaults => urlData => (
  createUrl({ ...defaults, ...urlData })
);

export {
  createUrl,
  getUrlQuery,
  makeUrlCreator,
};

export const getApplicationShowUrl = (pathname, id) => `#${pathname}/${id}/show`;

export const escapeValue = value => `escapedValueStart__${JSON.stringify(value)}__escapedValueEnd`;

export const urlWithResolveValues = urlQuery => urlQuery.replace(/(%22escapedValueStart__)|(__escapedValueEnd%22)/g, '');

export const resolveEscapedValuesInUrlQuery = urlQuery =>  urlWithResolveValues(urlQuery);

export const escapeWithUrlEscaper = (valuesList) => (obj) => (
  map(
    (value) => {
      if (valuesList.includes(value)) {
        return escapeValue(null);
      }

      if (is(Object, value)) {
        return escapeWithUrlEscaper(valuesList)(value);
      }

      return value;
    },
    obj,
  )
);

export const escapeNull = escapeWithUrlEscaper([null]);
