import { createFilterQuery } from '../../services/filters';
import { DEFAULT_PER_PAGE_VAL, FRANK_STATUSES } from '../../constants';

import { makeUrlCreator, escapeNull } from '../../services/url';
import { extendsUrlSettingsWithSelectedTabName } from '../../utils/utils';

const GENERAL_QUEUE_NAME = 'GENERAL-QUEUE';
const IRS_VALIDATION_NAME = 'IRS-VALIDATION';
const ERROR_REVIEW_NAME = 'ERROR-REVIEW';
const ESCALATED_NAME = 'ESCALATED';


const filterPresets = {
  [GENERAL_QUEUE_NAME]: {
    frankStatus: createFilterQuery({
      operator: 'or',
      group: 'and',
      value: [FRANK_STATUSES.DATA_COMPLETE]
    }),
    assignedTo: createFilterQuery({
      operator: 'or',
      group: 'and',
      value: null
    }),
  },
  [IRS_VALIDATION_NAME]: {
    isPendingIRS: true,
    isPendingIRSForParent: true,
    assignedTo: createFilterQuery({
      operator: 'or',
      group: 'and',
      value: null
    }),
  },
  [ERROR_REVIEW_NAME]: {
    frankStatus: createFilterQuery({
      operator: 'or',
      group: 'and',
      value: [FRANK_STATUSES.ERROR]
    }),
    assignedTo: createFilterQuery({
      operator: 'or',
      group: 'and',
      value: null
    }),
  },
  [ESCALATED_NAME]: {
    escalated: true,
  },
};

const urlCreator = makeUrlCreator({
  url: 'team-lead-dashboard',
});

const linksData = (perPage = DEFAULT_PER_PAGE_VAL) => extendsUrlSettingsWithSelectedTabName([
  {
    label: 'General Queue',
    name: GENERAL_QUEUE_NAME,
    url: urlCreator({
      queryData: {
        filter: JSON.stringify(escapeNull(filterPresets[GENERAL_QUEUE_NAME])),
        perPage,
      }
    })
  },
  {
    label: 'IRS VALIDATION',
    name: IRS_VALIDATION_NAME,
    url: urlCreator({
      queryData: {
        filter: JSON.stringify(escapeNull(filterPresets[IRS_VALIDATION_NAME])),
        perPage,
      }
    }),
  },
  {
    label: 'ERROR REVIEW',
    name: ERROR_REVIEW_NAME,
    url: urlCreator({
      queryData: {
        filter: JSON.stringify(escapeNull(filterPresets[ERROR_REVIEW_NAME])),
        perPage,
      }
    }),
  },
  {
    label: 'ESCALATED',
    name: ESCALATED_NAME,
    url: urlCreator({
      queryData: {
        filter: JSON.stringify(escapeNull(filterPresets[ESCALATED_NAME])),
        perPage,
      }
    }),
  },
]);

const emptyStateMessages = {
  [GENERAL_QUEUE_NAME]: 'No new FAFSAs',
  [IRS_VALIDATION_NAME]: 'No FAFSAs with tax docs to validate',
  [ERROR_REVIEW_NAME]: 'No errors to review. Congrats!',
  [ESCALATED_NAME]: 'Here aren\'t any FAFSAs currently escalated',
};

const indexLink = (perPage) => linksData(perPage)[0].url;

export {
  emptyStateMessages,
  filterPresets,
  linksData,
  indexLink,
}
