import React from 'react';
import { string } from 'prop-types';
import { IconMenu, IconButton } from 'material-ui';

import Logout from '../Logout';
import Avatar from '../Avatar';
import withUserName from '../../hocs/withUserName';

const styles = {
  menu: {
    minWidth: 155,
  },
  button: {
    padding: 0,
    width: 30,
    height: 30,
  },
  origin: {
    horizontal: 'left',
    vertical: 'bottom',
  },
};

const UserIconMenu = ({ userName }) => (
  <IconMenu
    iconButtonElement={
      <IconButton style={styles.button}>
        <Avatar size={30} userName={userName} />
      </IconButton>
    }
    targetOrigin={styles.origin}
    menuStyle={styles.menu}
  >
    <Logout />
  </IconMenu>
);

UserIconMenu.propTypes = {
  userName: string,
};

UserIconMenu.defaultProps = {
  userName: '',
};

export default withUserName(UserIconMenu);
