import React from 'react';
import { node } from 'prop-types';

import './styles.scss';

const PinnedCommentsHolder = ({ children }) => (
  <div className="pinned-comments-holder">
    <span className="pinned-comments-holder__title">Pinned comment</span>
    {children}
  </div>
);

PinnedCommentsHolder.propTypes = {
  children: node,
};

PinnedCommentsHolder.defaultProps = {
  children: null,
};

export default PinnedCommentsHolder;
