import React from 'react';
import { string } from 'prop-types';

const NotificationMessagePreview = ({ userMessage, englishMessage }) =>
  userMessage === englishMessage ? (
    <div>
      <h4>User will get this:</h4> <div>{userMessage}</div>
    </div>
  ) : (
    <div>
      <div>
        <h4>Message (English):</h4> {englishMessage}
      </div>
      <div>
        <h4>User will get this:</h4> {userMessage}
      </div>
    </div>
  );

NotificationMessagePreview.propTypes = {
  userMessage: string,
  englishMessage: string,
};

NotificationMessagePreview.defaultProps = {
  userMessage: '',
  englishMessage: '',
};

export default NotificationMessagePreview;
