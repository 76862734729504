export const labels = {
  submitCompletedApplications: 'Submit completed applications',
  submitFAFSA: 'Click submit at FAFSA.gov',
  schoolRecommended: 'Recommend Schools',
  sqs: 'Send to SQS queue',
  sqsRead: 'Should process submitter SUBMIT jobs',
  sqsProcess: 'Should process submitter CHECK_STATUS jobs',
  maintenanceMode: 'Maintenance Mode',
  maintenanceNotification: 'Maintenance Notification',
  timeWindowStatus: 'CHECK_STATUS process window',
  timeWindowSubmit: 'SUBMIT applications process window',
};

export const helperText = {
  submitCompletedApplications:
    '“Data Complete”applications will be automatically submitted',
  submitFAFSA:
    'All applications will be submitted on FAFSA.gov without ability for manual review',
};
