import React, { PureComponent } from 'react';
import { number, func } from 'prop-types';
import TextField from 'material-ui/TextField';
import { FlatButton } from 'material-ui';

export default class PageInput extends PureComponent {
  static propTypes = {
    onChange: func.isRequired,
    value: number.isRequired,
    maxPage: number.isRequired,
  };

  state = { value: '' };

  componentDidMount() {
    this.updateLocalValue(this.props.value);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.updateLocalValue(this.props.value);
    }
  }

  onChange = (e) => {
    const { value } = e.target;
    const isValueNumber = !Number.isNaN(value);
    const isValidInputValue = (
      value === '' ||
      (isValueNumber && this.isValueInRange(value))
    );

    if (isValidInputValue) {
      this.updateLocalValue(value);
    }
  };

  isValueInRange = (value) => {
    const { maxPage } = this.props;

    return value > 0 && value <= maxPage;
  };

  updateLocalValue = (value) => {
    this.setState({ value });
  };

  goToPage = () => {
    const { value } = this.state;
    const { onChange } = this.props;
    const valueAsNumber = Number(value);
    const isValueChanged = valueAsNumber !== this.props.value;

    if (!isValueChanged || !this.isValueInRange(value)) {
      return;
    }

    onChange(valueAsNumber);
  };

  submit = (e) => {
    e.preventDefault();

    this.goToPage();
  };

  render() {
    const { value } = this.state;
    return (
      <form onSubmit={this.submit}>
        <FlatButton
          type="submit"
          primary
          style={{ marginRight: 10 }}
          onClick={this.goToPage}
        >Go to page</FlatButton>
        <TextField name="pageNumber" type="number" value={value} onChange={this.onChange} style={{ width: 50 }} />      
      </form>
    );
  }
}

