import React from 'react';
import { FlatButton } from 'material-ui';
import PersonIcon from 'material-ui/svg-icons/social/person';

import { IMPERSONATE_ABLE_RESOURCES } from '../constants/resources';

const impersonateButton = (props) => (
  <FlatButton
    icon={<PersonIcon />}
    primary={true}
    label="Impersonate"
    target="_blank"
    {...props}
  />
);

export default ({ resource, record }) => {
  if (!IMPERSONATE_ABLE_RESOURCES.includes(resource)) {
    return impersonateButton({ disabled: true, title: `Not supported for resource of type "${resource}"` });
  }
  if (!record || !record.backofficeData || !record.backofficeData.impersonateUrl) {
    return impersonateButton({ disabled: true, title: 'Impersonation URL not available' });
  }
  const { backofficeData: { impersonateUrl } } = record;
  return impersonateButton({ href: impersonateUrl });
};
