import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { shape, func } from 'prop-types';
import { DatePicker, TimePicker, RaisedButton } from 'material-ui';

import Toggle from '../../../components/Toggle';
import { labels } from '../../helpers';
import { changeMaintenanceSetting } from '../../../api/maintenance';
import { updateMaintenanceSetting as updateMaintenanceSettingAction } from '../../reducer';
import { DATE_FORMAT_6 } from '../../../constants/date-formats';
import { date } from '../../../utils/formatter';
import colors from '../../../constants/colors';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 60,
  },
  label: {
    marginRight: 60,
    fontSize: 14,
    fontWeight: '600',
    color: colors.navyBlue,
  },
  field: {
    width: 150,
    marginRight: 20,
  },
};

class Maintenance extends PureComponent {
  static propTypes = {
    setting: shape(),
    updateMaintenanceSetting: func,
  };

  static defaultProps = {
    setting: {},
    updateMaintenanceSetting: () => {},
  };

  onToggle = async () => {
    const { setting, updateMaintenanceSetting } = this.props;
    const maintenanceMode = !setting.maintenanceMode;
    const maintenanceAt = maintenanceMode ? setting.maintenanceAt : null;

    const data = await changeMaintenanceSetting({
      ...setting,
      maintenanceAt,
      maintenanceMode,
    });

    updateMaintenanceSetting(data);
  };

  onChange = (e, value) => {
    const { setting, updateMaintenanceSetting } = this.props;

    updateMaintenanceSetting({
      ...setting,
      maintenanceAt: value,
    });
  };

  onWarnClick = async () => {
    const { setting, updateMaintenanceSetting } = this.props;

    const data = await changeMaintenanceSetting(setting);

    updateMaintenanceSetting(data);
  };

  formatDate = value => date(value, DATE_FORMAT_6);

  render() {
    const { setting } = this.props;
    const maintenanceDate = setting.maintenanceAt && new Date(setting.maintenanceAt);

    return (
      <>
        <div style={styles.container}>
          <span style={styles.label}>{labels.maintenanceNotification}</span>
          <DatePicker
            hintText="Notification Date"
            textFieldStyle={styles.field}
            formatDate={this.formatDate}
            value={maintenanceDate}
            onChange={this.onChange}
          />
          <TimePicker
            hintText="Notification Time"
            textFieldStyle={styles.field}
            value={maintenanceDate}
            onChange={this.onChange}
          />
          <RaisedButton
            type="submit"
            primary
            label="Warn"
            onClick={this.onWarnClick}
          />
        </div>
        <Toggle
          label={labels.maintenanceMode}
          value={setting.maintenanceMode}
          onToggle={this.onToggle}
        />
      </>
    );
  }
}

const mapDispatchToProps = {
  updateMaintenanceSetting: updateMaintenanceSettingAction,
};

export default connect(
  null,
  mapDispatchToProps,
)(Maintenance);
