import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { string, shape, func } from 'prop-types';
import Toggle from '../../../components/Toggle';

import { updateSubmitterSettings as updateSubmitterSettingsAction } from '../../reducer';
import { changeSubmitterSettings } from '../../../api/submitter';

class SubmitterSettingsToggle extends PureComponent {
  static propTypes = {
    setting: shape(),
    updateSubmitterSettings: func,
    label: string.isRequired,
    helperText: string,
    containerStyle: shape(),
  };

  static defaultProps = {
    setting: {},
    updateSubmitterSettings: () => {},
    helperText: '',
    containerStyle: {},
  };

  onToggle = async () => {
    const { setting, updateSubmitterSettings } = this.props;

    const data = await changeSubmitterSettings({
      ...setting,
      value: `${!(setting.value === 'true')}`,
    });

    updateSubmitterSettings({ [setting.setting]: data });
  };

  render() {
    const { setting, label, helperText, containerStyle } = this.props;

    return (
      <Toggle
        label={label}
        value={(setting.value === 'true')}
        onToggle={this.onToggle}
        helperText={helperText}
        containerStyle={containerStyle}
      />
    );
  }
}

const mapDispatchToProps = {
  updateSubmitterSettings: updateSubmitterSettingsAction,
};

export default connect(
  null,
  mapDispatchToProps,
)(SubmitterSettingsToggle);
