import {
  cyan500,
  grey100,
  grey300,
  grey400,
  grey500,
  white,
  darkBlack,
  fullBlack
} from 'material-ui/styles/colors';
import { fade } from 'material-ui/utils/colorManipulator';
import spacing from 'material-ui/styles/spacing';
import getSettingsOfHost from './utils/settingsOfHost';

const settingsOfHost = getSettingsOfHost();
const frankPrimary700 = '#2755C4';
const frankPrimary500 = '#000a46';
const tableHighlightColor = '#e9efff';

export default {
  spacing,
  borderRadius: 2,
  fontFamily: 'Graphik Web, sans-serif',
  palette: {
    primary1Color: frankPrimary500,
    primary2Color: frankPrimary700,
    primary3Color: grey400,
    accent1Color: settingsOfHost.borderColor,
    accent2Color: grey100,
    accent3Color: grey500,
    textColor: frankPrimary500,
    secondaryTextColor: fade(darkBlack, 0.54),
    alternateTextColor: white,
    canvasColor: white,
    borderColor: grey300,
    disabledColor: fade(darkBlack, 0.3),
    pickerHeaderColor: cyan500,
    clockCircleColor: fade(darkBlack, 0.07),
    shadowColor: fullBlack
  },
  checkbox: {
    boxColor: frankPrimary700,
    checkedColor: frankPrimary700,
  },
  table: {
    backgroundColor: 'white',
  },
  button: {
    textTransform: 'none',
  },
  raisedButton: {
    primaryColor: '#2755C4',
  },  
  tableRow: {
    hoverColor: tableHighlightColor,
    selectedColor: tableHighlightColor,
  },
  menuItem: {
    selectedTextColor: frankPrimary700,
  },
};