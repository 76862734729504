import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS,
  showNotification,
} from 'admin-on-rest';

import { post } from './api';
import {
  updateLocalStorageAuthData,
  clearLocalStorageAuthData,
  getToken,
  getPermissions,
} from './localAuthStorage';

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    return post('/auth/login', { email: username, password })
      .then(({ token }) => {
        updateLocalStorageAuthData(token, username);
      })
      .catch(error => {
        showNotification(error.message || 'Unkown error');
        throw error;
      });
  } else if (type === AUTH_LOGOUT) {
    clearLocalStorageAuthData();
    return Promise.resolve();
  } else if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      return Promise.reject();
    }
    return Promise.resolve();
  } else if (type === AUTH_CHECK) {
    return getToken() ? Promise.resolve() : Promise.reject();
  } else if (type === AUTH_GET_PERMISSIONS) {
    return Promise.resolve(getPermissions() || []);
  }
  return Promise.reject(new Error('Unknown method'));
};
