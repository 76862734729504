import { resolveEscapedValuesInUrlQuery } from '../services/url';

const originalFetch = window.fetch;

const requestQueryModifier = () => {
  window.fetch = ( url, ...opts ) => {
    const urlData = new URL(url);
    const { search } = urlData;

    urlData.search = resolveEscapedValuesInUrlQuery(search);

    const processedUrl = urlData.toString();

    return originalFetch( processedUrl, ...opts );
  };
};

requestQueryModifier();
