import React, { PureComponent } from 'react';
import { shape, func, string, bool, oneOfType, number } from 'prop-types';

import { isFunc } from '../utils/helper';
import CopyTaxAutomationValueButton from './buttons/CopyTaxAutomationValueButton'

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  altContainer: {
    display: 'inline-block',
    marginTop: 20,
    alignItems: 'center',
    position: 'relative',
  },
  price: {
    display: 'flex',
    alignItems: 'center',
  },
  dollarSymbol: {
    fontWeight: '600',
  },
  helperText: {
    fontSize: 14,
    marginTop: 10,
    color: '#1b1b1b',
    width: 60,
  },
  altHelperText: {
    fontSize: 14,
    marginTop: 10,
    color: '#1b1b1b',
    width: 'auto',
  },
  label: {
    fontWeight: '600',
    width: 30,
    flexShrink: 0,
  },
  description: {
    position: 'absolute',
    left: 390,
    width: 500,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#1b1b1b',
  },
};

const getLabelValue = (labelStringOrFunc, record) => {
  if (isFunc(labelStringOrFunc)) return labelStringOrFunc(record);
  return labelStringOrFunc;
}

const withLabelAndHelperText = WrappedComponent => {
  class WithLabelAndHelperText extends PureComponent {
    static propTypes = {
      label: oneOfType([string, func]),
      description: string,
      helperText: string,
      taxAutomationValue: oneOfType([string, number]),
      addField: bool,
      group: bool,
      record: shape(),
      altFieldStyles: bool,
    };

    static defaultProps = {
      helperText: '',
      taxAutomationValue: '',
      label: () => {},
      description: '',
      addField: true,
      group: false,
      record: {},
      altFieldStyles: false,
    };

    render() {
      const {
        label, description, group, record, helperText, taxAutomationValue, altFieldStyles, ...props
      } = this.props;
      const { source } = props;
      const labelValue = label && getLabelValue(label, record);

      return (
        <div style={altFieldStyles ? styles.altContainer : styles.container}>
          <span style={styles.label}>{labelValue || ''}</span>
          <div style={altFieldStyles ? styles.altHelperText : styles.helperText}>{helperText || ''}</div>
          <div style={styles.price}>
            <span style={styles.dollarSymbol}>$</span>
            <WrappedComponent label="" record={record} {...props} />
            <span>.00</span>
          </div>
          {group &&
            <CopyTaxAutomationValueButton
              source={source}
              taxAutomationValue={`${taxAutomationValue}`}
              value={`${record[source]}`}
            />
          }
          {description ? <div style={styles.description}>{description}</div> : null}

        </div>
      );
    }
  }

  WithLabelAndHelperText.defaultProps = { addField: true };

  return WithLabelAndHelperText;
};

export default withLabelAndHelperText;
