import React from 'react';
import Link from 'react-router-dom/es/Link';
import { shape, string, number } from 'prop-types';
import EmptyState from '../../components/EmptyState';

const getApplicationText = ({ id, applicationYear } = {}) => (
  `Application for ${applicationYear} year (application id: ${id})`
);

const getApplicationRoute = (basePath, id) => `${basePath}/${id}/show`;

const ApplicationsLinks = ({ applicationsList = [], basePath }) => {
  if (!applicationsList.length) {
    return (
      <EmptyState
        title="No applications for current customer"
      />);
  }

  return applicationsList.map(application => (
    <Link
      key={application.id}
      className="customer-application-link"
      to={getApplicationRoute(basePath, application.id)}
    >
      {getApplicationText(application)}
    </Link>
  ));
};

ApplicationsLinks.propTypes = {
  basePath: string,
  record: shape({
    id: number,
  }),
};

ApplicationsLinks.defaultProps = {
  record: {},
  basePath: '',
};

export default ApplicationsLinks;
