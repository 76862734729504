const SET_SUBMITTER_SETTINGS = 'SET_SUBMITTER_SETTINGS';
const UPDATE_SUBMITTER_SETTINGS = 'UPDATE_SUBMITTER_SETTINGS';
const SET_MAINTENANCE_SETTING = 'SET_MAINTENANCE_SETTING';
const UPDATE_MAINTENANCE_SETTING = 'UPDATE_MAINTENANCE_SETTING';

export const setSubmitterSettings = data => ({
  type: SET_SUBMITTER_SETTINGS,
  payload: data,
});

export const updateSubmitterSettings = setting => ({
  type: UPDATE_SUBMITTER_SETTINGS,
  payload: setting,
});

export const setMaintenanceSetting = data => ({
  type: SET_MAINTENANCE_SETTING,
  payload: data,
});

export const updateMaintenanceSetting = setting => ({
  type: UPDATE_MAINTENANCE_SETTING,
  payload: setting,
});

const INIT = {
  submitterSettings: {},
  maintenanceSetting: {},
};

export default (state = INIT, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_SUBMITTER_SETTINGS:
      return { ...state, submitterSettings: payload };
    case UPDATE_SUBMITTER_SETTINGS:
      return {
        ...state,
        submitterSettings: { ...state.submitterSettings, ...payload },
      };
    case SET_MAINTENANCE_SETTING:
      return { ...state, maintenanceSetting: payload };
    case UPDATE_MAINTENANCE_SETTING:
      return {
        ...state,
        maintenanceSetting: { ...state.maintenanceSetting, ...payload },
      };
    default:
      return state;
  }
};
