// NOTE: We have generally 3 types of variables in our DB to store application.
// string, boolean and number
// We are trying to cover default values here for these fields when user haven't enter the values yet.

// Falsy value collection when user have not entered it yet.
// We can not include true or false here as it can be a valid value for boolean fields.
const falsyValues = [null, 'false', ''];

// Keeping the order of fields from this Google Doc so it will be easy to verify any update.
// https://docs.google.com/spreadsheets/d/1RPqL9Tl5OKQb8zh5SX6e0rfIW6nNtmuiDFjx-P9ja6U/edit#gid=1000726271
const defaultAnswers = {
  'user.firstName': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'user.lastName': { 'originalValue': [...falsyValues], 'defaultValue': '', },

  'user.email': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'user.phone': { 'originalValue': [...falsyValues], 'defaultValue': '', },

  'addressStreetAndNumber': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'homeAddressCity': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'homeAddressState': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'homeAddressZipCode': { 'originalValue': [...falsyValues], 'defaultValue': '', },

  'livedInHomeAddressStateAtLeastFiveYears': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'legalStateOfResidence': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'movedToStateOfResidenceMonth': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'movedToStateOfResidenceYear': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'isCitizen': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'immigrationStatus': { 'originalValue': [...falsyValues], 'defaultValue': '', },

  'highSchoolCompletion': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'plannedDegree': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  // TODO: Need confirmation from Ops team for this.
  'willHaveFirstBachelorsBeforeSchoolYear': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'collegeGradeLevel': { 'originalValue': [...falsyValues], 'defaultValue': '', },

  'gender': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'gavePermissionToRegisterForSelectiveServices': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'reasonForNotRegisteringForSelectiveServices': { 'originalValue': [...falsyValues], 'defaultValue': '', },

  'isFosterIndependentStudent': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'highestSchoolCompletedByParent1': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'highestSchoolCompletedByParent2': { 'originalValue': [...falsyValues], 'defaultValue': '', },

  'hadFinancialAidBefore': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'haveYouBeenConvictedForIlegalDrugs': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'haveYourFederalDrugRelatedBeenRemoved': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'didPossessingDrugsOccurDuringStudentAid': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'haveYouCompletedADrugRehabilitationProgram': { 'originalValue': [...falsyValues], 'defaultValue': '', },

  'highSchoolName': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'highSchoolCity': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'highSchoolState': { 'originalValue': [...falsyValues], 'defaultValue': '', },

  'collegeChoices': { 'originalValue': [...falsyValues], 'defaultValue': [], },

  'studentMaritalStatus': { 'originalValue': [...falsyValues], 'defaultValue': 'Single' },
  'studentMaritalStatusMonth': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'studentMaritalStatusYear': { 'originalValue': [...falsyValues], 'defaultValue': '', },

  'hasChildrenGettingSupport': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'supportedChildren': { 'originalValue': [...falsyValues], 'defaultValue': 0, },
  'childrenInCollege': { 'originalValue': [...falsyValues], 'defaultValue': 0, },
  'hasDependentsGettingSupport': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'otherSupportedChildren': { 'originalValue': [...falsyValues], 'defaultValue': 0, },
  'otherChildrenInCollege': { 'originalValue': [...falsyValues], 'defaultValue': 0, },
  'spouseGoingToCollege': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'numOfPeopleGoingToCollege': { 'originalValue': [...falsyValues], 'defaultValue': 1, },

  'isServingOrServedInArmy': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'armyServiceType': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'dependentChecklistBothParentsAreDeceased': { 'originalValue': [...falsyValues], 'defaultValue': 'No', },
  'dependentChecklistIsFoster': { 'originalValue': [...falsyValues], 'defaultValue': 'No', },
  'dependentChecklistIsDependentOrWardOfCourt': { 'originalValue': [...falsyValues], 'defaultValue': 'No', },
  'dependentChecklistIsEmancipatedMinor': { 'originalValue': [...falsyValues], 'defaultValue': 'No', },
  'dependentChecklistHasLegalGuardianNotAParent': { 'originalValue': [...falsyValues], 'defaultValue': 'No', },
  'legalGuardianAppointedByCourt': { 'originalValue': [...falsyValues], 'defaultValue': 'No', },
  'dependentChecklistIsNoneOfTheAbove': { 'originalValue': [...falsyValues], 'defaultValue': 'No', },

  'dependentChecklistIsHomelessOrAtRiskOfBecomingHomeless': { 'originalValue': [...falsyValues], 'defaultValue': 'No', },
  'declaredHomelessByHighSchool': { 'originalValue': [...falsyValues], 'defaultValue': 'No', },
  'declaredHomelessByHUD': { 'originalValue': [...falsyValues], 'defaultValue': 'No', },
  'declaredHomelessByBasicCenter': { 'originalValue': [...falsyValues], 'defaultValue': 'No', },

  'parentsMaritalStatus': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'parentsMaritalStatusMonth': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'parentsMaritalStatusYear': { 'originalValue': [...falsyValues], 'defaultValue': '', },

  'parent1FirstName': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'parent1LastName': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'parentType': { 'originalValue': [...falsyValues], 'defaultValue': '', },

  'parent2FirstName': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'parent2LastName': { 'originalValue': [...falsyValues], 'defaultValue': '', },

  'parentLivedInHomeAddressStateAtLeastFiveYears': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'parentLegalStateOfResidence': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'parentMovedToStateOfResidenceMonth': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'parentMovedToStateOfResidenceYear': { 'originalValue': [...falsyValues], 'defaultValue': '', },

  'howManyChildrenSupportedByParent': { 'originalValue': [...falsyValues], 'defaultValue': 0, },
  'howManyOtherSupportedByParent': { 'originalValue': [...falsyValues], 'defaultValue': 0, },
  'parentNumOfPeopleGoingToCollege': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  


  'didParentCompleteTaxReturn': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'parentEarningsFromWorkNoTaxReturn': { 'originalValue': [...falsyValues], 'defaultValue': 0, },
  'otherParentEarningsFromWorkNoTaxReturn': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  
  'parentTaxReturnType': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'parentTaxFilingStatus': { 'originalValue': [...falsyValues], 'defaultValue': '', },  

  'parentAdjustedGrossIncome': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  

  'parentDislocatedWorker': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'didParentFileSchedule1': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'parentBenefitMedicaid': { 'originalValue': [...falsyValues], 'defaultValue': 'No', },
  'parentBenefitSSI': { 'originalValue': [...falsyValues], 'defaultValue': 'No', },
  'parentBenefitSNAP': { 'originalValue': [...falsyValues], 'defaultValue': 'No', },
  'parentBenefitLunch': { 'originalValue': [...falsyValues], 'defaultValue': 'No', },
  'parentBenefitTANF': { 'originalValue': [...falsyValues], 'defaultValue': 'No', },
  'parentBenefitWIC': { 'originalValue': [...falsyValues], 'defaultValue': 'No', },

  'parentEarningsFromWork': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  

  'parentIncomeTax': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  

  'parentCombatPayAmount': { 'originalValue': [...falsyValues], 'defaultValue': 0, },
  'parentCollegeGrantAmount': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  
  'parentTaxLine50': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  
  'parentUntaxedPortionsOfIRA': { 'originalValue': [...falsyValues], 'defaultValue': 0, },
  'parentIraDeductions': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  
  'parentTaxExemptInterestIncome': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  

  'parentChildSupportPaidAmount': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  
  'parentEarningsFromCoopWork': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  
  'parentEarningsFromEmploymentPrograms': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  

  'parentChildSupportReceivedAmount': { 'originalValue': [...falsyValues], 'defaultValue': 0, },
  'parentAllowancesAmount': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  
  'parentAmountSavedInPensions': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  
  'parentVeteranNoneducationBenefitsAmount': { 'originalValue': [...falsyValues], 'defaultValue': 0, },
  'otherUntaxedIncomeNotReported': { 'originalValue': [...falsyValues], 'defaultValue': 0, },

  'parentCashBalance': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  
  'parentNetWorthOfInvestments': { 'originalValue': [...falsyValues], 'defaultValue': 0, },
  'parentNetWorthOfBusinesses': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  

  'didStudentCompleteTaxReturn': { 'originalValue': [...falsyValues], 'defaultValue': '', },

  'studentEarningsFromWorkNoTaxReturn': { 'originalValue': [...falsyValues], 'defaultValue': 0, },
  'studentPartnerEarningsFromWork': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  
  'studentTaxReturnType': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'studentTaxFilingStatus': { 'originalValue': [...falsyValues], 'defaultValue': '', },  

  'studentAdjustedGrossIncome': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  

  'studentDislocatedWorker': { 'originalValue': [...falsyValues], 'defaultValue': 'I_DON\'T_KNOW', },
  'didStudentFileSchedule1': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'studentBenefitMedicaid': { 'originalValue': [...falsyValues], 'defaultValue': 'No', },
  'studentBenefitSSI': { 'originalValue': [...falsyValues], 'defaultValue': 'No', },
  'studentBenefitSNAP': { 'originalValue': [...falsyValues], 'defaultValue': 'No', },
  'studentBenefitLunch': { 'originalValue': [...falsyValues], 'defaultValue': 'No', },
  'studentBenefitTANF': { 'originalValue': [...falsyValues], 'defaultValue': 'No', },
  'studentBenefitWIC': { 'originalValue': [...falsyValues], 'defaultValue': 'No', },

  'studentEarningsFromWork': { 'originalValue': [...falsyValues], 'defaultValue': 0, },    

  'studentIncomeTax': { 'originalValue': [...falsyValues], 'defaultValue': 0, },

  'studentCombatPayAmount': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  
  'studentCollegeGrantAmount': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  
  'studentTaxLine50': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  
  'studentUntaxedPortionsOfIRA': { 'originalValue': [...falsyValues], 'defaultValue': 0, },
  'studentIraDeductions': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  
  'studentTaxExemptInterestIncome': { 'originalValue': [...falsyValues], 'defaultValue': 0, },

  'studentChildSupportPaidAmount': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  
  'studentEarningsFromCoopWork': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  
  'studentEarningsFromEmploymentPrograms': { 'originalValue': [...falsyValues], 'defaultValue': 0, },

  'studentChildSupportReceivedAmount': { 'originalValue': [...falsyValues], 'defaultValue': 0, },
  'studentAllowancesAmount': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  
  'studentAmountSavedInPensions': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  
  'studentVeteranNoneducationBenefitsAmount': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  
  'didYouHaveUntaxedIncomeNotReported': { 'originalValue': [...falsyValues], 'defaultValue': 0, },
  'howMuchMoneyNotReportedOnThisForm': { 'originalValue': [...falsyValues], 'defaultValue': 0, },

  'studentCashBalance': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  
  'studentNetWorthOfInvestments': { 'originalValue': [...falsyValues], 'defaultValue': 0, },  
  'studentNetWorthOfBusinesses': { 'originalValue': [...falsyValues], 'defaultValue': 0, },

  // 2019-20 and earlier Only
  'educationLevel': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'studentIsOrWasMarried': { 'originalValue': [...falsyValues], 'defaultValue': '', },
  'otherParentEarningsFromWork': { 'originalValue': [...falsyValues], 'defaultValue': 0, },
  'workingOnDegree': {
    'originalValue': ['GRADUATE_PROFESSIONAL_OR_DOCTORAL_DEGREE'], 'defaultValue': 'Yes',
    'defaultNegativeValue': 'No',
  }
}

const getDefaultAnswer = (element, value) => {
  const defaultProps = defaultAnswers[element];
  if (defaultProps !== undefined) {
    const { originalValue = [], defaultValue, defaultNegativeValue } = defaultProps;
    if (originalValue.includes(value)) {
      return defaultValue;
    // Check "workingOnDegree" for this usecase.
    } else if(defaultNegativeValue) {
      return defaultNegativeValue;
    }
  }
  return value;
}

export default getDefaultAnswer;