import React from 'react';
import { bool } from 'prop-types';

export default WrappedComponent => {
  const WithActivation = (props) => {
    const { active } = props;

    return active ? <WrappedComponent {...props} /> : null;
  };

  WithActivation.propTypes = {
    active: bool,
  };

  WithActivation.defaultProps = {
    active: true,
  };

  return WithActivation;
}
