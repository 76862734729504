import React from 'react';
import { connect } from 'react-redux';
import { shape, func } from 'prop-types';
import { MenuItem } from 'material-ui';
import { userLogout as userLogoutAction } from 'admin-on-rest';

const Logout = ({ userLogout, style }) => (
  <MenuItem primaryText="Logout" innerDivStyle={style} onClick={userLogout} />
);

Logout.propTypes = {
  userLogout: func,
  style: shape(),
};

Logout.defaultProps = {
  userLogout: () => {},
  style: {
    fontSize: 16,
    color: '#000a46',
  },
};

const mapDispatchToProps = {
  userLogout: userLogoutAction,
};

export default connect(
  null,
  mapDispatchToProps,
)(Logout);
