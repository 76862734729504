import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'ramda';
import { arrayOf, func, shape, number, string } from 'prop-types';

import { applicationPerPageSelector } from '../../components/CustomPerPageSelect/selectors';
import DashboardNavigation from '../../Dashboard/DashboardNavigation';
import withSelectedTabName from '../../hocs/withSelectedTabName';
import StudentApplicationsList from './StudentApplicationsList';

class StudentApplicationsTabs extends PureComponent {
  static propTypes = {
    applicationsCount: arrayOf(number),
    perPage: number,
    selectedTabName: string,
    linksData: func.isRequired,
    filterPresets: shape().isRequired,
    indexLink: func.isRequired,
  };

  static defaultProps = {
    applicationsCount: 0,
    perPage: 10,
    selectedTabName: '',
  };


  render() {
    const {
      applicationsCount,
      perPage,
      selectedTabName,
      linksData,
      indexLink,
    } = this.props;

    return (
      <>
        {!selectedTabName && <Redirect to={indexLink(perPage)} />}
        <DashboardNavigation
          counters={applicationsCount}
          items={linksData(perPage)}
        />
        <StudentApplicationsList {...this.props} />
      </>
    );
  }
}

const mapStateToProps = state => ({
  perPage: applicationPerPageSelector(state),
});

export default compose(
  withSelectedTabName,
  connect(mapStateToProps),
)(StudentApplicationsTabs);
