import React from 'react';
import { string, func, shape, node } from 'prop-types';

import {
  List,
  Datagrid,
  TextField,
  EditButton,
  CreateButton,
  FunctionField,
  RefreshButton,
} from 'admin-on-rest';
import { CardActions } from 'material-ui/Card';

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

const UserActions = ({
  resource,
  filters,
  displayedFilters,
  filterValues,
  basePath,
  showFilter,
}) => (
  <CardActions style={cardActionStyle}>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton basePath={basePath} label="Invite" />
    <RefreshButton />
  </CardActions>
);

UserActions.propTypes = {
  filters: node,
  resource: string,
  displayedFilters: shape(),
  filterValues: shape(),
  basePath: string,
  showFilter: func,
};

UserActions.defaultProps = {
  filters: null,
  resource: '',
  displayedFilters: {},
  filterValues: {},
  basePath: '',
  showFilter: () => {},
};

export default props => (
  <List {...props} title="Backoffice Users" actions={<UserActions />}>
    <Datagrid>
      <TextField label="First name" source="firstName" />
      <TextField label="Last name" source="lastName" />
      <TextField source="email" />
      <TextField source="role" />
      <FunctionField
        label="Permissions"
        render={record => `${
          record && record.permissions
          && record.permissions.length > 0
          ? record.permissions.join(', ') : ''}`
        }
      />
      <EditButton />
    </Datagrid>
  </List>
);
