import React from 'react';
import { shape, string } from 'prop-types';
import { Chip } from 'material-ui';

import { frankStatusForDisplaying } from '../../enumChoices';
import withChipStyles, { getChipColor } from '../../../hocs/withChipStyles';

const FrankStatus = ({ frankStatus, styles }) => (
  <Chip
    labelStyle={styles.label}
    style={{ ...styles.chip, ...getChipColor(frankStatus) }}
    className="selectable_chip"
  >
    {frankStatusForDisplaying[frankStatus]}
  </Chip>
);

FrankStatus.propTypes = {
  frankStatus: string,
  styles: shape(),
};

FrankStatus.defaultProps = {
  frankStatus: '',
  styles: {},
};

export default withChipStyles(FrankStatus);
