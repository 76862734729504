import React, { PureComponent } from 'react';
import { string, bool } from 'prop-types';

import './style.scss'

export default class EmptyState extends PureComponent {
  static propTypes = {
    display: bool,
    title: string,
    message: string,
  };

  static defaultProps = {
    display: true,
    title: '',
    message: '',
  };

  render() {
    const { display, title, message } = this.props;

    if (!display) { return null; }

    return (
      <div className="empty-state-container">
        <div className="message">
          <h1>{title}</h1>
          <div>{message}</div>
        </div>
      </div>
    );
  }
}
