import React from 'react';

import { getChipColor } from './chipsColor';
import colors from '../../constants/colors';

const styles = {
  chip: {
    margin: 4,
    border: '1px solid',
  },
  label: {
    fontSize: '12px',
    fontWeight: '600',
    color: colors.navyBlue,
  },
  backgroundColor: colors.lighterBlue,
};

export { getChipColor };

export default Component => props => <Component styles={styles} {...props} />;
