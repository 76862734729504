import React from 'react';

/*
 This makes scrolling temporarily remove the focus from active number inputs so
 that the mouse wheel won't change the field's value.
 */
const handleWheel = () => {
  const { activeElement } = document;
  if (activeElement.tagName === 'INPUT' && activeElement.type === 'number') {
    activeElement.blur();
    setTimeout(() => {
      activeElement.focus();
    }, 400);
  }
};

const numberScrollProtector = WrappedComponent => {
  const NumberScrollProtector = (props) => (
    <div onWheel={handleWheel}>
      <WrappedComponent {...props} />
    </div>
  );

  NumberScrollProtector.defaultProps = { addField: true };

  return NumberScrollProtector;
};

export default numberScrollProtector;
