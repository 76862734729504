import React, { Fragment } from 'react';
import { node, func, oneOfType } from 'prop-types';
import { FormField } from 'admin-on-rest';

import withActivation from '../../hocs/withActivation';

const FieldsHolder = ({ children, ...props }) => {
  const processedChildren = typeof children === 'function' ? children(props) : children;

  return (
    <Fragment>
      {React.Children.map(processedChildren, (child) => (
        child ? <FormField input={child} {...props} {...child.props} /> : child
      ))}
    </Fragment>
  );
};

FieldsHolder.propTypes = {
  children: oneOfType([node, func]),
};

FieldsHolder.defaultProps = {
  children: null,
};

export default withActivation(FieldsHolder);
