import React, { PureComponent } from 'react';
import { number, string, arrayOf } from 'prop-types';
import { Redirect } from 'react-router-dom';
import { compose } from 'ramda';

import withCounter from '../../hocs/withCounter';
import withSelectedTabName from '../../hocs/withSelectedTabName';
import { PER_PAGE_ITEMS } from '../../components/CustomPerPageSelect';

import {
  linksData,
  filterPresets,
  emptyStateMessages,
  indexLink,
} from './settings';
import Dashboard from '../Dashboard';

const defaultPerPage = PER_PAGE_ITEMS[0];

class TeamLeadDashboard extends PureComponent {
  static propTypes = {
    applicationsCount: arrayOf(number),
    selectedTabName: string,
  };

  static defaultProps = {
    applicationsCount: [],
    selectedTabName: '',
  };

  render() {
    const { applicationsCount, selectedTabName } = this.props;

    return (
      <>
        {!selectedTabName && <Redirect to={indexLink(defaultPerPage)} />}
        <Dashboard
          emptyStateMessages={emptyStateMessages}
          linksData={linksData(defaultPerPage)}
          applicationsCount={applicationsCount}
          emptyStateTitle='Team Lead Dashboard'
          emptyStateMessage='Nothing to assign. Great work!'
          resource="team-lead-dashboard"
          title="Team Lead Dashboard"
          {...this.props}
        />
      </>
    )
  }
}

const filters = linksData().map(({ name }) => filterPresets[name]);

export { indexLink };

export default compose(
  withSelectedTabName,
  withCounter({ filters }),
)(TeamLeadDashboard);
