import { STUDENT } from '../Applications/ApplicationType';

export const apiBaseUrl = process.env.REACT_APP_SERVER_URL;

const doFetch = (method, path, data, token) => {
  const params = {
    method,
    headers: {
      'content-type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };

  if (data) {
    params.body = data && JSON.stringify(data);
  }

  return fetch(`${apiBaseUrl}${path}`, params);
};

const apiCall = (method, path, data, token) =>
  doFetch(method, path, data, token)
    .then(response => response.json())
    .catch(() => ({ ok: false, message: 'Internal server error' }))
    .then(json => {
      const { ok, data: respData, message } = json;
      if (ok) {
        return Promise.resolve(respData);
      }
      return Promise.reject(new Error(message));
    });

export const postFile = (path, data, token) =>
  doFetch('POST', path, data, token).then(response => {
    if (response.ok) {
      return response.blob();
    }
    throw new Error('Error fetching file');
  });
export const get = (path, token) => apiCall('GET', path, null, token);
export const post = (path, data, token) => apiCall('POST', path, data, token);
export const put = (path, data, token) => apiCall('PUT', path, data, token);

export const resourceNameFor = applicationType =>
  applicationType === STUDENT ? 'applications' : 'parents-applications';
