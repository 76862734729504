import React from 'react';
import SocialPerson from 'material-ui/svg-icons/social/person';

const AvatarPlaceHolder = () => (
  <span className="user-avatar-default">
    <SocialPerson color="#606060" />
  </span>
);

export default AvatarPlaceHolder;
