import React from 'react';
import { DisabledInput, SelectInput } from 'admin-on-rest';
import { DependentInput } from 'aor-dependent-input';

import { TaxFilingStatus, TaxReturnType } from '../../../Applications/enumChoices';
import { NumberInput } from '../../fields';
import styles from '../common-styles';
import labels from './labels';
import helperText from './helper-text';
import ParentInfo from './ParentInfo';


export default [
  <ParentInfo
    key="parentInfo"    
  />,
  <DisabledInput
    key="dependencyStatus"
    source="studentDependency"
    label="Dependency Status"
    elStyle={styles.textInput}
  />,
  <DependentInput  key="studentDependency" dependsOn="studentDependency">
    <SelectInput
      label="Filing status"
      source="parentTaxFilingStatus"
      choices={TaxFilingStatus}
      elStyle={styles.select}
      allowEmpty
    />
    <SelectInput
      label="Tax return type"
      source="parentTaxReturnType"
      choices={TaxReturnType}
      elStyle={styles.select}
      allowEmpty
    />
    <NumberInput
      source="parentAdjustedGrossIncome"
      label={labels.parentAdjustedGrossIncome}
      helperText={helperText.parentAdjustedGrossIncome}
      elStyle={styles.numberInput}
      altFieldStyles='true'
    />
    <NumberInput
      source="parentEarningsFromWork"
      label={labels.parentEarningsFromWork}
      helperText={helperText.parentEarningsFromWork}
      elStyle={styles.numberInput}
      altFieldStyles='true'
    />
    <NumberInput
      source="parentIncomeTax"
      label={labels.parentIncomeTax}
      helperText={helperText.parentIncomeTax}
      elStyle={styles.numberInput}
      altFieldStyles='true'
    />
    <NumberInput
      source="parentIraDeductions"
      label={labels.parentIraDeductions}
      helperText={helperText.parentIraDeductions}
      elStyle={styles.numberInput}
      altFieldStyles='true'
    />
    <NumberInput
      source="parentTaxExemptInterestIncome"
      label={labels.parentTaxExemptInterestIncome}
      helperText={helperText.parentTaxExemptInterestIncome}
      elStyle={styles.numberInput}
      altFieldStyles='true'
    />
    <NumberInput
      source="parentUntaxedPortionsOfIRA"
      label={labels.parentUntaxedPortionsOfIRA}
      helperText={helperText.parentUntaxedPortionsOfIRA}
      elStyle={styles.numberInput}
      altFieldStyles='true'
    />
    <NumberInput
      source="parentCashBalance"
      label={labels.parentCashBalance}
      helperText={helperText.parentCashBalance}
      elStyle={styles.numberInput}
      altFieldStyles='true'
    />
  </DependentInput>,
]
