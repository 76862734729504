import React from 'react';
import { shape } from 'prop-types';

import TaxBefore2018Fields from './TaxBefore2018Fields';
import Tax2018Fields from './Tax2018Fields';

import { isApplicationUseTax2018 } from '../../../utils/application';

const EditForm = (props) => {
  const { record } = props;

  return (
    isApplicationUseTax2018(record)
      ? <Tax2018Fields {...props} />
      : <TaxBefore2018Fields {...props} />
  );
};

EditForm.propTypes = {
  record: shape(),
};

EditForm.defaultProps = {
  record:null,
};

export default EditForm;
