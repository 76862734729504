import React, { memo } from 'react';
import { string, oneOfType, number } from 'prop-types';
import { Link } from 'react-router-dom';

import DescriptionList from '../DescriptionList';
import Card from '../CustomCard/Card';
import FrankStatus from '../../Applications/StudentApplications/StudentApplicationsList/FrankStatus';
import FafsaStatus from '../../Applications/StudentApplications/StudentApplicationsList/FafsaStatus';
import { date } from '../../utils/formatter';
import { DATE_FORMAT_1 } from '../../constants/date-formats';

const getTimestampsData = ({
  createdAt,
  studentConfirmed,
  parentApplicationLink,
  parentConfirmed,
  updatedAt,
}) => [
  {
    label: 'Application Created',
    value: createdAt ? date(createdAt, DATE_FORMAT_1) : <span>&mdash;</span>,
  },
  {
    label: 'Student Submitted',
    value: studentConfirmed? date(studentConfirmed, DATE_FORMAT_1) : <span>&mdash;</span>,
  },
  {
    label: (
      <>
        Parent Submitted
        <div>
          <Link
            className="description-list__link"
            to={parentApplicationLink}
          >
            Parent application
          </Link>
        </div>
      </>
    ),
    value: parentConfirmed? date(parentConfirmed, DATE_FORMAT_1) : <span>&mdash;</span>,
  },
  {
    label: 'Last Updated',
    value: updatedAt? date(updatedAt, DATE_FORMAT_1) : <span>&mdash;</span>,
  },
];

const getStatusData = ({
  applicationId,
  frankStatus,
  fafsaStatus,
  submitterLog,
}) => [
  {
    label: 'Frank status',
    value: <FrankStatus frankStatus={frankStatus} />,
  },
  {
    label: 'FAFSA® status',
    value: <FafsaStatus fafsaStatus={fafsaStatus} />,
  },
  {
    label: 'Submitter log',
    value: submitterLog,
  },
  {
    label: 'Application ID',
    value: applicationId,
  },
];

const ApplicationStatusCard = props => {
  const {
    createdAt,
    updatedAt,
    parentApplicationLink,
    studentConfirmed,
    parentConfirmed,
    applicationId,
    frankStatus,
    fafsaStatus,
    submitterLog,
  } = props;

  return (
    <Card title="Application Status">
      <div className="description-section">
        <div className="description-section__item">
          <DescriptionList
            title="Updated"
            data={getTimestampsData({
              createdAt,
              updatedAt,
              parentApplicationLink,
              studentConfirmed,
              parentConfirmed,
            })}
          />
        </div>
        <div className="description-section__item">
          <DescriptionList
            title="Status"
            data={getStatusData({
              applicationId,
              frankStatus,
              fafsaStatus,
              submitterLog,
            })}
          />
        </div>
      </div>
    </Card>
  );
};

ApplicationStatusCard.propTypes = {
  createdAt: string.isRequired,
  updatedAt: string.isRequired,
  parentApplicationLink: string,
  studentConfirmed: string,
  parentConfirmed: string,
  applicationId: oneOfType([string, number]).isRequired,
  frankStatus: string.isRequired,
  fafsaStatus: string,
  submitterLog: string,
};

ApplicationStatusCard.defaultProps = {
  parentApplicationLink: null,
  studentConfirmed: null,
  parentConfirmed: null,
  fafsaStatus: null,
  submitterLog: null,
};

export default memo(ApplicationStatusCard);
